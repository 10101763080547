import { Action } from '@ngrx/store';
import { ProfileResponse } from '../../../models';
import { RegisterSecurityForm } from '../../../models/register/registerSecurity.model';
import { User } from '../../../models/user/user.model';
import { UserApiResponse } from '../../../models/user/userApi-response';
import { type } from '../../../utility';

export const ActionTypes = {
    LOGIN_USER: type('[User] Login'),
    LOGIN_USER_SUCCESS: type('[User] Login Success'),
    LOGIN_USER_CHALLENGE: type('[User] Login Challenge'),
    LOGIN_USER_CHALLENGE_RETRY: type('[User] Login Challenge Retry'),
    LOGIN_USER_SUBMIT_CHALLENGE: type('[User] Login Submit Challenge'),
    LOGIN_USER_SUBMIT_CHALLENGE_SUCCESS: type('[User] Login Submit Challenge Success'),
    LOGIN_USER_SUBMIT_CHALLENGE_FAIL: type('[User] Login Submit Challenge Fail'),
    LOGIN_USER_FAIL: type('[User] Login Fail'),
    LOGIN_RESET_PASSWORD: type('[User] Login Reset Password'),
    LOGIN_RESET_PASSWORD_SUBMIT: type('[User] Login Reset Password Submit'),
    LOGIN_RESET_PASSWORD_SUBMIT_SUCCESS: type('[User] Login Reset Password Submit Success'),
    LOGIN_RESET_PASSWORD_SUBMIT_FAIL: type('[User] Login Reset Password Submit Fail'),
    LOGIN_REG_VERIFY: type('[User] Login Verify'),
    LOGIN_REG_VERIFY_SUBMIT: type('[User] Login Verify Submit'),
    LOGIN_REG_VERIFY_SUBMIT_SUCCESS: type('[User] Login Verify Submit Success'),
    LOGIN_REG_VERIFY_SUBMIT_FAIL: type('[User] Login Verify Submit Fail'),

    LOGIN_QUESTIONS: type('[User] Login Registration Questions'),
    LOGIN_QUESTIONS_SUBMIT: type('[User] Login Registration Questions Submit'),
    LOGIN_QUESTIONS_SUBMIT_SUCCESS: type('[User] Login Registration Questions Submit Success'),
    LOGIN_QUESTIONS_SUBMIT_FAIL: type('[User] Login Registration Questions Submit Fail'),

    FORGOT_PASSWORD: type('[User] Forgot Password'),
    FORGOT_PASSWORD_SUCCESS: type('[User] Forgot Password Success'),
    FORGOT_PASSWORD_FAIL: type('[User] Forgot Password Fail'),
    FORGOT_USERNAME: type('[User] Forgot Username'),
    FORGOT_USERNAME_SUCCESS: type('[User] Forgot Username Success'),
    FORGOT_USERNAME_FAIL: type('[User] Forgot Username Fail'),

    RECOVER_PWD_INIT: type('[User] Recover Password Init'),
    RECOVER_PWD_INIT_SUCCESS: type('[User] Recover Password Init Success'),
    RECOVER_PWD_INIT_FAIL: type('[User] Recover Password Init Fail'),

    RECOVER_PWD_AUTH: type('[User] Recover Password Authorize'),
    RECOVER_PWD_AUTH_RETRY: type('[User] Recover Password Authorize Retry'),
    RECOVER_PWD_AUTH_SUCCESS: type('[User] Recover Password Authorize Success'),
    RECOVER_PWD_AUTH_FAIL: type('[User] Recover Password Authorize Fail'),

    RECOVER_PWD_COMPLETE: type('[User] Recover Password Complete'),
    RECOVER_PWD_COMPLETE_SUCCESS: type('[User] Recover Password Complete Success'),
    RECOVER_PWD_COMPLETE_FAIL: type('[User] Recover Password Complete Fail'),

    LOGIN_RESET_SECURITY_SUBMIT: type('[User] Login Reset Security Submit'),
    LOGIN_RESET_SECURITY_SUBMIT_SUCCESS: type('[User] Login Reset Security Submit Success'),
    LOGIN_RESET_SECURITY_SUBMIT_FAIL: type('[User] Login Reset Security Submit Fail'),
};

/**
 * User Actions
 */

export class LoginUserAction implements Action {
    public type = ActionTypes.LOGIN_USER;
    constructor(public payload: User = null) {}
}

export class LoginUserSuccessAction implements Action {
    public type = ActionTypes.LOGIN_USER_SUCCESS;
    constructor(public payload: User = null) {}
}

export class LoginUserFailAction implements Action {
    public type = ActionTypes.LOGIN_USER_FAIL;
    constructor(public payload: any = null) {}
}

export class LoginUserChallengeAction implements Action {
    public type = ActionTypes.LOGIN_USER_CHALLENGE;
    constructor(public payload: UserApiResponse = null) {}
}
export class LoginUserChallengeRetryAction implements Action {
    public type = ActionTypes.LOGIN_USER_CHALLENGE_RETRY;
    constructor(public payload: UserApiResponse = null) {}
}

export class LoginUserSubmitChallengeAction implements Action {
    public type = ActionTypes.LOGIN_USER_SUBMIT_CHALLENGE;
    constructor(public payload: any = null) {}
}
export class LoginUserSubmitChallengeSuccessAction implements Action {
    public type = ActionTypes.LOGIN_USER_SUBMIT_CHALLENGE_SUCCESS;
    constructor(public payload: UserApiResponse = null) {}
}
export class LoginUserSubmitChallengeFailAction implements Action {
    public type = ActionTypes.LOGIN_USER_SUBMIT_CHALLENGE_FAIL;
    constructor(public payload: any = null) {}
}

export class LoginResetPasswordAction implements Action {
    public type = ActionTypes.LOGIN_RESET_PASSWORD;
    constructor(public payload: UserApiResponse = null) {}
}
export class LoginResetPasswordSubmitAction implements Action {
    public type = ActionTypes.LOGIN_RESET_PASSWORD_SUBMIT;
    constructor(public payload: string = null) {}
}
export class LoginResetPasswordSubmitSuccessAction implements Action {
    public type = ActionTypes.LOGIN_RESET_PASSWORD_SUBMIT_SUCCESS;
    constructor(public payload: UserApiResponse = null) {}
}
export class LoginResetPasswordSubmitFailAction implements Action {
    public type = ActionTypes.LOGIN_RESET_PASSWORD_SUBMIT_FAIL;
    constructor(public payload: any = null) {}
}

export class LoginVerifyAction implements Action {
    public type = ActionTypes.LOGIN_REG_VERIFY;
    constructor(public payload: UserApiResponse = null) {}
}
export class LoginVerifySubmitAction implements Action {
    public type = ActionTypes.LOGIN_REG_VERIFY_SUBMIT;
    constructor(public payload: string = null) {}
}
export class LoginVerifySubmitSuccessAction implements Action {
    public type = ActionTypes.LOGIN_REG_VERIFY_SUBMIT_SUCCESS;
    constructor(public payload: any = null) {}
}
export class LoginVerifySubmitFailAction implements Action {
    public type = ActionTypes.LOGIN_REG_VERIFY_SUBMIT_FAIL;
    constructor(public payload: any = null) {}
}

export class LoginQuestionsAction implements Action {
    public type = ActionTypes.LOGIN_QUESTIONS;
    constructor(public payload: ProfileResponse = null) {}
}
export class LoginQuestionsSubmitAction implements Action {
    public type = ActionTypes.LOGIN_QUESTIONS_SUBMIT;
    constructor(public payload: RegisterSecurityForm = null) {}
}
export class LoginQuestionsSubmitSuccessAction implements Action {
    public type = ActionTypes.LOGIN_QUESTIONS_SUBMIT_SUCCESS;
    constructor(public payload: any = null) {}
}
export class LoginQuestionsSubmitFailAction implements Action {
    public type = ActionTypes.LOGIN_QUESTIONS_SUBMIT_FAIL;
    constructor(public payload: any = null) {}
}

export class ForgotPasswordAction implements Action {
    public type = ActionTypes.FORGOT_PASSWORD;
    constructor(public payload: any = null) {}
}
export class ForgotPasswordSuccessAction implements Action {
    public type = ActionTypes.FORGOT_PASSWORD_SUCCESS;
    constructor(public payload: UserApiResponse = null) {}
}
export class ForgotPasswordFailAction implements Action {
    public type = ActionTypes.FORGOT_PASSWORD_FAIL;
    constructor(public payload: any = null) {}
}

export class ForgotUsernameAction implements Action {
    public type = ActionTypes.FORGOT_USERNAME;
    constructor(public payload: any = null) {}
}
export class ForgotUsernameSuccessAction implements Action {
    public type = ActionTypes.FORGOT_USERNAME_SUCCESS;
    constructor(public payload: UserApiResponse = null) {}
}
export class ForgotUsernameFailAction implements Action {
    public type = ActionTypes.FORGOT_USERNAME_FAIL;
    constructor(public payload: any = null) {}
}

export class RecoverPasswordInitAction implements Action {
    public type = ActionTypes.RECOVER_PWD_INIT;
    constructor(public payload: string = null) {}
}
export class RecoverPasswordInitSuccessAction implements Action {
    public type = ActionTypes.RECOVER_PWD_INIT_SUCCESS;
    constructor(public payload: UserApiResponse = null) {}
}
export class RecoverPasswordInitFailAction implements Action {
    public type = ActionTypes.RECOVER_PWD_INIT_FAIL;
    constructor(public payload: any = null) {}
}

export class RecoverPasswordAuthAction implements Action {
    public type = ActionTypes.RECOVER_PWD_AUTH;
    constructor(public payload: string = null) {}
}
export class RecoverPasswordAuthSuccessAction implements Action {
    public type = ActionTypes.RECOVER_PWD_AUTH_SUCCESS;
    constructor(public payload: any = null) {}
}
export class RecoverPasswordAuthRetryAction implements Action {
    public type = ActionTypes.RECOVER_PWD_AUTH_RETRY;
    constructor(public payload: any = null) {}
}
export class RecoverPasswordAuthFailAction implements Action {
    public type = ActionTypes.RECOVER_PWD_AUTH_FAIL;
    constructor(public payload: any = null) {}
}

export class RecoverPasswordCompleteAction implements Action {
    public type = ActionTypes.RECOVER_PWD_COMPLETE;
    constructor(public payload: string = null) {}
}
export class RecoverPasswordCompleteSuccessAction implements Action {
    public type = ActionTypes.RECOVER_PWD_COMPLETE_SUCCESS;
    constructor(public payload: any = null) {}
}
export class RecoverPasswordCompleteFailAction implements Action {
    public type = ActionTypes.RECOVER_PWD_COMPLETE_FAIL;
    constructor(public payload: any = null) {}
}

export class LoginResetSecuritySubmitAction implements Action {
    public type = ActionTypes.LOGIN_RESET_SECURITY_SUBMIT;
    constructor(public payload: string = null) {}
}
export class LoginResetSecuritySubmitSuccessAction implements Action {
    public type = ActionTypes.LOGIN_RESET_SECURITY_SUBMIT_SUCCESS;
    constructor(public payload: UserApiResponse = null) {}
}
export class LoginResetSecuritySubmitFailAction implements Action {
    public type = ActionTypes.LOGIN_RESET_SECURITY_SUBMIT_FAIL;
    constructor(public payload: any = null) {}
}

export type Actions =
    | LoginUserAction
    | LoginUserSuccessAction
    | LoginUserChallengeAction
    | LoginUserFailAction
    | LoginUserSubmitChallengeAction
    | LoginUserSubmitChallengeSuccessAction
    | LoginUserSubmitChallengeFailAction
    | ForgotPasswordAction
    | ForgotPasswordSuccessAction
    | ForgotPasswordFailAction
    | ForgotUsernameAction
    | ForgotUsernameSuccessAction
    | ForgotUsernameFailAction
    | RecoverPasswordInitAction
    | RecoverPasswordInitSuccessAction
    | RecoverPasswordInitFailAction
    | RecoverPasswordAuthAction
    | RecoverPasswordAuthSuccessAction
    | RecoverPasswordAuthFailAction
    | RecoverPasswordCompleteAction
    | RecoverPasswordCompleteSuccessAction
    | RecoverPasswordCompleteFailAction
    | LoginResetSecuritySubmitAction
    | LoginResetSecuritySubmitSuccessAction
    | LoginResetSecuritySubmitFailAction
;
