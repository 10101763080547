import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoginComponent } from '../../../user/login/login.component';
import { AuthSandbox } from './auth.sandbox';

@Injectable()
export class AuthGuard implements CanActivate, CanDeactivate<LoginComponent> {
    constructor(private router: Router, private authSandbox: AuthSandbox) {}

    public canActivate(): Observable<boolean> {
        return this.authSandbox.isUserLoggedIn$.pipe(
            tap(userLoggedIn => {
                if (!userLoggedIn) {
                    this.authSandbox.reroute({ path: ['/login'] });
                }
            })
        );
    }

    public canDeactivate(): Observable<boolean> {
        return this.authSandbox.isUserLoggedIn$.pipe(
            tap(userLoggedIn => {
                if (userLoggedIn) {
                    this.authSandbox.reroute({ path: ['/policy'] });
                }
            }),
            map(userLoggedIn => !userLoggedIn)
        );
    }
}
