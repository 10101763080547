export class ClaimsApiResponse {
    public ok:    boolean;
    public id:  string;
    public rev:  string;
    public claimNbr:  string;
    public policyNbr:  string;
    public unitNbr: string;
    public claimType: string;
    public created:  string;
    public modified: string;
    public completed: string;
    public processType: string;

    constructor(init: Partial<ClaimsApiResponse>) {
      if (!init) {init = {}; }
      this.ok   = init.ok || null;
      this.id = init.id || null;
      this.rev = init.rev || null;
      this.claimNbr = init.claimNbr || null;
      this.policyNbr = init.policyNbr || null;
      this.created = init.created || null;
      this.modified = init.modified || null;
      this.completed = init.completed || null;
      this.processType = init.processType || null;
    }
  }
