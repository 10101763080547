import { Action } from '@ngrx/store';
import { DueAmountsApiResponse, TcReceiptData } from '../../../models';
import { PolicySearchForm } from '../../../models/pay-my-bill/policy-search.model';
import { type } from '../../../utility';

// tslint:disable-next-line:variable-name
export const ActionTypes = {
    POLICY_SEARCH: type('[PayMyBill] Policy Search'),
    POLICY_SEARCH_SUCCESS: type('[PayMyBill] Policy Search Success'),
    POLICY_SEARCH_FAIL: type('[PayMyBill] Policy Search Fail'),

    RECEIPT_TRIGGERED: type('[PayMyBill] Receipt Triggered'),
    TCP_POST_PAYMENT: type('[PayMyBill] Post Payment to Billing')
};

/**
 * User Actions
 */

export class PolicySearchAction implements Action {
    public type = ActionTypes.POLICY_SEARCH;
    constructor(public payload: PolicySearchForm = null) {}
}

export class PolicySearchSuccessAction implements Action {
    public type = ActionTypes.POLICY_SEARCH_SUCCESS;
    constructor(public payload: DueAmountsApiResponse = null) {}
}

export class PolicySearchFailAction implements Action {
    public type = ActionTypes.POLICY_SEARCH_FAIL;
    constructor(public payload: any = null) {}
}

export class ReceiptTriggeredAction implements Action {
    public type = ActionTypes.RECEIPT_TRIGGERED;
    constructor(public payload: any = null) {}
}

export class TcpPostPaymentAction implements Action {
    public type = ActionTypes.TCP_POST_PAYMENT;
    constructor(public payload: TcReceiptData = null) {}
}


export type Actions = PolicySearchAction | PolicySearchSuccessAction | PolicySearchFailAction | ReceiptTriggeredAction | TcpPostPaymentAction;
