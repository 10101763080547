export class DriverHistoryForm {
    public sameHouse: boolean;
    public continuousCoverage: string;
    public revoked: boolean;
    public revokedYear: number;
    public revokedReason: string;
    public violation: boolean;
    public stolen: boolean;
    public accident: boolean;
    public insuranceCancelled: boolean;

    constructor(init?: Partial<DriverHistoryForm>) {
        Object.assign(this, init);
    }
}
