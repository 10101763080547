import { NavigationExtras } from '@angular/router';
import { Action } from '@ngrx/store';
import { type } from '../../../utility';

// tslint:disable-next-line:variable-name
export const ActionTypes = {
  GO: type('[Router] Go'),
  BACK: type('[Router] Back'),
  FORWARD: type('[Router] Forward'),
  CHANGE: type('[Router] Route Change'),
  ROUTE_LAZY_LOAD_START: type('[Router] Route Lazy Load Start'),
  ROUTE_LAZY_LOAD_END: type('[Router] Route Lazy Load End')
};

/**
 * Policies Actions
 */

export class RouterGo implements Action {
  public readonly type = ActionTypes.GO;

  constructor(
    public payload: {
      path: any[];
      queryParams?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

export class RouterBack implements Action {
  public readonly type = ActionTypes.BACK;
}

export class RouterForward implements Action {
  public readonly type = ActionTypes.FORWARD;
}

export class RouteChange implements Action {
  public readonly type = ActionTypes.CHANGE;
  constructor(public payload: { params: any; path: string }) {}
}

export class RouteLazyLoadStart implements Action {
  public readonly type = ActionTypes.ROUTE_LAZY_LOAD_START;
}

export class RouteLazyLoadEnd implements Action {
  public readonly type = ActionTypes.ROUTE_LAZY_LOAD_END;
}

export type Actions =
  | RouterGo
  | RouterBack
  | RouterForward
  | RouteChange
  | RouteLazyLoadStart
  | RouteLazyLoadEnd;
