import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TabLayout } from '../../models';
import { LayoutSandbox } from './layout.sandbox';

@Component({
    selector: 'app-layout',
    styleUrls: ['./layout.container.scss'],
    templateUrl: './layout.container.html'
})
// tslint:disable-next-line:component-class-suffix
export class LayoutContainer implements OnInit, OnDestroy {
    public tabLayout: TabLayout;
    private tabsSub: Subscription;

    constructor(public layoutSandbox: LayoutSandbox) {}

    public ngOnDestroy(): void {
        this.tabsSub.unsubscribe();
    }

    public ngOnInit(): void {
        this.tabsSub = this.layoutSandbox.$tabLayout.subscribe(tabs => (this.tabLayout = tabs));
    }
}
