import { Component, Input, OnInit } from '@angular/core';
import { PolicyBase } from '../../../shared/models';

@Component({
  selector: 'app-policy-coverage',
  templateUrl: './policy-coverage.component.html'
})
export class PolicyCoverageComponent implements OnInit {

    @Input() public policy: PolicyBase;

    constructor() { }

    public ngOnInit() {
    }

}
