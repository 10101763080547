import { ContactMechanism } from '../claims';

export class Agent {
    public mutualNbrs: string[];
    public lifeNbr: string;
    public homepageUrl: string;
    public pictureUrl: string;
    public longName: string;
    public clientId: string;
    public contactList: ContactMechanism;
    public firstName: string;
    public lastName: string;
    public userId: string;
    public titleCd: string;
    public titleDesc: string;
    public officeNbrs: string[];

    constructor(init?: Partial<Agent>) {
        Object.assign(this, init);
    }
}
