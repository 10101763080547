import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CancelReason, VehicleDeleteReason } from '../../models';
import { AutoService } from '../adapters';
import { Adapter, Body, GET, HttpService, Path, POST, PUT } from '../http';

@Injectable()
export class AutoApiClient extends HttpService {
    @POST('auto/change/{policyNbr}/driver',false )
    // @Adapter(AutoService.policiesAdapter)
    public submitDriver(@Body form: any, @Path('policyNbr') policyNbr: string): Observable<any> {
        return null;
    }

    @POST('auto/change/{policyNbr}/complete',false)
    public confirmUpdate(@Body form: any, @Path('policyNbr') policyNbr: string): Observable<any> {
        return null;
    }

    @GET('auto/lookup/vehicle/vin/{vin}',false)
    public lookupVin(@Path('vin') vin: string): Observable<any> {
        return null;
    }

    @GET('auto/support/vehtypes?cps=true',false)
    public getVehicleTypes(): Observable<any> {
        return null;
    }

    @GET('auto/lookup/vehicle/{type}/year',false)
    public getVehicleYears(@Path('type') type: string): Observable<any> {
        return null;
    }

    @GET('auto/lookup/vehicle/{type}/{year}/make',false)
    public getVehicleMakes(@Path('type') type: string, @Path('year') year: string): Observable<any> {
        return null;
    }

    @GET('auto/lookup/vehicle/{type}/{year}/{make}/model',false)
    public getVehicleModels(@Path('type') type: string, @Path('year') year: string, @Path('make') make: string): Observable<any> {
        return null;
    }

    @GET('auto/support/coverages/{policyEffDate}',false)
    @Adapter(AutoService.coverageAdapter)
    public getCoverages(@Path('policyEffDate') policyEffDate: string): Observable<any> {
        return null;
    }

    @GET('auto/support/milesdriven',false)
    public getMilesDriven(): Observable<any> {
        return null;
    }

    @POST('auto/change/{policyNbr}/vehicle',false)
    public submitAddVehicle(@Body form: any, @Path('policyNbr') policyNbr: string): Observable<any> {
        return null;
    }

    @POST('auto/change/{policyNbr}/driver/vehicle',false)
    public submitDriverAndVehicle(@Body form: any, @Path('policyNbr') policyNbr: string): Observable<any> {
        return null;
    }

    @GET('auto/support/reasonvehicledelete',false)
    public getVehicleDeleteReasons(): Observable<VehicleDeleteReason[]> {
        return null;
    }

    @POST('auto/change/{policyNbr}/vehicle/{vin}',false)
    public submitDeleteVehicle(@Body form: any, @Path('policyNbr') policyNbr: string, @Path('vin') vin: string): Observable<any> {
        return null;
    }

    @PUT('auto/change/{policyNbr}/vehicle/{vin}',false)
    public submitReplaceVehicle(@Body form: any, @Path('policyNbr') policyNbr: string, @Path('vin') vin: string): Observable<any> {
        return null;
    }

    @GET('auto/support/reasoncancelpolicy',false)
    public getCancelReasons(): Observable<CancelReason[]> {
        return null;
    }

    @PUT('auto/change/{policyNbr}/vehicle/{vin}/coverage',false)
    public submitIncreaseDed(@Body form: any, @Path('policyNbr') policyNbr: string, @Path('vin') vin: string): Observable<any> {
        return null;
    }

    @POST('auto/change/{policyNbr}/vehicle/{vin}/coverage',false)
    public submitRemoveCoverage(@Body form: any, @Path('policyNbr') policyNbr: string, @Path('vin') vin: string): Observable<any> {
        return null;
    }

    @PUT('auto/change/{policyNbr}/vehicle/{vin}/lienholder',false)
    public submitEditLienholder(@Body form: any, @Path('policyNbr') policyNbr: string, @Path('vin') vin: string): Observable<any> {
        return null;
    }

    @POST('auto/change/{policyNbr}/vehicle/{vin}/lienholder',false)
    public submitAddLienholder(@Body form: any, @Path('policyNbr') policyNbr: string, @Path('vin') vin: string): Observable<any> {
        return null;
    }

    @POST('auto/change/{policyNbr}/vehicle/{vin}/lienholder/delete',false)
    public submitRemoveLienholder(@Body form: any, @Path('policyNbr') policyNbr: string, @Path('vin') vin: string): Observable<any> {
        return null;
    }

    @POST('auto/change/{policyNbr}',false)
    public submitCancelPolicy(@Body form: any, @Path('policyNbr') policyNbr: string): Observable<any> {
        return null;
    }
}
