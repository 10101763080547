import { AutoLienholderApiRequest } from "./autoLienholderApi-request";

export class AutoEditLienholderApiRequest {
    public customerId: string;
	public newLienHolder: AutoLienholderApiRequest;
    public currentLienHolder: AutoLienholderApiRequest;

    constructor(init?: Partial<AutoEditLienholderApiRequest>) {
        Object.assign(this, init);
    }
}
