import { Injectable } from '@angular/core';
import { Http, Request } from '@angular/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpAdapter } from './http.adapter';
import { HttpResponseHandler } from './httpResponseHandler.service';

/**
 * Supported @Produces media types
 */
export enum MediaType {
    JSON,
    FORM_DATA
}

@Injectable()
export class HttpService {
    public constructor(protected http: Http, protected responseHandler: HttpResponseHandler) {}

    protected getBaseUrl(): string {
        return environment.api.baseUrl;
    }

    // protected getClaimBaseUrl(): string {
    //     return environment.claimCC.baseUrl;
    // }

    protected getUseWithCredentials(): boolean {
        return environment.api.withCredentials;
    }

    protected getDefaultHeaders(): Object {
        return null;
    }

    /**
     * Request Interceptor
     *
     * @method requestInterceptor
     * @param {Request} req - request object
     */
    protected requestInterceptor(req: Request) {
        // req.withCredentials = true;
    }

    /**
     * Response Interceptor
     *
     * @method responseInterceptor
     * @param {Response} observableRes - response object
     * @returns {Response} res - transformed response object
     */
    protected responseInterceptor(observableRes: Observable<any>, adapterFn?: Function): Observable<any> {
        return observableRes.pipe(
            map(res => HttpAdapter.baseAdapter(res, adapterFn)),
            catchError((err, source) => this.responseHandler.onCatch(err, source))
        );
    }
}
