import { Actions, ActionTypes } from '../../actions/router/router.action';

export interface RouterState {
  lazyLoading: boolean;
}

const INITIAL_STATE: RouterState = {
  lazyLoading: false
};

//  Reducers specify how the application’s state changes in response to actions.
export function routerReducer(
  state = INITIAL_STATE,
  action: Actions
): RouterState {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case ActionTypes.ROUTE_LAZY_LOAD_START: {
      return {
        ...state,
        lazyLoading: true
      };
    }

    case ActionTypes.ROUTE_LAZY_LOAD_END: {
      return {
        ...state,
        lazyLoading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const routerDataLoading = (state: RouterState) => state.lazyLoading;
