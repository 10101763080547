import { Action } from '@ngrx/store';
import { DeviceInfo } from '../../../models';
import { ProfileResponse } from '../../../models/register/profile-response.model';
import { RegisterAccountForm } from '../../../models/register/registerAccount.model';
import { RegisterPolicyForm } from '../../../models/register/registerPolicy.model';
import { RegisterSecurityForm } from '../../../models/register/registerSecurity.model';
import { ValidationResponse } from '../../../models/register/validation-response.model';
import { type } from '../../../utility';


// tslint:disable-next-line:variable-name
const RegisterActionTypes = {
    SUBMIT_POLICY: type('[Register] Submit Policy'),
    SUBMIT_POLICY_SUCCESS: type('[Register] Submit Policy Success'),
    SUBMIT_POLICY_FAIL: type('[Register] Submit Policy Fail'),

    SUBMIT_ACCOUNT: type('[Register] Submit Account'),
    SUBMIT_ACCOUNT_SUCCESS: type('[Register] Submit Account Success'),
    SUBMIT_ACCOUNT_FAIL: type('[Register] Submit Account Fail'),

    SUBMIT_SECURITY: type('[Register] Submit Security'),
    SUBMIT_SECURITY_SUCCESS: type('[Register] Submit Security Success'),
    SUBMIT_SECURITY_FAIL: type('[Register] Submit Security Fail'),

    SUBMIT_VERIFICATION: type('[Register] Submit Verification'),
    SUBMIT_VERIFICATION_SUCCESS: type('[Register] Submit Verification Success'),
    SUBMIT_VERIFICATION_FAIL: type('[Register] Submit Verification Fail')
};


// tslint:disable-next-line:variable-name
export const ActionTypes = {
    ...RegisterActionTypes
};

/**
 * General Claims API Actions
 */

export class SubmitPolicyAction implements Action {
    public type = ActionTypes.SUBMIT_POLICY;
    constructor(public payload: RegisterPolicyForm = null) { }
}
export class SubmitPolicySuccessAction implements Action {
    public type = ActionTypes.SUBMIT_POLICY_SUCCESS;
    constructor(public payload: ValidationResponse = null) { }
}
export class SubmitPolicyFailAction implements Action {
    public type = ActionTypes.SUBMIT_POLICY_FAIL;
    constructor(public payload: any = null) { }
}

export class SubmitAccountAction implements Action {
    public type = ActionTypes.SUBMIT_ACCOUNT;
    constructor(public payload: RegisterAccountForm = null) { }
}
export class SubmitAccountSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_ACCOUNT_SUCCESS;
    constructor(public payload: ProfileResponse = null) { }
}
export class SubmitAccountFailAction implements Action {
    public type = ActionTypes.SUBMIT_ACCOUNT_FAIL;
    constructor(public payload: any = null) { }
}

export class SubmitSecurityAction implements Action {
    public type = ActionTypes.SUBMIT_SECURITY;
    constructor(public payload: RegisterSecurityForm = null) { }
}
export class SubmitSecuritySuccessAction implements Action {
    public type = ActionTypes.SUBMIT_SECURITY_SUCCESS;
    constructor(public payload: DeviceInfo = null) { }
}
export class SubmitSecurityFailAction implements Action {
    public type = ActionTypes.SUBMIT_SECURITY_FAIL;
    constructor(public payload: any = null) { }
}

export class SubmitVerificationAction implements Action {
    public type = ActionTypes.SUBMIT_VERIFICATION;
    constructor(public payload: string = null) { }
}
export class SubmitVerificationSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_VERIFICATION_SUCCESS;
    constructor(public payload: DeviceInfo = null) { }
}
export class SubmitVerificationFailAction implements Action {
    public type = ActionTypes.SUBMIT_VERIFICATION_FAIL;
    constructor(public payload: any = null) { }
}

type RegisterClaimActions
    = SubmitPolicyAction
    | SubmitPolicySuccessAction
    | SubmitPolicyFailAction
    | SubmitAccountAction
    | SubmitAccountSuccessAction
    | SubmitAccountFailAction
    | SubmitSecurityAction
    | SubmitSecuritySuccessAction
    | SubmitSecurityFailAction
    | SubmitVerificationAction
    | SubmitVerificationSuccessAction
    | SubmitVerificationFailAction
    ;

export type Actions
    = RegisterClaimActions
    ;
