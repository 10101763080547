import { ContactMechanism, TemporaryContactMechanism } from './contact-mechanism.model';

export class ClaimsContactForm {
    public contactList: ContactMechanism[];
    public contactTempList: TemporaryContactMechanism[];

    constructor(init?: Partial<ClaimsContactForm>) {
        if (!init) {
            init = {};
        }
        this.contactList = init.contactList || new Array<ContactMechanism>();
        this.contactTempList = init.contactTempList || new Array<TemporaryContactMechanism>();
    }
}
