import { Action } from '@ngrx/store';
import { County } from '../../..//models/claims/county.model';
import { GenericClaim } from '../../..//models/claims/genericClaim.model';
import { Vendor } from '../../..//models/claims/vendor.model';
import { type } from '../../..//utility';
import { ClaimsApiRequest } from '../../../models';
import { ClaimsSupport } from '../../../models/claims/claims-support.model';
import { CcDetail } from 'src/app/shared/models/claims/genericGWCC-claim.model';

// tslint:disable-next-line:variable-name
export const ActionTypes = {
    LOAD_VEHICLE_COLORS: type('[Claims Support] Load Vehicle Colors'),
    LOAD_VEHICLE_COLORS_SUCCESS: type('[Claims Support] Load Vehicle Colors Success'),
    LOAD_VEHICLE_COLORS_FAIL: type('[Claims Support] Load Vehicle Colors Fail'),
    RELOAD_VEHICLE_COLORS: type('[Claims Support] Loaded Vehicle Colors Needs Reloaded'),

    LOAD_GLASS_VENDORS_BY_COUNTY: type('[Claims Support] Load Glass Vendors By County'),
    LOAD_GLASS_VENDORS_BY_COUNTY_SUCCESS: type('[Claims Support] Load Glass Vendors By County Success'),
    LOAD_GLASS_VENDORS_BY_COUNTY_FAIL: type('[Claims Support] Load Glass Vendors By County Fail'),

    LOAD_COUNTIES: type('[Claims Support] Load Counties'),
    LOAD_COUNTIES_SUCCESS: type('[Claims Support] Load Counties Success'),
    LOAD_COUNTIES_FAIL: type('[Claims Support] Load Counties Fail'),
    RELOAD_COUNTIES: type('[Claims Support] Loaded Counties Needs Reloaded'),

    LOAD_AUTO_DAMAGE_LOCATIONS: type('[Claims Support] Load Auto Damage Locations'),
    LOAD_AUTO_DAMAGE_LOCATIONS_SUCCESS: type('[Claims Support] Load Auto Damage Locations Success'),
    LOAD_AUTO_DAMAGE_LOCATIONS_FAIL: type('[Claims Support] Load Auto Damage Locations Fail'),
    RELOAD_AUTO_DAMAGE_LOCATIONS: type('[Claims Support] Loaded Auto Damage Locations Needs Reloaded'),

    LOAD_ALL_DAMAGE_LOCATIONS: type('[Claims Support] Load All Damage Locations'),
    LOAD_ALL_DAMAGE_LOCATIONS_SUCCESS: type('[Claims Support] Load All Damage Locations Success'),
    LOAD_ALL_DAMAGE_LOCATIONS_FAIL: type('[Claims Support] Load All Damage Locations Fail'),
    RELOAD_ALL_DAMAGE_LOCATIONS: type('[Claims Support] Loaded All Damage Locations Needs Reloaded'),

    LOAD_GLASS_DAMAGE_LOCATIONS: type('[Claims Support] Load Glass Damage Locations'),
    LOAD_GLASS_DAMAGE_LOCATIONS_SUCCESS: type('[Claims Support] Load Glass Damage Locations Success'),
    LOAD_GLASS_DAMAGE_LOCATIONS_FAIL: type('[Claims Support] Load Glass Damage Locations Fail'),
    RELOAD_GLASS_DAMAGE_LOCATIONS: type('[Claims Support] Loaded Glass Damage Locations Needs Reloaded'),

    LOAD_CLAIMS_HISTORY: type('[Claims Support] Load Claims History'),
    LOAD_CLAIMS_HISTORY_SUCCESS: type('[Claims Support] Load Claims History Success'),
    LOAD_CLAIMS_HISTORY_FAIL: type('[Claims Support] Load Claims History Fail'),
    RELOAD_CLAIMS_HISTORY: type('[Claims Support] Loaded Claims History Needs Reloaded'),

    LOAD_CLAIM_DETAILS: type('[Claims Support] Load Claim Details'),
    LOAD_CLAIM_DETAILS_SUCCESS: type('[Claims Support] Load Claim Details Success'),
    LOAD_CLAIM_DETAILS_FAIL: type('[Claims Support] Load Claim Details Fail'),

    LOAD_REPORTING_AGENCY_TYPES: type('[Claims Support] Load Reporting Agency Types'),
    LOAD_REPORTING_AGENCY_TYPES_SUCCESS: type('[Claims Support] Load Reporting Agency Types Success'),
    LOAD_REPORTING_AGENCY_TYPES_FAIL: type('[Claims Support] Load Load Reporting Agency Types Fail')
};

/**
 * Claims Support Actions
 */

export class LoadVehicleColorsAction implements Action {
    public type = ActionTypes.LOAD_VEHICLE_COLORS;
    constructor(public payload: any = null) {}
}

export class LoadVehicleColorsSuccessAction implements Action {
    public type = ActionTypes.LOAD_VEHICLE_COLORS_SUCCESS;
    constructor(public payload: any = null) {}
}
export class LoadVehicleColorsFailAction implements Action {
    public type = ActionTypes.LOAD_VEHICLE_COLORS_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadGlassVendorsByCountyAction implements Action {
    public type = ActionTypes.LOAD_GLASS_VENDORS_BY_COUNTY;
    constructor(public payload: any = null) {}
}

export class LoadGlassVendorsByCountySuccessAction implements Action {
    public type = ActionTypes.LOAD_GLASS_VENDORS_BY_COUNTY_SUCCESS;
    constructor(public payload: Vendor[] = null) {}
}

export class LoadGlassVendorsByCountyFailAction implements Action {
    public type = ActionTypes.LOAD_GLASS_VENDORS_BY_COUNTY_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadCountiesAction implements Action {
    public type = ActionTypes.LOAD_COUNTIES;
    constructor(public payload: any = null) {}
}

export class LoadCountiesSuccessAction implements Action {
    public type = ActionTypes.LOAD_COUNTIES_SUCCESS;
    constructor(public payload: County[]) {}
}

export class LoadCountiesFailAction implements Action {
    public type = ActionTypes.LOAD_COUNTIES_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadAutoDamageLocationsAction implements Action {
    public type = ActionTypes.LOAD_AUTO_DAMAGE_LOCATIONS;
    constructor(public payload: any = null) {}
}

export class LoadAutoDamageLocationsSuccessAction implements Action {
    public type = ActionTypes.LOAD_AUTO_DAMAGE_LOCATIONS_SUCCESS;
    constructor(public payload: ClaimsSupport[]) {}
}

export class LoadAutoDamageLocationsFailAction implements Action {
    public type = ActionTypes.LOAD_AUTO_DAMAGE_LOCATIONS_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadAllDamageLocationsAction implements Action {
    public type = ActionTypes.LOAD_ALL_DAMAGE_LOCATIONS;
    constructor(public payload: any = null) {}
}

export class LoadAllDamageLocationsSuccessAction implements Action {
    public type = ActionTypes.LOAD_ALL_DAMAGE_LOCATIONS_SUCCESS;
    constructor(public payload: ClaimsSupport[]) {}
}

export class LoadAllDamageLocationsFailAction implements Action {
    public type = ActionTypes.LOAD_ALL_DAMAGE_LOCATIONS_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadGlassDamageLocationsAction implements Action {
    public type = ActionTypes.LOAD_GLASS_DAMAGE_LOCATIONS;
    constructor(public payload: any = null) {}
}

export class LoadGlassDamageLocationsSuccessAction implements Action {
    public type = ActionTypes.LOAD_GLASS_DAMAGE_LOCATIONS_SUCCESS;
    constructor(public payload: ClaimsSupport[]) {}
}

export class LoadGlassDamageLocationsFailAction implements Action {
    public type = ActionTypes.LOAD_GLASS_DAMAGE_LOCATIONS_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadClaimsHistoryAction implements Action {
    public type = ActionTypes.LOAD_CLAIMS_HISTORY;
    constructor(public payload: any = null) {}
}

export class LoadClaimsHistorySuccessAction implements Action {
    public type = ActionTypes.LOAD_CLAIMS_HISTORY_SUCCESS;
    constructor(public payload: CcDetail[]) {}
}
export class LoadClaimsHistoryFailAction implements Action {
    public type = ActionTypes.LOAD_CLAIMS_HISTORY_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadClaimDetailsAction implements Action {
    public type = ActionTypes.LOAD_CLAIM_DETAILS;
    constructor(public payload: string = null) {}
}

export class LoadClaimDetailsSuccessAction implements Action {
    public type = ActionTypes.LOAD_CLAIM_DETAILS_SUCCESS;
    constructor(public payload: ClaimsApiRequest) {}
}
export class LoadClaimDetailsFailAction implements Action {
    public type = ActionTypes.LOAD_CLAIM_DETAILS_FAIL;
    constructor(public payload: any = null) {}
}

export class ReloadCountiesAction implements Action {
    public type = ActionTypes.RELOAD_COUNTIES;
    constructor(public payload: any = null) {}
}

export class ReloadAutoDamageLocationsAction implements Action {
    public type = ActionTypes.RELOAD_AUTO_DAMAGE_LOCATIONS;
    constructor(public payload: any = null) {}
}

export class ReloadAllDamageLocationsAction implements Action {
    public type = ActionTypes.RELOAD_ALL_DAMAGE_LOCATIONS;
    constructor(public payload: any = null) {}
}

export class ReloadGlassDamageLocationsAction implements Action {
    public type = ActionTypes.RELOAD_GLASS_DAMAGE_LOCATIONS;
    constructor(public payload: any = null) {}
}

export class ReloadClaimsHistoryAction implements Action {
    public type = ActionTypes.RELOAD_CLAIMS_HISTORY;
    constructor(public payload: any = null) {}
}

export class LoadReportingAgencyTypesAction implements Action {
    public type = ActionTypes.LOAD_REPORTING_AGENCY_TYPES;
    constructor(public payload: any = null) {}
}

export class LoadReportingAgencyTypesSuccessAction implements Action {
    public type = ActionTypes.LOAD_REPORTING_AGENCY_TYPES_SUCCESS;
    constructor(public payload: any = null) {}
}
export class LoadReportingAgencyTypesFailAction implements Action {
    public type = ActionTypes.LOAD_REPORTING_AGENCY_TYPES_FAIL;
    constructor(public payload: any = null) {}
}

export type Actions =
    | LoadVehicleColorsAction
    | LoadVehicleColorsSuccessAction
    | LoadVehicleColorsFailAction
    | LoadCountiesAction
    | LoadCountiesSuccessAction
    | LoadCountiesFailAction
    | LoadGlassVendorsByCountyAction
    | LoadGlassVendorsByCountySuccessAction
    | LoadGlassVendorsByCountyFailAction
    | LoadAutoDamageLocationsAction
    | LoadAutoDamageLocationsSuccessAction
    | LoadAutoDamageLocationsFailAction
    | LoadAllDamageLocationsAction
    | LoadAllDamageLocationsSuccessAction
    | LoadAllDamageLocationsFailAction
    | LoadGlassDamageLocationsAction
    | LoadGlassDamageLocationsSuccessAction
    | LoadGlassDamageLocationsFailAction
    | LoadClaimsHistoryAction
    | LoadClaimsHistorySuccessAction
    | LoadClaimsHistoryFailAction
    | LoadClaimDetailsAction
    | LoadClaimDetailsSuccessAction
    | LoadClaimDetailsFailAction
    | ReloadCountiesAction
    | ReloadAutoDamageLocationsAction
    | ReloadAllDamageLocationsAction
    | ReloadGlassDamageLocationsAction
    | ReloadClaimsHistoryAction
    | LoadReportingAgencyTypesAction
    | LoadReportingAgencyTypesSuccessAction
    | LoadReportingAgencyTypesFailAction;
