export class ForgotPasswordForm {
    public username: string;
    public email: string;
    public ssn: string;
    public dob: string;

    constructor(init?: Partial<ForgotPasswordForm>) {
        Object.assign(this, init);
    }
}
