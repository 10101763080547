import { Injectable } from '@angular/core';
import {
  CoverageMaximums,
  CoverageResponse,
  CoverageTypes
} from '../../models';

@Injectable()
export class AutoService {
  public static coverageAdapter(response: any): CoverageResponse {
    try {
      const result = new CoverageResponse();

      result.coverage = response;

      const cover = new CoverageTypes();
      const coverMax = new CoverageMaximums();

      for (const coverage of response) {
        cover[coverage.code].push(coverage);

        if (coverage.deductibleAmt > coverMax[coverage.code]) {
          coverMax[coverage.code] = coverage.deductibleAmt;
        }
      }

      result.coverageList = cover;
      result.coverageListMax = coverMax;

      return result;
    } catch (e) {
      console.error('Exception caught');
      console.error(e);
      return null;
    }
  }
}
