export class ClaimsPhotoUpload {
    public name: string;
    public base64: string;
    public description: string;
    public type: string;
    public mimeType: string;
    public base64Url: string;

    constructor(init?: Partial<ClaimsPhotoUpload>) {
        if (!init) {init = {}; }
        this.name = init.name || null;
        this.base64 = init.base64 || null;
        this.description = init.description || null;
        this.type = init.type || null;
        this.mimeType = init.mimeType || null;
        this.base64Url = init.base64Url || null;
    }

    public static fromBase64Url(imgData: string): ClaimsPhotoUpload {
        const img = new ClaimsPhotoUpload();
        img.base64Url = imgData;
        let mimeType = imgData.split(';')[0];
        if (mimeType.startsWith('data:')) {
            mimeType = mimeType.substr(5);
        }
        img.mimeType = mimeType;
        const dataSplit = imgData.split(',');
        if (dataSplit.length === 2 &&
            dataSplit[1] !== undefined &&
            dataSplit[1] !== null) {
          img.base64 = imgData.split(',')[1];
        }
        return img;
    }
}
