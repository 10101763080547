import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { DeviceInfo } from '../../models';
import { ProfileRequest } from '../../models/register/profile-request.model';
import { ProfileResponse } from '../../models/register/profile-response.model';
import { QuestionRequest } from '../../models/register/question-request.model';
import { ResendEmail } from '../../models/register/resend-email-request.model';
import { ValidationRequest } from '../../models/register/validation-request.model';
import { ValidationResponse } from '../../models/register/validation-response.model';
import { Adapter, Body, HttpService, Path, POST, PUT, Query } from '../http';

@Injectable()
export class RegisterApiClient extends HttpService {
    /**
     *    API Myaccount Register Calls
     */

    @POST('myaccount/register/validation',false)
    //   @Adapter(ClientService.clientContactAdapter)
    public validate(@Body validate: ValidationRequest): Observable<ValidationResponse> {
        return null;
    }

    @POST('myaccount/register/profile',false)
    // @Adapter(UserService.userAdapter)
    public profile(@Body profileReq: ProfileRequest): Observable<ProfileResponse> {
        return null;
    }

    @POST('myaccount/register/questions',false)
    // @Adapter(UserService.userAdapter)
    public questions(@Body form: QuestionRequest): Observable<DeviceInfo> {
        return null;
    }

    @POST('myaccount/register/verify?id={id}',false)
    public verify(@Path('id') id: string): Observable<any> {
        return null;
    }

    @POST('myaccount/resend',false)
    public resend(@Body resend: ResendEmail): Observable<any> {
        return null;
    }
}
