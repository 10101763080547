import { Action } from '@ngrx/store';
import { TabLayout } from '../../../models';
import { type } from '../../../utility';

// tslint:disable-next-line:variable-name
export const ActionTypes = {
    UPDATE_NAV_TABS: type('[Layout] UpdateNavTabs')
};

export class UpdateTabLayoutAction implements Action {
    public type = ActionTypes.UPDATE_NAV_TABS;
    constructor(public payload: TabLayout
        = null) { }
}

export type LayoutActions
    = UpdateTabLayoutAction;
