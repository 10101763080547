export class County {

   public code:    string;
   public codeDes: string;

    constructor(init?: Partial<County>) {
        if (!init) { init = {}; }
        this.code = init.code || null;
        this.codeDes = init.codeDes || null;
    }
  }
