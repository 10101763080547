export class TabLayout {
    public homeTab: boolean;
    public billingTab: boolean;
    public claimsTab: boolean;
    public documentsTab: boolean;
    public membershipTab: boolean;
    public contactTab: boolean;


    constructor(init: Partial<TabLayout> = {}) {
        ({
            homeTab: this.homeTab,
            billingTab: this.billingTab,
            claimsTab: this.claimsTab,
            documentsTab: this.documentsTab,
            membershipTab: this.membershipTab,
            contactTab: this.contactTab
        } = {
            ...{
                homeTab: true,
                billingTab: true,
                claimsTab: true,
                documentsTab: true,
                membershipTab: true,
                contactTab: true
            }, ...init
            });
    }
}
