import { Action } from '@ngrx/store';
import { ChangeSecurityQuestionsForm, User } from '../../../models';
import { type } from '../../../utility';

// tslint:disable-next-line:variable-name
export const ActionTypes = {
    CHANGE_PASSWORD: type('[My Profile] Change Password'),
    CHANGE_PASSWORD_SUCCESS: type('[My Profile] Change Password Success'),
    CHANGE_PASSWORD_FAIL: type('[My Profile] Change Password Fail'),
    CHANGE_PASSWORD_CLEAR: type('[My Profile] Change Password Clear'),

    LOAD_SECURITY_QUESTIONS: type('[My Profile] Load Security Questions'),
    LOAD_SECURITY_QUESTIONS_SUCCESS: type('[My Profile] Load Security Questions Success'),
    LOAD_SECURITY_QUESTIONS_FAIL: type('[My Profile] Load Security Questions Fail'),

    CHANGE_SECURITY_QUESTIONS: type('[My Profile] Change Security Questions'),
    CHANGE_SECURITY_QUESTIONS_SUCCESS: type('[My Profile] Change Security Questions Success'),
    CHANGE_SECURITY_QUESTIONS_FAIL: type('[My Profile] Change Security Questions Fail'),
    CHANGE_SECURITY_QUESTIONS_CLEAR: type('[My Profile] Change Security Questions Clear'),

    LOAD_USER_DATA: type('[My Profile] Load User Data'),
    LOAD_USER_DATA_SUCCESS: type('[My Profile] Load User Data Success'),
    LOAD_USER_DATA_FAIL: type('[My Profile] Load User Data Fail'),

    EDIT_PERSONAL_INFO: type('[My Profile] Edit Personal Info'),
    EDIT_PERSONAL_INFO_SUCCESS: type('[My Profile] Edit Personal Info Success'),
    EDIT_PERSONAL_INFO_FAIL: type('[My Profile] Edit Personal Info Fail'),
    EDIT_PERSONAL_INFO_CLEAR: type('[My Profile] Edit Personal Info Clear')
};

/**
 * Claims Support Actions
 */

export class ChangePasswordAction implements Action {
    public type = ActionTypes.CHANGE_PASSWORD;
    constructor(public payload: { existingPass: string; newPass: string } = null) {}
}
export class ChangePasswordSuccessAction implements Action {
    public type = ActionTypes.CHANGE_PASSWORD_SUCCESS;
    constructor(public payload: string = null) {}
}
export class ChangePasswordFailAction implements Action {
    public type = ActionTypes.CHANGE_PASSWORD_FAIL;
    constructor(public payload: any = null) {}
}
export class ChangePasswordClearAction implements Action {
    public type = ActionTypes.CHANGE_PASSWORD_CLEAR;
    constructor(public payload: any = null) {}
}

export class LoadSecurityQuestionsAction implements Action {
    public type = ActionTypes.LOAD_SECURITY_QUESTIONS;
    constructor(public payload: any = null) {}
}
export class LoadSecurityQuestionsSuccessAction implements Action {
    public type = ActionTypes.LOAD_SECURITY_QUESTIONS_SUCCESS;
    constructor(public payload: any = null) {}
}
export class LoadSecurityQuestionsFailAction implements Action {
    public type = ActionTypes.LOAD_SECURITY_QUESTIONS_FAIL;
    constructor(public payload: any = null) {}
}

export class ChangeSecurityQuestionsAction implements Action {
    public type = ActionTypes.CHANGE_SECURITY_QUESTIONS;
    constructor(public payload: ChangeSecurityQuestionsForm = null) {}
}
export class ChangeSecurityQuestionsSuccessAction implements Action {
    public type = ActionTypes.CHANGE_SECURITY_QUESTIONS_SUCCESS;
    constructor(public payload: string = null) {}
}
export class ChangeSecurityQuestionsFailAction implements Action {
    public type = ActionTypes.CHANGE_SECURITY_QUESTIONS_FAIL;
    constructor(public payload: any = null) {}
}
export class ChangeSecurityQuestionsClearAction implements Action {
    public type = ActionTypes.CHANGE_SECURITY_QUESTIONS_CLEAR;
    constructor(public payload: any = null) {}
}

export class EditPersonalInfoAction implements Action {
    public type = ActionTypes.EDIT_PERSONAL_INFO;
    constructor(public payload: User = null) {}
}
export class EditPersonalInfoSuccessAction implements Action {
    public type = ActionTypes.EDIT_PERSONAL_INFO_SUCCESS;
    constructor(public payload: { user: User; message: string } = null) {}
}
export class EditPersonalInfoFailAction implements Action {
    public type = ActionTypes.EDIT_PERSONAL_INFO_FAIL;
    constructor(public payload: any = null) {}
}
export class EditPersonalInfoClearAction implements Action {
    public type = ActionTypes.EDIT_PERSONAL_INFO_CLEAR;
    constructor(public payload: any = null) {}
}

export class LoadUserDataAction implements Action {
    public type = ActionTypes.LOAD_USER_DATA;
    constructor(public payload: string = null) {}
}
export class LoadUserDataSuccessAction implements Action {
    public type = ActionTypes.LOAD_USER_DATA_SUCCESS;
    constructor(public payload: User = null) {}
}
export class LoadUserDataFailAction implements Action {
    public type = ActionTypes.LOAD_USER_DATA_FAIL;
    constructor(public payload: any = null) {}
}

export type Actions =
    | ChangePasswordAction
    | ChangePasswordSuccessAction
    | ChangePasswordFailAction
    | ChangePasswordClearAction
    | LoadSecurityQuestionsAction
    | LoadSecurityQuestionsSuccessAction
    | LoadSecurityQuestionsFailAction
    | ChangeSecurityQuestionsAction
    | ChangeSecurityQuestionsSuccessAction
    | ChangeSecurityQuestionsFailAction
    | ChangeSecurityQuestionsClearAction
    | EditPersonalInfoAction
    | EditPersonalInfoSuccessAction
    | EditPersonalInfoFailAction
    | EditPersonalInfoClearAction
    | LoadUserDataAction
    | LoadUserDataSuccessAction
    | LoadUserDataFailAction;
