export class TcReceiptData {
    public actionName: string;
    public commercialCard: string;
    public cardBrand: string;
    public cardNumber: string;
    public cardExp: string;
    public statusName: string;
    public ticketNumber: string;
    public achAccountNumber: string;
    public achRoutingNumber: string;
    public description: string;
    public avs: string;
    public transId: string;
    public paymentAmt: number;
    public authCode: string;
    public payorName: string;

    public policyType: string;  			// Custom 1
    public policyNumber: string;			// Custom 2
    public uiType: string;					// Custom 3
    public collectionNbr: string;			// Custom 4
    public payorIsPolicyOwner: Boolean;	    // Custom 14
    public sourceSystem: string;	        // Custom 17

    public notificationEmail: String;
}

export function convertResponseToDataModel(res: TcReceiptResponse): TcReceiptData {
    const retVal = new TcReceiptData();

    retVal.actionName = res['transaction[action_name]'];
    retVal.commercialCard = res['transaction[commercialcard]'];
    retVal.cardBrand = res['transaction[ccBrand]'];
    retVal.cardNumber = res['transaction[cc]'];
    retVal.cardExp = res['transaction[exp]'];
    retVal.statusName = res['transaction[status_name]'];
    retVal.ticketNumber = res['transaction[ticket]'];
    retVal.achAccountNumber = res['transaction[account]'];
    retVal.achRoutingNumber = res['transaction[routing]'];
    retVal.description = res['transaction[description]'];
    retVal.avs = res['transaction[avs]'];
    retVal.transId = res['transaction[transid]'];
    retVal.paymentAmt = Number.parseFloat(res['transaction[amount]']);
    retVal.authCode = res['transaction[authcode]'];
    retVal.payorName = res['transaction[name]'];

    retVal.policyType = res['transaction[transactioncustomfield]'][1];
    retVal.policyNumber = res['transaction[transactioncustomfield]'][2];
    retVal.uiType = res['transaction[transactioncustomfield]'][3];
    retVal.collectionNbr = res['transaction[transactioncustomfield]'][4];

    const payorNIN = res['transaction[transactioncustomfield]'][14];
    if (payorNIN === null || payorNIN === undefined) {
        retVal.payorIsPolicyOwner = null;
    } else {
        retVal.payorIsPolicyOwner = payorNIN.toUpperCase() === 'Y' ? true : false;
    }
    retVal.sourceSystem = res['transaction[transactioncustomfield]'][17];

    return retVal;
}

export interface TcReceiptResponse {
'transaction[action_name]': string;
'transaction[amount]': string;
'transaction[authcode]': string;
'transaction[ccBrand]': string;
'transaction[cc]': string;
'transaction[commercialcard]': string;
'transaction[exp]': string;
'transaction[name]': string;
'transaction[status_name]': string;
'transaction[ticket]': string;
'transaction[transactioncustomfield]': TcTransactionCustomField;
'transaction[transid]': string;
'transaction[account]': string;
'transaction[routing]': string;
'transaction[description]': string;
'transaction[avs]': string;
}

interface TcTransactionCustomField {
'1': string;
'2': string;
'3': string;
'4': string;
'14': string;
'17': string;
}
