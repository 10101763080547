import { NgModule } from '@angular/core';
import { ActiveDriversPipe } from './activeDrivers.pipe';
import { AutoUpdatePipe } from './autoUpdate.pipe';
import {CurrencyOrStringPipe} from './currency_or_string.pipe';
import { ExcludedDriversPipe } from './excludedDrivers.pipe';
import { ExcludeLifePipe } from './excludeLife.pipe';
import { ExcludeMembershipPipe } from './excludeMembership.pipe';
import { LimitToPipe } from './limitTo.pipe';
import { PhonePipe } from './phone.pipe';
import { PolicyTypeUrlPipe } from './policyTypeUrl.pipe';

export const PIPES = [
    PolicyTypeUrlPipe,
    ExcludeMembershipPipe,
    ExcludeLifePipe,
    PhonePipe,
    LimitToPipe,
    ActiveDriversPipe,
    ExcludedDriversPipe,
    AutoUpdatePipe,
    CurrencyOrStringPipe
];

@NgModule({
    imports: [],
    declarations: PIPES,
    exports: PIPES
})
export class PipesModule {}
