export class SecurityQuestions {
  public questionId: string;
  public questionText: string;
  public userAnswer: string;

  constructor(init?: Partial<SecurityQuestions>) {
    if (!init) {
      init = {};
    }
    this.questionId = init.questionId || null;
    this.questionText = init.questionText || null;
    this.userAnswer = init.userAnswer || null;
  }
}
