import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, HttpService, Path } from '../http';

@Injectable()
export class OfficeApiClient extends HttpService {
  @GET('office/agency/search/{officeNbr}',false)
  public getAgency(@Path('officeNbr') officeNbr: string): Observable<any> {
    return null;
  }
}
