export class AutoDetailsForm {
    public lossDescription:  string;
    public lossLocationDesc: string;
    public city:             string;
    public stateCd:          string;
    public reportFiled:      boolean;
    public reportNbr:        string;
    public reportAuthority:  string;
    public reportAuthorityCity: string;
    public reportAuthorityStateCd: string;
    public reportAuthorityType: string;

    constructor(init?: Partial<AutoDetailsForm>) {
        if (!init) { init = {}; }
        this.lossDescription = init.lossDescription || null;
        this.lossLocationDesc = init.lossLocationDesc || null;
        this.city = init.city || null;
        this.stateCd = init.stateCd || null;
        this.reportFiled = init.reportFiled || null;
        this.reportNbr = init.reportNbr || null;
        this.reportAuthority = init.reportAuthority || null;
        this.reportAuthorityCity = init.reportAuthorityCity || null;
        this.reportAuthorityStateCd = init.reportAuthorityStateCd || null;
        this.reportAuthorityType = init.reportAuthorityType || null;
    }
  }
