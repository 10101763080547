import { PaperlessVerification } from '../../../models';
import { ActionTypes, PaperlessActions } from '../../actions/myprofile/paperless.action';

export interface PaperlessState {
    paperlessUpdating: boolean;
    paperlessUpdateFailed: boolean;
    paperlessUpdateError: string;
    paperlessVerificationSending: boolean;
    paperlessVerificationSendFailed: boolean;
    paperlessVerificationSendError: string;
    paperlessVerificationAckNeeded: boolean;
    paperlessVerificationsSent: PaperlessVerification[];
    verificationProcessing: boolean;
    verificationComplete: boolean;
    verificationError: string;
}

const INITIAL_STATE: PaperlessState = {
    paperlessUpdating: false,
    paperlessUpdateFailed: false,
    paperlessUpdateError: null,
    paperlessVerificationSending: false,
    paperlessVerificationSendFailed: false,
    paperlessVerificationSendError: null,
    paperlessVerificationAckNeeded: false,
    paperlessVerificationsSent: [],
    verificationProcessing: false,
    verificationComplete: false,
    verificationError: null
};

//  Reducers specify how the application’s state changes in response to actions.
export function paperlessReducer(state = INITIAL_STATE, action: PaperlessActions): PaperlessState {

    if (!action) {
        return state;
    }

    switch (action.type) {
        case ActionTypes.UPDATE_PAPERLESS: {
            return {
                ...state,
                paperlessUpdating: true,
                paperlessUpdateFailed: false,
                paperlessUpdateError: null
            };
        }
        case ActionTypes.UPDATE_PAPERLESS_SUCCESS: {
            return {
                ...state,
                paperlessUpdating: false,
                paperlessUpdateFailed: false,
                paperlessUpdateError: null

            };
        }
        case ActionTypes.UPDATE_PAPERLESS_FAIL: {
            return {
                ...state,
                paperlessUpdating: false,
                paperlessUpdateFailed: true,
                paperlessUpdateError: action.payload
            };
        }
        case ActionTypes.SEND_PAPERLESS_VERIFICATIONS: {
            return {
                ...state,
                paperlessVerificationSending: true,
                paperlessVerificationSendFailed: false,
                paperlessVerificationSendError: null
            };
        }

        case ActionTypes.SEND_PAPERLESS_VERIFICATIONS_SUCESS: {
            return {
                ...state,
                paperlessVerificationSending: false,
                paperlessVerificationSendFailed: false,
                paperlessVerificationSendError: null,
                paperlessVerificationAckNeeded: true,
                paperlessVerificationsSent: action.payload

            };
        }
        case ActionTypes.SEND_PAPERLESS_VERIFICATIONS_FAIL: {
            return {
                ...state,
                paperlessVerificationSending: false,
                paperlessVerificationSendFailed: true,
                paperlessVerificationSendError: action.payload,
                paperlessVerificationAckNeeded: true
            };
        }

        case ActionTypes.SEND_PAPERLESS_VERIFICATIONS_CLEAR_ACK: {
            return {
                ...state,
                paperlessVerificationAckNeeded: false
            };
        }

        case ActionTypes.VERIFY_PAPERLESS: {
            return {
                ...state,
                verificationProcessing: true,
                verificationError: null
            };
        }
        case ActionTypes.VERIFY_PAPERLESS_SUCCESS: {
            return {
                ...state,
                verificationProcessing: false,
                verificationComplete: true
            };
        }
        case ActionTypes.VERIFY_PAPERLESS_FAIL: {
            return {
                ...state,
                verificationError: action.payload.error
            };
        }

        default: {
            return state;
        }
    }
}

export const getPaperlessVerificationProcessing = (state: PaperlessState) => state.verificationProcessing;
export const getPaperlessVerificationComplete = (state: PaperlessState) => state.verificationComplete;
export const getPaperlessVerificationError = (state: PaperlessState) => state.verificationError;
