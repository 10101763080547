import { createSelector } from '@ngrx/store';
import { State } from '../..';
import * as fromForgot from '../../reducers/user/forgot.reducer';

export const getForgotState = (state: State) => state.forgot;

export const getIsPasswordLoaded = createSelector(
    getForgotState,
    fromForgot.getIsPasswordLoaded
);
export const getIsPasswordLoading = createSelector(
    getForgotState,
    fromForgot.getIsPasswordLoading
);
export const getPasswordForm = createSelector(
    getForgotState,
    fromForgot.getPasswordForm
);
export const getPasswordError = createSelector(
    getForgotState,
    fromForgot.getPasswordError
);

export const getIsUsernameLoaded = createSelector(
    getForgotState,
    fromForgot.getIsUsernameLoaded
);
export const getIsUsernameLoading = createSelector(
    getForgotState,
    fromForgot.getIsUsernameLoading
);
export const getUsernameForm = createSelector(
    getForgotState,
    fromForgot.getUsernameForm
);
export const getUsernameError = createSelector(
    getForgotState,
    fromForgot.getUsernameError
);
