export class VehicleUseForm {
    public accident: boolean;
    public accidentExp: string;
    public schoolWork: boolean;
    public milesDrivenCode: string;
    public business: boolean;
    public carry: boolean;
    public carryPersons: boolean;
    public transNetwork: boolean;
    public parked: boolean;

    constructor(init?: Partial<VehicleUseForm>) {
        Object.assign(this, init);
    }
}
