import { Claim } from '../../claim.model';
import { ClaimsSelectPolicy } from '../../claims-select-policy.model';
import { GlassDetailsForm } from './glassDetails.model';


export class GlassClaim extends Claim {

    public selectPolicy: ClaimsSelectPolicy;
    public glassDetail: GlassDetailsForm;
    public agreementConfirmation: boolean;
    public policyNbr: string;
    public unitNbr: string;
    public claimType: string;

    constructor(init?: Partial<GlassClaim>) {
        if (!init) { init = {}; }
        super(init);
        this.selectPolicy = init.selectPolicy || new ClaimsSelectPolicy();
        this.glassDetail = init.glassDetail || new GlassDetailsForm();
        this.agreementConfirmation = init.agreementConfirmation || null;
        this.policyNbr = init.policyNbr || null;
        this.unitNbr = init.unitNbr || null;
        this.claimType = init.claimType || null;
    }
}
