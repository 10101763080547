import { ContactTypeEnums } from '../../enums/contact-type-enum';

export class ClaimsContactInfoForm {
    public type: ContactTypeEnums;
    public value: string;
    public preferredContactMethod: boolean;

    constructor(init?: Partial<ClaimsContactInfoForm>) {
      if (!init) {init = {}; }
      this.type = init.type || null;
      this.value = init.value || '';
      this.preferredContactMethod = init.preferredContactMethod || false;
    }
  }
