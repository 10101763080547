import { Claim } from '../../claim.model';
import { PeopleInvolvedForm } from '../auto';
import { WaterDetailsForm } from './waterDetails.model';

export class WaterClaim extends Claim {
    public waterDetail: WaterDetailsForm;
    public unitNbr: string;
    public peopleInvolved: PeopleInvolvedForm;

    constructor(init?: Partial<WaterClaim>) {
        if (!init) {
            init = {};
        }
        super(init);
        this.waterDetail = init.waterDetail || new WaterDetailsForm();
        this.unitNbr = init.unitNbr || null;
        this.peopleInvolved = init.peopleInvolved || null;
    }
}
