import { ClaimsContactForm } from '../../../models/claims/claims-contact.model';
import * as clientActions from '../../actions/client/client.action';

export interface ClientState {
    clientContactsLoading: boolean;
    clientContactsLoaded: boolean;
    clientContactsError: string;
    clientContactsData: ClaimsContactForm;
}

const INITIAL_STATE: ClientState = {
    clientContactsLoading: false,
    clientContactsLoaded: false,
    clientContactsError: null,
    clientContactsData: new ClaimsContactForm()
};

export function clientReducer(state = INITIAL_STATE, action: clientActions.Actions): ClientState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case clientActions.ActionTypes.LOAD_CLIENT_CONTACTS: {
            return Object.assign({}, state, {
                clientContactsLoading: true,
                clientContactsError: null
            });
        }
        case clientActions.ActionTypes.LOAD_CLIENT_CONTACTS_SUCCESS: {
            return Object.assign({}, state, <ClientState>{
                clientContactsLoading: false,
                clientContactsLoaded: true,
                clientContactsData: action.payload
            });
        }
        case clientActions.ActionTypes.LOAD_CLIENT_CONTACTS_FAIL: {
            return Object.assign({}, state, <ClientState>{
                clientContactsLoading: false,
                clientContactsLoaded: false,
                clientContactsError: action.payload.error,
                clientContactsData: INITIAL_STATE.clientContactsData
            });
        }

        default: {
            return state;
        }
    }
}

export const getClientContactsLoading = (state: ClientState) => state.clientContactsLoading;
export const getClientContactsLoaded = (state: ClientState) => state.clientContactsLoaded;
export const getClientContactsError = (state: ClientState) => state.clientContactsError;
export const getClientContactsData = (state: ClientState) => state.clientContactsData;

export const getClientDataLoading = (state: ClientState) => state.clientContactsLoading;
