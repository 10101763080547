import { ClaimsPhotoUpload } from './claims-photo-upload.model';

export class ClaimsUploadForm {
    public uploads: ClaimsPhotoUpload[];

    constructor(init?: Partial<ClaimsUploadForm>) {
        if (!init) {init = {}; }
        this.uploads = init.uploads || new Array<ClaimsPhotoUpload>();
    }
}
