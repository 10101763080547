import { AlertAction } from '../../enums/alertAction.enum';
import { AlertType } from '../../enums/alertType.enum';

export class Alert {
    public alertId: number;
    public custId: number;
    public policyNbr: string;
    public systemId: string;
    public type: AlertType;
    public action: AlertAction;
    public message: string;
    public viewPolicyLink: string;

    constructor(init?: Partial<Alert>) {
        if (!init) {
            init = {};
        }
        this.alertId = init.alertId || null;
        this.custId = init.custId || null;
        this.policyNbr = init.policyNbr || null;
        this.systemId = init.systemId || null;
        this.type = init.type || null;
        this.action = init.action || null;
        this.message = init.message || null;
        this.viewPolicyLink = init.viewPolicyLink || null;
    }
}
