import { ProfileResponse } from '../register/profile-response.model';

export class ResetQuestions extends ProfileResponse {
    public status: string;
    public id: string;

    constructor(init?: Partial<ResetQuestions>) {
        super();
        if (!init) {
            init = {};
        }
        this.status = init.status || null;
        this.id = init.id || null;
    }
}
