import { CoverageIncreaseDed } from '.';
import { AutoChangeState } from '../../store/reducers';
import { ObjectCleaner } from '../../utility';
import { LookupService } from '../../utility/lookup-service';
import { Driver, Vehicle } from '../policy';
import { User } from '../user';
import { AutoAddDriverApiRequest } from './api/autoAddDriverApi-request';
import { AutoAddDriverVehicleApiRequest } from './api/autoAddDriverVehicleApi-request';
import { AutoAddVehicleApiRequest, AutoCoverageApiRequest } from './api/autoAddVehicleApi-request';
import { AutoCancelPolicyApiRequest } from './api/autoCancelPolicyApi-request';
import { AutoDeleteVehicleApiRequest } from './api/autoDeleteVehicleApi-request';
import { AutoEditLienholderApiRequest } from './api/autoEditLienholderApi-request';
import { AutoIncreaseDedApiRequest } from './api/autoIncreaseDedApi-request';
import { AutoLienholderApiRequest } from './api/autoLienholderApi-request';
import { CancelPolicyForm } from './cancel';

export class AutoApiRequestFactory {
    public static createAddDriverRequest(autoAddDriver: AutoChangeState, user: User) {
        const lookupService = new LookupService();

        const requestData = new AutoAddDriverApiRequest({
            customerId: user.custId.toString(),
            driver: {
                firstName: autoAddDriver.driverInfo.firstName,
                lastName: autoAddDriver.driverInfo.lastName,
                dateOfBirth: autoAddDriver.driverInfo.dob,
                gender: autoAddDriver.driverInfo.gender,
                maritalStatusCd: autoAddDriver.driverInfo.maritalStatus,
                maritalStatusDesc: lookupService.getMaritalStatus(autoAddDriver.driverInfo.maritalStatus),
                licenseStateCd: autoAddDriver.driverInfo.state,
                licenseStateDesc: lookupService.getState(autoAddDriver.driverInfo.state),
                licenseNbr: autoAddDriver.driverInfo.driversLicense
            },
            relationToInsured: autoAddDriver.driverInfo.relationToInsured,
            primaryVehicle: {
                year: autoAddDriver.vehicle.year,
                makeCd: autoAddDriver.vehicle.makeCd,
                makeDesc: autoAddDriver.vehicle.makeDesc,
                modelCd: autoAddDriver.vehicle.modelCd,
                modelDesc: autoAddDriver.vehicle.modelDesc.substr(0, 15)
            },
            inInsuredHousehold: autoAddDriver.driverHistory.sameHouse ? 'Y' : 'N',
            continuousCoverage: autoAddDriver.driverHistory.continuousCoverage,
            licSuspRevoked: autoAddDriver.driverHistory.revoked ? 'Y' : 'N',
            licSuspRevokedWhen: autoAddDriver.driverHistory.revokedYear,
            licSuspRevokedWhy: autoAddDriver.driverHistory.revokedReason,
            drvForfeitConvicted: autoAddDriver.driverHistory.violation ? 'Y' : 'N',
            vehStolenBurned: autoAddDriver.driverHistory.stolen ? 'Y' : 'N',
            accLastThreeYears: autoAddDriver.driverHistory.accident ? 'Y' : 'N',
            insCancelRejected: autoAddDriver.driverHistory.insuranceCancelled ? 'Y' : 'N'
        });
        return ObjectCleaner.removeUnneededKeys(requestData);
    }

    public static createAddVehicleRequest(autoAddVehicle: AutoChangeState, user: User) {
        const requestData = new AutoAddVehicleApiRequest({
            customerId: user.custId.toString(),
            addVehicle: {
                year: autoAddVehicle.vehicleDetail.vehicleYear,
                makeCd: autoAddVehicle.vehicleDetail.vehicleMake,
                makeDesc: autoAddVehicle.vehicleDetail.vehicleMakeDesc,
                modelCd: autoAddVehicle.vehicleDetail.vehicleModel,
                modelDesc: autoAddVehicle.vehicleDetail.vehicleModelDesc,
                vinSerialNbr: autoAddVehicle.vehicleDetail.vin,
                typeCd: autoAddVehicle.vehicleDetail.vehicleType
            },
            vehLienInd: autoAddVehicle.vehicleDetail.lienOrLease === 'lien' ? 'Y' : 'N',
            vehLeasedInd: autoAddVehicle.vehicleDetail.lienOrLease === 'lease' ? 'Y' : 'N',
            datePurchased: autoAddVehicle.vehicleDetail.dateOfPurchase, // yyyy-mm-dd
            vehPurchasedFrom: autoAddVehicle.vehicleDetail.purchaseLoc,
            vehRegisteredState: autoAddVehicle.vehicleDetail.registrationState,
            vehTitledName: autoAddVehicle.vehicleDetail.titledInName,
            coverageList: [
                new AutoCoverageApiRequest({
                    description: 'COMPREHENSIVE',
                    newAmount: autoAddVehicle.vehicleDetail.compCoverage.deductibleAmt
                }),
                new AutoCoverageApiRequest({
                    description: 'COLLISION',
                    newAmount: autoAddVehicle.vehicleDetail.collisionCoverage.deductibleAmt
                }),
                new AutoCoverageApiRequest({
                    description: 'EMERGENCY ROAD SERVICE',
                    newAmount: autoAddVehicle.vehicleDetail.ersCoverage.deductibleAmt
                }),
                new AutoCoverageApiRequest({
                    description: 'RENTAL REIMBURSEMENT',
                    newAmount: autoAddVehicle.vehicleDetail.rentalCoverage.deductibleAmt
                })
            ],
            vehAccSincePurchase: autoAddVehicle.vehicleUse.accident ? 'Y' : 'N',
            vehAccSincePurchaseExp: autoAddVehicle.vehicleUse.accident,
            vehDrivenWorkSchool: autoAddVehicle.vehicleUse.schoolWork ? 'Y' : 'N',
            milesDrivenOneWay: autoAddVehicle.vehicleUse.milesDrivenCode,
            vehUsedInBusiness: autoAddVehicle.vehicleUse.business ? 'Y' : 'N',
            vehUsedCarryProperty: autoAddVehicle.vehicleUse.carry ? 'Y' : 'N',
            vehUsedCarryPersons: autoAddVehicle.vehicleUse.carryPersons ? 'Y' : 'N',
            transNetworkCompany: autoAddVehicle.vehicleUse.transNetwork ? 'Y' : 'N',
            foodNetworkCo: autoAddVehicle.vehicleUse.carry ? 'Y' : 'N',  
            vehGaragedResidence: autoAddVehicle.vehicleUse.parked ? 'Y' : 'N',
            vehPrimaryDriver: autoAddVehicle.driver.firstName + ' ' + autoAddVehicle.driver.lastName,
            vehPrimaryDrv: autoAddVehicle.driver.firstName + ' ' + autoAddVehicle.driver.lastName
        });
        if (autoAddVehicle.vehicleDetail.lienholder.name) {
            Object.assign(requestData, {
                vehLienLeasedAddress: {
                    name: autoAddVehicle.vehicleDetail.lienholder.name,
                    addr1: autoAddVehicle.vehicleDetail.lienholder.address,
                    addr2: autoAddVehicle.vehicleDetail.lienholder.address2,
                    city: autoAddVehicle.vehicleDetail.lienholder.city,
                    stateCd: autoAddVehicle.vehicleDetail.lienholder.state,
                    zip: autoAddVehicle.vehicleDetail.lienholder.zip
                }
            });
        }
        return ObjectCleaner.removeUnneededKeys(requestData);
    }

    public static createAddDriverAndVehicleRequest(autoChange: AutoChangeState, user: User) {
        const newAutoChangeState: AutoChangeState = {
            ...autoChange,
            vehicle: new Vehicle({
                year: autoChange.vehicleDetail.vehicleYear.toString(),
                makeDesc: autoChange.vehicleDetail.vehicleMakeDesc,
                modelDesc: autoChange.vehicleDetail.vehicleModelDesc
            }),
            driver: new Driver({ firstName: autoChange.driverInfo.firstName, lastName: autoChange.driverInfo.lastName })
        };

        const result = new AutoAddDriverVehicleApiRequest({
            customerId: user.custId.toString(),
            driverChg: this.createAddDriverRequest(newAutoChangeState, user),
            vehicleChg: this.createAddVehicleRequest(newAutoChangeState, user)
        });

        if (newAutoChangeState.vehicleDetail.lienholder.name) {
            result.vehLienLeasedAddress = new AutoLienholderApiRequest({
                name: newAutoChangeState.vehicleDetail.lienholder.name,
                addr1: newAutoChangeState.vehicleDetail.lienholder.address,
                addr2: newAutoChangeState.vehicleDetail.lienholder.address2,
                city: newAutoChangeState.vehicleDetail.lienholder.city,
                stateCd: newAutoChangeState.vehicleDetail.lienholder.state,
                zip: newAutoChangeState.vehicleDetail.lienholder.zip
            });
        }

        return ObjectCleaner.removeUnneededKeys(result);
    }

    public static createDeleteVehicleRequest(state: AutoChangeState, user: User) {
        return ObjectCleaner.removeUnneededKeys(
            new AutoDeleteVehicleApiRequest({
                customerId: user.custId.toString(),
                reasonForDeletion: state.deleteVehicle.reason,
                reasonForDeletionExp: state.deleteVehicle.reasonOther || ''
            })
        );
    }

    public static createReplaceVehicleRequest(state: AutoChangeState, user: User) {
        const deleteRequest = AutoApiRequestFactory.createDeleteVehicleRequest(state, user);
        const addRequest = AutoApiRequestFactory.createAddVehicleRequest(state, user);

        return ObjectCleaner.removeUnneededKeys(Object.assign(deleteRequest, addRequest));
    }
    public static createIncreaseDedRequest(coverages: CoverageIncreaseDed[], user: User) {
        const requestData = new AutoIncreaseDedApiRequest({
            customerId: user.custId.toString(),
            coverageList: []
        });

        coverages.forEach(coverage => {
            const currentAmt = coverage.typeCovCd === 'D' ? coverage.deductibleAmt : coverage.limit1Amt;
            requestData.coverageList.push(
                new AutoCoverageApiRequest({
                    description: coverage.desc,
                    currentAmount: currentAmt,
                    newAmount: coverage.selectedDed
                })
            );
        });

        return ObjectCleaner.removeUnneededKeys(requestData);
    }

    public static createLienholderUpdateRequest(state: AutoChangeState, user: User) {
        const requestData = new AutoEditLienholderApiRequest({
            customerId: user.custId.toString()
        });
        if (state.lienholder && state.lienholder.name) {
            requestData.newLienHolder = {
                name: state.lienholder.name,
                addr1: state.lienholder.address,
                addr2: state.lienholder.address2,
                city: state.lienholder.city,
                stateCd: state.lienholder.state,
                zip: state.lienholder.zip
            };
        }
        if (state.vehicle && state.vehicle.losspayee && state.vehicle.losspayee.address) {
            requestData.currentLienHolder = {
                name: state.vehicle.losspayee.legalName,
                addr1: state.vehicle.losspayee.address.addr1,
                addr2: state.vehicle.losspayee.address.addr2,
                city: state.vehicle.losspayee.address.city,
                stateCd: state.vehicle.losspayee.address.stateCd,
                zip: state.vehicle.losspayee.address.zip
            };
        }

        return ObjectCleaner.removeUnneededKeys(requestData);
    }

    public static createCancelPolicyRequest(form: CancelPolicyForm, user: User) {
        return ObjectCleaner.removeUnneededKeys(
            new AutoCancelPolicyApiRequest({
                customerId: user.custId.toString(),
                cancelReason: form.reason,
                cancelDetails: form.reasonOther || 'N/A'
            })
        );
    }
}
