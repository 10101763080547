import { Injectable } from '@angular/core';
import { MaritalStatusConstants, StatesConstants } from '../constants';

@Injectable()
export class LookupService {

    public getState = function (stateCode) {

        StatesConstants.states.forEach(state => {
            if (state.abbreviation === stateCode) { return state.name; }
        });
        return null;
    };

    public getMaritalStatus = function (status) {

        MaritalStatusConstants.maritalStatus.forEach(ms => {
            if (ms.abbreviation === status) { return ms.name; }
        });
        return null;
    };
}
