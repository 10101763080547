export class VinLookupResults {
    public vehicleType: string;
    public vehicleYear: string;
    public vehicleMake: string;
    public vehicleModel: string;
    public vehicleTypeDesc: string;
    public vehicleMakeDesc: string;
    public vehicleModelDesc: string;

    constructor(init?: Partial<VinLookupResults>) {
        Object.assign(this, init);
    }
}
