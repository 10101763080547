import { CoverageOption } from '../../auto/add-vehicle/coverage-option.model';

export class Coverage {

    public category: string;
    public code: string;
    public deductibleAmt: number;
    public deductibleDesc: string;
    public desc: string;
    public effDate: string;
    public expDate: string;
    public limit1Amt: number;
    public limit2Amt: number;
    public limitDesc: string;
    public premium: number;
    public typeCovCd: string;
    public coverages: Coverage[];
    public limit: string;

    constructor(init?: Partial<Coverage>) {
        if (!init) { init = {}; }
        this.category = init.category || null;
        this.code = init.code || null;
        this.deductibleAmt = init.deductibleAmt || null;
        this.desc = init.desc || null;
        this.effDate = init.effDate || null;
        this.expDate = init.expDate || null;
        this.limit1Amt = init.limit1Amt || null;
        this.limit2Amt = init.limit2Amt || null;
        this.limitDesc = init.limitDesc || null;
        this.premium = init.premium || null;
        this.typeCovCd = init.typeCovCd || null;
        this.coverages = init.coverages || new Array<Coverage>();
        this.limit = init.limit || null;

    }
}
