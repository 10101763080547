import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material';
import { PolicyDisplayItem } from '../../models';

@Component({
  selector: 'app-policy-display-item',
  templateUrl: './policy-display-item.component.html',
  styleUrls: ['./policy-display-item.component.scss']
})
export class PolicyDisplayItemComponent implements OnInit {
  @ViewChild('myPanel')
  public myPanel: MatExpansionPanel;
  public matIcon = 'keyboard_arrow_down' || 'keyboard_arrow_up';

  @Input()
  public item: PolicyDisplayItem;
  @Input()
  public expanded: boolean;

  @Output()
  public actionCallback: EventEmitter<PolicyDisplayItem> = new EventEmitter<PolicyDisplayItem>();

  constructor() { }

  public ngOnInit() {
    this.myPanel.expandedChange.subscribe(data => {
      this.matIcon = data ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    });
  }

  public expandPannel() {
    this.myPanel.expanded = !this.myPanel.expanded;
  }

  public handleAction(item: PolicyDisplayItem) {
    this.actionCallback.emit(item);
  }
}
