import { ClaimsBasicInfoForm } from './claims-basic-info.model';
import { ClaimsContactForm } from './claims-contact.model';
import { ClaimsPhotoUpload } from './claims-photo-upload.model';
import { ClaimsSelectPolicy } from './claims-select-policy.model';

export class Claim {
    public claimNbr: string;
    public id: string;
    public basicInfo: ClaimsBasicInfoForm;
    public selectPolicy: ClaimsSelectPolicy;
    public photoUploads: ClaimsPhotoUpload[];
    public contacts: ClaimsContactForm;
    public agreementConfirmation: boolean;

    constructor(init?: Partial<Claim>) {
        if (!init) {
            init = {};
        }
        this.claimNbr = init.claimNbr || null;
        this.id = init.id || null;
        this.basicInfo = init.basicInfo || new ClaimsBasicInfoForm();
        this.selectPolicy = init.selectPolicy || new ClaimsSelectPolicy();
        this.photoUploads = init.photoUploads || new Array<ClaimsPhotoUpload>();
        this.contacts = init.contacts || new ClaimsContactForm();
        this.agreementConfirmation = init.agreementConfirmation === undefined ? null : init.agreementConfirmation;
    }
}
