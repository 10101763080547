import { ResetQuestions, User, UserApiResponse } from '../../../models';
import * as myprofileActions from '../../actions/myprofile/myprofile.action';
import * as userActions from '../../actions/user/user.action';

export interface UserState {
    isUserLoggingIn: boolean;
    isUserLoggedIn: boolean;
    isUserLoginFail: boolean;
    isUserLoginChallenged: boolean;
    isUserLoginChallengedSubmit: boolean;
    userLoginError: string;
    user: User;
    resetQuestions: ResetQuestions;
    loginChallengeError: string;

    isResetPasswordLoaded: boolean;
    isResetPasswordLoading: boolean;
    resetPasswordError: string;

    isRegVerifyLoaded: boolean;
    isRegVerifyLoading: boolean;
    regVerifyError: string;

    isQuestionUpdateLoaded: boolean;
    isQuestionUpdateLoading: boolean;
    questionUpdateError: string;

    recoverPasswordId: string;
    isRecoverPasswordInitLoaded: boolean;
    isRecoverPasswordInitLoading: boolean;
    recoverPasswordInitError: string;
    recoverPasswordInitResponse: UserApiResponse;

    isRecoverPasswordAuthLoaded: boolean;
    isRecoverPasswordAuthLoading: boolean;
    recoverPasswordAuthError: string;
    recoverPasswordAuthResponse: any;

    isRecoverPasswordCompleteLoaded: boolean;
    isRecoverPasswordCompleteLoading: boolean;
    recoverPasswordCompleteError: string;
    recoverPasswordCompleteResponse: any;

    isResetSecurityInitLoaded: boolean;
    isResetSecurityInitFailed: boolean;
    resetSecurityInitError: any;
}

const INITIAL_STATE: UserState = {
    isUserLoggingIn: false,
    isUserLoggedIn: false,
    isUserLoginFail: false,
    isUserLoginChallenged: false,
    userLoginError: null,
    user: new User(),
    resetQuestions: null,
    loginChallengeError: null,
    isUserLoginChallengedSubmit: false,

    isResetPasswordLoaded: false,
    isResetPasswordLoading: false,
    resetPasswordError: null,

    isRegVerifyLoaded: false,
    isRegVerifyLoading: false,
    regVerifyError: null,

    isQuestionUpdateLoaded: false,
    isQuestionUpdateLoading: false,
    questionUpdateError: null,

    recoverPasswordId: null,
    isRecoverPasswordInitLoaded: false,
    isRecoverPasswordInitLoading: false,
    recoverPasswordInitError: null,
    recoverPasswordInitResponse: null,

    isRecoverPasswordAuthLoaded: false,
    isRecoverPasswordAuthLoading: false,
    recoverPasswordAuthError: null,
    recoverPasswordAuthResponse: null,

    isRecoverPasswordCompleteLoaded: false,
    isRecoverPasswordCompleteLoading: false,
    recoverPasswordCompleteError: null,
    recoverPasswordCompleteResponse: null,

    isResetSecurityInitLoaded: false,
    isResetSecurityInitFailed: false,
    resetSecurityInitError: null
};

//  Reducers specify how the application’s state changes in response to actions.
export function userReducer(state = INITIAL_STATE, action: userActions.Actions): UserState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        // Auto
        case userActions.ActionTypes.LOGIN_USER: {
            return {
                ...state,
                user: {
                    ...state.user
                },
                isUserLoggingIn: true,
                userLoginError: null
            };
        }

        case userActions.ActionTypes.LOGIN_USER_SUCCESS: {
            return {
                ...state,
                isUserLoggingIn: false,
                isUserLoggedIn: true,
                userLoginError: null,
                user: action.payload.user
            };
        }

        case userActions.ActionTypes.LOGIN_USER_FAIL: {
            return { ...state, isUserLoginFail: true, isUserLoggingIn: false, userLoginError: action.payload.error };
        }

        case userActions.ActionTypes.LOGIN_USER_CHALLENGE: {
            return {
                ...state,
                isUserLoggingIn: false,
                user: {
                    ...state.user,
                    questions: action.payload.questions,
                    id: action.payload.id
                }
            };
        }

        case userActions.ActionTypes.LOGIN_USER_CHALLENGE_RETRY: {
            return {
                ...state,
                user: {
                    ...state.user,
                    questions: action.payload.questions
                },
                isUserLoginChallengedSubmit: false,
                loginChallengeError: 'The answer provided does not match our records.'
            };
        }

        case myprofileActions.ActionTypes.LOAD_USER_DATA_SUCCESS: {
            return { ...state, user: action.payload };
        }

        case myprofileActions.ActionTypes.EDIT_PERSONAL_INFO_SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    emailAddress: action.payload.user.emailAddress,
                    firstName: action.payload.user.firstName,
                    lastName: action.payload.user.lastName,
                    phoneNumber: action.payload.user.phoneNumber
                }
            };
        }

        case userActions.ActionTypes.LOGIN_USER_SUBMIT_CHALLENGE: {
            return { ...state, isUserLoginChallengedSubmit: true, loginChallengeError: null };
        }

        case userActions.ActionTypes.LOGIN_USER_SUBMIT_CHALLENGE_SUCCESS: {
            return { ...state, isUserLoginChallengedSubmit: false, user: action.payload.user };
        }

        case userActions.ActionTypes.LOGIN_USER_SUBMIT_CHALLENGE_FAIL: {
            return { ...state, isUserLoginChallengedSubmit: false, loginChallengeError: action.payload.error };
        }

        case userActions.ActionTypes.LOGIN_RESET_PASSWORD: {
            return {
                ...state,
                isUserLoggingIn: false,
                isUserLoggedIn: false,
                user: {
                    ...state.user,
                    id: action.payload.id
                }
            };
        }

        case userActions.ActionTypes.LOGIN_RESET_PASSWORD_SUBMIT: {
            return { ...state, isResetPasswordLoaded: false, isResetPasswordLoading: true, resetPasswordError: null };
        }

        case userActions.ActionTypes.LOGIN_RESET_PASSWORD_SUBMIT_SUCCESS: {
            return { ...state, isResetPasswordLoaded: true, isResetPasswordLoading: false, resetPasswordError: null, isUserLoggedIn: true };
        }

        case userActions.ActionTypes.LOGIN_RESET_PASSWORD_SUBMIT_FAIL: {
            return { ...state, isResetPasswordLoaded: true, isResetPasswordLoading: false, resetPasswordError: action.payload.error };
        }

        case userActions.ActionTypes.LOGIN_REG_VERIFY: {
            return {
                ...state,
                user: {
                    ...state.user,
                    id: action.payload.id
                },
                isUserLoggingIn: false,
                regVerifyError: null
            };
        }

        case userActions.ActionTypes.LOGIN_REG_VERIFY_SUBMIT: {
            return { ...state, isRegVerifyLoaded: false, isRegVerifyLoading: true, regVerifyError: null };
        }

        case userActions.ActionTypes.LOGIN_REG_VERIFY_SUBMIT_SUCCESS: {
            return { ...state, isRegVerifyLoaded: true, isRegVerifyLoading: false, regVerifyError: null, isUserLoggedIn: true };
        }

        case userActions.ActionTypes.LOGIN_REG_VERIFY_SUBMIT_FAIL: {
            return {
                ...state,
                isRegVerifyLoaded: true,
                isRegVerifyLoading: false,
                regVerifyError: action.payload.error,
                isUserLoggedIn: false
            };
        }

        case userActions.ActionTypes.LOGIN_QUESTIONS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    id: action.payload.id,
                    status: action.payload.status
                },
                isUserLoggingIn: false,
                resetQuestions: action.payload
            };
        }

        case userActions.ActionTypes.LOGIN_QUESTIONS_SUBMIT: {
            return { ...state, isQuestionUpdateLoaded: false, isQuestionUpdateLoading: true, questionUpdateError: null };
        }

        case userActions.ActionTypes.LOGIN_QUESTIONS_SUBMIT_SUCCESS: {
            return { ...state, isQuestionUpdateLoaded: true, isQuestionUpdateLoading: false, isUserLoggedIn: true };
        }

        case userActions.ActionTypes.LOGIN_QUESTIONS_SUBMIT_FAIL: {
            return { ...state, isQuestionUpdateLoaded: true, isQuestionUpdateLoading: false, questionUpdateError: action.payload.error };
        }

        case userActions.ActionTypes.RECOVER_PWD_INIT: {
            return {
                ...state,
                isRecoverPasswordInitLoaded: false,
                isRecoverPasswordInitLoading: true,
                recoverPasswordInitError: null,
                recoverPasswordId: action.payload
            };
        }

        case userActions.ActionTypes.RECOVER_PWD_INIT_SUCCESS: {
            return {
                ...state,
                isRecoverPasswordInitLoaded: true,
                isRecoverPasswordInitLoading: false,
                recoverPasswordInitResponse: action.payload,
                recoverPasswordInitError: null
            };
        }

        case userActions.ActionTypes.RECOVER_PWD_INIT_FAIL: {
            return {
                ...state,
                isRecoverPasswordInitLoaded: false,
                isRecoverPasswordInitLoading: false,
                recoverPasswordInitError: action.payload.error
            };
        }

        case userActions.ActionTypes.RECOVER_PWD_AUTH: {
            return { ...state, isRecoverPasswordAuthLoaded: false, isRecoverPasswordAuthLoading: true, recoverPasswordAuthError: null };
        }

        case userActions.ActionTypes.RECOVER_PWD_AUTH_RETRY: {
            return { ...state, isRecoverPasswordAuthLoaded: false, isRecoverPasswordAuthLoading: false, recoverPasswordAuthError: 'The answer provided does not match our records.', recoverPasswordInitResponse: action.payload };
        }

        case userActions.ActionTypes.RECOVER_PWD_AUTH_SUCCESS: {
            return {
                ...state,
                isRecoverPasswordAuthLoaded: true,
                isRecoverPasswordAuthLoading: false,
                recoverPasswordAuthResponse: action.payload,
                recoverPasswordAuthError: null
            };
        }

        case userActions.ActionTypes.RECOVER_PWD_AUTH_FAIL: {
            return {
                ...state,
                isRecoverPasswordAuthLoaded: false,
                isRecoverPasswordAuthLoading: false,
                recoverPasswordAuthError: action.payload.error
            };
        }

        case userActions.ActionTypes.RECOVER_PWD_COMPLETE: {
            return {
                ...state,
                isRecoverPasswordCompleteLoaded: false,
                isRecoverPasswordCompleteLoading: true,
                recoverPasswordCompleteError: null
            };
        }

        case userActions.ActionTypes.RECOVER_PWD_COMPLETE_SUCCESS: {
            return {
                ...state,
                isRecoverPasswordCompleteLoaded: true,
                isRecoverPasswordCompleteLoading: false,
                recoverPasswordCompleteResponse: action.payload,
                recoverPasswordAuthError: null
            };
        }

        case userActions.ActionTypes.RECOVER_PWD_COMPLETE_FAIL: {
            return {
                ...state,
                isRecoverPasswordCompleteLoaded: false,
                isRecoverPasswordCompleteLoading: false,
                recoverPasswordAuthError: action.payload.error
            };
        }

        case userActions.ActionTypes.LOGIN_RESET_SECURITY_SUBMIT: {
            return {
                ...state,
                isResetSecurityInitFailed: false,
                isResetSecurityInitLoaded: false,
                resetSecurityInitError: null
            };
        }
        case userActions.ActionTypes.LOGIN_RESET_SECURITY_SUBMIT_SUCCESS: {
            return {
                ...state,
                isResetSecurityInitFailed: false,
                isResetSecurityInitLoaded: true,
                resetSecurityInitError: null
            };
        }
        case userActions.ActionTypes.LOGIN_RESET_SECURITY_SUBMIT_FAIL: {
            return {
                ...state,
                isResetSecurityInitFailed: true,
                isResetSecurityInitLoaded: true,
                resetSecurityInitError: action.payload.error
            };
        }

        default: {
            return state;
        }
    }
}

export const userQuestions = (state: UserState) => state.user.questions;
export const getLoginChallengeError = (state: UserState) => state.loginChallengeError;

export const getIsUserLoggingIn = (state: UserState) => state.isUserLoggingIn;
export const getIsUserLoggedIn = (state: UserState) => state.isUserLoggedIn;
export const getIsUserLoginFail = (state: UserState) => state.isUserLoginFail;
export const getUserLoginError = (state: UserState) => state.userLoginError;
export const getIsUserLoginChallenged = (state: UserState) => state.isUserLoginChallenged;
export const getIsUserLoginChallengeSubmit = (state: UserState) => state.isUserLoginChallengedSubmit;
export const getUser = (state: UserState) => state.user;

export const getIsResetPasswordLoaded = (state: UserState) => state.isResetPasswordLoaded;
export const getIsResetPasswordLoading = (state: UserState) => state.isResetPasswordLoading;
export const getResetPasswordError = (state: UserState) => state.resetPasswordError;

export const getIsRegVerifyLoaded = (state: UserState) => state.isRegVerifyLoaded;
export const getIsRegVerifyLoading = (state: UserState) => state.isRegVerifyLoading;
export const getRegVerifyError = (state: UserState) => state.regVerifyError;

export const getResetQuestions = (state: UserState) => state.resetQuestions;

export const getQuestionUpdateLoaded = (state: UserState) => state.isQuestionUpdateLoaded;
export const getQuestionUpdateLoading = (state: UserState) => state.isQuestionUpdateLoading;
export const getQuestionUpdateError = (state: UserState) => state.questionUpdateError;

export const getIsRecoverPasswordInitLoaded = (state: UserState) => state.isRecoverPasswordInitLoaded;
export const getIsRecoverPasswordInitLoading = (state: UserState) => state.isRecoverPasswordInitLoading;
export const getRecoverPasswordInitResponse = (state: UserState) => state.recoverPasswordInitResponse;

export const getIsRecoverPasswordAuthLoaded = (state: UserState) => state.isRecoverPasswordAuthLoaded;
export const getIsRecoverPasswordAuthLoading = (state: UserState) => state.isRecoverPasswordAuthLoading;
export const getRecoverPasswordAuthResponse = (state: UserState) => state.recoverPasswordAuthResponse;

export const getIsRecoverPasswordCompleteLoaded = (state: UserState) => state.isRecoverPasswordCompleteLoaded;
export const getIsRecoverPasswordCompleteLoading = (state: UserState) => state.isRecoverPasswordCompleteLoading;
export const getRecoverPasswordCompleteResponse = (state: UserState) => state.recoverPasswordCompleteResponse;

export const getRecoverPasswordError = (state: UserState) =>
    state.recoverPasswordInitError || state.recoverPasswordAuthError || state.recoverPasswordCompleteError;

export const getResetSecurityInitError = (state: UserState) => state.resetSecurityInitError;
export const getIsResetSecurityInitLoaded = (state: UserState) => state.isResetSecurityInitLoaded;
export const getIsResetSecurityInitFailed = (state: UserState) => state.isResetSecurityInitFailed;
