import { DeviceInfo } from './deviceInfo.model';
import { SecurityQuestions } from './securityQuestions.model';
import { User } from './user.model';

export class UserApiResponse {
  public status: string;
  public user: User;
  public questions: SecurityQuestions[];
  public deviceInfo: DeviceInfo;
  public id: string;
  public bind: boolean;

  constructor(init: Partial<UserApiResponse>) {
    if (!init) {
      init = {};
    }
    this.status = init.status || null;
    this.user = init.user || null;
    this.questions = init.questions || null;
    this.deviceInfo = init.deviceInfo || null;
    this.id = init.id || null;
    this.bind = init.bind || false;
  }
}
