import { Claim } from '../claim.model';
import { ClaimsUploadForm } from '../claims-upload.model';
import { PropertyDetailsForm } from './propertyDetail.model';


export class PropertyClaim extends Claim {
    public propertyDetail:          PropertyDetailsForm;
    public documents:               ClaimsUploadForm;

    constructor(init?: Partial<PropertyClaim>) {
        if (!init) { init = {}; }
        super(init);
        this.propertyDetail = init.propertyDetail || new PropertyDetailsForm();
        this.documents = init.documents || new ClaimsUploadForm();
    }
}
