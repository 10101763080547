import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ClaimsContactForm } from '../../models';
import { ClientService } from '../adapters/client.service';
import {
  Adapter,
  Body,
  HttpService,
  Path,
  POST,
  PUT
} from '../http';

@Injectable()
export class ClientApiClient extends HttpService {

   /**
   *    API Calls Client Inquiry for contact data
   */

  @POST('client/inquiry/contacts',false)
  @Adapter(ClientService.clientContactAdapter)
  public clientContactInquiry(@Body clientIds: string[]): Observable<ClaimsContactForm> { return null; }

}
