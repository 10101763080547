
export class ContactUsForm {
    public messageType: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public confirmEmail: string;
    public address: string;
    public zipCode: string;
    public city: string;
    public state: string;
    public phone: string;
    public policyNumber: string;
    public message: string;

    constructor(init?: Partial<ContactUsForm>) {
        Object.assign(this, init);
    }
}
