import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecurityQuestions, SecurityQuestionsGroup, User } from '../../models';
import { MyProfileService } from '../adapters';
import { Adapter, Body, GET, HttpService, Path, PUT } from '../http';

@Injectable()
export class MyProfileApiClient extends HttpService {
    @PUT('myaccount/{custId}/profile/password',false)
    public changePassword(@Path('custId') custId: string, @Body data: { existingPassword: string; user: User }): Observable<void> {
        return null;
    }

    @GET('myaccount/{custId}/profile/questions',false)
    @Adapter(MyProfileService.questionGroupAdapter)
    public loadSecurityQuestions(@Path('custId') custId: string): Observable<SecurityQuestionsGroup[]> {
        return null;
    }

    @PUT('myaccount/{custId}/profile/questions',false)
    public setSecurityQuestions(@Path('custId') custId: string, @Body data: { questions: SecurityQuestions[] }): Observable<void> {
        return null;
    }

    @GET('myaccount/{custId}/profile',false)
    @Adapter(MyProfileService.userAdapter)
    public loadUser(@Path('custId') custId: string): Observable<User> {
        return null;
    }

    @PUT('myaccount/{custId}/profile',false)
    @Adapter(MyProfileService.userAdapter)
    public submitProfileChanges(@Path('custId') custId: string, @Body data: { user: User }): Observable<User> {
        return null;
    }
}
