import { Claim } from '../../claim.model';
import { ErsDetailsForm } from './ersDetails.model';


export class ErsClaim extends Claim {

    public ersDetail:               ErsDetailsForm;

    constructor(init?: Partial<ErsClaim>) {
      if (!init) {init = {}; }
      super(init);
      this.ersDetail = init.ersDetail || new ErsDetailsForm();
    }
  }
