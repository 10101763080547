import { PolicyBase } from '../base/policy-base.model';
import { Watercraft } from './watercraft.model';

export class WatercraftPolicy extends PolicyBase {
    public watercrafts: Watercraft[];

    constructor(init?: Partial<WatercraftPolicy>) {
        if (!init) { init = {}; }
        super(init);
        this.watercrafts = init.watercrafts || new Array<Watercraft>();
    }
}
