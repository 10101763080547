export class AmountDueResponse {
    public name: string;
    public amountDue: AmountDue[];
    public isLegacy: Boolean;
}

export class AmountDue {
    public dueDate: string;
    public dueAmt: number;
    public collectionId: string;
    public pastDue: boolean;
    public graceBill: boolean;
    public selected: boolean;
}
