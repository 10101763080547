import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CcDetail, ClaimsApiRequest } from '../../models';
import { ClaimsSupport } from '../../models/claims/claims-support.model';
import { County } from '../../models/claims/county.model';
import { Vendor } from '../../models/claims/vendor.model';
import { ClaimsService } from '../adapters';
import { Adapter, GET, HttpService, Path } from '../http';

@Injectable()
export class ClaimsSupportApiClient extends HttpService {
      /**
     * Retrieves all claims support damage locations
     */
    @GET('claims/guidewire/support/collision-points',false)
    @Adapter(ClaimsService.gridAdapter)
    public getAllDamageLocations(): Observable<ClaimsSupport[]> {
        return null;
    }

    /**
     * Retrieves all claims support boat damage locations
     */
    @GET('claims/guidewire/support/collision-points',false)
    @Adapter(ClaimsService.boatDamageAdapter)
    public getboatDamageLocations(): Observable<ClaimsSupport[]> {
        return null;
    }

    /**
     * Retrieves all claims support auto damage locations
     */
    @GET('claims/guidewire/support/collision-points',false)
    @Adapter(ClaimsService.autoDamageAdapter)
    public getAutoDamageLocations(): Observable<ClaimsSupport[]> {
        return null;
    }

    /**
     * Retrieves all claims support Glass - List of Glass Locations
     */
    @GET('claims/guidewire/support/glass-damaged',false)
    //@Adapter(ClaimsService.glassDamageAdapter)
    public getGlassDamageLocations(): Observable<ClaimsSupport[]> {
        return null;
    }

    /**
     * Retrieves all claims support auto vehicle colors
     */
    @GET('claims/guidewire/support/colors',false)
    @Adapter(ClaimsService.gridAdapter)
    public getAutoVehicleColors(): Observable<ClaimsSupport[]> {
        return null;
    }

     /**
      * Retrieves all claims support cause of loss
     */
    @GET('claims/guidewire/support/loss-causes',false)
    @Adapter(ClaimsService.gridAdapter)
    public getLossCauses(): Observable<ClaimsSupport[]> {
        return null;
    }

    /**
     * Retrieves all claims support Glass - List of Counties (needed for preferred glass)
     */
    // @GET('/claims/support/counties') //This Endpoint does not exist
    @GET('claims/guidewire/support/counties',false)
    @Adapter(ClaimsService.countyAdapter)
    public getCountiesForGlassVendors(): Observable<County[]> {
        return null;
    }


    @GET('claims/guidewire/support/report-agency-types',false)
    @Adapter(ClaimsService.gridAdapter)
    public getReportAgencyTypes(): Observable<ClaimsSupport[]> {
        return null;
    }

    /**
     * Retrieves all preferred vendors for a county (needed for preferred glass)
     */
    //@GET('/claims/preferred/glass/{countyId}',false)
    @GET('claims/guidewire/preferred/glass/{countyId}',false)
    @Adapter(ClaimsService.vendorAdapter)
    public getGlassVendorsByCounty(@Path('countyId') countyId: number): Observable<Vendor[]> {
        return null;
    }

    /**
     * Retrieves all claims history
     */
    @GET('claims/guidewire/inquiry/membership/{membershipNbr}',false)
    @Adapter(ClaimsService.claimHistoryAdapter)
    public getClaimsHistory(@Path('membershipNbr') membership: string): Observable<CcDetail[]> {
        return null;
    }

    /**
     * Retrieves details for a claims
     */
    @GET('claims/details/{claimNumber}',false)
    @Adapter(ClaimsService.claimDetailAdapter)
    public getClaimDetails(@Path('claimNumber') claimNumber: string): Observable<ClaimsApiRequest> {
        return null;
    }
}
