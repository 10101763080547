import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ClientApiClient } from '../../../asyncServices/api-clients/client-apiclient.service';
import { ErrorMsgService } from '../../../utility/error-msg.service';
import * as clientActions from '../../actions/client/client.action';

@Injectable()
export class ClientEffects {
    constructor(private actions$: Actions, private clientApiClient: ClientApiClient, private errorMsgService: ErrorMsgService) {}

    @Effect()
    public getContactsbyClientId$: Observable<Action> = this.actions$.ofType(clientActions.ActionTypes.LOAD_CLIENT_CONTACTS).pipe(
        map((action: clientActions.LoadClientContactsAction) => action.payload),
        switchMap(state => {
            return this.clientApiClient.clientContactInquiry(state).pipe(
                map(response => new clientActions.LoadClientContactsSuccessAction(response)),
                catchError(error => of(new clientActions.LoadClientContactsFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );
}
