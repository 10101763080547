import { CoverageOption } from './add-vehicle/coverage-option.model';

export class CoverageResponse {
    public coverage: CoverageOption[];
    public coverageList: CoverageTypes;
    public coverageListMax: CoverageMaximums;

    constructor(init?: Partial<CoverageResponse>) {
        Object.assign(this, init);
    }
}

export class CoverageTypes {
    public CMP: CoverageOption[];
    public COL: CoverageOption[];
    public ERS: CoverageOption[];
    public RR: CoverageOption[];

    constructor(init?: Partial<CoverageTypes>) {
        this.CMP = [];
        this.COL = [];
        this.ERS = [];
        this.RR = [];
        Object.assign(this, init);
    }
}

export class CoverageMaximums {
    public CMP: number;
    public COL: number;
    public ERS: number;
    public RR: number;

    constructor(init?: Partial<CoverageMaximums>) {
        this.CMP = 0;
        this.COL = 0;
        this.ERS = 0;
        this.RR = 0;
        Object.assign(this, init);
    }
}

export class CoverageOptionSet {
    public CMP: CoverageOption;
    public COL: CoverageOption;
    public ERS: CoverageOption;
    public RR: CoverageOption;

    constructor(init?: Partial<CoverageOptionSet>) {
        this.CMP = null;
        this.COL = null;
        this.ERS = null;
        this.RR = null;
        Object.assign(this, init);
    }
}
