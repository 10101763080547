export class Billing {
    public statusCd: string;
    public billPlanCd: string;
    public billFrequencyCd: string;
    public billPlanDesc: string;
    public billFrequencyDesc: string;
    public zip: string;
    public billingAccess?: {
        ONE_TIME_PAYMENTS?: {
            allowed: boolean;
            reason?: string;
        },
        EDIT_AUTO_PAY_CC?: {
            allowed: boolean;
            reason?: string;
        }
        EDIT_AUTO_PAY_EFT?: {
            allowed: boolean;
            reason?: string;
        },
        ENROLL_AUTO_PAY_EFT?: {
            allowed: boolean;
            reason?: string;
        },
        ENROLL_AUTO_PAY_CC?: {
            allowed: boolean;
            reason?: string;
        }
    };
    public currentDue?: [
        {
            dueAmt: number;
        }
    ];
    public displayDue: {
        billToMortgage?: boolean;
        isEFTNextInstallment?: boolean;
        isEFT?: boolean;
        amount?: number;
        statusColor?: string;
        planDescText?: string;
        whenText?: string;
        whenDate?: string;
    };
    public forcePayment: boolean;
    public suspenseBilling: boolean;
    public prepay: boolean;

    constructor(init?: Partial<Billing>) {
        if (!init) { init = {}; }
        this.statusCd = init.statusCd || null;
        this.billPlanCd = init.billPlanCd || null;
        this.billFrequencyCd = init.billFrequencyCd || null;
        this.billPlanDesc = init.billPlanDesc || null;
        this.billFrequencyDesc = init.billFrequencyDesc || null;
        this.zip = init.zip || null;
        this.billingAccess = init.billingAccess || null;
        this.currentDue = init.currentDue || null;
        this.displayDue = init.displayDue || {};
        this.forcePayment = init.forcePayment || null;
        this.suspenseBilling = init.suspenseBilling || null;
        this.prepay = init.prepay || null;
    }
}
