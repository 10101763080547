import { CcDetail, ClaimsApiRequest } from '../../../models';
import { ClaimsSupport } from '../../../models/claims/claims-support.model';
import { County } from '../../../models/claims/county.model';
import { Vendor } from '../../../models/claims/vendor.model';
import * as actions from '../../actions/claims/claims-support.action';

export interface ClaimsSupportState {
    vehicleColorLoading: boolean;
    vehicleColorLoaded: boolean;
    vehicleColorError: string;
    vehicleColorData: ClaimsSupport[];

    autoDamageLocationsLoading: boolean;
    autoDamageLocationsLoaded: boolean;
    autoDamageLocationsError: string;
    autoDamageLocationsData: ClaimsSupport[];

    allDamageLocationsLoading: boolean;
    allDamageLocationsLoaded: boolean;
    allDamageLocationsFailed: boolean;
    allDamageLocationsData: ClaimsSupport[];

    glassDamageLocationsLoading: boolean;
    glassDamageLocationsLoaded: boolean;
    glassDamageLocationsFailed: boolean;
    glassDamageLocationsData: ClaimsSupport[];

    countiesLoading: boolean;
    countiesLoaded: boolean;
    countiesFailed: boolean;
    countiesData: County[];

    claimHistoryLoading: number;
    claimHistoryLoaded: boolean;
    claimHistoryError: boolean;
    claimHistoryData: CcDetail[];

    glassVendorsByCountyLoading: boolean;
    glassVendorsByCountyLoaded: boolean;
    glassVendorsByCountyFailed: boolean;
    glassVendorsByCountyData: Vendor[];

    claimDetailsLoading: boolean;
    claimDetailsLoaded: boolean;
    claimDetailsFailed: boolean;
    claimDetailsData: ClaimsApiRequest;

    authorityTypesDataLoading: boolean;
    authorityTypesDataLoaded: boolean;
    authorityTypesDataFailed: boolean;
    authorityTypesData: ClaimsSupport[];
}

const INITIAL_STATE: ClaimsSupportState = {
    vehicleColorLoading: false,
    vehicleColorLoaded: false,
    vehicleColorError: null,
    vehicleColorData: null,

    autoDamageLocationsLoading: false,
    autoDamageLocationsLoaded: false,
    autoDamageLocationsError: null,
    autoDamageLocationsData: null,

    allDamageLocationsLoading: false,
    allDamageLocationsLoaded: false,
    allDamageLocationsFailed: false,
    allDamageLocationsData: null,

    glassDamageLocationsLoading: false,
    glassDamageLocationsLoaded: false,
    glassDamageLocationsFailed: false,
    glassDamageLocationsData: null,

    countiesLoading: false,
    countiesLoaded: false,
    countiesFailed: false,
    countiesData: null,

    claimHistoryLoading: 0,
    claimHistoryLoaded: false,
    claimHistoryError: null,
    claimHistoryData: null,

    glassVendorsByCountyLoading: false,
    glassVendorsByCountyLoaded: false,
    glassVendorsByCountyFailed: false,
    glassVendorsByCountyData: null,

    claimDetailsLoading: false,
    claimDetailsLoaded: false,
    claimDetailsFailed: false,
    claimDetailsData: null,

    authorityTypesDataLoading: false,
    authorityTypesDataLoaded: false,
    authorityTypesDataFailed: false,
    authorityTypesData: null
};

export function claimsSupportReducer(state = INITIAL_STATE, action: actions.Actions): ClaimsSupportState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case actions.ActionTypes.LOAD_VEHICLE_COLORS: {
            return Object.assign({}, state, {
                vehicleColorLoading: true,
                vehicleColorError: null
            });
        }

        case actions.ActionTypes.LOAD_VEHICLE_COLORS_SUCCESS: {
            return Object.assign({}, state, {
                vehicleColorLoaded: true,
                vehicleColorLoading: false,
                vehicleColorData: action.payload
            });
        }

        case actions.ActionTypes.LOAD_VEHICLE_COLORS_FAIL: {
            return Object.assign({}, state, {
                vehicleColorLoaded: false,
                vehicleColorLoading: false,
                vehicleColorError: action.payload.error,
                vehicleColorData: null
            });
        }

        case actions.ActionTypes.LOAD_REPORTING_AGENCY_TYPES: {
            return Object.assign({}, state, {
                authorityTypesDataLoading: true,
                authorityTypesDataFailed: null
            });
        }

        case actions.ActionTypes.LOAD_REPORTING_AGENCY_TYPES_SUCCESS: {
            return Object.assign({}, state, {
                authorityTypesDataLoaded: true,
                authorityTypesDataLoading: false,
                authorityTypesData: action.payload
            });
        }

        case actions.ActionTypes.LOAD_REPORTING_AGENCY_TYPES_FAIL: {
            return Object.assign({}, state, {
                authorityTypesDataLoaded: false,
                authorityTypesDataLoading: false,
                authorityTypesDataError: action.payload.error,
                authorityTypesData: null
            });
        }


        case actions.ActionTypes.LOAD_AUTO_DAMAGE_LOCATIONS: {
            return Object.assign({}, state, {
                autoDamageLocationsLoading: true,
                autoDamageLocationsError: null
            });
        }

        case actions.ActionTypes.LOAD_AUTO_DAMAGE_LOCATIONS_SUCCESS: {
            return Object.assign({}, state, {
                autoDamageLocationsLoaded: true,
                autoDamageLocationsLoading: false,
                autoDamageLocationsData: action.payload
            });
        }

        case actions.ActionTypes.LOAD_AUTO_DAMAGE_LOCATIONS_FAIL: {
            return Object.assign({}, state, {
                autoDamageLocationsLoaded: false,
                autoDamageLocationsLoading: false,
                autoDamageLocationsError: action.payload.error,
                autoDamageLocationsData: null
            });
        }

        case actions.ActionTypes.LOAD_ALL_DAMAGE_LOCATIONS: {
            return Object.assign({}, state, {
                allDamageLocationsLoading: true
            });
        }

        case actions.ActionTypes.LOAD_ALL_DAMAGE_LOCATIONS_SUCCESS: {
            return Object.assign({}, state, {
                allDamageLocationsLoaded: true,
                allDamageLocationsLoading: false,
                allDamageLocationsFailed: false,
                allDamageLocationsData: action.payload
            });
        }

        case actions.ActionTypes.LOAD_ALL_DAMAGE_LOCATIONS_FAIL: {
            return Object.assign({}, state, {
                allDamageLocationsLoaded: false,
                allDamageLocationsLoading: false,
                allDamageLocationsFailed: true,
                allDamageLocationsData: null
            });
        }

        case actions.ActionTypes.LOAD_GLASS_DAMAGE_LOCATIONS: {
            return Object.assign({}, state, {
                glassDamageLocationsLoading: true
            });
        }

        case actions.ActionTypes.LOAD_GLASS_DAMAGE_LOCATIONS_SUCCESS: {
            return Object.assign({}, state, {
                glassDamageLocationsLoaded: true,
                glassDamageLocationsLoading: false,
                glassDamageLocationsFailed: false,
                glassDamageLocationsData: action.payload
            });
        }

        case actions.ActionTypes.LOAD_GLASS_DAMAGE_LOCATIONS_FAIL: {
            return Object.assign({}, state, {
                glassDamageLocationsLoaded: false,
                glassDamageLocationsLoading: false,
                glassDamageLocationsFailed: true,
                glassDamageLocationsData: null
            });
        }

        case actions.ActionTypes.LOAD_COUNTIES: {
            return Object.assign({}, state, {
                countiesLoading: true
            });
        }

        case actions.ActionTypes.LOAD_COUNTIES_SUCCESS: {
            return Object.assign({}, state, {
                countiesLoaded: true,
                countiesLoading: false,
                countiesFailed: false,
                countiesData: action.payload
            });
        }

        case actions.ActionTypes.LOAD_COUNTIES_FAIL: {
            return Object.assign({}, state, {
                countiesLoaded: false,
                countiesLoading: false,
                countiesFailed: true,
                countiesData: null
            });
        }

        case actions.ActionTypes.LOAD_CLAIMS_HISTORY: {
            return Object.assign({}, state, {
                claimHistoryLoading: state.claimHistoryLoading + 1,
                claimHistoryError: null,
                claimHistoryData: null
            });
        }

        case actions.ActionTypes.LOAD_CLAIMS_HISTORY_SUCCESS: {
            return Object.assign({}, state, {
                claimHistoryLoaded: state.claimHistoryLoading === 0 && state.claimHistoryError === null,
                claimHistoryLoading: state.claimHistoryLoading - 1,
                claimHistoryData: state.claimHistoryData === null ? action.payload : [...state.claimHistoryData, ...action.payload]
            });
        }

        case actions.ActionTypes.LOAD_CLAIMS_HISTORY_FAIL: {
            return Object.assign({}, state, {
                claimHistoryLoaded: state.claimHistoryLoading === 0 && state.claimHistoryError === null,
                claimHistoryLoading: state.claimHistoryLoading - 1,
                claimHistoryError: action.payload.error,
            });
        }

        case actions.ActionTypes.LOAD_GLASS_VENDORS_BY_COUNTY: {
            return Object.assign({}, state, {
                glassVendorsByCountyLoading: true
            });
        }

        case actions.ActionTypes.LOAD_GLASS_VENDORS_BY_COUNTY_SUCCESS: {
            return Object.assign({}, state, {
                glassVendorsByCountyLoaded: true,
                glassVendorsByCountyLoading: false,
                glassVendorsByCountyFailed: false,
                glassVendorsByCountyData: action.payload
            });
        }

        case actions.ActionTypes.LOAD_GLASS_VENDORS_BY_COUNTY_FAIL: {
            return Object.assign({}, state, {
                glassVendorsByCountyLoaded: false,
                glassVendorsByCountyLoading: false,
                glassVendorsByCountyFailed: true,
                glassVendorsByCountyData: null
            });
        }

        case actions.ActionTypes.LOAD_CLAIM_DETAILS: {
            return Object.assign({}, state, {
                claimDetailsLoading: true
            });
        }

        case actions.ActionTypes.LOAD_CLAIM_DETAILS_SUCCESS: {
            return Object.assign({}, state, {
                claimDetailsLoaded: true,
                claimDetailsLoading: false,
                claimDetailsFailed: false,
                claimDetailsData: action.payload
            });
        }

        case actions.ActionTypes.LOAD_CLAIM_DETAILS_FAIL: {
            return Object.assign({}, state, {
                claimDetailsLoaded: false,
                claimDetailsLoading: false,
                claimDetailsFailed: true,
                claimDetailsData: null
            });
        }
        case actions.ActionTypes.RELOAD_COUNTIES: {
            return {
                ...state,
                countiesLoaded: false
            };
        }
        case actions.ActionTypes.RELOAD_AUTO_DAMAGE_LOCATIONS: {
            return {
                ...state,
                autoDamageLocationsLoaded: false
            };
        }
        case actions.ActionTypes.RELOAD_ALL_DAMAGE_LOCATIONS: {
            return {
                ...state,
                allDamageLocationsLoaded: false
            };
        }
        case actions.ActionTypes.RELOAD_GLASS_DAMAGE_LOCATIONS: {
            return {
                ...state,
                glassDamageLocationsLoaded: false
            };
        }
        case actions.ActionTypes.RELOAD_CLAIMS_HISTORY: {
            return {
                ...state,
                claimHistoryLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}

export const getVehicleColorData = (state: ClaimsSupportState) => state.vehicleColorData;
export const getVehicleColorLoading = (state: ClaimsSupportState) => state.vehicleColorLoading;
export const getVehicleColorLoaded = (state: ClaimsSupportState) => state.vehicleColorLoaded;

export const getAutoDamageLocationsData = (state: ClaimsSupportState) => state.autoDamageLocationsData;
export const getAutoDamageLocationsLoading = (state: ClaimsSupportState) => state.autoDamageLocationsLoading;
export const getAutoDamageLocationsLoaded = (state: ClaimsSupportState) => state.autoDamageLocationsLoaded;

export const getAllDamageLocationsData = (state: ClaimsSupportState) => state.allDamageLocationsData;
export const getAllDamageLocationsLoading = (state: ClaimsSupportState) => state.allDamageLocationsLoading;
export const getAllDamageLocationsLoaded = (state: ClaimsSupportState) => state.allDamageLocationsLoaded;
export const getAllDamageLocationsFailed = (state: ClaimsSupportState) => state.allDamageLocationsFailed;

export const getGlassDamageLocationsData = (state: ClaimsSupportState) => state.glassDamageLocationsData;
export const getGlassDamageLocationsLoading = (state: ClaimsSupportState) => state.glassDamageLocationsLoading;
export const getGlassDamageLocationsLoaded = (state: ClaimsSupportState) => state.glassDamageLocationsLoaded;
export const getGlassDamageLocationsFailed = (state: ClaimsSupportState) => state.glassDamageLocationsFailed;

export const getCountiesData = (state: ClaimsSupportState) => state.countiesData;
export const getCountiesLoading = (state: ClaimsSupportState) => state.countiesLoading;
export const getCountiesLoaded = (state: ClaimsSupportState) => state.countiesLoaded;
export const getCountiesFailed = (state: ClaimsSupportState) => state.countiesFailed;

export const getClaimHistoryData = (state: ClaimsSupportState) => state.claimHistoryData;
export const getClaimHistoryLoading = (state: ClaimsSupportState) => state.claimHistoryLoading;
export const getClaimHistoryLoaded = (state: ClaimsSupportState) => state.claimHistoryLoaded;

export const getGlassVendorsByCountyData = (state: ClaimsSupportState) => state.glassVendorsByCountyData;
export const getGlassVendorsByCountyLoading = (state: ClaimsSupportState) => state.glassVendorsByCountyLoading;
export const getGlassVendorsByCountyLoaded = (state: ClaimsSupportState) => state.glassVendorsByCountyLoaded;
export const getGlassVendorsByCountyFailed = (state: ClaimsSupportState) => state.glassVendorsByCountyFailed;

export const getClaimDetailsData = (state: ClaimsSupportState) => state.claimDetailsData;
export const getClaimDetailsLoading = (state: ClaimsSupportState) => state.claimDetailsLoading;
export const getClaimDetailsLoaded = (state: ClaimsSupportState) => state.claimDetailsLoaded;
export const getClaimDetailsFailed = (state: ClaimsSupportState) => state.claimDetailsFailed;

export const getAuthorityTypesData = (state: ClaimsSupportState) => state.authorityTypesData;
export const getAuthorityTypesDataLoading = (state: ClaimsSupportState) => state.authorityTypesDataLoading;
export const getAuthorityTypesLoaded = (state: ClaimsSupportState) => state.authorityTypesDataLoaded;
export const getAuthorityTypesDataFailed = (state: ClaimsSupportState) => state.authorityTypesDataFailed;

export const claimsSupportDataLoading = (state: ClaimsSupportState) =>
    state.claimDetailsLoading ||
    state.vehicleColorLoading ||
    state.autoDamageLocationsLoading ||
    state.allDamageLocationsLoading ||
    state.glassDamageLocationsLoading ||
    state.countiesLoading ||
    state.claimHistoryLoading ||
    state.glassVendorsByCountyLoading ||
    state.authorityTypesDataLoading ;

export const getClaimsSupportError = (state: ClaimsSupportState) =>
    state.claimHistoryError || state.vehicleColorError || state.autoDamageLocationsError;
