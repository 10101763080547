import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DueAmountsApiResponse, TcReceiptData } from '../../models';
import { Body, GET, HttpService, Path, POST, Query } from '../http';

@Injectable()
export class PayMyBillApiClient extends HttpService {
    @GET('billing/tcp/due/policy/{policyNumber}/{systemId}',false)
    public getDueAmounts(
        @Path('policyNumber') policyNumber: string,
        @Path('systemId') systemId: string,
        @Query('zip') zipcode: string
    ): Observable<DueAmountsApiResponse> {
        return null;
    }


  @POST('billing/tcp/payment/complete',false)
  public postTcpPayment(@Body tcpPayment: TcReceiptData): Observable<void> { return null; }
}
