import { createSelector } from '@ngrx/store';
import { State } from '../..';
import * as fromPayMyBill from '../../reducers/pay-my-bill';

export const getPayMyBillState = (state: State) => state.payMyBill;

export const getPolicySearchLoading = createSelector(
    getPayMyBillState,
    fromPayMyBill.getPolicySearchLoading
);
export const getPolicySearchResponse = createSelector(
    getPayMyBillState,
    fromPayMyBill.getPolicySearchResponse
);
export const getPolicySearchFailed = createSelector(
    getPayMyBillState,
    fromPayMyBill.getPolicySearchFailed
);
export const getPolicySearchError = createSelector(
    getPayMyBillState,
    fromPayMyBill.getPolicySearchError
);

export const getPayMyBillLoading = createSelector(
    getPayMyBillState,
    fromPayMyBill.getPayMyBillLoading
);

export const getReceiptTriggerData = createSelector(
    getPayMyBillState,
    fromPayMyBill.getReceiptTriggerData
);
