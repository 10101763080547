import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Agency, PolicyBase } from '../../models';
import { PolicyResponse } from '../../models/policy/policyApi-response.model';
import { PolicyService } from '../adapters';
import { Adapter, GET, HttpService, Path, Query } from '../http';

@Injectable()
export class PolicyApiClient extends HttpService {
    @GET('policy/search/',false)
    // @Adapter(PolicyService.getPoliciesByCustId_Adapter)
    @Adapter(PolicyService.policiesAdapter)
    public getPoliciesByCustId(@Query('custid') id: number, @Query('alerts') alerts: boolean): Observable<PolicyResponse> {
        return null;
    }

    @GET('{type}/policies/{polNum}',false)
    @Adapter(PolicyService.policyAdapter)
    public getPolicyDetailsByDate(
        @Path('type') type: string,
        @Path('polNum') polNum: string,
        @Query('date') date: string
    ): Observable<PolicyBase[]> {
        return null;
    }

    @GET('membership/{polNum}',false)
    @Adapter(PolicyService.policyAdapter)
    public getMembership(@Path('polNum') polNum: string): Observable<PolicyBase[]> {
        return null;
    }

    // @GET('policy/search/custid/{id}/lossdate/{date}')
    // Observable<PolicyBase> { return null; }
    @GET('life/policies/{polNum}/surrender',false)
    public getLifeSurrenderInfo(@Path('polNum') polNum: string, @Query('date') date: string): Observable<any> {
        return null;
    }

    @GET('life/policies/{polNum}/taxes',false)
    public getAnnuityTaxInfo(@Path('polNum') polNum: string): Observable<any> {
        return null;
    }

    @GET('policy/search/custid/{id}/lossdate/{date}',false)
    @Adapter(PolicyService.getPoliciesByCustIdByDate_Adapter)
    public getPoliciesByCustIdByDate(@Path('id') id: string, @Path('date') date: string): Observable<any> {
        return null;
    }

    @GET('office/agency/search/{officeNbr}',false)
    public getAgency(@Path('officeNbr') officeNbr: string): Observable<Agency> {
        return null;
    }
}
