export class Vendor {
    public name: string;
    public clientId: string;
    public phone: string;
    public email: string;
    public fax: string;
    public counties: number[];

    constructor(init?: Partial<Vendor>) {
        if (!init) {init = {}; }
        this.name = init.name || null;
        this.clientId = init.clientId || null;
        this.phone = init.phone || null;
        this.email = init.email || null;
        this.fax = init.fax || null;
        this.counties = init.counties || new Array<number>();
    }
}
