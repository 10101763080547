import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AutoApiClient } from '../../../asyncServices/api-clients/auto-apiclient.service';
import { ErrorMsgService } from '../../../utility/error-msg.service';
import {
    ActionTypes,
    GetCancelReasonsAction,
    GetCancelReasonsFailAction,
    GetCancelReasonsSuccessAction,
    GetCoveragesAction,
    GetCoveragesFailAction,
    GetCoveragesSuccessAction,
    GetDeleteVehicleReasonsAction,
    GetDeleteVehicleReasonsFailAction,
    GetDeleteVehicleReasonsSuccessAction,
    GetMilesDrivenAction,
    GetMilesDrivenFailAction,
    GetMilesDrivenSuccessAction,
    GetVehicleMakesAction,
    GetVehicleMakesFailAction,
    GetVehicleMakesSuccessAction,
    GetVehicleModelsAction,
    GetVehicleModelsFailAction,
    GetVehicleModelsSuccessAction,
    GetVehicleTypesAction,
    GetVehicleTypesFailAction,
    GetVehicleTypesSuccessAction,
    GetVehicleYearsAction,
    GetVehicleYearsFailAction,
    GetVehicleYearsSuccessAction,
    LookupVinAction,
    LookupVinFailAction,
    LookupVinSuccessAction
} from '../../actions/auto/auto-support.action';

@Injectable()
export class AutoSupportEffects {
    constructor(private actions$: Actions, private autoApiClient: AutoApiClient, private errorMsgService: ErrorMsgService) {}

    @Effect()
    public getCancelReasons$: Observable<Action> = this.actions$.ofType(ActionTypes.GET_CANCEL_REASONS).pipe(
        map((action: GetCancelReasonsAction) => action.payload),
        switchMap(payload => {
            return this.autoApiClient.getCancelReasons().pipe(
                map(apiResponse => {
                    return new GetCancelReasonsSuccessAction(apiResponse);
                }),
                catchError(error =>
                    of(
                        new GetCancelReasonsFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    @Effect()
    public getVehicleDeleteReasons$: Observable<Action> = this.actions$.ofType(ActionTypes.GET_DELETE_VEHICLE_REASONS).pipe(
        map((action: GetDeleteVehicleReasonsAction) => {
            return action.payload;
        }),
        switchMap(payload => {
            return this.autoApiClient.getVehicleDeleteReasons().pipe(
                map(apiResponse => new GetDeleteVehicleReasonsSuccessAction(apiResponse)),
                catchError(error => of(new GetDeleteVehicleReasonsFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public getVehicleTypes$: Observable<Action> = this.actions$.ofType(ActionTypes.GET_VEHICLE_TYPES).pipe(
        map((action: GetVehicleTypesAction) => action.payload),
        switchMap(payload => {
            return this.autoApiClient.getVehicleTypes().pipe(
                map(apiResponse => {
                    return new GetVehicleTypesSuccessAction(apiResponse);
                }),
                catchError(error => of(new GetVehicleTypesFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public getVehicleYears$: Observable<Action> = this.actions$.ofType(ActionTypes.GET_VEHICLE_YEARS).pipe(
        map((action: GetVehicleYearsAction) => action.payload),
        switchMap(payload => {
            return this.autoApiClient.getVehicleYears(payload).pipe(
                map(apiResponse => {
                    return new GetVehicleYearsSuccessAction(apiResponse);
                }),
                catchError(error => of(new GetVehicleYearsFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public getVehicleMakes$: Observable<Action> = this.actions$.ofType(ActionTypes.GET_VEHICLE_MAKES).pipe(
        map((action: GetVehicleMakesAction) => action.payload),
        switchMap(payload => {
            return this.autoApiClient.getVehicleMakes(payload.vehicleType, payload.vehicleYear).pipe(
                map(apiResponse => {
                    return new GetVehicleMakesSuccessAction(apiResponse);
                }),
                catchError(error => of(new GetVehicleMakesFailAction()))
            );
        })
    );

    @Effect()
    public getVehicleModels$: Observable<Action> = this.actions$.ofType(ActionTypes.GET_VEHICLE_MODELS).pipe(
        map((action: GetVehicleModelsAction) => action.payload),
        switchMap(payload => {
            return this.autoApiClient.getVehicleModels(payload.vehicleType, payload.vehicleYear.toString(), payload.vehicleMake).pipe(
                map(apiResponse => {
                    return new GetVehicleModelsSuccessAction(apiResponse);
                }),
                catchError(error => of(new GetVehicleModelsFailAction()))
            );
        })
    );

    @Effect()
    public getCoverages$: Observable<Action> = this.actions$.ofType(ActionTypes.GET_COVERAGES).pipe(
        map((action: GetCoveragesAction) => action.payload),
        switchMap(policyEffDate => {
            return this.autoApiClient.getCoverages(policyEffDate).pipe(
                map(apiResponse => {
                    return new GetCoveragesSuccessAction(apiResponse);
                }),
                catchError(error => of(new GetCoveragesFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public lookupVin$: Observable<Action> = this.actions$.ofType(ActionTypes.LOOKUP_VIN).pipe(
        map((action: LookupVinAction) => action.payload),
        switchMap(payload => {
            return this.autoApiClient.lookupVin(payload).pipe(
                map(apiResponse => {
                    return new LookupVinSuccessAction(apiResponse);
                }),
                catchError(error => of(new LookupVinFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public getMilesDriven$: Observable<Action> = this.actions$.ofType(ActionTypes.GET_MILES_DRIVEN).pipe(
        map((action: GetMilesDrivenAction) => action.payload),
        switchMap(payload => {
            return this.autoApiClient.getMilesDriven().pipe(
                map(apiResponse => {
                    return new GetMilesDrivenSuccessAction(apiResponse);
                }),
                catchError(error => of(new GetMilesDrivenFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );
}
