import { Action } from '@ngrx/store';
import {
    Coverage,
    DeleteVehicleForm,
    Driver,
    DriverHistoryForm,
    DriverInfoForm,
    LienholderForm,
    PolicyBase,
    Vehicle,
    VehicleDetailForm,
    VehicleIncreaseDed,
    VehicleUseForm
} from '../../../models';
import { CancelPolicyForm } from '../../../models/auto/cancel/cancel-policy.model';
import { type } from '../../../utility';

// tslint:disable-next-line:variable-name
export const ActionTypes = {
    INIT: type('[Auto Change] Initialize'),
    SELECT_POLICY: type('[Auto Change] Select Policy'),
    SELECT_VEHICLE: type('[Auto Change] Select Vehicle'),
    SELECT_DRIVER: type('[Auto Change] Select Driver'),

    SUBMIT_CANCEL: type('[Auto Change] Submit'),
    SUBMIT_CANCEL_SUCCESS: type('[Auto Change] Submit Success'),
    SUBMIT_CANCEL_FAIL: type('[Auto Change] Submit Fail'),

    SAVE_LIENHOLDER: type('[Auto Change] Save Lienholder'),
    START_ADD_LIENHOLDER: type('[Auto Change] Start Add Lienholder'),
    CANCEL_ADD_LIENHOLDER: type('[Auto Change] Cancel Add Lienholder'),
    SUBMIT_EDIT_LIENHOLDER: type('[Auto Change] Submit Edit Lienholder'),
    SUBMIT_EDIT_LIENHOLDER_SUCCESS: type('[Auto Change] Submit Edit Lienholder Success'),
    SUBMIT_EDIT_LIENHOLDER_FAIL: type('[Auto Change] Submit Edit Lienholder Fail'),
    SUBMIT_ADD_LIENHOLDER: type('[Auto Change] Submit Add Lienholder'),
    SUBMIT_ADD_LIENHOLDER_SUCCESS: type('[Auto Change] Submit Add Lienholder Success'),
    SUBMIT_ADD_LIENHOLDER_FAIL: type('[Auto Change] Submit Add Lienholder Fail'),
    SUBMIT_REMOVE_LIENHOLDER: type('[Auto Change] Submit Remove Lienholder'),
    SUBMIT_REMOVE_LIENHOLDER_SUCCESS: type('[Auto Change] Submit Remove Lienholder Success'),
    SUBMIT_REMOVE_LIENHOLDER_FAIL: type('[Auto Change] Submit Remove Lienholder Fail'),

    SAVE_ADD_DRIVER_STEP2: type('[Auto Change] Save Add Driver Step 2'),
    SAVE_ADD_DRIVER_STEP3: type('[Auto Change] Save Add Driver Step 3'),
    SUBMIT_ADD_DRIVER: type('[Auto Change] Submit Add Driver'),
    SUBMIT_ADD_DRIVER_SUCCESS: type('[Auto Change] Submit Add Driver Success'),
    SUBMIT_ADD_DRIVER_FAIL: type('[Auto Change] Submit Add Driver Fail'),

    SAVE_ADD_VEHICLE_STEP2: type('[Auto Change] Add Vehicle Save Step 2'),
    SAVE_ADD_VEHICLE_STEP3: type('[Auto Change] Add Vehicle Save Step 3'),
    SUBMIT_ADD_VEHICLE: type('[Auto Change] Add Vehicle Submit'),
    SUBMIT_ADD_VEHICLE_SUCCESS: type('[Auto Change] Add Vehicle Submit Success'),
    SUBMIT_ADD_VEHICLE_FAIL: type('[Auto Change] Add Vehicle Submit Fail'),

    SUBMIT_ADD_DRIVER_VEHICLE: type('[Auto Change] Submit Add Driver & Vehicle'),
    SUBMIT_ADD_DRIVER_VEHICLE_SUCCESS: type('[Auto Change] Submit Add Driver & Vehicle Success'),
    SUBMIT_ADD_DRIVER_VEHICLE_FAIL: type('[Auto Change] Submit Add Driver & Vehicle Fail'),

    SELECT_VEHICLE_FOR_INCREASE_DED: type('[Auto Change] Select Vehicle for Increase Ded'),
    INCREASE_DED_SET_COVERAGE: type('[Auto Change] Increase Ded Set Coverage'),
    SAVE_INCREASE_DED_STEP2: type('[Auto Change] Save Increase Ded Step 2'),

    SUBMIT_INCREASE_DED: type('[Auto Change] Submit Increase Ded'),
    SUBMIT_INCREASE_DED_SUCCESS: type('[Auto Change] Submit Increase Ded Success'),
    SUBMIT_INCREASE_DED_FAIL: type('[Auto Change] Submit Increase Ded Fail'),

    SAVE_REMOVE_COVERAGE: type('[Auto Change] Save Coverages'),
    SUBMIT_REMOVE_COVERAGE: type('[Auto Change] Submit Remove'),
    SUBMIT_REMOVE_COVERAGE_SUCCESS: type('[Auto Change] Submit Remove Success'),
    SUBMIT_REMOVE_COVERAGE_FAIL: type('[Auto Change] Submit Remove Fail'),

    SUBMIT_REPLACE_VEHICLE: type('[Auto Change] Replace Vehicle Submit'),
    SUBMIT_REPLACE_VEHICLE_SUCCESS: type('[Auto Change] Replace Vehicle Submit Success'),
    SUBMIT_REPLACE_VEHICLE_FAIL: type('[Auto Change] Replace Vehicle Submit Fail'),

    SAVE_DELETE_VEHICLE: type('[Auto Change] Delete Vehicle Save'),
    SUBMIT_DELETE_VEHICLE: type('[Auto Change] Submit Delete Vehicle'),
    SUBMIT_DELETE_VEHICLE_SUCCESS: type('[Auto Change] Submit Delete Vehicle Success'),
    SUBMIT_DELETE_VEHICLE_FAIL: type('[Auto Change] Submit Delete Vehicle Fail'),

    DECLINE: type('[Auto Change] Decline'),

    CONFIRM: type('[Auto Change] Confirm'),
    CONFIRM_SUCCESS: type('[Auto Change] Confirm Success'),
    CONFIRM_FAIL: type('[Auto Change] Confirm Fail')
};

/**
 * Auto Change - Cancel Policy Actions
 */

export class InitAction implements Action {
    public type = ActionTypes.INIT;
    constructor(public payload: any = null) {}
}

export class SelectPolicyAction implements Action {
    public type = ActionTypes.SELECT_POLICY;
    constructor(public payload: PolicyBase = null) {}
}

export class SelectDriverAction implements Action {
    public type = ActionTypes.SELECT_DRIVER;
    constructor(public payload: Driver = null) {}
}

export class SelectVehicleAction implements Action {
    public type = ActionTypes.SELECT_VEHICLE;
    constructor(public payload: Vehicle = null) {}
}

export class SubmitCancelAction implements Action {
    public type = ActionTypes.SUBMIT_CANCEL;
    constructor(public payload: CancelPolicyForm = null) {}
}

export class SubmitCancelSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_CANCEL_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitCancelFailAction implements Action {
    public type = ActionTypes.SUBMIT_CANCEL_FAIL;
    constructor(public payload: any = null) {}
}

export class DeclineAction implements Action {
    public type = ActionTypes.DECLINE;
    constructor(public payload: any = null) {}
}

export class ConfirmAction implements Action {
    public type = ActionTypes.CONFIRM;
    constructor(public payload: any = null) {}
}

export class ConfirmSuccessAction implements Action {
    public type = ActionTypes.CONFIRM_SUCCESS;
    constructor(public payload: any = null) {}
}

export class ConfirmFailAction implements Action {
    public type = ActionTypes.CONFIRM_FAIL;
    constructor(public payload: any = null) {}
}

export class SaveLienholderAction implements Action {
    public type = ActionTypes.SAVE_LIENHOLDER;
    constructor(public payload: LienholderForm = null) {}
}

export class StartAddLienholderAction implements Action {
    public type = ActionTypes.START_ADD_LIENHOLDER;
    constructor(public payload: Vehicle = null) {}
}

export class CancelAddLienholderAction implements Action {
    public type = ActionTypes.CANCEL_ADD_LIENHOLDER;
    constructor(public payload: any = null) {}
}

export class SubmitEditLienholderAction implements Action {
    public type = ActionTypes.SUBMIT_EDIT_LIENHOLDER;
    constructor(public payload: any = null) {}
}

export class SubmitEditLienholderSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_EDIT_LIENHOLDER_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitEditLienholderFailAction implements Action {
    public type = ActionTypes.SUBMIT_EDIT_LIENHOLDER_FAIL;
    constructor(public payload: any = null) {}
}

export class SubmitAddLienholderAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_LIENHOLDER;
    constructor(public payload: any = null) {}
}

export class SubmitAddLienholderSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_LIENHOLDER_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitAddLienholderFailAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_LIENHOLDER_FAIL;
    constructor(public payload: any = null) {}
}

export class SubmitRemoveLienholderAction implements Action {
    public type = ActionTypes.SUBMIT_REMOVE_LIENHOLDER;
    constructor(public payload: Vehicle = null) {}
}

export class SubmitRemoveLienholderSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_REMOVE_LIENHOLDER_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitRemoveLienholderFailAction implements Action {
    public type = ActionTypes.SUBMIT_REMOVE_LIENHOLDER_FAIL;
    constructor(public payload: any = null) {}
}

export class SaveAddDriverStep2Action implements Action {
    public type = ActionTypes.SAVE_ADD_DRIVER_STEP2;
    constructor(public payload: DriverInfoForm = null) {}
}

export class SaveAddDriverStep3Action implements Action {
    public type = ActionTypes.SAVE_ADD_DRIVER_STEP3;
    constructor(public payload: DriverHistoryForm = null) {}
}

export class SubmitAddDriverAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_DRIVER;
    constructor(public payload: any = null) {}
}

export class SubmitAddDriverSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_DRIVER_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitAddDriverFailAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_DRIVER_FAIL;
    constructor(public payload: any = null) {}
}

export class SaveAddVehicleStep2 implements Action {
    public type = ActionTypes.SAVE_ADD_VEHICLE_STEP2;
    constructor(public payload: VehicleDetailForm = null) {}
}

export class SaveAddVehicleStep3 implements Action {
    public type = ActionTypes.SAVE_ADD_VEHICLE_STEP3;
    constructor(public payload: VehicleUseForm = null) {}
}

export class SubmitAddVehicleAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_VEHICLE;
    constructor(public payload: any = null) {}
}

export class SubmitAddVehicleSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_VEHICLE_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitAddVehicleFailAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_VEHICLE_FAIL;
    constructor(public payload: any = null) {}
}

export class SubmitAddDriverVehicleAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_DRIVER_VEHICLE;
    constructor(public payload: any = null) {}
}

export class SubmitAddDriverVehicleSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_DRIVER_VEHICLE_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitAddDriverVehicleFailAction implements Action {
    public type = ActionTypes.SUBMIT_ADD_DRIVER_VEHICLE_FAIL;
    constructor(public payload: any = null) {}
}

export class SelectVehicleForIncreaseDedAction implements Action {
    public type = ActionTypes.SELECT_VEHICLE_FOR_INCREASE_DED;
    constructor(public payload: any = null) {}
}

export class SetCoverageAction implements Action {
    public type = ActionTypes.INCREASE_DED_SET_COVERAGE;
    constructor(public payload: VehicleIncreaseDed = null) {}
}

export class SaveIncDedStep2Action implements Action {
    public type = ActionTypes.SAVE_INCREASE_DED_STEP2;
    constructor(public payload: Coverage[] = null) {}
}

export class SubmitIncDedAction implements Action {
    public type = ActionTypes.SUBMIT_INCREASE_DED;
    constructor(public payload: any = null) {}
}

export class SubmitIncDedSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_INCREASE_DED_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitIncDedFailAction implements Action {
    public type = ActionTypes.SUBMIT_INCREASE_DED_FAIL;
    constructor(public payload: any = null) {}
}

export class SaveRemoveListAction implements Action {
    public type = ActionTypes.SAVE_REMOVE_COVERAGE;
    constructor(public payload: Coverage[] = null) {}
}

export class SubmitRemoveAction implements Action {
    public type = ActionTypes.SUBMIT_REMOVE_COVERAGE;
    constructor(public payload: any = null) {}
}

export class SubmitRemoveSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_REMOVE_COVERAGE_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitRemoveFailAction implements Action {
    public type = ActionTypes.SUBMIT_REMOVE_COVERAGE_FAIL;
    constructor(public payload: any = null) {}
}

export class SubmitReplaceAction implements Action {
    public type = ActionTypes.SUBMIT_REPLACE_VEHICLE;
    constructor(public payload: any = null) {}
}

export class SubmitReplaceSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_REPLACE_VEHICLE_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitReplaceFailAction implements Action {
    public type = ActionTypes.SUBMIT_REPLACE_VEHICLE_FAIL;
    constructor(public payload: any = null) {}
}

export class SaveDeleteVehicleAction implements Action {
    public type = ActionTypes.SAVE_DELETE_VEHICLE;
    constructor(public payload: DeleteVehicleForm = null) {}
}

export class SubmitDeleteVehicleAction implements Action {
    public type = ActionTypes.SUBMIT_DELETE_VEHICLE;
    constructor(public payload: number = null) {} // TODO
}

export class SubmitDeleteVehicleSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_DELETE_VEHICLE_SUCCESS;
    constructor(public payload: any = null) {}
}

export class SubmitDeleteVehicleFailAction implements Action {
    public type = ActionTypes.SUBMIT_DELETE_VEHICLE_FAIL;
    constructor(public payload: any = null) {}
}

export type Actions =
    | InitAction
    | SelectPolicyAction
    | SelectVehicleAction
    | SelectDriverAction
    | DeclineAction
    | ConfirmAction
    | ConfirmSuccessAction
    | ConfirmFailAction
    | SubmitCancelAction
    | SubmitCancelSuccessAction
    | SubmitCancelFailAction
    | SaveLienholderAction
    | StartAddLienholderAction
    | SubmitEditLienholderAction
    | SubmitEditLienholderSuccessAction
    | SubmitEditLienholderFailAction
    | SubmitAddLienholderAction
    | SubmitAddLienholderSuccessAction
    | SubmitAddLienholderFailAction
    | SubmitRemoveLienholderAction
    | SubmitRemoveLienholderSuccessAction
    | SubmitRemoveLienholderFailAction
    | SaveAddDriverStep2Action
    | SaveAddDriverStep3Action
    | SubmitAddDriverAction
    | SubmitAddDriverSuccessAction
    | SubmitAddDriverFailAction
    | SaveAddVehicleStep2
    | SaveAddVehicleStep3
    | SubmitAddVehicleAction
    | SubmitAddVehicleSuccessAction
    | SubmitAddVehicleFailAction
    | SubmitAddDriverVehicleAction
    | SubmitAddDriverVehicleSuccessAction
    | SubmitAddDriverVehicleFailAction
    | SelectVehicleForIncreaseDedAction
    | SetCoverageAction
    | SaveIncDedStep2Action
    | SubmitIncDedAction
    | SubmitIncDedSuccessAction
    | SubmitIncDedFailAction
    | SaveRemoveListAction
    | SubmitRemoveAction
    | SubmitRemoveSuccessAction
    | SubmitRemoveFailAction
    | SubmitReplaceAction
    | SubmitReplaceSuccessAction
    | SubmitReplaceFailAction
    | SaveDeleteVehicleAction
    | SubmitDeleteVehicleAction
    | SubmitDeleteVehicleSuccessAction
    | SubmitDeleteVehicleFailAction;
