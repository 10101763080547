import { Headers, Request, RequestOptions, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

export function methodBuilder(method: number) {
    return function(url: string, claim: boolean) {
        return function(target: HttpService, propertyKey: string, descriptor: any) {
            const pPath = target[`${propertyKey}_Path_parameters`],
                pQuery = target[`${propertyKey}_Query_parameters`],
                pBody = target[`${propertyKey}_Body_parameters`],
                pHeader = target[`${propertyKey}_Header_parameters`];

            descriptor.value = function(...args: any[]) {
                const body: string = createBody(pBody, descriptor, args);
                let resUrl: string = createPath(url, pPath, args);
                const search: URLSearchParams = createQuery(pQuery, args);
                let headers: Headers = createHeaders(pHeader, descriptor, this.getDefaultHeaders(), args);
                let baseUrl = this.getBaseUrl()

            if (!resUrl.startsWith('http')) {
                resUrl = baseUrl + resUrl;
            }

                // Request options
                const options = new RequestOptions({
                    method,
                    url: resUrl,
                    headers,
                    body,
                    search,
                    withCredentials: this.getUseWithCredentials()
                 
                });

                const req = new Request(options);
               
                // intercept the request
                this.requestInterceptor(req);
                //console.log(req.headers);
                // make the request and store the observable for later transformation
                let observable: Observable<Response> = this.http.request(req);

                // intercept the response
                observable = this.responseInterceptor(observable, descriptor.adapter);

                return observable;
            };

            return descriptor;
        };
    };
}

export function paramBuilder(paramName: string) {
    return function(key: string) {
        // return function(target: HttpService, propertyKey: string | symbol, parameterIndex: number) {
        return function(target: HttpService, propertyKey: string, parameterIndex: number) {
            const metadataKey = `${propertyKey}_${paramName}_parameters`;
            const paramObj: any = {
                key: key,
                parameterIndex: parameterIndex
            };

            if (Array.isArray(target[metadataKey])) {
                target[metadataKey].push(paramObj);
            } else {
                target[metadataKey] = [paramObj];
            }
        };
    };
}

function createBody(pBody: any[], descriptor: any, args: any[]): string {
    if (descriptor.isFormData) {
        return args[0];
    }
    return pBody ? JSON.stringify(args[pBody[0].parameterIndex]) : null;
}

function createPath(url: string, pPath: any[], args: any[]): string {
    let resUrl: string = url;

    if (pPath) {
        for (const k in pPath) {
            if (pPath.hasOwnProperty(k)) {
                resUrl = resUrl.replace('{' + pPath[k].key + '}', args[pPath[k].parameterIndex]);
            }
        }
    }

    return resUrl;
}

function createQuery(pQuery: any, args: any[]): URLSearchParams {
    const search = new URLSearchParams();

    if (pQuery) {
        pQuery
            .filter(p => args[p.parameterIndex]) // filter out optional parameters
            .forEach(p => {
                const key = p.key;
                let value = args[p.parameterIndex];
                // if the value is a instance of Object, we stringify it
                if (value instanceof Object) {
                    value = JSON.stringify(value);
                }
                search.set(encodeURIComponent(key), encodeURIComponent(value));
            });
    }

    return search;
}

function createHeaders(pHeader: any, descriptor: any, defaultHeaders: any, args: any[]): Headers {
    const headers = new Headers(defaultHeaders);

    // set method specific headers
    for (const k in descriptor.headers) {
        if (descriptor.headers.hasOwnProperty(k)) {
            if (headers.has(k)) {
                headers.delete(k);
            }
            headers.append(k, descriptor.headers[k]);
        }
    }

    // set parameter specific headers
    if (pHeader) {
        for (const k in pHeader) {
            if (pHeader.hasOwnProperty(k)) {
                if (headers.has(k)) {
                    headers.delete(k);
                }
                headers.append(pHeader[k].key, args[pHeader[k].parameterIndex]);
            }
        }
    }

    return headers;
}
