import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './pageNotFound/pageNotFound.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/policy', pathMatch: 'full' },
    {
        path: 'billing',
        loadChildren: './billing/billing.module#BillingModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'change',
        loadChildren: './change/change.module#ChangeModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'claims',
        loadChildren: './claims/claims.module#ClaimsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: './user/user.module#UserModule'
    },
    {
        path: 'documents',
        loadChildren: './document/document.module#DocumentModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'policy',
        loadChildren: './policy/policy.module#PolicyModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'myprofile',
        loadChildren: './my-profile/my-profile.module#MyProfileModule'
    },
    {
        path: 'membership',
        loadChildren: './membership/membership.module#MembershipModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'register',
        loadChildren: './register/register.module#RegisterModule'
    },
    {
        path: 'paymybill',
        loadChildren: './pay-my-bill/pay-my-bill.module#PayMyBillModule'
    },
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
