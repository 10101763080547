import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { State } from '../..';
import { UserApiClient } from '../../../asyncServices/api-clients/user-apiclient.service';
import { RegisterApiRequestFactory } from '../../../models/register/registerApi-request.factory';
import { UserApiRequestFactory } from '../../../models/user/userApi-request.factory';
import { ErrorMsgService } from '../../../utility/error-msg.service';
import * as contactUsActions from '../../actions/contact-us/contact-us.action';
import * as userActions from '../../actions/user/user.action';
import { getUser, getUserState } from '../../selectors';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private userApiClient: UserApiClient,
        private router: Router,
        private appState$: Store<State>,
        private errorMsgService: ErrorMsgService
    ) {}

    @Effect()
    public loginUser$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.LOGIN_USER).pipe(
        map((action: userActions.LoginUserAction) => {
            return action.payload;
        }),
        switchMap(payload => {
            return this.userApiClient.login(RegisterApiRequestFactory.createLoginRequest(payload)).pipe(
                map(user => {
                    if (user.status === 'AUTHENTICATED') {
                        return new userActions.LoginUserSuccessAction(user);
                    } else if (user.status === 'CHALLENGED') {
                        return new userActions.LoginUserChallengeAction(user);
                    } else if (user.status === 'CHANGE_PASSWORD') {
                        return new userActions.LoginResetPasswordAction(user);
                    } else if (user.status === 'REG_VERIFY') {
                        return new userActions.LoginVerifyAction(user);
                    } else if (user.status === 'REG_QUESTIONS' || user.status === 'RESET_QUESTIONS') {
                        return new userActions.LoginQuestionsAction(user);
                    }
                }),
                catchError(error => of(new userActions.LoginUserFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    // @Effect({ dispatch: false })
    // public loginSuccess$ = this.actions$.pipe(
    //     ofType(userActions.ActionTypes.LOGIN_USER_SUCCESS),
    //     tap(() => this.router.navigate(['/login'], { skipLocationChange: true }))
    // );

    @Effect({ dispatch: false })
    public loginchallenge$ = this.actions$.pipe(
        ofType(userActions.ActionTypes.LOGIN_USER_CHALLENGE),
        tap(() => this.router.navigate(['/login/challenge'], { skipLocationChange: true }))
    );

    @Effect({ dispatch: false })
    public loginResetPassword$ = this.actions$.pipe(
        ofType(userActions.ActionTypes.LOGIN_RESET_PASSWORD),
        tap(() => this.router.navigate(['/login/reset'], { skipLocationChange: true }))
    );

    @Effect({ dispatch: false })
    public loginVerify$ = this.actions$.pipe(
        ofType(userActions.ActionTypes.LOGIN_REG_VERIFY),
        tap(() => this.router.navigate(['/login/verify'], { skipLocationChange: true }))
    );

    @Effect({ dispatch: false })
    public challengeSuccess$ = this.actions$.pipe(
        ofType(userActions.ActionTypes.LOGIN_USER_SUBMIT_CHALLENGE_SUCCESS),
        tap(() => this.router.navigate(['/login']))
    );

    @Effect({ dispatch: false })
    public loginQuestions$ = this.actions$.pipe(
        ofType(userActions.ActionTypes.LOGIN_QUESTIONS),
        tap(() => this.router.navigate(['/login/questions'], { skipLocationChange: true }))
    );

    @Effect()
    public submitChallenge$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.LOGIN_USER_SUBMIT_CHALLENGE).pipe(
        map((action: userActions.LoginUserSubmitChallengeAction) => action.payload),
        switchMap(payload => {
            return this.userApiClient.authenticate(RegisterApiRequestFactory.createAuthenticateRequest(payload)).pipe(
                map(userApiResponse => {
                    if (userApiResponse.status === 'AUTHENTICATED') {
                        return new userActions.LoginUserSubmitChallengeSuccessAction(userApiResponse);
                    } else {
                        return new userActions.LoginUserChallengeRetryAction(userApiResponse);
                    }
                }),
                catchError(error => of(new userActions.LoginUserSubmitChallengeFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public submit$: Observable<Action> = this.actions$.ofType(contactUsActions.ActionTypes.SUBMIT).pipe(
        map((action: contactUsActions.SubmitAction) => action.payload),
        switchMap(payload => {
            return this.userApiClient.contact(UserApiRequestFactory.createContactRequest(payload)).pipe(
                map(apiResponse => new contactUsActions.SubmitSuccessAction(apiResponse)),
                catchError(error => of(new contactUsActions.SubmitFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public forgotPassword$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.FORGOT_PASSWORD).pipe(
        map((action: userActions.ForgotPasswordAction) => action.payload),
        switchMap(payload => {
            return this.userApiClient.forgotPassword(RegisterApiRequestFactory.createForgotPasswordRequest(payload, this.router.url)).pipe(
                map(apiResponse => new userActions.ForgotPasswordSuccessAction(apiResponse)),
                catchError(error => of(new userActions.ForgotPasswordFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public forgotUsername$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.FORGOT_USERNAME).pipe(
        map((action: userActions.ForgotUsernameAction) => action.payload),
        switchMap(payload => {
            return this.userApiClient.forgotUsername(RegisterApiRequestFactory.createForgotUsernameRequest(payload)).pipe(
                map(apiResponse => new userActions.ForgotUsernameSuccessAction(apiResponse)),
                catchError(error => of(new userActions.ForgotUsernameFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public loginResetPasswordSubmit$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.LOGIN_RESET_PASSWORD_SUBMIT).pipe(
        map((action: userActions.LoginResetPasswordSubmitAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.userApiClient.resetPassword(RegisterApiRequestFactory.createResetPasswordRequest(payload, user)).pipe(
                map(apiResponse => new userActions.LoginResetPasswordSubmitSuccessAction(apiResponse)),
                catchError(error => of(new userActions.LoginResetPasswordSubmitFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public resendVerificationEmail$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.LOGIN_REG_VERIFY_SUBMIT).pipe(
        map((action: userActions.LoginVerifySubmitAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.userApiClient
                .resentVerificationEmail(RegisterApiRequestFactory.createResendVerficationEmailRequest(this.router.url, user))
                .pipe(
                    map(apiResponse => new userActions.LoginVerifySubmitSuccessAction(apiResponse)),
                    catchError(error => of(new userActions.LoginVerifySubmitFailAction({ error: this.errorMsgService.getMsg(error) })))
                );
        })
    );

    @Effect({ dispatch: false })
    public loginResetPasswordSuccess$ = this.actions$.pipe(
        ofType(userActions.ActionTypes.LOGIN_RESET_PASSWORD_SUBMIT_SUCCESS),
        tap(() => this.router.navigate(['/']))
    );

    @Effect()
    public loginQuestionsSubmit$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.LOGIN_QUESTIONS_SUBMIT).pipe(
        map((action: userActions.LoginQuestionsSubmitAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            if (user.status === 'REG_QUESTIONS') {
                return this.userApiClient
                    .registerQuestions(RegisterApiRequestFactory.createRegisterSecurityRq(payload, user.id, this.router.url))
                    .pipe(
                        map(apiResponse => new userActions.LoginQuestionsSubmitSuccessAction(apiResponse)),
                        catchError(error =>
                            of(new userActions.LoginQuestionsSubmitFailAction({ error: this.errorMsgService.getMsg(error) }))
                        )
                    );
            } else {
                return this.userApiClient
                    .resetQuestions(RegisterApiRequestFactory.createRegisterSecurityRq(payload, user.id, this.router.url))
                    .pipe(
                        map(apiResponse => new userActions.LoginQuestionsSubmitSuccessAction(apiResponse)),
                        catchError(error =>
                            of(new userActions.LoginQuestionsSubmitFailAction({ error: this.errorMsgService.getMsg(error) }))
                        )
                    );
            }
        })
    );

    @Effect({ dispatch: false })
    public loginQuestionsSubmitSuccess$ = this.actions$.pipe(
        ofType(userActions.ActionTypes.LOGIN_QUESTIONS_SUBMIT_SUCCESS),
        tap(() => this.router.navigate(['/']))
    );

    @Effect()
    public recoverPasswordInit$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.RECOVER_PWD_INIT).pipe(
        map((action: userActions.RecoverPasswordInitAction) => action.payload),
        switchMap(payload => {
            return this.userApiClient.recoverPasswordInit(payload).pipe(
                map(apiResponse => new userActions.RecoverPasswordInitSuccessAction(apiResponse)),
                catchError(error => of(new userActions.RecoverPasswordInitFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public resetSecurityInit$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.LOGIN_RESET_SECURITY_SUBMIT).pipe(
        map((action: userActions.LoginResetSecuritySubmitAction) => action.payload),
        switchMap(payload => {
            return this.userApiClient.resetInit(payload).pipe(
                map(apiResponse => new userActions.LoginResetSecuritySubmitSuccessAction(apiResponse)),
                catchError(error => of(new userActions.LoginResetSecuritySubmitFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public recoverPasswordAuth$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.RECOVER_PWD_AUTH).pipe(
        map((action: userActions.RecoverPasswordAuthAction) => action.payload),
        withLatestFrom(this.appState$.select(getUserState)),
        switchMap(([payload, userState]) => {
            return this.userApiClient
                .recoverPasswordAuth(RegisterApiRequestFactory.createRecoverPasswordAuthRequest(payload, userState))
                .pipe(
                    map(apiResponse => {
                        if (apiResponse.status === 'AUTHENTICATED') {
                            return new userActions.RecoverPasswordAuthSuccessAction(apiResponse);
                        } else {
                            return new userActions.RecoverPasswordAuthRetryAction(apiResponse);
                        }
                    }),
                    catchError(error => of(new userActions.RecoverPasswordAuthFailAction({ error: this.errorMsgService.getMsg(error) })))
                );
        })
    );

    @Effect()
    public recoverPasswordComplete$: Observable<Action> = this.actions$.ofType(userActions.ActionTypes.RECOVER_PWD_COMPLETE).pipe(
        map((action: userActions.RecoverPasswordCompleteAction) => action.payload),
        withLatestFrom(this.appState$.select(getUserState)),
        switchMap(([payload, userState]) => {
            return this.userApiClient
                .recoverPasswordComplete(RegisterApiRequestFactory.createRecoverPasswordCompleteRequest(payload, userState))
                .pipe(
                    map(apiResponse => new userActions.RecoverPasswordCompleteSuccessAction(apiResponse)),
                    catchError(error =>
                        of(new userActions.RecoverPasswordCompleteFailAction({ error: this.errorMsgService.getMsg(error) }))
                    )
                );
        })
    );
}
