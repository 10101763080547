export enum ContactTypeEnums {

    HOME_PHONE       = 'HOME_PHONE',
    WORK_PHONE       = 'WORK_PHONE',
    CELL_PHONE       = 'CELL_PHONE',
    FAX              = 'FAX',
    SPEED_DIAL       = 'SPEED_DIAL',
    HOME_EMAIL       = 'HOME_EMAIL',
    WORK_EMAIL       = 'WORK_EMAIL',
    INSURANCE_EMAIL  = 'INSURANCE_EMAIL',
    FB_EMAIL         = 'FB_EMAIL',
    TRH_EMAIL        = 'TRH_EMAIL'
}

export namespace ContactTypeEnums {
    export function isEmail(input: ContactTypeEnums): boolean {
        switch (input) {
            case ContactTypeEnums.HOME_EMAIL:
            case ContactTypeEnums.FB_EMAIL:
            case ContactTypeEnums.INSURANCE_EMAIL:
            case ContactTypeEnums.TRH_EMAIL:
            case ContactTypeEnums.WORK_EMAIL:
            return true;
            default:
            return false;
        }
    }
    export function isPhone(input: ContactTypeEnums): boolean {
        switch (input) {
            case ContactTypeEnums.CELL_PHONE:
            case ContactTypeEnums.WORK_PHONE:
            case ContactTypeEnums.HOME_PHONE:
                return true;
            default:
                return false;
        }
    }
}
