import { createSelector } from '@ngrx/store';
import { State } from '../..';

import * as fromClaimsSupport from '../../reducers/claims/claims-support.reducer';

export const getClaimsSupport = (state: State) => state.claimsSupport;

export const getVehicleColorData = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getVehicleColorData
);
export const getVehicleColorLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getVehicleColorLoading
);
export const getVehicleColorLoaded = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getVehicleColorLoaded
);

export const getAutoDamageLocationsData = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAutoDamageLocationsData
);
export const getAutoDamageLocationsLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAutoDamageLocationsLoading
);
export const getAutoDamageLocationsLoaded = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAutoDamageLocationsLoaded
);

export const getAllDamageLocationsData = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAllDamageLocationsData
);
export const getAllDamageLocationsLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAllDamageLocationsLoading
);
export const getAllDamageLocationsLoaded = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAllDamageLocationsLoaded
);
export const getAllDamageLocationsFailed = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAllDamageLocationsFailed
);

export const getGlassDamageLocationsData = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getGlassDamageLocationsData
);
export const getGlassDamageLocationsLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getGlassDamageLocationsLoading
);
export const getGlassDamageLocationsLoaded = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getGlassDamageLocationsLoaded
);
export const getGlassDamageLocationsFailed = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getGlassDamageLocationsFailed
);

export const getCountiesData = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getCountiesData
);
export const getCountiesLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getCountiesLoading
);
export const getCountiesLoaded = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getCountiesLoaded
);
export const getCountiesFailed = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getCountiesFailed
);

export const getClaimHistoryData = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getClaimHistoryData
);
export const getClaimHistoryLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getClaimHistoryLoading
);
export const getClaimHistoryLoaded = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getClaimHistoryLoaded
);

export const getGlassVendorsByCountyData = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getGlassVendorsByCountyData
);
export const getGlassVendorsByCountyLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getGlassVendorsByCountyLoading
);
export const getGlassVendorsByCountyLoaded = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getGlassVendorsByCountyLoaded
);
export const getGlassVendorsByCountyFailed = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getGlassVendorsByCountyFailed
);

export const getClaimDetailsData = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getClaimDetailsData
);
export const getClaimDetailsLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getClaimDetailsLoading
);
export const getClaimDetailsLoaded = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getClaimDetailsLoaded
);
export const getClaimDetailsFailed = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getClaimDetailsFailed
);

export const claimsSupportDataLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.claimsSupportDataLoading
);

export const getClaimsSupportError = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getClaimsSupportError
);

export const getAuthorityTypesData= createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAuthorityTypesData
);
export const getAuthorityTypesDataLoading = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAuthorityTypesDataLoading
);
export const getAuthorityTypesDataLoaded = createSelector(
    getClaimsSupport,
    fromClaimsSupport.getAuthorityTypesLoaded
);
