import { AutoAddDriverApiRequest } from './autoAddDriverApi-request';
import { AutoAddVehicleApiRequest } from './autoAddVehicleApi-request';
import { AutoLienholderApiRequest } from './autoLienholderApi-request';

export class AutoAddDriverVehicleApiRequest {
    public customerId: string;
    public driverChg: AutoAddDriverApiRequest;
	public vehicleChg: AutoAddVehicleApiRequest;
	public vehLienLeasedAddress: AutoLienholderApiRequest;

    constructor(init?: Partial<AutoAddDriverVehicleApiRequest>) {
        Object.assign(this, init);
    }
}
