import { Vendor } from '../../vendor.model';

export class GlassDetailsForm {
    public damageLocations: string[];
    public incidentDetails: string;
    public preferredProvider: boolean;
    public otherGlassVendor: string;
    public county: string;
    public vendor: Vendor;

    constructor(init?: Partial<GlassDetailsForm>) {
        if (!init) { init = {}; }
        this.damageLocations = init.damageLocations || new Array<string>();
        this.incidentDetails = init.incidentDetails || null;
        this.preferredProvider = init.preferredProvider === undefined ? null : init.preferredProvider;
        this.otherGlassVendor = init.otherGlassVendor || null;
        this.county = init.county || null;
        this.vendor = init.vendor || new Vendor();
    }
}
