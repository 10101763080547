export class ClaimLocation {
    public lossLocationDesc: string;
    public city: string;
    public countyCd: string;
    public stateCd: string;
    public zip: string;
    public newLossLocation: boolean;

    constructor(init?: Partial<ClaimLocation>) {
        if (!init) { init = {}; }
        this.lossLocationDesc = init.lossLocationDesc || null;
        this.city = init.city || null;
        this.countyCd = init.countyCd || null;
        this.stateCd = init.stateCd || null;
        this.zip = init.zip || null;
        this.newLossLocation = init.newLossLocation || null;
    }
}
