import { SecurityQuestionsGroup } from '../../../models/register/security-questions-group.model';
import { Actions, ActionTypes, EditPersonalInfoSuccessAction } from '../../actions/myprofile/myprofile.action';

export interface MyProfileState {
    passwordChangeProcessing: boolean;
    passwordChangeFailed: boolean;
    passwordChangeMsg: string;

    securityQuestions: SecurityQuestionsGroup[];
    securityQuestionsLoading: boolean;
    securityQuestionsLoaded: boolean;

    securityQuestionsChangeProcessing: boolean;
    securityQuestionsChangeFailed: boolean;
    securityQuestionsChangeMsg: string;

    editPersonalInfoProcessing: boolean;
    editPersonalInfoFailed: boolean;
    editPersonalInfoMsg: string;
}

const INITIAL_STATE: MyProfileState = {
    passwordChangeProcessing: false,
    passwordChangeFailed: false,
    passwordChangeMsg: null,

    securityQuestions: new Array<SecurityQuestionsGroup>(),
    securityQuestionsLoading: false,
    securityQuestionsLoaded: false,

    securityQuestionsChangeProcessing: false,
    securityQuestionsChangeFailed: false,
    securityQuestionsChangeMsg: null,

    editPersonalInfoProcessing: false,
    editPersonalInfoFailed: false,
    editPersonalInfoMsg: null
};

//  Reducers specify how the application’s state changes in response to actions.
export function myProfileReducer(state = INITIAL_STATE, action: Actions): MyProfileState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case ActionTypes.CHANGE_PASSWORD: {
            return {
                ...state,
                passwordChangeProcessing: true
            };
        }
        case ActionTypes.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                passwordChangeProcessing: false,
                passwordChangeFailed: false,
                passwordChangeMsg: action.payload
            };
        }
        case ActionTypes.CHANGE_PASSWORD_FAIL: {
            return {
                ...state,
                passwordChangeProcessing: false,
                passwordChangeFailed: true,
                passwordChangeMsg: action.payload
            };
        }
        case ActionTypes.CHANGE_PASSWORD_CLEAR: {
            return {
                ...state,
                passwordChangeProcessing: INITIAL_STATE.passwordChangeProcessing,
                passwordChangeFailed: INITIAL_STATE.passwordChangeFailed,
                passwordChangeMsg: INITIAL_STATE.passwordChangeMsg
            };
        }

        case ActionTypes.LOAD_SECURITY_QUESTIONS: {
            return {
                ...state,
                securityQuestionsLoading: true,
                securityQuestionsLoaded: false
            };
        }
        case ActionTypes.LOAD_SECURITY_QUESTIONS_SUCCESS: {
            return {
                ...state,
                securityQuestionsLoading: false,
                securityQuestionsLoaded: true,
                securityQuestions: action.payload
            };
        }
        case ActionTypes.LOAD_SECURITY_QUESTIONS_FAIL: {
            return {
                ...state,
                securityQuestionsLoading: false,
                securityQuestionsLoaded: false
            };
        }

        case ActionTypes.CHANGE_SECURITY_QUESTIONS: {
            return {
                ...state,
                securityQuestionsChangeProcessing: true
            };
        }
        case ActionTypes.CHANGE_SECURITY_QUESTIONS_SUCCESS: {
            return {
                ...state,
                securityQuestionsChangeProcessing: false,
                securityQuestionsChangeFailed: false,
                securityQuestionsChangeMsg: action.payload
            };
        }
        case ActionTypes.CHANGE_SECURITY_QUESTIONS_FAIL: {
            return {
                ...state,
                securityQuestionsChangeProcessing: false,
                securityQuestionsChangeFailed: true,
                securityQuestionsChangeMsg: action.payload
            };
        }
        case ActionTypes.CHANGE_SECURITY_QUESTIONS_CLEAR: {
            return {
                ...state,
                securityQuestionsChangeProcessing: INITIAL_STATE.securityQuestionsChangeProcessing,
                securityQuestionsChangeFailed: INITIAL_STATE.securityQuestionsChangeFailed,
                securityQuestionsChangeMsg: INITIAL_STATE.securityQuestionsChangeMsg
            };
        }

        case ActionTypes.EDIT_PERSONAL_INFO: {
            return {
                ...state,
                editPersonalInfoProcessing: true
            };
        }
        case ActionTypes.EDIT_PERSONAL_INFO_SUCCESS: {
            return {
                ...state,
                editPersonalInfoProcessing: false
            };
        }
        case ActionTypes.EDIT_PERSONAL_INFO_FAIL: {
            return {
                ...state,
                editPersonalInfoProcessing: false,
                editPersonalInfoFailed: true,
                editPersonalInfoMsg: action.payload
            };
        }
        case ActionTypes.EDIT_PERSONAL_INFO_CLEAR: {
            return {
                ...state,
                editPersonalInfoProcessing: INITIAL_STATE.editPersonalInfoProcessing,
                editPersonalInfoFailed: INITIAL_STATE.editPersonalInfoFailed,
                editPersonalInfoMsg: INITIAL_STATE.editPersonalInfoMsg
            };
        }

        default: {
            return state;
        }
    }
}

export const myProfileDataLoading = (state: MyProfileState) =>
    state.passwordChangeProcessing ||
    state.securityQuestionsLoading ||
    state.securityQuestionsChangeProcessing ||
    state.editPersonalInfoProcessing;

export const getPasswordChangeMessage = (state: MyProfileState) => state.passwordChangeMsg;
export const getPasswordChangeFailed = (state: MyProfileState) => state.passwordChangeFailed;
export const getPasswordChangeProcessing = (state: MyProfileState) => state.passwordChangeProcessing;

export const getSecurityQuestions = (state: MyProfileState) => state.securityQuestions;
export const getSecurityQuestionsLoading = (state: MyProfileState) => state.securityQuestionsLoading;
export const getSecurityQuestionsLoaded = (state: MyProfileState) => state.securityQuestionsLoaded;

export const getSecurityQuestionsChangeMessage = (state: MyProfileState) => state.securityQuestionsChangeMsg;
export const getSecurityQuestionsChangeFailed = (state: MyProfileState) => state.securityQuestionsChangeFailed;
export const getSecurityQuestionsChangeProcessing = (state: MyProfileState) => state.securityQuestionsChangeProcessing;

export const getEditPersonalInfoMessage = (state: MyProfileState) => state.editPersonalInfoMsg;
export const getEditPersonalInfoFailed = (state: MyProfileState) => state.editPersonalInfoFailed;
export const getEditPersonalInfoProcessing = (state: MyProfileState) => state.editPersonalInfoProcessing;
