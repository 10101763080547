import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { State } from '../..';
import { PolicyApiClient } from '../../../asyncServices';
import { RegisterApiClient } from '../../../asyncServices/api-clients/register-apiclient.service';
import { RegisterApiRequestFactory } from '../../../models/register/registerApi-request.factory';
import { ErrorMsgService } from '../../../utility/error-msg.service';
import * as registerActions from '../../actions/register/register.action';
import { getPolicyResponse, getUser } from '../../selectors';

@Injectable()
export class RegisterEffects {
    constructor(
        private actions$: Actions,
        private registerApiClient: RegisterApiClient,
        private appState$: Store<State>,
        private errorMsgService: ErrorMsgService,
        private router: Router
    ) { }

    /**
     * Registration Effects
     */
    @Effect()
    public submitPolicy$: Observable<Action> = this.actions$.ofType(registerActions.ActionTypes.SUBMIT_POLICY).pipe(
        map((action: registerActions.SubmitPolicyAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.registerApiClient.validate(RegisterApiRequestFactory.createRegisterPolicyRq(payload)).pipe(
                map(apiResponse => new registerActions.SubmitPolicySuccessAction(apiResponse)),
                catchError(error =>
                    of(
                        new registerActions.SubmitPolicyFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    @Effect()
    public submitAccount$: Observable<Action> = this.actions$.ofType(registerActions.ActionTypes.SUBMIT_ACCOUNT).pipe(
        map((action: registerActions.SubmitAccountAction) => action.payload),
        withLatestFrom(this.appState$.select(getPolicyResponse)),
        switchMap(([payload, policyResponse]) => {
            return this.registerApiClient.profile(RegisterApiRequestFactory.createRegisterAccountRq(payload, policyResponse)).pipe(
                map(apiResponse => new registerActions.SubmitAccountSuccessAction(apiResponse)),
                catchError(error =>
                    of(
                        new registerActions.SubmitAccountFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    @Effect()
    public submitSecurity$: Observable<Action> = this.actions$.ofType(registerActions.ActionTypes.SUBMIT_SECURITY).pipe(
        map((action: registerActions.SubmitSecurityAction) => action.payload),
        withLatestFrom(this.appState$.select(getPolicyResponse)),
        switchMap(([payload, policyResponse]) => {
            return this.registerApiClient.questions(RegisterApiRequestFactory.createRegisterSecurityRq(payload, policyResponse.id, this.router.url)).pipe(
                map(apiResponse => new registerActions.SubmitSecuritySuccessAction(apiResponse)),
                catchError(error =>
                    of(
                        new registerActions.SubmitSecurityFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    @Effect()
    public submitVerification$: Observable<Action> = this.actions$.ofType(registerActions.ActionTypes.SUBMIT_VERIFICATION).pipe(
        map((action: registerActions.SubmitVerificationAction) => action.payload),
        switchMap((payload) => {
            return this.registerApiClient.verify(payload).pipe(
                map(apiResponse => new registerActions.SubmitVerificationSuccessAction(apiResponse)),
                catchError(error =>
                    of(
                        new registerActions.SubmitVerificationFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );
}
