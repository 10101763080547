import {CurrencyPipe} from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currency_or_string'
})
export class CurrencyOrStringPipe extends CurrencyPipe {
    public transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null {
        try{
            return super.transform(value, currencyCode, display, digitsInfo, locale);
        } catch (err) {
            return value;
        }
    }
}
