import { Alert } from './alert.model';
import { PolicyBase } from './base/policy-base.model';

export class PolicyResponse {
    public policies: PolicyBase[];
    public alerts: Alert[];

    constructor(init?: Partial<PolicyResponse>) {
        if (!init) { init = {}; }
        this.alerts = init.alerts || new Array<Alert>();
        this.policies = init.policies || new Array<PolicyBase>();
    }
}
