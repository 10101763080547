import { Action } from '@ngrx/store';
import {
    AutoDetailsForm,
    Claim,
    ClaimsApiResponse,
    ClaimsBasicInfoForm,
    ClaimsContactForm,
    ClaimsPhotoUpload,
    ClaimsSelectPolicy,
    ErsDetailsForm,
    GlassDetailsForm,
    PolicyBase,
    PropertyDetailsForm,
    WaterDetailsForm
} from '../../../models';
import { AutoClaim } from '../../../models/claims/auto/auto/autoClaim.model';
import { PeopleInvolvedForm } from '../../../models/claims/auto/auto/peopleInvolved.model';
import { VehicleDetailsForm } from '../../../models/claims/auto/auto/vehicleDetails.model';
import { type } from '../../../utility';



// tslint:disable-next-line:variable-name
const GeneralClaimActionTypes = {
    COMPLETE_CLAIM:                         type('[Claims File] Complete Claim'),
    COMPLETE_CLAIM_SUCCESS:                 type('[Claims File] Complete Claim Success'),
    COMPLETE_CLAIM_FAIL:                    type('[Claims File] Complete Claim Fail'),

    SAVE_CLAIM:                             type('[Claims File] Save Claim'),
    SAVE_CLAIM_SUCCESS:                     type('[Claims File] Save Claim Success'),
    SAVE_CLAIM_FAIL:                        type('[Claims File] Save Claim Fail'),

    FIND_POLICY:                            type('[Claims File] Find Policy'),
    FIND_POLICY_SUCCESS:                    type('[Claims File] Find Policy Success'),
    FIND_POLICY_FAIL:                       type('[Claims File] Find Policy Fail'),

    SELECT_POLICY:                          type('[Claims File] Select Policy'),
    SELECT_POLICY_SUCCESS:                  type('[Claims File] Select Policy Success'),
    SELECT_POLICY_FAIL:                     type('[Claims File] Select Policy Fail'),

    LOAD_CONTACT_INFO:                      type('[Claims File] Load Contact Info'),
    LOAD_CONTACT_INFO_SUCCESS:              type('[Claims File] Load Contact Info Success'),
    LOAD_CONTACT_INFO_FAIL:                 type('[Claims File] Load Contact Info Fail'),

    CLEAR_STORE:                            type('[Claims File] Clear Store')
};

// tslint:disable-next-line:variable-name
const AutoClaimActionTypes = {
    SUBMIT_AUTO_BASIC_INFO:                 type('[Claims File] Submit Auto Basic Info'),
    SUBMIT_AUTO_BASIC_INFO_SUCCESS:         type('[Claims File] Submit Auto Basic Info Success'),
    SUBMIT_AUTO_BASIC_INFO_FAIL:            type('[Claims File] Submit Auto Basic Info Fail'),

    UPDATE_AUTO_BASIC_INFO:                 type('[Claims File] Update Auto Basic Info'),
    UPDATE_AUTO_BASIC_INFO_SUCCESS:         type('[Claims File] Update Auto Basic Info Success'),
    UPDATE_AUTO_BASIC_INFO_FAIL:            type('[Claims File] Update Auto Basic Info Fail'),

    SAVE_AUTO_VEHICLE_INFO:                 type('[Claims File] Save Auto Vehicle Info'),
    SAVE_AUTO_VEHICLE_INFO_SUCCESS:         type('[Claims File] Save Auto Vehicle Info Success'),
    SAVE_AUTO_VEHICLE_INFO_FAIL:            type('[Claims File] Save Auto Vehicle Info Fail'),

    SUBMIT_AUTO_VEHICLE_INFO:               type('[Claims File] Submit Auto Vehicle Info'),
    SUBMIT_AUTO_VEHICLE_INFO_SUCCESS:       type('[Claims File] Submit Auto Vehicle Info Success'),
    SUBMIT_AUTO_VEHICLE_INFO_FAIL:          type('[Claims File] Submit Auto Vehicle Info Fail'),

    SUBMIT_AUTO_CLIENT_INFO:                type('[Claims File] Submit Auto Client Info'),
    SUBMIT_AUTO_CLIENT_INFO_SUCCESS:        type('[Claims File] Submit Auto Client Info Success'),
    SUBMIT_AUTO_CLIENT_INFO_FAIL:           type('[Claims File] Submit Auto Client Info Fail'),

    SUBMIT_AUTO_DETAIL:                     type('[Claims File] Submit Auto Detail'),
    SUBMIT_AUTO_DETAIL_SUCCESS:             type('[Claims File] Submit Auto Detail Success'),
    SUBMIT_AUTO_DETAIL_FAIL:                type('[Claims File] Submit Auto Detail Fail'),

    SUBMIT_AUTO_DOCUMENTS:                  type('[Claims File] Submit Auto Documents'),
    SUBMIT_AUTO_DOCUMENTS_SUCCESS:          type('[Claims File] Submit Auto Documents Success'),
    SUBMIT_AUTO_DOCUMENTS_FAIL:             type('[Claims File] Submit Auto Documents Fail'),

    SUBMIT_AUTO_CONTACT_INFO:               type('[Claims File] Submit Auto Contact Info'),
    SUBMIT_AUTO_CONTACT_INFO_SUCCESS:       type('[Claims File] Submit Auto Contact Info Success'),
    SUBMIT_AUTO_CONTACT_INFO_FAIL:          type('[Claims File] Submit Auto Contact Info Fail')
};

// tslint:disable-next-line:variable-name
const PropertyClaimActionTypes = {
    SUBMIT_PROPERTY_BASIC_INFO:                 type('[Claims File] Submit Property Basic Info'),
    SUBMIT_PROPERTY_BASIC_INFO_SUCCESS:         type('[Claims File] Submit Property Basic Info Success'),
    SUBMIT_PROPERTY_BASIC_INFO_FAIL:            type('[Claims File] Submit Property Basic Info Fail'),

    UPDATE_PROPERTY_BASIC_INFO:                 type('[Claims File] Update Property Basic Info'),
    UPDATE_PROPERTY_BASIC_INFO_SUCCESS:         type('[Claims File] Update Property Basic Info Success'),
    UPDATE_PROPERTY_BASIC_INFO_FAIL:            type('[Claims File] Update Property Basic Info Fail'),

    SUBMIT_PROPERTY_DETAIL:                     type('[Claims File] Submit Property Detail'),
    SUBMIT_PROPERTY_DETAIL_SUCCESS:             type('[Claims File] Submit Property Detail Success'),
    SUBMIT_PROPERTY_DETAIL_FAIL:                type('[Claims File] Submit Property Detail Fail'),

    SUBMIT_PROPERTY_CLIENT_INFO:                type('[Claims File] Submit Property Client Info'),
    SUBMIT_PROPERTY_CLIENT_INFO_SUCCESS:        type('[Claims File] Submit Property Client Info Success'),
    SUBMIT_PROPERTY_CLIENT_INFO_FAIL:           type('[Claims File] Submit Property Client Info Fail'),

    SUBMIT_PROPERTY_DOCUMENTS:                  type('[Claims File] Submit Property Documents'),
    SUBMIT_PROPERTY_DOCUMENTS_SUCCESS:          type('[Claims File] Submit Property Documents Success'),
    SUBMIT_PROPERTY_DOCUMENTS_FAIL:             type('[Claims File] Submit Property Documents Fail'),

    SUBMIT_PROPERTY_CONTACT_INFO:               type('[Claims File] Submit Property Contact Info'),
    SUBMIT_PROPERTY_CONTACT_INFO_SUCCESS:       type('[Claims File] Submit Property Contact Info Success'),
    SUBMIT_PROPERTY_CONTACT_INFO_FAIL:          type('[Claims File] Submit Property Contact Info Fail')
};

// tslint:disable-next-line:variable-name
const WatercraftClaimActionTypes = {
    SUBMIT_WATERCRAFT_BASIC_INFO:                 type('[Claims File] Submit Watercraft Basic Info'),
    SUBMIT_WATERCRAFT_BASIC_INFO_SUCCESS:         type('[Claims File] Submit Watercraft Basic Info Success'),
    SUBMIT_WATERCRAFT_BASIC_INFO_FAIL:            type('[Claims File] Submit Watercraft Basic Info Fail'),

    UPDATE_WATERCRAFT_BASIC_INFO:                 type('[Claims File] Update Watercraft Basic Info'),
    UPDATE_WATERCRAFT_BASIC_INFO_SUCCESS:         type('[Claims File] Update Watercraft Basic Info Success'),
    UPDATE_WATERCRAFT_BASIC_INFO_FAIL:            type('[Claims File] Update Watercraft Basic Info Fail'),

    SAVE_WATERCRAFT_VEHICLE_INFO:                 type('[Claims File] Save Watercraft Vehicle Info'),
    SAVE_WATERCRAFT_VEHICLE_INFO_SUCCESS:         type('[Claims File] Save Watercraft Vehicle Info Success'),
    SAVE_WATERCRAFT_VEHICLE_INFO_FAIL:            type('[Claims File] Save Watercraft Vehicle Info Fail'),

    SUBMIT_WATERCRAFT_VEHICLE_INFO:               type('[Claims File] Submit Watercraft Vehicle Info'),
    SUBMIT_WATERCRAFT_VEHICLE_INFO_SUCCESS:       type('[Claims File] Submit Watercraft Vehicle Info Success'),
    SUBMIT_WATERCRAFT_VEHICLE_INFO_FAIL:          type('[Claims File] Submit Watercraft Vehicle Info Fail'),

    SUBMIT_WATERCRAFT_CLIENT_INFO:                type('[Claims File] Submit Watercraft Client Info'),
    SUBMIT_WATERCRAFT_CLIENT_INFO_SUCCESS:        type('[Claims File] Submit Watercraft Client Info Success'),
    SUBMIT_WATERCRAFT_CLIENT_INFO_FAIL:           type('[Claims File] Submit Watercraft Client Info Fail'),

    SUBMIT_WATERCRAFT_DETAIL:                     type('[Claims File] Submit Watercraft Detail'),
    SUBMIT_WATERCRAFT_DETAIL_SUCCESS:             type('[Claims File] Submit Watercraft Detail Success'),
    SUBMIT_WATERCRAFT_DETAIL_FAIL:                type('[Claims File] Submit Watercraft Detail Fail'),

    SUBMIT_WATERCRAFT_CONTACT_INFO:               type('[Claims File] Submit Watercraft Contact Info'),
    SUBMIT_WATERCRAFT_CONTACT_INFO_SUCCESS:       type('[Claims File] Submit Watercraft Contact Info Success'),
    SUBMIT_WATERCRAFT_CONTACT_INFO_FAIL:          type('[Claims File] Submit Watercraft Contact Info Fail')
};

// tslint:disable-next-line:variable-name
const AutoErsClaimActionTypes = {
    SUBMIT_ERS_BASIC_INFO:                 type('[Claims File] Submit Ers Basic Info'),
    SUBMIT_ERS_BASIC_INFO_SUCCESS:         type('[Claims File] Submit Ers Basic Info Success'),
    SUBMIT_ERS_BASIC_INFO_FAIL:            type('[Claims File] Submit Ers Basic Info Fail'),

    UPDATE_ERS_BASIC_INFO:                 type('[Claims File] Update Ers Basic Info'),
    UPDATE_ERS_BASIC_INFO_SUCCESS:         type('[Claims File] Update Ers Basic Info Success'),
    UPDATE_ERS_BASIC_INFO_FAIL:            type('[Claims File] Update Ers Basic Info Fail'),

    SUBMIT_ERS_DETAIL:                     type('[Claims File] Submit Ers Detail'),
    SUBMIT_ERS_DETAIL_SUCCESS:             type('[Claims File] Submit Ers Detail Success'),
    SUBMIT_ERS_DETAIL_FAIL:                type('[Claims File] Submit Ers Detail Fail'),

    SUBMIT_ERS_DOCUMENTS:                  type('[Claims File] Submit Ers Documents'),
    SUBMIT_ERS_DOCUMENTS_SUCCESS:          type('[Claims File] Submit Ers Documents Success'),
    SUBMIT_ERS_DOCUMENTS_FAIL:             type('[Claims File] Submit Ers Documents Fail'),

    SUBMIT_ERS_CONTACT_INFO:               type('[Claims File] Submit Ers Contact Info'),
    SUBMIT_ERS_CONTACT_INFO_SUCCESS:       type('[Claims File] Submit Ers Contact Info Success'),
    SUBMIT_ERS_CONTACT_INFO_FAIL:          type('[Claims File] Submit Ers Contact Info Fail')
};

// tslint:disable-next-line:variable-name
const AutoGlassClaimActionTypes = {
    SUBMIT_GLASS_BASIC_INFO:                 type('[Claims File] Submit Glass Basic Info'),
    SUBMIT_GLASS_BASIC_INFO_SUCCESS:         type('[Claims File] Submit Glass Basic Info Success'),
    SUBMIT_GLASS_BASIC_INFO_FAIL:            type('[Claims File] Submit Glass Basic Info Fail'),

    UPDATE_GLASS_BASIC_INFO:                 type('[Claims File] Update Glass Basic Info'),
    UPDATE_GLASS_BASIC_INFO_SUCCESS:         type('[Claims File] Update Glass Basic Info Success'),
    UPDATE_GLASS_BASIC_INFO_FAIL:            type('[Claims File] Update Glass Basic Info Fail'),

    SUBMIT_GLASS_DETAIL:                     type('[Claims File] Submit Glass Detail'),
    SUBMIT_GLASS_DETAIL_SUCCESS:             type('[Claims File] Submit Glass Detail Success'),
    SUBMIT_GLASS_DETAIL_FAIL:                type('[Claims File] Submit Glass Detail Fail'),

    SUBMIT_GLASS_CONTACT_INFO:               type('[Claims File] Submit Glass Contact Info'),
    SUBMIT_GLASS_CONTACT_INFO_SUCCESS:       type('[Claims File] Submit Glass Contact Info Success'),
    SUBMIT_GLASS_CONTACT_INFO_FAIL:          type('[Claims File] Submit Glass Contact Info Fail')
};



// tslint:disable-next-line:variable-name
export const ActionTypes = {
    ...GeneralClaimActionTypes,
    ...AutoClaimActionTypes,
    ...AutoErsClaimActionTypes,
    ...AutoGlassClaimActionTypes,
    ...PropertyClaimActionTypes,
    ...WatercraftClaimActionTypes
};

/**
 * General Claims API Actions
 */

export class FindPolicyAction implements Action {
    public type = ActionTypes.FIND_POLICY;
    constructor(public payload: any = null) { }
}
export class FindPolicySuccessAction implements Action {
    public type = ActionTypes.FIND_POLICY_SUCCESS;
    constructor(public payload: PolicyBase[] = null) { }
}
export class FindPolicyFailAction implements Action {
    public type = ActionTypes.FIND_POLICY_FAIL;
    constructor(public payload: any = null) { }
}

export class SelectPolicyAction implements Action {
    public type = ActionTypes.SELECT_POLICY;
    constructor(public payload: ClaimsSelectPolicy = null) { }
}
export class SelectPolicySuccessAction implements Action {
    public type = ActionTypes.SELECT_POLICY_SUCCESS;
    constructor(public payload: ClaimsSelectPolicy = null) { }
}
export class SelectPolicyFailAction implements Action {
    public type = ActionTypes.SELECT_POLICY_FAIL;
    constructor(public payload: any = null) { }
}

export class CompleteClaimAction implements Action {
    public type = ActionTypes.COMPLETE_CLAIM;
    constructor(public payload: any  = null) { }
}
export class CompleteClaimSuccessAction implements Action {
    public type = ActionTypes.COMPLETE_CLAIM_SUCCESS;
    constructor(public payload: ClaimsApiResponse = null) { }
}
export class CompleteClaimFailAction implements Action {
    public type = ActionTypes.COMPLETE_CLAIM_FAIL;
    constructor(public payload: any = null) { }
}

export class SaveClaimAction implements Action {
    public type = ActionTypes.COMPLETE_CLAIM;
    constructor(public payload: Claim = null) { }
}
export class SaveClaimSuccessAction implements Action {
    public type = ActionTypes.COMPLETE_CLAIM_SUCCESS;
    constructor(public payload: ClaimsApiResponse = null) { }
}
export class SaveClaimFailAction implements Action {
    public type = ActionTypes.COMPLETE_CLAIM_FAIL;
    constructor(public payload: any = null) { }
}

export class LoadContactInfoAction implements Action {
    public type = ActionTypes.LOAD_CONTACT_INFO;
    constructor(public payload: Claim = null) { }
}
export class LoadContactInfoSuccessAction implements Action {
    public type = ActionTypes.LOAD_CONTACT_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse = null) { }
}
export class LoadContactInfoFailAction implements Action {
    public type = ActionTypes.LOAD_CONTACT_INFO_FAIL;
    constructor(public payload: any = null) { }
}

export class ClearClaimsFileStore implements Action {
    public type = ActionTypes.CLEAR_STORE;
    constructor(public payload: any = null) { }
}

/**
 * Auto Claim Actions
 */

export class SubmitAutoBasicInfoAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class SubmitAutoBasicInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitAutoBasicInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class UpdateAutoBasicInfoAction implements Action {
    public type = ActionTypes.UPDATE_AUTO_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class UpdateAutoBasicInfoSuccessAction implements Action {
    public type = ActionTypes.UPDATE_AUTO_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class UpdateAutoBasicInfoFailAction implements Action {
    public type = ActionTypes.UPDATE_AUTO_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SaveAutoVehicleInfoAction implements Action {
    public type = ActionTypes.SAVE_AUTO_VEHICLE_INFO;
    constructor(public payload: VehicleDetailsForm) {}
}
export class SaveAutoVehicleInfoSuccessAction implements Action {
    public type = ActionTypes.SAVE_AUTO_VEHICLE_INFO_SUCCESS;
    constructor(public payload: VehicleDetailsForm) {}
}
export class SaveAutoVehicleInfoFailAction implements Action {
    public type = ActionTypes.SAVE_AUTO_VEHICLE_INFO_FAIL;
    constructor(public payload: VehicleDetailsForm) {}
}
export class SubmitAutoVehicleInfoAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_VEHICLE_INFO;
    constructor(public payload: any) {}
}
export class SubmitAutoVehicleInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_VEHICLE_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitAutoVehicleInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_VEHICLE_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SubmitAutoClientInfoAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_CLIENT_INFO;
    constructor(public payload: PeopleInvolvedForm) {}
}
export class SubmitAutoClientInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_CLIENT_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitAutoClientInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_CLIENT_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SubmitAutoDetailAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_DETAIL;
    constructor(public payload: AutoDetailsForm) {}
}
export class SubmitAutoDetailSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_DETAIL_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitAutoDetailFailAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_DETAIL_FAIL;
    constructor(public payload: any) {}
}

export class SubmitAutoDocumentsAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_DOCUMENTS;
    constructor(public payload: ClaimsPhotoUpload []) {}
}
export class SubmitAutoDocumentsSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_DOCUMENTS_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {
    }
}
export class SubmitAutoDocumentsFailAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_DOCUMENTS_FAIL;
    constructor(public payload: any) {}
}

export class SubmitAutoContactInfoAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_CONTACT_INFO;
    constructor(public payload: ClaimsContactForm) {}
}
export class SubmitAutoContactInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_CONTACT_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitAutoContactInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_AUTO_CONTACT_INFO_FAIL;
    constructor(public payload: any) {}
}

/**
 * Property Claim Actions
 */
export class SubmitPropertyBasicInfoAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class SubmitPropertyBasicInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitPropertyBasicInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class UpdatePropertyBasicInfoAction implements Action {
    public type = ActionTypes.UPDATE_PROPERTY_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class UpdatePropertyBasicInfoSuccessAction implements Action {
    public type = ActionTypes.UPDATE_PROPERTY_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class UpdatePropertyBasicInfoFailAction implements Action {
    public type = ActionTypes.UPDATE_PROPERTY_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SubmitPropertyDetailAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_DETAIL;
    constructor(public payload: PropertyDetailsForm) {}
}
export class SubmitPropertyDetailSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_DETAIL_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitPropertyDetailFailAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_DETAIL_FAIL;
    constructor(public payload: any) {}
}

export class SubmitPropertyClientInfoAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_CLIENT_INFO;
    constructor(public payload: PropertyDetailsForm) {}
}
export class SubmitPropertyClientInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_CLIENT_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitPropertyClientInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_CLIENT_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SubmitPropertyDocumentsAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_DOCUMENTS;
    constructor(public payload: ClaimsPhotoUpload[]) {}
}
export class SubmitPropertyDocumentsSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_DOCUMENTS_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitPropertyDocumentsFailAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_DOCUMENTS_FAIL;
    constructor(public payload: any) {}
}

export class SubmitPropertyContactInfoAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_CONTACT_INFO;
    constructor(public payload: ClaimsContactForm) {}
}
export class SubmitPropertyContactInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_CONTACT_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitPropertyContactInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_PROPERTY_CONTACT_INFO_FAIL;
    constructor(public payload: any) {}
}

/**
 * Watercraft Claim Actions
 */
export class SubmitWatercraftBasicInfoAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class SubmitWatercraftBasicInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitWatercraftBasicInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class UpdateWatercraftBasicInfoAction implements Action {
    public type = ActionTypes.UPDATE_WATERCRAFT_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class UpdateWatercraftBasicInfoSuccessAction implements Action {
    public type = ActionTypes.UPDATE_WATERCRAFT_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class UpdateWatercraftBasicInfoFailAction implements Action {
    public type = ActionTypes.UPDATE_WATERCRAFT_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SubmitWatercraftVehicleInfoAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_VEHICLE_INFO;
    constructor(public payload: VehicleDetailsForm) {}
}
export class SubmitWatercraftVehicleInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_VEHICLE_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitWatercraftVehicleInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_VEHICLE_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SubmitWatercraftClientInfoAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_CLIENT_INFO;
    constructor(public payload: PeopleInvolvedForm) {}
}
export class SubmitWatercraftClientInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_CLIENT_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitWatercraftClientInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_CLIENT_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SubmitWatercraftDetailAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_DETAIL;
    constructor(public payload: WaterDetailsForm) {}
}
export class SubmitWatercraftDetailSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_DETAIL_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitWatercraftDetailFailAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_DETAIL_FAIL;
    constructor(public payload: any) {}
}

export class SubmitWatercraftContactInfoAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_CONTACT_INFO;
    constructor(public payload: ClaimsContactForm) {}
}
export class SubmitWatercraftContactInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_CONTACT_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitWatercraftContactInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_WATERCRAFT_CONTACT_INFO_FAIL;
    constructor(public payload: any) {}
}

/**
 * ERS Claim Actions
 */
export class SubmitErsBasicInfoAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class SubmitErsBasicInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitErsBasicInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class UpdateErsBasicInfoAction implements Action {
    public type = ActionTypes.UPDATE_ERS_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class UpdateErsBasicInfoSuccessAction implements Action {
    public type = ActionTypes.UPDATE_ERS_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class UpdateErsBasicInfoFailAction implements Action {
    public type = ActionTypes.UPDATE_ERS_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SubmitErsDetailAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_DETAIL;
    constructor(public payload: ErsDetailsForm) {}
}
export class SubmitErsDetailSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_DETAIL_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitErsDetailFailAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_DETAIL_FAIL;
    constructor(public payload: any) {}
}

export class SubmitErsDocumentsAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_DOCUMENTS;
    constructor(public payload: ClaimsPhotoUpload[]) {}
}
export class SubmitErsDocumentsSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_DOCUMENTS_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitErsDocumentsFailAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_DOCUMENTS_FAIL;
    constructor(public payload: any) {}
}

export class SubmitErsContactInfoAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_CONTACT_INFO;
    constructor(public payload: ClaimsContactForm ) {}
}
export class SubmitErsContactInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_CONTACT_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitErsContactInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_ERS_CONTACT_INFO_FAIL;
    constructor(public payload: any) {}
}

/**
 * Glass Claim Actions
 */

export class SubmitGlassBasicInfoAction implements Action {
    public type = ActionTypes.SUBMIT_GLASS_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class SubmitGlassBasicInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_GLASS_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitGlassBasicInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_GLASS_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class UpdateGlassBasicInfoAction implements Action {
    public type = ActionTypes.UPDATE_GLASS_BASIC_INFO;
    constructor(public payload: Claim) {}
}
export class UpdateGlassBasicInfoSuccessAction implements Action {
    public type = ActionTypes.UPDATE_GLASS_BASIC_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class UpdateGlassBasicInfoFailAction implements Action {
    public type = ActionTypes.UPDATE_GLASS_BASIC_INFO_FAIL;
    constructor(public payload: any) {}
}

export class SubmitGlassDetailAction implements Action {
    public type = ActionTypes.SUBMIT_GLASS_DETAIL;
    constructor(public payload: GlassDetailsForm) {}
}
export class SubmitGlassDetailSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_GLASS_DETAIL_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitGlassDetailFailAction implements Action {
    public type = ActionTypes.SUBMIT_GLASS_DETAIL_FAIL;
    constructor(public payload: any) {}
}

export class SubmitGlassContactInfoAction implements Action {
    public type = ActionTypes.SUBMIT_GLASS_CONTACT_INFO;
    constructor(public payload: ClaimsContactForm) {}
}
export class SubmitGlassContactInfoSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_GLASS_CONTACT_INFO_SUCCESS;
    constructor(public payload: ClaimsApiResponse) {}
}
export class SubmitGlassContactInfoFailAction implements Action {
    public type = ActionTypes.SUBMIT_GLASS_CONTACT_INFO_FAIL;
    constructor(public payload: any) {}
}

type GeneralClaimActions
    = CompleteClaimAction
    | CompleteClaimSuccessAction
    | CompleteClaimFailAction

    | SaveClaimAction
    | SaveClaimSuccessAction
    | SaveClaimFailAction

    | FindPolicyAction
    | FindPolicySuccessAction
    | FindPolicyFailAction

    | SelectPolicyAction
    | SelectPolicySuccessAction
    | SelectPolicyFailAction

    | LoadContactInfoAction
    | LoadContactInfoSuccessAction
    | LoadContactInfoFailAction
;

type AutoClaimActions
    = SubmitAutoBasicInfoAction
    | SubmitAutoBasicInfoSuccessAction
    | SubmitAutoBasicInfoFailAction

    | UpdateAutoBasicInfoAction
    | UpdateAutoBasicInfoSuccessAction
    | UpdateAutoBasicInfoFailAction

    | SubmitAutoVehicleInfoAction
    | SubmitAutoVehicleInfoSuccessAction
    | SubmitAutoVehicleInfoFailAction

    | SubmitAutoClientInfoAction
    | SubmitAutoClientInfoSuccessAction
    | SubmitAutoClientInfoFailAction

    | SubmitAutoDetailAction
    | SubmitAutoDetailSuccessAction
    | SubmitAutoDetailFailAction

    | SubmitAutoDocumentsAction
    | SubmitAutoDocumentsSuccessAction
    | SubmitAutoDocumentsFailAction

    | SubmitAutoContactInfoAction
    | SubmitAutoContactInfoSuccessAction
    | SubmitAutoContactInfoFailAction
;

type PropertyClaimActions
    = SubmitPropertyBasicInfoAction
    | SubmitPropertyBasicInfoSuccessAction
    | SubmitPropertyBasicInfoFailAction

    | UpdatePropertyBasicInfoAction
    | UpdatePropertyBasicInfoSuccessAction
    | UpdatePropertyBasicInfoFailAction

    | SubmitPropertyDetailAction
    | SubmitPropertyDetailSuccessAction
    | SubmitPropertyDetailFailAction

    | SubmitPropertyClientInfoAction
    | SubmitPropertyClientInfoSuccessAction
    | SubmitPropertyClientInfoFailAction

    | SubmitPropertyDocumentsAction
    | SubmitPropertyDocumentsSuccessAction
    | SubmitPropertyDocumentsFailAction

    | SubmitPropertyContactInfoAction
    | SubmitPropertyContactInfoSuccessAction
    | SubmitPropertyContactInfoFailAction
;

type WatercraftClaimActions
    = SubmitWatercraftBasicInfoAction
    | SubmitWatercraftBasicInfoSuccessAction
    | SubmitWatercraftBasicInfoFailAction

    | UpdateWatercraftBasicInfoAction
    | UpdateWatercraftBasicInfoSuccessAction
    | UpdateWatercraftBasicInfoFailAction

    | SubmitWatercraftVehicleInfoAction
    | SubmitWatercraftVehicleInfoSuccessAction
    | SubmitWatercraftVehicleInfoFailAction

    | SubmitWatercraftClientInfoAction
    | SubmitWatercraftClientInfoSuccessAction
    | SubmitWatercraftClientInfoFailAction

    | SubmitWatercraftDetailAction
    | SubmitWatercraftDetailSuccessAction
    | SubmitWatercraftDetailFailAction

    | SubmitWatercraftContactInfoAction
    | SubmitWatercraftContactInfoSuccessAction
    | SubmitWatercraftContactInfoFailAction
;

type ErsClaimActions
    = SubmitErsBasicInfoAction
    | SubmitErsBasicInfoSuccessAction
    | SubmitErsBasicInfoFailAction

    | UpdateErsBasicInfoAction
    | UpdateErsBasicInfoSuccessAction
    | UpdateErsBasicInfoFailAction

    | SubmitErsDetailAction
    | SubmitErsDetailSuccessAction
    | SubmitErsDetailFailAction

    | SubmitErsDocumentsAction
    | SubmitErsDocumentsSuccessAction
    | SubmitErsDocumentsFailAction

    | SubmitErsContactInfoAction
    | SubmitErsContactInfoSuccessAction
    | SubmitErsContactInfoFailAction
;

type GlassClaimActions
    = SubmitGlassBasicInfoAction
    | SubmitGlassBasicInfoSuccessAction
    | SubmitGlassBasicInfoFailAction

    | UpdateGlassBasicInfoAction
    | UpdateGlassBasicInfoSuccessAction
    | UpdateGlassBasicInfoFailAction

    | SubmitGlassDetailAction
    | SubmitGlassDetailSuccessAction
    | SubmitGlassDetailFailAction

    | SubmitGlassContactInfoAction
    | SubmitGlassContactInfoSuccessAction
    | SubmitGlassContactInfoFailAction
;
export type Actions
    = GeneralClaimActions
    | AutoClaimActions
    | ErsClaimActions
    | GlassClaimActions
    | PropertyClaimActions
    | WatercraftClaimActions
    ;
