import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { PaperlessVerification } from '../../models';
import { Body, DELETE, HttpService, Path, POST, PUT, Query } from '../http';

@Injectable()
export class PaperlessApiClient extends HttpService {
    @POST('myaccount/{custId}/paperless',false)
    public AddPaperless(
        @Body payload: { paperlessInfo: any[], url: string },
        @Path('custId') id: string
    ): Observable<any> {
        return null;
    }

    @PUT('myaccount/{custId}/paperless',false)
    public UpdatePaperless(
        @Body payload: { paperlessInfo: any[], url: string },
        @Path('custId') id: string
    ): Observable<any> {
        return null;
    }

    @DELETE('myaccount/{custId}/paperless/policy/{policyNbr}/systemid/{systemId}',false)
    public CancelPaperless(
        @Path('custId') custId: string,
        @Path('policyNbr') policyNbr: string,
        @Path('systemId') systemId: string,
        @Query('source') source: string
    ): Observable<any> {
        return null;
    }

    @POST('myaccount/{custId}/paperless/resend',false)
    public SendVerification(
        @Body payload: PaperlessVerification,
        @Path('custId') id: string
    ): Observable<any> {
        return null;
    }

    @POST('myaccount/paperless/verify',false)
    public VerifyPaperless(
        @Query('id') id: string,
        @Query('source') source: string,
        @Query('policy') policy: string,
        @Query('date') date: string
    ): Observable<any> {
        return null;
    }
}
