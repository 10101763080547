import {Pipe, PipeTransform} from '@angular/core';
import { PolicyBase } from '../../shared/models';

@Pipe({name: 'policyTypeUrl'})
export class PolicyTypeUrlPipe implements PipeTransform  {

    public transform(policy: PolicyBase): string {
        const route = policy.policyTypeDesc; // 'Personal Fire'
        if (route) { return route.replace(/\s+/g, '-').toLowerCase(); } // 'personal-fire'
        return null;
    }
}
