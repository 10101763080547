export class PropertyDetailsForm {
    public incidentDetails: string;
    public damageDesc: string;
    public propertyAddress: boolean;
    public location: string;
    public city: string;
    public state: string;
    public zip: string;
    public livable: boolean;
    public reportFiled: boolean;
    public reportNbr: string;
    public reportAuthority: string;
    public causeOfLoss: string;
    public causeOfWeatherLoss: string 
    public reportAuthorityCity: string;
    public reportAuthorityStateCd: string;
    public reportAuthorityType: string;

    constructor(init?: Partial<PropertyDetailsForm>) {
        this.incidentDetails = init.incidentDetails || null;
        this.damageDesc = init.damageDesc || null;
        this.propertyAddress = init.propertyAddress || null;
        this.location = init.location || null;
        this.city = init.city || null;
        this.state = init.state || null;
        this.zip = init.zip || null;
        this.livable = init.livable === undefined ? null : init.livable;
        this.reportFiled = init.reportFiled === undefined ? null : init.reportFiled;
        this.reportNbr = init.reportNbr || null;
        this.reportAuthority = init.reportAuthority || null;
        this.causeOfLoss = init.causeOfLoss || null;
        this.causeOfWeatherLoss = init.causeOfWeatherLoss || null;
        this.reportAuthorityCity = init.reportAuthorityCity || null;
        this.reportAuthorityStateCd = init.reportAuthorityStateCd || null;
        this.reportAuthorityType = init.reportAuthorityType || null;

    }
}
