import { createSelector } from '@ngrx/store';
import { State } from '../..';

import * as fromClaimsFile from '../../reducers/claims/claims-file.reducer';

export const getClaimsFile = (state: State) => state.claimsFile;

export const getClaim = createSelector(getClaimsFile, fromClaimsFile.getClaim);
export const getSelectedPolicy = createSelector(
    getClaimsFile,
    fromClaimsFile.getSelectedPolicy
);
export const getSelectedPolicyDetails = createSelector(
    getClaimsFile,
    fromClaimsFile.getSelectedPolicyDetails
);
export const getVehicleDetails = createSelector(
    getClaimsFile,
    fromClaimsFile.getVehicleDetails
);
export const isAutoBasicInfoLoaded = createSelector(
    getClaimsFile,
    fromClaimsFile.isAutoBasicInfoLoaded
);
export const isAutoBasicInfoLoading = createSelector(
    getClaimsFile,
    fromClaimsFile.isAutoBasicInfoLoading
);

export const getPolicies = createSelector(
    getClaimsFile,
    fromClaimsFile.getPolicies
);

export const claimsFileDataLoading = createSelector(
    getClaimsFile,
    fromClaimsFile.claimsFileDataLoading
);

export const getClaimsFileError = createSelector(
    getClaimsFile,
    fromClaimsFile.getClaimsFileError
);
