import { ChallengeApiRequest } from '../user/challengeApiRequest';

export class QuestionRequest extends ChallengeApiRequest {
    public url: string;

    constructor(init?: Partial<QuestionRequest>) {
        if (!init) {
            init = {};
        }
        super(init);
        this.url = init.url || null;
    }
}
