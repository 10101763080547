import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {
    public transform(value: string, args: string): string {
        const limit = args ? parseInt(args, 10) : 10;
        return value.length > limit ? value.substring(0, limit) : value;
    }
}
