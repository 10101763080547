import { PolicyBase } from '../base/policy-base.model';
import { Vehicle } from './vehicle.model';

export class AutoPolicy extends PolicyBase {

    public vehicles: Vehicle[];

    constructor(init?: Partial<AutoPolicy>) {
        if (!init) { init = {}; }
        super(init);
        this.vehicles = init.vehicles || new Array<Vehicle>();
    }
}
