export class DriverInfoForm {
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public suffixDesc: string;
    public dob: string;
    public driversLicense: string;
    public state: string;
    public gender: string;
    public maritalStatus: string;
    public relationToInsured: string;

    constructor(init?: Partial<DriverInfoForm>) {
        Object.assign(this, init);
    }
}
