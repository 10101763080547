import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '../../shared/models';

@Pipe({
    name: 'autoUpdate'
})
export class AutoUpdatePipe implements PipeTransform {
    public transform(vehicles: Vehicle[]): Vehicle[] {
        if (!vehicles) { return vehicles; }
        return vehicles.filter(policy => policy.typeCd === 'PP' || policy.typeCd === 'PU' || policy.typeCd === 'VN');
    }
}
