export class MaritalStatusConstants {
    public static readonly maritalStatus = [
        {
            'name': 'Single',
            'abbreviation': 'S'
        },
        {
            'name': 'Married',
            'abbreviation': 'M'
        },
        {
            'name': 'Divorced',
            'abbreviation': 'D'
        },
        {
            'name': 'Widowed',
            'abbreviation': 'W'
        }
    ];
}
