import { ContactTypeEnums } from '../../enums/contact-type-enum';
import { ObjectCleaner } from '../../utility';
import { DocumentAttachment } from '../document/document-attachment.model';
import { DocumentProperties } from '../document/document-properties.model';
import { MailingAddress } from '../policy';
import { User } from '../user';
import { WaterDetailsForm } from './auto';
import { AutoDetailsForm } from './auto/auto/autoDetails.model';
import { PeopleInvolvedForm } from './auto/auto/peopleInvolved.model';
import { VehicleDetailsForm } from './auto/auto/vehicleDetails.model';
import { ErsDetailsForm } from './auto/ers/ersDetails.model';
import { GlassDetailsForm } from './auto/glass/glassDetails.model';
import { ClaimProperty } from './claim-property.model';
import { Claim } from './claim.model';
import { ClaimClient } from './claimClient.model';
import { ClaimLocation } from './claims-location.model';
import { ClaimsPhotoUpload } from './claims-photo-upload.model';
import { ClaimsApiRequest } from './claimsApi-request';
import { ClaimVehicle } from './claimVehicle.model';
import { ContactMechanism } from './contact-mechanism.model';
import { PersonalInfo } from './personalInfo.model';
import { PropertyDetailsForm } from './property/propertyDetail.model';
import { UnitDamage } from './unitDamage.model';

export class ClaimsApiRequestFactory {
    public static createAutoBasicInfoRq(claim: Claim, user: User) {
        const req = new ClaimsApiRequest({
            policyNbr: claim.selectPolicy.policyNbr,
            custId: user.custId.toString(),
            lossDate: claim.basicInfo.lossDate,
            lossTime: claim.basicInfo.lossTime
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }
    public static createAutoErsBasicInfoRq(claim: Claim, user: User) {
        const req = new ClaimsApiRequest({
            policyNbr: claim.selectPolicy.policyNbr,
            custId: user.custId.toString(),
            lossDate: claim.basicInfo.lossDate,
            lossTime: claim.basicInfo.lossTime
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }
    public static createAutoGlassBasicInfoRq(claim: Claim, user: User) {
        const req = new ClaimsApiRequest({
            policyNbr: claim.selectPolicy.policyNbr,
            custId: user.custId.toString(),
            lossDate: claim.basicInfo.lossDate,
            lossTime: claim.basicInfo.lossTime
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }
    public static createPropertyBasicInfoRq(claim: Claim, user: User) {
        const req = new ClaimsApiRequest({
            policyNbr: claim.selectPolicy.policyNbr,
            custId: user.custId.toString(),
            lossDate: claim.basicInfo.lossDate,
            lossTime: claim.basicInfo.lossTime
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }
    public static createWatercraftBasicInfoRq(claim: Claim, user: User) {
        const req = new ClaimsApiRequest({
            policyNbr: claim.selectPolicy.policyNbr,
            custId: user.custId.toString(),
            lossDate: claim.basicInfo.lossDate,
            lossTime: claim.basicInfo.lossTime
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }

    // Builds auto detail request for api
    public static createAutoDetailsRq(autoDetail: AutoDetailsForm, claim: Claim) {
        const request = new ClaimsApiRequest({
            lossDesc: autoDetail.lossDescription,
            lossLocation: new ClaimLocation({
                lossLocationDesc: autoDetail.lossLocationDesc,
                city: autoDetail.city,
                countyCd: claim.selectPolicy.policy.mailingAddr.countyCd,
                stateCd: autoDetail.stateCd
            }),
            reportNbr: autoDetail.reportNbr
        });
        if (autoDetail.reportFiled === true) {
            request.claimClients = new Array<ClaimClient>();
            request.claimClients.push(
                new ClaimClient({
                    lastName: autoDetail.reportAuthority,
                    roleCd: 'AUCT',
                    roleRelCd: autoDetail.reportAuthorityType,
                    physicalAddress: new MailingAddress({
                        city: autoDetail.reportAuthorityCity,
                        stateCd: autoDetail.reportAuthorityStateCd
                    })  
                })
            );
        }
        return ObjectCleaner.removeUnneededKeys(request);
    }

    public static createClientRq(clientDetails: PeopleInvolvedForm, autoDetails: AutoDetailsForm) {
        const clients = new Array<ClaimClient>();
        if (autoDetails.reportFiled === true) {
            const authority = new ClaimClient();
            authority.roleCd = 'AUCT';
            authority.lastName = autoDetails.reportAuthority;
            authority.physicalAddress.city = autoDetails.reportAuthorityCity;
            authority.physicalAddress.stateCd = autoDetails.reportAuthorityStateCd;
            authority.roleRelCd = autoDetails.reportAuthorityType

            clients.push(authority);
        }
        if (clientDetails.addInjured) {
            clientDetails.injured.forEach(injured => {
                const injuredPerson = this.personalInfoToClaimClient(injured, 'INJD');
                clients.push(injuredPerson);
            });
        }

        if (clientDetails.addWitnesses) {
            clientDetails.witnesses.forEach(witness => {
                const witnessClient = this.personalInfoToClaimClient(witness, 'WITN');
                clients.push(witnessClient);
            });
        }

        const request = new ClaimsApiRequest({
            claimClients: clients
        });
        return ObjectCleaner.removeUnneededKeys(request);
    }

    private static personalInfoToClaimClient(personalInfo: PersonalInfo, roleCd: string): ClaimClient {
        const claimClient = new ClaimClient();
        claimClient.roleCd = roleCd;
        claimClient.firstName = personalInfo.firstName;
        claimClient.lastName = personalInfo.lastName;

        if (personalInfo.city || personalInfo.address || personalInfo.zipcode || personalInfo.state) {
            claimClient.physicalAddress = new MailingAddress({
                addr1: personalInfo.address,
                city: personalInfo.city,
                stateCd: personalInfo.state,
                zip: personalInfo.zipcode
            });
        }
        if (personalInfo.email) {
            const contact = new ContactMechanism();
            contact.type = ContactTypeEnums.HOME_EMAIL;
            contact.value = personalInfo.email;
            claimClient.contactList.push(contact);
        }
        if (personalInfo.phone) {
            const contact = new ContactMechanism();
            contact.type = ContactTypeEnums.HOME_PHONE;
            contact.value = personalInfo.phone;
            claimClient.contactList.push(contact);
        }
        return claimClient;
    }

    public static createAutoErsDetailRq(ersDetail: ErsDetailsForm, claim: Claim) {
        const req = new ClaimsApiRequest({
            lossDesc: ersDetail.incidentDetails,
            ersAmount: ersDetail.serviceBillAmount,
            ersCompany: ersDetail.serviceCompany,
            claimVehicles: [
                new ClaimVehicle({
                    unitNbr: claim.selectPolicy.vehicle.unitNbr,
                    vinSerialNbr: claim.selectPolicy.vehicle.vinSerialNbr
                })
            ]
            
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }

    public static createAutoGlassDetailRq(glassDetail: GlassDetailsForm, claim: Claim) {
        const req = new ClaimsApiRequest({
            lossDesc: glassDetail.incidentDetails,
            claimVehicles: [
                new ClaimVehicle({
                    unitNbr: claim.selectPolicy.vehicle.unitNbr,
                    vinSerialNbr: claim.selectPolicy.vehicle.vinSerialNbr,
                    involvementDesc: glassDetail.damageLocations.toString()
                })
            ]
        });
        if (glassDetail.preferredProvider) {
            req.claimVehicles[0].preferredGlassVendor = glassDetail.vendor.clientId;
            req.claimVehicles[0].whichCountyGlassSvc = glassDetail.county.replace('TN ','');
        } else {
            req.claimVehicles[0].claimClients = [
                new ClaimClient({
                    roleCd: 'VEND',
                    lastName: glassDetail.otherGlassVendor
                })
            ];
        }
        return ObjectCleaner.removeUnneededKeys(req);
    }

    // Builds auto vehicle detail request for api
    public static createAutoVehicleDetailsRq(
        vehicleDetail: VehicleDetailsForm,
        clientDetails: PeopleInvolvedForm,
        vin: string,
        unitNbr: string
    ) {
        let driverSameInd;

        const claimClients = new Array<ClaimClient>();

        let driver = new PersonalInfo();
        const other = new ClaimClient();
        other.roleCd = 'DRVR';

        if (clientDetails.otherDriver.firstName != null) {
            driverSameInd = 3;
            driver = clientDetails.otherDriver;

            other.firstName = clientDetails.otherDriver.firstName;
            other.lastName = clientDetails.otherDriver.lastName;
            other.physicalAddress.addr1 = clientDetails.otherDriver.address;
            other.physicalAddress.city = clientDetails.otherDriver.city;
            other.physicalAddress.stateCd = clientDetails.otherDriver.state;
            other.physicalAddress.zip = clientDetails.otherDriver.zipcode;
            other.drvLicenseNbr = clientDetails.otherDriver.licenseNbr;
            //   other.roleCd = 'DRVR';

            if (clientDetails.otherDriver.email != null) {
                const contact = new ContactMechanism();
                contact.type = ContactTypeEnums.HOME_EMAIL;
                contact.value = clientDetails.otherDriver.email;
                other.contactList.push(contact);
            }

            if (clientDetails.otherDriver.phone != null) {
                const contact = new ContactMechanism();
                contact.type = ContactTypeEnums.HOME_PHONE;
                contact.value = clientDetails.otherDriver.phone;
                other.contactList.push(contact);
            }

            claimClients.push(other);
        } else if (<string>(<any>clientDetails.driver) === 'Parked') {
            driverSameInd = 4;
        } else {
            driverSameInd = 7;
            other.clientId = clientDetails.driver.clientId;
            claimClients.push(other);
        }

        const req = new ClaimsApiRequest({
            ersCompany: vehicleDetail.towingCompany,

            claimVehicles: [
                new ClaimVehicle({
                    driverSameInd: driverSameInd,
                    vehicleColor: vehicleDetail.vehicleColor,
                    airBagsDeploy: vehicleDetail.airBagsDeploy,
                    driveableInd: vehicleDetail.driveableInd,
                    whereCanBeViewed: vehicleDetail.towingLocation,
                    vinSerialNbr: vin,
                    unitDamage: vehicleDetail.damageAreas.map(area => new UnitDamage({ dmgLocationCd: area })),
                    unitNbr: unitNbr,
                    claimClients: claimClients,
                    unitTypeInd: '',
                    rentalNeeded: vehicleDetail.rentalNeeded
                })
            ]
        });

        return ObjectCleaner.removeUnneededKeys(req);
    }
    // Builds auto vehicle detail request for api
    public static createWatercraftDetailsRq(
        vehicleDetail: VehicleDetailsForm,
        clientDetails: PeopleInvolvedForm,
        vin: string,
        unitNbr: string
    ) {
        let driverSameInd;
        const claimClients = new Array<ClaimClient>();

        let driver = new PersonalInfo();
        const other = new ClaimClient();
        other.roleCd = 'OPTR';

        if (clientDetails.otherDriver.firstName != null) {
            driverSameInd = 3;
            driver = clientDetails.otherDriver;

            other.firstName = clientDetails.otherDriver.firstName;
            other.lastName = clientDetails.otherDriver.lastName;
            other.physicalAddress.addr1 = clientDetails.otherDriver.address;
            other.physicalAddress.city = clientDetails.otherDriver.city;
            other.physicalAddress.stateCd = clientDetails.otherDriver.state;
            other.physicalAddress.zip = clientDetails.otherDriver.zipcode;
            other.drvLicenseNbr = clientDetails.otherDriver.licenseNbr;
            //   other.roleCd = 'DRVR';

            if (clientDetails.otherDriver.email != null) {
                const contact = new ContactMechanism();
                contact.type = ContactTypeEnums.HOME_EMAIL;
                contact.value = clientDetails.otherDriver.email;
                other.contactList.push(contact);
            }

            if (clientDetails.otherDriver.phone != null) {
                const contact = new ContactMechanism();
                contact.type = ContactTypeEnums.HOME_PHONE;
                contact.value = clientDetails.otherDriver.phone;
                other.contactList.push(contact);
            }

            claimClients.push(other);
        } else if (<string>(<any>clientDetails.driver) === 'Parked') {
            driverSameInd = 4;
        } else {
            driverSameInd = 2;
            other.clientId = clientDetails.driver.clientId;
            claimClients.push(other);
        }

        const unitDamage = new Array<UnitDamage>();
        const req = new ClaimsApiRequest({
            claimVehicles: [
                new ClaimVehicle({
                    whereCanBeViewed: vehicleDetail.towingLocation,
                    driverSameInd: driverSameInd,
                    vinSerialNbr: vin,
                    unitDamage: vehicleDetail.damageAreas.map(area => new UnitDamage({ dmgLocationCd: area })),
                    unitNbr: unitNbr,
                    claimClients: claimClients,
                    unitTypeInd: ''
                })
            ]
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }

    public static createAutoAttachmentsRq(attachments: ClaimsPhotoUpload[]): ClaimsApiRequest {
        const req = new ClaimsApiRequest();
        attachments.forEach(attachment => {
            const attProps = new DocumentProperties({
                caption: attachment.description,
                userSelDocType: attachment.type
            });
            const docAtt = new DocumentAttachment({
                mimeType: attachment.mimeType,
                b64Content: attachment.base64,
                name: attachment.name,
                attachProperties: attProps
            });
            if (attachment.type === 'DP' || attachment.type === 'OI') {
                req.userImages.contentlist.push(docAtt);
            } else {
                req.userDocuments.contentlist.push(docAtt);
            }
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }

    public static createPropertyAttachmentsRq(attachments: ClaimsPhotoUpload[]): ClaimsApiRequest {
        const req = new ClaimsApiRequest();
        attachments.forEach(attachment => {
            const attProps = new DocumentProperties({
                caption: attachment.description,
                userSelDocType: attachment.type
            });
            const docAtt = new DocumentAttachment({
                mimeType: attachment.mimeType,
                b64Content: attachment.base64,
                name: attachment.name,
                attachProperties: attProps
            });
            if (attachment.type === 'DP' || attachment.type === 'OI') {
                req.userImages.contentlist.push(docAtt);
            } else {
                req.userDocuments.contentlist.push(docAtt);
            }
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }

    public static createErsAttachmentsRq(attachments: ClaimsPhotoUpload[]): ClaimsApiRequest {
        const req = new ClaimsApiRequest();
        attachments.forEach(attachment => {
            const attProps = new DocumentProperties({
                caption: attachment.description,
                userSelDocType: attachment.type
            });
            const docAtt = new DocumentAttachment({
                mimeType: attachment.mimeType,
                b64Content: attachment.base64,
                name: attachment.name,
                attachProperties: attProps
            });
            if (attachment.type === 'DP' || attachment.type === 'OI') {
                req.userImages.contentlist.push(docAtt);
            } else {
                req.userDocuments.contentlist.push(docAtt);
            }
        });
        return ObjectCleaner.removeUnneededKeys(req);
    }

    public static createPropertyDetailInfoRq(detail: PropertyDetailsForm, claim: Claim): ClaimsApiRequest {
        let nonLivableRev = false;
        if (detail.livable === false) {
            nonLivableRev = true;
        }
        if(detail.causeOfLoss === "AllOther_FB"){
            detail.causeOfLoss =""
        }
        const req = new ClaimsApiRequest({
            lossDesc: detail.incidentDetails,
            lossLocation: new ClaimLocation({
                lossLocationDesc: detail.location,
                city: detail.city,
                stateCd: detail.state,
                countyCd: claim.selectPolicy.dwelling.countyCd,
                zip: detail.zip,
                newLossLocation: detail.propertyAddress
            }),
            claimProperties: [
                new ClaimProperty({
                    unitNbr: claim.selectPolicy.unitNbr,
                    damageDesc: detail.damageDesc,
                    nonLivable: nonLivableRev
                })
            ],
            reportNbr: detail.reportNbr,
            causeOfLossCd: detail.causeOfLoss
            //causeOfLossDesc: detail.
        });
        if (detail.reportFiled === true) {
            req.claimClients = new Array<ClaimClient>();
            req.claimClients.push(
                new ClaimClient({
                    lastName: detail.reportAuthority,
                    roleCd: 'AUCT',
                    roleRelCd: detail.reportAuthorityType,
                    physicalAddress: new MailingAddress({
                        city: detail.reportAuthorityCity,
                        stateCd: detail.reportAuthorityStateCd
                    })  
                })
            );
        }
        return ObjectCleaner.removeUnneededKeys(req);
    }

    public static createPropertyClientInfoRq(detail: PropertyDetailsForm): ClaimsApiRequest {
        const req = new ClaimsApiRequest();
        req.claimClients = new Array<ClaimClient>();
        req.claimClients.push(
            new ClaimClient({
                lastName: detail.reportAuthority,
                roleCd: 'AUCT',
                roleRelCd: detail.reportAuthorityType,
                    physicalAddress: new MailingAddress({
                        city: detail.reportAuthorityCity,
                        stateCd: detail.reportAuthorityStateCd
                })  
            })
        );
        return ObjectCleaner.removeUnneededKeys(req);
    }
}
