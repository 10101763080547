import { ContactMechanism } from '../claims';
import { MailingAddress } from '../policy';
import { Agent } from './agent.model';

export class Agency {
    public officeNbr: string;
    public officeName: string;
    public officeHours: string;

    public countyName: string;
    public countyNbr: string;
    public branchNbr: string;
    public salesRegion: string;
    public claimsRegion: string;
    public fbRegion: string;
    public agents: Agent[];

    public longName: string;
    public clientId: string;
    public mailingAddress: MailingAddress;
    public physicalAddress: MailingAddress;
    public contactList: ContactMechanism[];

    constructor(init?: Partial<Agency>) {
        Object.assign(this, init);
    }
}
