import { Pipe, PipeTransform } from '@angular/core';
import { Driver } from '../models/policy/base/policy-base.model';

@Pipe({
    name: 'activeDrivers'
})
export class ActiveDriversPipe implements PipeTransform {
    public transform(drivers: Driver[]): any {
        if (!drivers) { return drivers; }
        return drivers.filter(driver => !driver.excluded);
    }
}
