import { Action } from '@ngrx/store';
import { type } from '../../../utility';
import { ContactUsForm } from '../../../models/contact-us/contact-us.model';

export const ActionTypes = {

    INIT: type('[Contact Us] Init'),
    SUBMIT: type('[Contact Us] Submit'),
    SUBMIT_SUCCESS: type('[Contact Us] Submit Success'),
    SUBMIT_FAIL: type('[Contact Us] Submit Fail')
};

/**
 * Contact us actions
 */

export class InitAction implements Action {
    public type = ActionTypes.INIT;
    constructor(public payload: any = null) { }
}

export class SubmitAction implements Action {
    public type = ActionTypes.SUBMIT;
    constructor(public payload: ContactUsForm = null) { }
}

export class SubmitSuccessAction implements Action {
    public type = ActionTypes.SUBMIT_SUCCESS;
    constructor(public payload: any = null) { }
}

export class SubmitFailAction implements Action {
    public type = ActionTypes.SUBMIT_FAIL;
    constructor(public payload: any = null) { }
}


export type Actions
    = InitAction
    | SubmitAction
    | SubmitSuccessAction
    | SubmitFailAction;
