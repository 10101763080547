import { User } from '../../../models';
import { ForgotPasswordForm } from '../../../models/user/forgot-password.model';
import { ForgotUsernameForm } from '../../../models/user/forgot-username.model';
import * as userActions from '../../actions/user/user.action';

export interface ForgotState {
    isPasswordLoaded: boolean;
    isPasswordLoading: boolean;
    passwordForm: ForgotPasswordForm;
    passwordError: string;

    isUsernameLoaded: boolean;
    isUsernameLoading: boolean;
    usernameForm: ForgotUsernameForm;
    usernameError: string;
}

const INITIAL_STATE: ForgotState = {
    isPasswordLoaded: false,
    isPasswordLoading: false,
    passwordForm: null,
    passwordError: null,

    isUsernameLoaded: false,
    isUsernameLoading: false,
    usernameForm: null,
    usernameError: null
};

export function forgotReducer(state = INITIAL_STATE, action: userActions.Actions): ForgotState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case userActions.ActionTypes.FORGOT_PASSWORD: {
            return { ...state, isPasswordLoading: true, isPasswordLoaded: false, passwordForm: action.payload, passwordError: null };
        }

        case userActions.ActionTypes.FORGOT_PASSWORD_SUCCESS: {
            return { ...state, isPasswordLoading: false, isPasswordLoaded: true };
        }

        case userActions.ActionTypes.FORGOT_PASSWORD_FAIL: {
            return { ...state, isPasswordLoading: false, passwordError: action.payload.error };
        }

        case userActions.ActionTypes.FORGOT_USERNAME: {
            return { ...state, isUsernameLoading: true, usernameForm: action.payload, usernameError: null };
        }

        case userActions.ActionTypes.FORGOT_USERNAME_SUCCESS: {
            return { ...state, isUsernameLoading: false, isUsernameLoaded: true };
        }

        case userActions.ActionTypes.FORGOT_USERNAME_FAIL: {
            return { ...state, isUsernameLoading: false, isUsernameLoaded: false, usernameError: action.payload.error };
        }

        default: {
            return state;
        }
    }
}

export const getIsPasswordLoaded = (state: ForgotState) => state.isPasswordLoaded;
export const getIsPasswordLoading = (state: ForgotState) => state.isPasswordLoading;
export const getPasswordForm = (state: ForgotState) => state.passwordForm;
export const getPasswordError = (state: ForgotState) => state.passwordError;

export const getIsUsernameLoaded = (state: ForgotState) => state.isUsernameLoaded;
export const getIsUsernameLoading = (state: ForgotState) => state.isUsernameLoading;
export const getUsernameForm = (state: ForgotState) => state.usernameForm;
export const getUsernameError = (state: ForgotState) => state.usernameError;
