import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuestionRequest } from '../../models';
import { ChallengeApiRequest } from '../../models/user/challengeApiRequest';
import { ContactApiRequest } from '../../models/user/contactApiRequest';
import { ForgotPasswordApiRequest } from '../../models/user/forgotPasswordApiRequest';
import { ForgotUsernameApiRequest } from '../../models/user/forgotUsernameApiRequest';
import { MyAccount } from '../../models/user/myaccount.model';
import { ResendVerificationEmailApiRequest } from '../../models/user/resendVerificationEmailApiRequest';
import { ResetPasswordApiRequest } from '../../models/user/resetPasswordApiRequest';
import { UserApiResponse } from '../../models/user/userApi-response';
import { Body, HttpService, Path, POST, Query } from '../http';


@Injectable()
export class UserApiClient extends HttpService {
    /**
     *    API Myaccount User Calls
     */

    @POST('myaccount/login/password',false)
    public resetPassword(@Body body: ResetPasswordApiRequest): Observable<any> {
        return null;
    }

    @POST('myaccount/login',false)
    public login(@Body form: MyAccount): Observable<UserApiResponse> {
        return null;
    }

    @POST('myaccount/login/authenticate',false)
    public authenticate(@Body form: ChallengeApiRequest): Observable<UserApiResponse> {
        return null;
    }

    @POST('myaccount/contactus',false)
    public contact(@Body form: ContactApiRequest): Observable<any> {
        return null;
    }

    @POST('myaccount/forgot/password/identify',false)
    public forgotPassword(@Body form: ForgotPasswordApiRequest): Observable<any> {
        return null;
    }

    @POST('myaccount/forgot/username',false)
    public forgotUsername(@Body form: ForgotUsernameApiRequest): Observable<any> {
        return null;
    }

    @POST('myaccount/register/resend',false)
    public resentVerificationEmail(@Body form: ResendVerificationEmailApiRequest): Observable<any> {
        return null;
    }

    @POST('myaccount/register/questions',false)
    public registerQuestions(@Body form: QuestionRequest): Observable<any> {
        return null;
    }

    @POST('myaccount/reset/initiate',false)
    public resetInit(@Query('id') id: string): Observable<any> {
        return null;
    }

    @POST('myaccount/reset/questions',false)
    public resetQuestions(@Body form: QuestionRequest): Observable<any> {
        return null;
    }

    @POST('myaccount/forgot/password/initiate',false)
    public recoverPasswordInit(@Query('id') id: string): Observable<any> {
        return null;
    }

    @POST('myaccount/forgot/password/authenticate',false)
    public recoverPasswordAuth(@Body form: QuestionRequest): Observable<any> {
        return null;
    }

    @POST('myaccount/forgot/password/reset',false)
    public recoverPasswordComplete(@Body form: ResetPasswordApiRequest): Observable<any> {
        return null;
    }

    @POST('myaccount/paperless/policy/{policyNumber}/document/verify/access/{accessCode}',false)
    public verifyDocumentAccessCode(@Path('policyNumber') policyNumber: string, @Path('accessCode') accessCode: string, @Query('date') date: string): Observable<void> {
        return null;
    }
}
