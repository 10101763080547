import { createSelector } from '@ngrx/store';
import { State } from '../..';
import * as fromMyProfile from '../../reducers/myprofile/myprofile.reducer';

export const getMyProfileState = (state: State) => state.myprofile;

export const getPasswordChangeFailed = createSelector(getMyProfileState, fromMyProfile.getPasswordChangeFailed);
export const getPasswordChangeProcessing = createSelector(getMyProfileState, fromMyProfile.getPasswordChangeProcessing);
export const getPasswordChangeMessage = createSelector(getMyProfileState, fromMyProfile.getPasswordChangeMessage);

export const getSecurityQuestions = createSelector(getMyProfileState, fromMyProfile.getSecurityQuestions);
export const getSecurityQuestionsLoading = createSelector(getMyProfileState, fromMyProfile.getSecurityQuestionsLoading);
export const getSecurityQuestionsLoaded = createSelector(getMyProfileState, fromMyProfile.getSecurityQuestionsLoaded);

export const getSecurityQuestionsChangeFailed = createSelector(getMyProfileState, fromMyProfile.getSecurityQuestionsChangeFailed);
export const getSecurityQuestionsChangeProcessing = createSelector(getMyProfileState, fromMyProfile.getSecurityQuestionsChangeProcessing);
export const getSecurityQuestionsChangeMessage = createSelector(getMyProfileState, fromMyProfile.getSecurityQuestionsChangeMessage);

export const getEditPersonalInfoFailed = createSelector(getMyProfileState, fromMyProfile.getEditPersonalInfoFailed);
export const getEditPersonalInfoProcessing = createSelector(getMyProfileState, fromMyProfile.getEditPersonalInfoProcessing);
export const getEditPersonalInfoMessage = createSelector(getMyProfileState, fromMyProfile.getEditPersonalInfoMessage);

export const myProfileDataLoading = createSelector(getMyProfileState, fromMyProfile.myProfileDataLoading);
