import { ContactTypeEnums } from '../../enums/contact-type-enum';

export class ContactMechanism {
    public type: ContactTypeEnums;
    public value: string;
    public preferredContactMethod: boolean;

    constructor(init?: Partial<ContactMechanism>) {
        if (!init) {
            init = {};
        }
        this.type = init.type || null;
        this.value = init.value || '';
        this.preferredContactMethod = init.preferredContactMethod === undefined ? null : init.preferredContactMethod;
    }
}

export class TemporaryContactMechanism extends ContactMechanism {
    public desc: string;

    constructor(init?: Partial<TemporaryContactMechanism>) {
        if (!init) {
            init = {};
        }
        super(init);
        this.desc = init.desc || null;
    }
}
