import * as R from "ramda";
import { PolicyBase } from "../models";

const typeCache: { [label: string]: boolean } = {};

type Predicate = (oldValues: any[], newValues: any[]) => boolean;

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels are unique.
 *
 * @param label
 */
export function type<T>(label: T | ""): T {
    if (typeCache[<string>label]) {
        throw new Error(`Action type "${label}" is not unqiue"`);
    }

    typeCache[<string>label] = true;

    return <T>label;
}

/**
 * Runs through every condition, compares new and old values and returns true/false depends on condition state.
 * This is used to distinct if two observable values have changed.
 *
 * @param oldValues
 * @param newValues
 * @param conditions
 */
export function distinctChanges(
    oldValues: any[],
    newValues: any[],
    conditions: Predicate[]
): boolean {
    if (conditions.every((cond) => cond(oldValues, newValues))) {
        return false;
    }
    return true;
}

/**
 * Returns true if the given value is type of Object
 *
 * @param val
 */
export function isObject(val: any) {
    if (val === null) {
        return false;
    }

    return typeof val === "function" || typeof val === "object";
}

/**
 * Capitalizes the first character in given string
 *
 * @param s
 */
export function capitalize(s: string) {
    if (!s || typeof s !== "string") {
        return s;
    }
    return s && s[0].toUpperCase() + s.slice(1);
}

/**
 * Uncapitalizes the first character in given string
 *
 * @param s
 */
export function uncapitalize(s: string) {
    if (!s || typeof s !== "string") {
        return s;
    }
    return s && s[0].toLowerCase() + s.slice(1);
}

/**
 * Flattens multi dimensional object into one level deep
 *
 * @param obj
 * @param preservePath
 */
export function flattenObject(ob: any, preservePath: boolean = false): any {
    const toReturn = {};

    for (const i in ob) {
        if (!ob.hasOwnProperty(i)) {
            continue;
        }

        if (typeof ob[i] === "object") {
            const flatObject = flattenObject(ob[i], preservePath);
            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) {
                    continue;
                }
                const path = preservePath ? i + "." + x : x;
                toReturn[path] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }

    return toReturn;
}

/**
 * Returns formated date based on given culture
 *
 * @param dateString
 * @param culture
 */
export function localeDateString(
    dateString: string,
    culture: string = "en-EN"
): string {
    const date = new Date(dateString);
    return date.toLocaleDateString(culture);
}

// /**
//  * Returns phone number formatted for sending to the API: (123)456-7890
//  *
//  * @param value
//  */
export function formatPhoneForApi(
    value: string,
    format: "nnn-nnn-nnnn" | "(nnn)nnn-nnnn"
): string {
    const phoneNumber: string = value.replace(/[^0-9]+/g, "");
    if (phoneNumber.length === 10) {
        switch (format) {
            case "nnn-nnn-nnnn":
                return (
                    phoneNumber.slice(0, 3) +
                    "-" +
                    phoneNumber.slice(3, 6) +
                    "-" +
                    phoneNumber.slice(6, 10)
                );

            case "(nnn)nnn-nnnn":
                return (
                    "(" +
                    phoneNumber.slice(0, 3) +
                    ")" +
                    phoneNumber.slice(3, 6) +
                    "-" +
                    phoneNumber.slice(6, 10)
                );

            default:
                throw new Error(
                    "Invalid format. Must be either 'nnn-nnn-nnnn' or '(nnn)nnn-nnnn'"
                );
        }
    } else {
        throw new Error("Invalid length.");
    }
}

export function defaultPolicySort(policies: PolicyBase[]): PolicyBase[] {
    return R.sortWith(
        [
            R.ascend(R.prop("sortCatagory")),
            R.ascend(R.prop("policyTypeDesc")),
            R.ascend(R.prop("policyNbr")),
        ],
        policies
    );
}

export function buildCoverages(lineCoverages: any[]) {
    if (lineCoverages)
        return lineCoverages
            .filter(
                (cov) =>
                    cov.isNeededforPremSummary !== false ||
                    (cov.code === "BP7AutomaticIncreaseCovBuilding" &&
                        cov.premium !== 0)
            )
            .map((cov) => {
                const { details, termsList } = splitTerms(cov);
                return {
                    desc: cov.desc,
                    details,
                    premium: cov.premium,
                    items: cov.items ? cov.items : null,
                    terms: termsList,
                };
            });
    else return [];
}

function splitTerms(coverage: any) {
    if (!coverage.terms) {
        return {};
    }
    const details: any[] = [];
    const termsList: any[] = [];

    coverage.terms.forEach((term) => {
        if (!term.isNeededForQuoteAndDeclaration) {
            return;
        }
        if (coverage.desc === term.desc) {
            details.push(term);
        } else {
            termsList.push(term);
        }
    });
    return { details: details.map((term) => term.value).join(", "), termsList };
}

export function filterCoverages(coverages: any[], typeOfCoverage?: string): any[] {
    if (coverages) {
        if (typeOfCoverage) {
            return coverages.filter(coverage => coverage.grouping && coverage.grouping.name === typeOfCoverage);
        }
        return coverages.filter(coverage => !coverage.hasOwnProperty('grouping'))
    }
    return null;
}

export function buildPOCoverages(lineCoverages: any[]) {
    if (lineCoverages)
        return lineCoverages
            .map((cov) => {
                const { details, termsList } = splitPOTerms(cov);
                return {
                    desc: cov.desc,
                    details,
                    premium: cov.premium,
                    items: cov.items ? cov.items : null,
                    terms: termsList,
                };
            });
    else return [];
}

function splitPOTerms(coverage: any) {
    if (!coverage.terms) {
        return {};
    }
    const details: any[] = [];
    const termsList: any[] = [];

    coverage.terms.forEach((term) => {
        if (coverage.desc === term.desc) {
            details.push(term);
        } else {
            termsList.push(term);
        }
    });
    return { details: details.map((term) => term.value).join(", "), termsList };
}