import { Component, Input, OnInit } from '@angular/core';
import { PolicyBase } from '../../../shared/models';

@Component({
  selector: 'app-policy-buttons',
  templateUrl: './policy-buttons.component.html'
})
export class PolicyButtonsComponent implements OnInit {

  @Input() public policy: PolicyBase;

  showChangeMyPolicyLink = false;  

  constructor() { }

  public ngOnInit() {
    if(this.policy.policyAccess.POLICY_CHANGES.allowed){ 
      this.showChangeMyPolicyLink = true;
    }
  }

}
