import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-validation-warning',
    templateUrl: './validation-warning.component.html',
    styleUrls: ['./validation-warning.component.scss']
})
export class ValidationWarningComponent {

    public id: string;
    public valMessage: string;

    get message(): string {
        return this.valMessage;
    }
    @Input()
    set message(message: string) {
        this.valMessage = message;

        window.setTimeout(() => {
            const el = document.getElementById(this.id);
            if (el) { el.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        });
    }

    constructor() {
        this.id = 'validation_warning_' + Math.random().toString(36).substr(2, 9);
    }

}
