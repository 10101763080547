import { Action } from '@ngrx/store';
import { Agency } from '../../../models/auto/agency.model';
import { SurrenderResponse } from '../../../models/policy/surrenderApi-response.model';
import { TaxResponse } from '../../../models/policy/taxApi-response.model';
import { type } from '../../../utility';

export const ActionTypes = {
    LOAD_POLICIES: type('[Policies] Load'),
    LOAD_POLICIES_SUCCESS: type('[Policies] Load Success'),
    LOAD_POLICIES_FAIL: type('[Policies] Load Fail'),
    LOAD_POLICY: type('[Policy] Load'),
    LOAD_POLICY_SUCCESS: type('[Policy] Load Success'),
    LOAD_POLICY_SUCCESS_LIFE: type('[Policy] Load Success Life'),
    LOAD_POLICY_SUCCESS_ANNUITY: type('[Policy] Load Success Annuity'),
    LOAD_POLICY_FAIL: type('[Policy] Load Fail'),
    LOAD_LIFE_SUCCESS: type('[Policy] Life Details Success'),
    LOAD_LIFE_FAIL: type('[Policy] Life Details Fail'),
    LOAD_ANNUITY_SUCCESS: type('[Policy] Annuity Details Success'),
    LOAD_ANNUITY_FAIL: type('[Policy] Annuity Details Fail'),
    GET_AGENCY: type('[Policy] Agency Load'),
    GET_AGENCY_SUCCESS: type('[Policy] Agency Load Success'),
    GET_AGENCY_FAIL: type('[Policy] Agency Load Fail')
};

/**
 * Policies Actions
 */

export class LoadPoliciesAction implements Action {
    public type = ActionTypes.LOAD_POLICIES;
    constructor(public payload: boolean = null) {}
}

export class LoadPoliciesSuccessAction implements Action {
    public type = ActionTypes.LOAD_POLICIES_SUCCESS;
    constructor(public payload: any = null) {}
}

export class LoadPoliciesFailAction implements Action {
    public type = ActionTypes.LOAD_POLICIES_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadPolicyAction implements Action {
    public type = ActionTypes.LOAD_POLICY;
    constructor(public payload: any = null) {}
}

export class LoadPolicySuccessAction implements Action {
    public type = ActionTypes.LOAD_POLICY_SUCCESS;
    constructor(public payload: any = null) {}
}

export class LoadPolicySuccessLifeAction implements Action {
    public type = ActionTypes.LOAD_POLICY_SUCCESS_LIFE;
    constructor(public payload: any = null) {}
}

export class LoadPolicySuccessAnnuityAction implements Action {
    public type = ActionTypes.LOAD_POLICY_SUCCESS_ANNUITY;
    constructor(public payload: any = null) {}
}

export class LoadPolicyFailAction implements Action {
    public type = ActionTypes.LOAD_POLICY_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadLifeSuccessAction implements Action {
    public type = ActionTypes.LOAD_LIFE_SUCCESS;
    constructor(public payload: SurrenderResponse = null) {}
}

export class LoadLifeFailAction implements Action {
    public type = ActionTypes.LOAD_LIFE_FAIL;
    constructor(public payload: any = null) {}
}

export class LoadAnnuitySuccessAction implements Action {
    public type = ActionTypes.LOAD_ANNUITY_SUCCESS;
    constructor(public payload: TaxResponse = null) {}
}

export class LoadAnnuityFailAction implements Action {
    public type = ActionTypes.LOAD_ANNUITY_FAIL;
    constructor(public payload: any = null) {}
}

export class GetAgencyAction implements Action {
    public type = ActionTypes.GET_AGENCY;
    constructor(public payload: string = null) {}
}

export class GetAgencySuccessAction implements Action {
    public type = ActionTypes.GET_AGENCY_SUCCESS;
    constructor(public payload: Agency = null) {}
}

export class GetAgencyFailAction implements Action {
    public type = ActionTypes.GET_AGENCY_FAIL;
    constructor(public payload: any = null) {}
}

export type Actions =
    | LoadPoliciesAction
    | LoadPoliciesSuccessAction
    | LoadPoliciesFailAction
    | LoadPolicyAction
    | LoadPolicySuccessAction
    | LoadPolicySuccessLifeAction
    | LoadPolicySuccessAnnuityAction
    | LoadPolicyFailAction
    | LoadLifeSuccessAction
    | LoadLifeFailAction
    | LoadAnnuitySuccessAction
    | LoadAnnuityFailAction
    | GetAgencyAction
    | GetAgencySuccessAction
    | GetAgencyFailAction;
