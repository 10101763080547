import { Vehicle } from '../policy/auto/vehicle.model';
import { ClaimClient } from './claimClient.model';
import { UnitDamage } from './unitDamage.model';

export class ClaimVehicle  extends Vehicle {
    public vehicleKey: string;
    public vehicleColor: string;
    public unitSeqNbr: string;
    public unitTypeCd: string;
    public unitDesc: string;
    public lossInfoId: string;
    public fuelTypeCd: string;
    public driverSameInd: string;
    public ownerSameInd: string;
    public parkedInd: string;
    public involvementDesc: string;
    public whereCanBeViewed: string;
    public whenCanBeViewed: string;
    public preferredGlassVendor: string;
    public whichCountyGlassSvc: string;
    public claimClients: ClaimClient[];
    public unitDamage: UnitDamage[];
    // List<Citation> citation;
    public unitOtherDamage: string;
    public theft: boolean;
    public airBagsDeploy: boolean;
    public driveableInd: boolean;
    public rentalNeeded: boolean;
    public totalLossInd: boolean;
    public garageLocDesc: string;
    public unitComment: string;
    public bureauCauseOfLossCd: string;
    public unitTypeInd: string;
    public unitObjectTypeCd: string;
    public unitObjectSeqNbr: string;
    public operableInd: boolean;
    public permissionInd: boolean;
    public watercraftLocDesc: string;
    public watersNavCd: string;
    public propulsionType: string;
    public registrationNbr: string;
    public registrationSt: string;
    public trailerRegistrationNbr: string;
    public trailerRegistrationSt: string;
    public usage: string;
    public hullMaterial: string;
    public horsePower: string;
    public length: string;
    public operatorSameInd: string;
    public watercraftTypeCd: string;
    public entrySource: string;

    constructor(init?: Partial<ClaimVehicle>) {
        if (!init) { init = {}; }
        super(init);
        this.vehicleKey = init.vehicleKey || null;
        this.vehicleColor = init.vehicleColor || null;
        this.unitSeqNbr = init.unitSeqNbr || null;
        this.unitTypeCd = init.unitTypeCd || null;
        this.unitDesc = init.unitDesc || null;
        this.lossInfoId = init.lossInfoId || null;
        this.fuelTypeCd = init.fuelTypeCd || null;
        this.driverSameInd = init.driverSameInd || null;
        this.ownerSameInd = init.ownerSameInd || null;
        this.parkedInd = init.parkedInd || null;
        this.involvementDesc = init.involvementDesc || null;
        this.whereCanBeViewed = init.whereCanBeViewed || null;
        this.whenCanBeViewed = init.whenCanBeViewed || null;
        this.preferredGlassVendor = init.preferredGlassVendor || null;
        this.whichCountyGlassSvc = init.whichCountyGlassSvc || null;
        this.claimClients = init.claimClients || new Array<ClaimClient>();
        this.unitDamage = init.unitDamage || new Array<UnitDamage>();
        this.unitOtherDamage = init.unitOtherDamage || null;
        this.theft = init.theft === undefined ? null : init.theft;
        this.airBagsDeploy = init.airBagsDeploy === undefined ? null : init.airBagsDeploy;
        this.driveableInd = init.driveableInd === undefined ? null : init.driveableInd;
        this.rentalNeeded = init.rentalNeeded === undefined ? null : init.rentalNeeded;
        this.totalLossInd = init.totalLossInd === undefined ? null : init.totalLossInd;
        this.garageLocDesc = init.garageLocDesc || null;
        this.unitComment = init.unitComment || null;
        this.bureauCauseOfLossCd = init.bureauCauseOfLossCd || null;
        this.unitTypeInd = init.unitTypeInd || null;
        this.unitObjectTypeCd = init.unitObjectTypeCd || null;
        this.unitObjectSeqNbr = init.unitObjectSeqNbr || null;
        this.operableInd = init.operableInd || null;
        this.permissionInd = init.permissionInd || null;
        this.watercraftLocDesc = init.watercraftLocDesc || null;
        this.watersNavCd = init.watersNavCd || null;
        this.propulsionType = init.propulsionType || null;
        this.registrationNbr = init.registrationNbr || null;
        this.registrationSt = init.registrationSt || null;
        this.trailerRegistrationNbr = init.trailerRegistrationNbr || null;
        this.trailerRegistrationSt = init.trailerRegistrationSt || null;
        this.usage = init.usage || null;
        this.hullMaterial = init.hullMaterial || null;
        this.horsePower = init.horsePower || null;
        this.length = init.length || null;
        this.operatorSameInd = init.operatorSameInd || null;
        this.watercraftTypeCd = init.watercraftTypeCd || null;
        this.entrySource = init.entrySource || null;
    }
}
