// Angular core modules
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

// Routes
import { AppRoutingModule } from './app-routing.module';

// Modules
import { AppComponent } from './app.component';
import { AsyncServicesModule } from './shared/asyncServices/';
import { UtilityModule } from './shared/utility';

// Store
import { effects, metareducers, reducers } from './shared/store';

// Guards
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { CanDeactivateGuard } from './shared/guards/can-deactivate/can-deactivate.gaurd';

// Third party libraries
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule, MatIconModule, MatListModule, MatSidenavModule, MatToolbarModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AnalyticsModule } from './analytics/analytics.module';
import { MyHammerConfig } from './hammer_config';
import { PageNotFoundComponent } from './pageNotFound/pageNotFound.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ComponentsModule } from './shared/components';
import { ContainersModule } from './shared/containers';
import { AuthSandbox } from './shared/guards/auth/auth.sandbox';
import { ErrorMsgService } from './shared/utility/error-msg.service';
import { LookupService } from './shared/utility/lookup-service';
import { StorageService } from './shared/utility/storage.service';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

@NgModule({
    declarations: [AppComponent, PageNotFoundComponent, TermsAndConditionsComponent, PrivacyPolicyComponent],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        BrowserAnimationsModule,

        StoreModule.forRoot(reducers, { metaReducers: metareducers }),

        AsyncServicesModule.forRoot(),
        UtilityModule.forRoot(),

        AppRoutingModule,
        ContainersModule,
        ComponentsModule,
        AnalyticsModule,

        StoreDevtoolsModule.instrument(),
        EffectsModule.forRoot(effects),
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule
    ],
    providers: [
        AuthGuard,
        AuthSandbox,
        CanDeactivateGuard,
        ErrorMsgService,
        LookupService,
        StorageService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
