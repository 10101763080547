import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { TabLayout } from 'src/app/shared/models';
import { PolicyApiClient } from '../../../asyncServices/api-clients/policy-apiclient.service';
import { SurrenderResponse } from '../../../models/policy/surrenderApi-response.model';
import { TaxResponse } from '../../../models/policy/taxApi-response.model';
import { ErrorMsgService } from '../../../utility/error-msg.service';
import * as layoutActions from '../../actions/layout/layout.action';
import * as policyActions from '../../actions/policy/policy.action';
import * as store from '../../index';
import { getUser } from '../../selectors';

@Injectable()
export class PolicyEffects {
    constructor(
        private actions$: Actions,
        private policyApiClient: PolicyApiClient,
        private appState$: Store<store.State>,
        private errorMsgService: ErrorMsgService
    ) {}

    @Effect()
    public getPoliciesByCustid$: Observable<Action> = this.actions$.ofType(policyActions.ActionTypes.LOAD_POLICIES).pipe(
        map((action: policyActions.LoadPoliciesAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.policyApiClient.getPoliciesByCustId(user.custId, payload).pipe(
                map(apiResponse => {
                    return new policyActions.LoadPoliciesSuccessAction(apiResponse);
                }),
                catchError(error => of(new policyActions.LoadPoliciesFailAction(error)))
            );
        })
    );

    @Effect()
    public getPoliciesFailed$: Observable<Action> = this.actions$.ofType(policyActions.ActionTypes.LOAD_POLICIES_FAIL).pipe(
        map(
            (action: policyActions.LoadPoliciesFailAction) =>
                new layoutActions.UpdateTabLayoutAction(
                    new TabLayout({
                        billingTab: false,
                        claimsTab: false,
                        documentsTab: false,
                        membershipTab: false
                    })
                )
        )
    );

    @Effect()
    public getPoliciesSuccess$: Observable<Action> = this.actions$.ofType(policyActions.ActionTypes.LOAD_POLICIES_SUCCESS).pipe(
        map(
            (action: policyActions.LoadPoliciesSuccessAction) =>
                new layoutActions.UpdateTabLayoutAction(
                    new TabLayout({
                        billingTab: true,
                        claimsTab: true,
                        documentsTab: true,
                        membershipTab: true
                    })
                )
        )
    );

    @Effect()
    public getPolicy$: Observable<Action> = this.actions$.ofType(policyActions.ActionTypes.LOAD_POLICY).pipe(
        map((action: policyActions.LoadPolicyAction) => action.payload),
        switchMap(payload => {
            if (payload.type === 'MEMB') {
                return this.policyApiClient.getMembership(payload.policyNbr).pipe(
                    map((ApiResponse: any) => {
                        return new policyActions.LoadPolicySuccessAction(ApiResponse);
                    }),
                    catchError(error =>
                        of(
                            new policyActions.LoadPolicyFailAction({
                                error: this.errorMsgService.getMsg(error),
                                policyNbr: payload.policyNbr,
                                translatedPolicyType: payload.type
                            })
                        )
                    )
                );
            } else {
                return this.policyApiClient.getPolicyDetailsByDate(payload.type, payload.policyNbr, payload.date).pipe(
                    map((apiResponse: any) => {
                        if (apiResponse.policyTypeCd === 'WHOLE' || apiResponse.policyTypeCd === 'UNIV') {
                            // For life policies this action will send another API call to get surrender information
                            return new policyActions.LoadPolicySuccessLifeAction(apiResponse);
                        } else if (apiResponse.policyTypeCd === 'ANNY') {
                            // For annuity policies this action will send another API call to get surrender information and another for tax information
                            return new policyActions.LoadPolicySuccessAnnuityAction(apiResponse);
                        } else {
                            return new policyActions.LoadPolicySuccessAction(apiResponse);
                        }
                    }),
                    catchError(error =>
                        of(
                            new policyActions.LoadPolicyFailAction({
                                error: this.errorMsgService.getMsg(error),
                                policyNbr: payload.policyNbr,
                                translatedPolicyType: payload.type
                            })
                        )
                    )
                );
            }
        })
    );

    @Effect()
    public getPolicySuccessLife$: Observable<Action> = this.actions$
        .ofType(policyActions.ActionTypes.LOAD_POLICY_SUCCESS_LIFE, policyActions.ActionTypes.LOAD_POLICY_SUCCESS_ANNUITY)
        .pipe(
            map((action: policyActions.LoadPolicyAction) => action.payload),
            switchMap(state => {
                const today = new Date().toISOString().slice(0, 10);
                return this.policyApiClient.getLifeSurrenderInfo(state.policyNbr, today).pipe(
                    map(ApiResponse => {
                        const response = new SurrenderResponse({
                            policyNbr: state.policyNbr,
                            surrender: ApiResponse
                        });
                        return new policyActions.LoadLifeSuccessAction(response);
                    }),
                    catchError(error =>
                        of(
                            new policyActions.LoadLifeFailAction({
                                error: 'Value Not Found',
                                policyNbr: state.policyNbr
                            })
                        )
                    )
                );
            })
        );

    @Effect()
    public getPolicySuccessAnnuity$: Observable<Action> = this.actions$.ofType(policyActions.ActionTypes.LOAD_POLICY_SUCCESS_ANNUITY).pipe(
        map((action: policyActions.LoadPolicyAction) => {
            return action.payload;
        }),
        switchMap(state => {
            return this.policyApiClient.getAnnuityTaxInfo(state.policyNbr).pipe(
                map(apiResponse => {
                    const response = new TaxResponse({
                        policyNbr: state.policyNbr,
                        contributionHistory: apiResponse
                    });
                    return new policyActions.LoadAnnuitySuccessAction(response);
                }),
                catchError(error => of(new policyActions.LoadAnnuityFailAction()))
            );
        })
    );

    @Effect()
    public getAgency$: Observable<Action> = this.actions$.ofType(policyActions.ActionTypes.GET_AGENCY).pipe(
        map((action: policyActions.GetAgencyAction) => {
            return action.payload;
        }),
        mergeMap(payload => {
            return this.policyApiClient.getAgency(payload).pipe(
                map(apiResponse => new policyActions.GetAgencySuccessAction(apiResponse)),
                catchError(error => of(new policyActions.GetAgencyFailAction(error)))
            );
        })
    );
}
