import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, HttpService, Path } from '../http';

@Injectable()
export class BillingApiClient extends HttpService {
  @GET('billing/summary/policy/{policyNbr}/PC?months=24',false)
  public getPCBillingSummary(
    @Path('policyNbr') policyNbr: string
  ): Observable<any> {
    return null;
  }

  @GET('billing/summary/policy/{policyNbr}/LF?months=24',false)
  public getLFBillingSummary(
    @Path('policyNbr') policyNbr: string
  ): Observable<any> {
    return null;
  }

  @GET('billing/summary/policy/{policyNbr}/{sysId}?months=24',false)
  public getBillingSummary(
    @Path('policyNbr') policyNbr: string,
    @Path('sysId') sysId: string
  ): Observable<any> {
    return null;
  }
}
