import { createSelector } from '@ngrx/store';
import { getAutoChangeLoading, getAutoSupportLoading } from './auto';
import { claimsFileDataLoading, claimsSupportDataLoading } from './claims';
import { getClientDataLoading } from './client';
import { myProfileDataLoading } from './myprofile';
import { getPayMyBillLoading } from './pay-my-bill';
import { policyDataLoading } from './policy';
import { routerDataLoading } from './router';
import { getIsUserLoggingIn, getLoginChallengeSubmit } from './user';

const dataLoadingChunk1 = createSelector(
    claimsFileDataLoading,
    claimsSupportDataLoading,
    policyDataLoading,
    routerDataLoading,
    myProfileDataLoading,
    getClientDataLoading,
    getAutoChangeLoading,
    getAutoSupportLoading,
    (s1, s2, s3, s4, s5, s6, s7, s8) => s1 || s2 || s3 || s4 || s5 || s6 || s7 || s8);

const dataLoadingChunk2 = createSelector(
        getPayMyBillLoading,
        getIsUserLoggingIn,
        getLoginChallengeSubmit,
        (s1, s2, s3) => s1 || s2 || s3);

export const dataLoading = createSelector(
        dataLoadingChunk1,
        dataLoadingChunk2,
        (s1, s2) => s1 || s2
    );
