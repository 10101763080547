import { DocumentProperties } from "./document-properties.model";

export class DocumentAttachment {
    public name: string;
    public mimeType: string;
    public b64Content: string;
    public attachProperties = new DocumentProperties();

    constructor(init?: Partial<DocumentAttachment>) {
        if (!init) {init = {}; }
        this.name = init.name || null;
        this.mimeType = init.mimeType || null;
        this.b64Content = init.b64Content || null;
        this.attachProperties = init.attachProperties || new DocumentProperties();
    }
}
