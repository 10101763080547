import { Billing } from './billing.model';
import { Insured } from './insured.model';
import { MailingAddress } from './mailingAddress.model';

export class PolicyBase {
    public effDate: string;
    public effTypeCd: string;
    public expDate: string;
    public ognEffDate: string;
    public plnExpDate: string;
    public lobCd: string;
    public lobDesc: string;
    public translatedPolicyType: string;
    public sortCatagory: string;
    public policyExpirationDateObject: Date;

    public policyId: string;
    public policyNbr: string;
    public policyTypeCd: string;
    public policyTypeDesc: string;
    public renewalStatusCd: string;
    public statusCd: string;
    public statusDesc: string;
    public symbolCd: string;
    public systemId: string;
    public sourceSystem: string;
    public termCd: string;
    public termDesc: string;
    public totalPremium: number;

    public mailingAddr: MailingAddress;

    public billing: Billing;

    public memberNbr: string;

    public namedInsured: Insured;
    // fix model for access
    public displayPolicy: boolean;
    public canFileClaim: boolean;
    public policyAccess: PolicyAccess;
    public policyContacts: any;

    public clients: any[];
    public primaryInsured: any;
    public owner: any;
    public payor: any;
    public surrender: any;
    public contributionHistory: any;
    public error: string;
    public detailsLoaded: boolean;
    public effectiveDt: string;
    public expirationYear: string;
    public displayExpiration: string;

    public agency: {
        officeNbr: string;
        agents: any[];
    };

    public drivers: Driver[];

    public paperless?: {
        statusCd?: string;
        email?: string;
        pendingChange?: {
            email: string;
            id: string;
        };
    };

    public coverages?: any[];
    public beneficiaries?: any[];
    public annualizedPremium?: number;
    public premium?: number;
    public addlNamedInsureds?: any;
    public discounts?: any[];
    public participating?: boolean;
    public dividends?: any;
    public moratorium?; public boolean;

    constructor(init?: Partial<PolicyBase>) {
        if (!init) {
            init = {};
        }
        this.effDate = init.effDate || null;
        this.effTypeCd = init.effTypeCd || null;
        this.expDate = init.expDate || null;
        this.ognEffDate = init.ognEffDate || null;
        this.plnExpDate = init.plnExpDate || null;
        this.lobCd = init.lobCd || null;
        this.lobDesc = init.lobDesc || null;
        this.translatedPolicyType = init.translatedPolicyType || null;
        this.sortCatagory = init.sortCatagory || null;
        this.policyExpirationDateObject = init.policyExpirationDateObject || null;

        this.policyId = init.policyId || null;
        this.policyNbr = init.policyNbr || null;
        this.policyTypeCd = init.policyTypeCd || null;
        this.policyTypeDesc = init.policyTypeDesc || null;
        this.renewalStatusCd = init.renewalStatusCd || null;
        this.statusCd = init.statusCd || null;
        this.statusDesc = init.statusDesc || null;
        this.symbolCd = init.symbolCd || null;
        this.systemId = init.systemId || null;
        this.sourceSystem = init.sourceSystem || null;
        this.termCd = init.termCd || null;
        this.termDesc = init.termDesc || null;
        this.totalPremium = init.totalPremium || null;

        this.mailingAddr = init.mailingAddr || new MailingAddress();

        this.billing = init.billing || new Billing();

        this.memberNbr = init.memberNbr || null;

        this.namedInsured = init.namedInsured || new Insured();

        this.displayPolicy = init.displayPolicy || null;
        this.canFileClaim = init.displayPolicy || null;
        this.policyAccess = init.policyAccess || new PolicyAccess();

        this.clients = init.clients || null;
        this.primaryInsured = init.primaryInsured || null;
        this.owner = init.owner || null;
        this.payor = init.payor || null;
        this.surrender = init.surrender || null;
        this.contributionHistory = init.contributionHistory || null;
        this.error = init.error || null;
        this.detailsLoaded = init.detailsLoaded || null;
        this.effectiveDt = init.effectiveDt || null;
        this.expirationYear = init.expirationYear || null;
        this.displayExpiration = init.displayExpiration || null;

        this.agency = init.agency || null;
        this.drivers = init.drivers || null;
        this.paperless = init.paperless || null;
        this.coverages = init.coverages || null;
        this.beneficiaries = init.beneficiaries || null;
        this.annualizedPremium = init.annualizedPremium || null;
        this.premium = init.premium || null;
        this.addlNamedInsureds = init.addlNamedInsureds || null;
        this.discounts = init.discounts || null;
        this.participating = init.participating || null;
        this.dividends = init.dividends || null;
        this.moratorium = init.moratorium || null;
    }
}

export class PolicyAccess {
    public FILE_CLAIM: Access;
    public POLICY_CHANGES: Access;
    public EDIT_PAPERLESS: Access;
    public ENROLL_PAPERLESS: Access;
    public VIEW_DOCUMENTS: Access;

    constructor(init?: Partial<PolicyAccess>) {
        Object.assign(this, init);
    }
}

export class Access {
    public allowed: boolean;
    public reason?: string;

    constructor(init?: Partial<Access>) {
        Object.assign(this, init);
    }
}

export class Driver {
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public excluded: boolean;
    public clientId: string;
    public suffixCd: string;
    public suffixDesc: string;
    public dateOfBirth: string;
    public gender: string;
    public maritalStatusCd: string;
    public maritalStatusDesc: string;
    public number: string;
    public licenseStateCd: string;
    public licenseStateDesc: string;
    public licenseNbr: string;
    public accidentForgiveness: true;
    public alive55: boolean;

    constructor(init?: Partial<Driver>) {
        Object.assign(this, init);
    }
}

export class LossPayee {
    public legalName: string;
    public clientId: string;
    public lastName: string;
    public interestTypeCd: string;
    public interestTypeDesc: string;
    public interestDesc: string;
    public address: MailingAddress;

    constructor(init?: Partial<LossPayee>) {
        Object.assign(this, init);
    }
}
