
export class PaperlessVerification {

    public id: string;
    public url: string;
    public policyNbr: string;
    public sourceSystem: string;
    public systemId: string;

    constructor(init: Partial<PaperlessVerification> = {}) {

        ({
            id: this.id,
            url: this.url,
            policyNbr: this.policyNbr,
            systemId: this.systemId,
            sourceSystem: this.sourceSystem
        } = init);
    }
}
