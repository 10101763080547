import { Document } from '../document/document.model';
import { ClaimProperty } from './claim-property.model';
import { ClaimClient } from './claimClient.model';
import { ClaimLocation } from './claims-location.model';
import { ClaimVehicle } from './claimVehicle.model';
import { ContactMechanism, TemporaryContactMechanism } from './contact-mechanism.model';

export class ClaimsApiRequest {
    // tslint:disable-next-line:variable-name
    public _id: string;
    // tslint:disable-next-line:variable-name
    public _rev: string;
    public xcdUser: string;
    public source: string;
    public extRefId: string;
    public custId: string;
    public processType: string;
    public processTaskStatus: string;
    public processRestartAttempts = 0;
    public processTaskDateTime: Date;
    public policyTypeCd: string;
    public created: string;
    public modified: string;
    public completed: string;
    public uow: string;
    public claimNbr: string;
    public claimKey: string;
    public techClaimNbr: string;
    public unitIncidentChk: boolean;
    public policyNbr: string;

    // Indicators for UI to reload saves
    public reportFiled: boolean;
    public usePreferredVendor: boolean;

    public panelSaveLoc: string;
    public policyId: string;
    public effDate: string;
    public expDate: string;
    public lobCd: string;
    public polSymDesc: string;
    public polSymCd: string;
    public claimType: string;
    public severityCd: string;
    public severityDesc: string;
    public lossDate: string;
    public lossTime: string;
    public lossDesc: string;
    public reportedDate: string;
    public reportedTime: string;
    public reportedTs: Date;
    public lossLocation: ClaimLocation;
    public claimDate: string;
    public settlementDate: string;
    public cat: boolean;
    public imaged: boolean;
    public reportNbr: string;
    public masterCompanyCd: string;
    public reportAuthority: ClaimClient;
    public symbol: string;
    public statusCd: string;
    public amountClaimed: string;
    public amountPaid: string;
    public causeOfLossCd: string;
    public causeOfLossDesc: string;
    public bureauCauseOfLossCd: string;
    public bureauCauseOfLossDesc: string;
    public clmCheckSum: string;
    public lossDescClmCmtKey: string;
    public lossDescClmCmtCheckSum: string;
    public lossLocClmCmtKey: string;
    public lossLocClmCmtCheckSum: string;
    public dirAutLimitAmt: string;
    public medAutLimitAmt: string;
    public expAutLimitAmt: string;
    public riskState: string;
    public branchCd: string;
    public policyCountyTownCd: string;
    public premiumNotPaidInd: string;
    public formsAvailableInd: string;
    public lossHandlingOfficeCd: string;
    public additionalComments: string;
    public contactList: ContactMechanism[];
    public contactTempList: TemporaryContactMechanism[];
    public ersAmount: string;
    public ersCompany: string;
    public duplicateInd: boolean;
    public authContactTypeCd: string;
    public clmNoticeCd: string;
    public hldDriverSameInd: string;
    public hldOperatorSameInd: string;
    public claimClients: ClaimClient[];
    public claimVehicles: ClaimVehicle[];
    public claimProperties: ClaimProperty[];
    // public claimAssignments: ClaimAssignment[] = [];
    public archiveDocuments: Document;
    public clientReports: Document;
    public userDocuments: Document;
    public userImages: Document;
    public errorReports: Document;
    // public duplicateClaims: ClaimItem[] = [];
    // public receiverUOWs: UowProcessList;
    public commFwRq: String[];
    public commFwRs: String[];
    public uowNotes: String[];

    constructor(init?: Partial<ClaimsApiRequest>) {
        if (!init) {
            init = {};
        }
        this._id = init._id || null;
        this._rev = init._rev || null;
        this.xcdUser = init.xcdUser || null;
        this.source = init.source || null;
        this.extRefId = init.extRefId || null;
        this.custId = init.custId || null;
        this.processType = init.processType || null;
        this.processTaskStatus = init.processTaskStatus || null;
        this.processRestartAttempts = init.processRestartAttempts || 0;
        this.processTaskDateTime = init.processTaskDateTime || null;
        this.policyTypeCd = init.policyTypeCd || null;
        this.created = init.created || null;
        this.modified = init.modified || null;
        this.completed = init.completed || null;
        this.uow = init.uow || null;
        this.claimNbr = init.claimNbr || null;
        this.claimKey = init.claimKey || null;
        this.techClaimNbr = init.techClaimNbr || null;
        this.unitIncidentChk = init.unitIncidentChk || null;
        this.policyNbr = init.policyNbr || null;

        // Indicators for UI to reload saves
        this.reportFiled = init.reportFiled || null;
        this.usePreferredVendor = init.usePreferredVendor || null;

        this.panelSaveLoc = init.panelSaveLoc || null;
        this.policyId = init.policyId || null;
        this.effDate = init.effDate || null;
        this.expDate = init.expDate || null;
        this.lobCd = init.lobCd || null;
        this.polSymDesc = init.polSymDesc || null;
        this.polSymCd = init.polSymCd || null;
        this.claimType = init.claimType || null;
        this.severityCd = init.severityCd || null;
        this.severityDesc = init.severityDesc || null;
        this.lossDate = init.lossDate || null;
        this.lossTime = init.lossTime || null;
        this.lossDesc = init.lossDesc || null;
        this.reportedDate = init.reportedDate || null;
        this.reportedTime = init.reportedTime || null;
        this.reportedTs = init.reportedTs || null;
        this.lossLocation = init.lossLocation || new ClaimLocation();
        this.claimDate = init.claimDate || null;
        this.settlementDate = init.settlementDate || null;
        this.cat = init.cat || null;
        this.imaged = init.imaged || null;
        this.reportNbr = init.reportNbr || null;
        this.masterCompanyCd = init.masterCompanyCd || null;
        this.reportAuthority = init.reportAuthority || null;
        this.symbol = init.symbol || null;
        this.statusCd = init.statusCd || null;
        this.amountClaimed = init.amountClaimed || null;
        this.amountPaid = init.amountPaid || null;
        this.causeOfLossCd = init.causeOfLossCd || null;
        this.causeOfLossDesc = init.causeOfLossDesc || null;
        this.bureauCauseOfLossCd = init.bureauCauseOfLossCd || null;
        this.bureauCauseOfLossDesc = init.bureauCauseOfLossDesc || null;
        this.clmCheckSum = init.clmCheckSum || null;
        this.lossDescClmCmtKey = init.lossDescClmCmtKey || null;
        this.lossDescClmCmtCheckSum = init.lossDescClmCmtCheckSum || null;
        this.lossLocClmCmtKey = init.lossLocClmCmtKey || null;
        this.lossLocClmCmtCheckSum = init.lossLocClmCmtCheckSum || null;
        this.dirAutLimitAmt = init.dirAutLimitAmt || null;
        this.medAutLimitAmt = init.medAutLimitAmt || null;
        this.expAutLimitAmt = init.expAutLimitAmt || null;
        this.riskState = init.riskState || null;
        this.branchCd = init.branchCd || null;
        this.policyCountyTownCd = init.policyCountyTownCd || null;
        this.premiumNotPaidInd = init.premiumNotPaidInd || null;
        this.formsAvailableInd = init.formsAvailableInd || null;
        this.lossHandlingOfficeCd = init.lossHandlingOfficeCd || null;
        this.additionalComments = init.additionalComments || null;
        this.contactList = init.contactList || new Array<ContactMechanism>();
        this.contactTempList = init.contactTempList || new Array<TemporaryContactMechanism>();
        this.ersAmount = init.ersAmount || null;
        this.ersCompany = init.ersCompany || null;
        this.duplicateInd = init.duplicateInd || null;
        this.authContactTypeCd = init.authContactTypeCd || null;
        this.clmNoticeCd = init.clmNoticeCd || null;
        this.hldDriverSameInd = init.hldDriverSameInd || null;
        this.hldOperatorSameInd = init.hldOperatorSameInd || null;
        this.claimClients = init.claimClients || new Array<ClaimClient>();
        this.claimVehicles = init.claimVehicles || new Array<ClaimVehicle>();
        this.claimProperties = init.claimProperties || new Array<ClaimProperty>();
        // this.claimAssignments = init.claimAssignments || new Array<ClaimAssignment>();
        this.archiveDocuments = init.archiveDocuments || new Document();
        this.clientReports = init.clientReports || new Document();
        this.userDocuments = init.userDocuments || new Document();
        this.userImages = init.userImages || new Document();
        this.errorReports = init.errorReports || new Document();
        // this.duplicateClaims = init.duplicateClaims || new Array<ClaimItem>();
        // this.receiverUOWs = init.receiverUOWs || new UowProcessList;
        this.commFwRq = init.commFwRq || new Array<String>();
        this.commFwRs = init.commFwRs || new Array<String>();
        this.uowNotes = init.uowNotes || new Array<String>();
    }
}
