import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Sandbox } from '../../sandbox/base.sandbox';
import { State } from '../../store';

@Injectable()
export class AuthSandbox extends Sandbox {
    constructor(protected appState$: Store<State>) {
        super(appState$);
    }
}
