import { DeviceInfo } from './deviceInfo.model';

export class ResetPasswordApiRequest {
    public deviceInfo: DeviceInfo;
    public id: string;
    public user: {
        password: string
    };

    constructor(init?: Partial<ResetPasswordApiRequest>) {
        Object.assign(this, init);
    }
}
