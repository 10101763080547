import { PolicyBase } from '../../models';

export class PolicyDisplayItem {
  public policy: PolicyBase;
  public expanded = false;

  public showGotoButton = false;
  public gotoButtonUrlOrLink = '';
  public gotoButtonUrlOrLinkParams = {};

  // Default to use routlink. If ture, we will use url for the a tag.
  public gotoButtonUsesUrl = false;
  public gotoButtonNewWindow = false;
  public gotoButtonLabel = '';
  public gotoButtonDisabled = false;

  public showActionButton = false;
  public actionButtonLabel = '';

  public showDetailsButton = false;
  public detailsButtonUrl = '';
  public detailsButtonLabel = '';

  public showPaymentDue = false;

  constructor(init?: Partial<PolicyDisplayItem>) {
    if (!init) {
      init = {};
    }
    ({
      policy: this.policy,
      expanded: this.expanded,

      showGotoButton: this.showGotoButton,
      gotoButtonUrlOrLink: this.gotoButtonUrlOrLink,
      gotoButtonUrlOrLinkParams: this.gotoButtonUrlOrLinkParams,
      gotoButtonUsesUrl: this.gotoButtonUsesUrl,
      gotoButtonNewWindow: this.gotoButtonNewWindow,
      gotoButtonLabel: this.gotoButtonLabel,
      gotoButtonDisabled: this.gotoButtonDisabled,

      showActionButton: this.showActionButton,
      actionButtonLabel: this.actionButtonLabel,

      showDetailsButton: this.showDetailsButton,
      detailsButtonUrl: this.detailsButtonUrl,
      detailsButtonLabel: this.detailsButtonLabel,

      showPaymentDue: this.showPaymentDue
    } = init);
  }
}
