import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { State } from '../..';
import { MyProfileApiClient } from '../../../../shared/asyncServices';
import { ErrorMsgService } from '../../../../shared/utility/error-msg.service';
import { MyProfileApiRequestFactory } from '../../../models/myprofile/myprofileApi-request.factory';
import {
    ActionTypes,
    ChangePasswordAction,
    ChangePasswordFailAction,
    ChangePasswordSuccessAction,
    ChangeSecurityQuestionsAction,
    ChangeSecurityQuestionsFailAction,
    ChangeSecurityQuestionsSuccessAction,
    EditPersonalInfoAction,
    EditPersonalInfoFailAction,
    EditPersonalInfoSuccessAction,
    LoadSecurityQuestionsAction,
    LoadSecurityQuestionsFailAction,
    LoadSecurityQuestionsSuccessAction,
    LoadUserDataAction,
    LoadUserDataFailAction,
    LoadUserDataSuccessAction
} from '../../actions/myprofile/myprofile.action';
import { getUser } from '../../selectors';

@Injectable()
export class MyProfileEffects {
    constructor(
        private actions$: Actions,
        public myProfileClient: MyProfileApiClient,
        private appState$: Store<State>,
        private router: Router,
        private errorMsgService: ErrorMsgService
    ) {}

    @Effect()
    public changePassword$: Observable<Action> = this.actions$.ofType(ActionTypes.CHANGE_PASSWORD).pipe(
        map((action: ChangePasswordAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.myProfileClient
                .changePassword(
                    user.custId.toString(),
                    MyProfileApiRequestFactory.createChangePasswordRq(payload.existingPass, payload.newPass)
                )
                .pipe(
                    map(() => {
                        return new ChangePasswordSuccessAction('Password updated successfully.');
                    }),
                    catchError(error => of(new ChangePasswordFailAction(this.errorMsgService.getMsg(error))))
                );
        })
    );

    @Effect()
    public loadSecurityQuestions$: Observable<Action> = this.actions$.ofType(ActionTypes.LOAD_SECURITY_QUESTIONS).pipe(
        map((action: LoadSecurityQuestionsAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.myProfileClient.loadSecurityQuestions(user.custId.toString()).pipe(
                map(questions => {
                    return new LoadSecurityQuestionsSuccessAction(questions);
                }),
                catchError(error => of(new LoadSecurityQuestionsFailAction(this.errorMsgService.getMsg(error))))
            );
        })
    );

    @Effect()
    public changeSecurityQuestions$: Observable<Action> = this.actions$.ofType(ActionTypes.CHANGE_SECURITY_QUESTIONS).pipe(
        map((action: ChangeSecurityQuestionsAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.myProfileClient
                .setSecurityQuestions(user.custId.toString(), MyProfileApiRequestFactory.createChangeSecurityQuestionsRq(payload))
                .pipe(
                    map(() => {
                        return new ChangeSecurityQuestionsSuccessAction('Security questions updated successfully.');
                    }),
                    catchError(error => of(new ChangeSecurityQuestionsFailAction(this.errorMsgService.getMsg(error))))
                );
        })
    );

    @Effect()
    public editPersonalInfo$: Observable<Action> = this.actions$.ofType(ActionTypes.EDIT_PERSONAL_INFO).pipe(
        map((action: EditPersonalInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.myProfileClient
                .submitProfileChanges(user.custId.toString(), MyProfileApiRequestFactory.createSaveProfileChangesRq(payload, user.userId))
                .pipe(
                    map(retUser => {
                        return new EditPersonalInfoSuccessAction({
                            user: retUser,
                            message: 'We have received your request and will contact you if we need more information.'
                        });
                    }),
                    catchError(error => of(new EditPersonalInfoFailAction(this.errorMsgService.getMsg(error))))
                );
        })
    );

    @Effect({ dispatch: false })
    public personalInfoSuccess$ = this.actions$.pipe(
        ofType(ActionTypes.EDIT_PERSONAL_INFO_SUCCESS),
        tap(() => {
            return this.router.navigate(['/myprofile']);
        })
    );

    @Effect()
    public loadUserData$: Observable<Action> = this.actions$.ofType(ActionTypes.LOAD_USER_DATA).pipe(
        map((action: LoadUserDataAction) => action.payload),
        switchMap(payload => {
            return this.myProfileClient.loadUser(payload).pipe(
                map(retUser => {
                    return new LoadUserDataSuccessAction(retUser);
                }),
                catchError(error => of(new LoadUserDataFailAction(this.errorMsgService.getMsg(error))))
            );
        })
    );
}
