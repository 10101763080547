import { createSelector } from '@ngrx/store';
import { State } from '../..';
import * as fromAutoChange from '../../reducers/auto/auto-change.reducer';

export const getAutoChange = (state: State) => state.autoChange;

export const getAutoChangeLoading = createSelector(
    getAutoChange,
    fromAutoChange.getAutoChangeLoading
);
