import { LienholderForm } from '../lienholder.model';
import { CoverageOption } from './coverage-option.model';

export class VehicleDetailForm {
    public vin: string;
    // EL: 1/8/2019
    // auto-review - add - vehicle.component.html(16, 71) has a reference vehicleDetail.vehicleType.vehTypeDesc
    // therefore, for AOT, vehicleType is changed to any form "string"
    public vehicleType: any;
    public vehicleYear: number;
    public vehicleMake: string;
    public vehicleModel: string;
    public vehicleTypeDesc: string;
    public vehicleMakeDesc: string;
    public vehicleModelDesc: string;
    public purchaseLoc: string;
    public dateOfPurchase: string;
    public registrationState: string;
    public titledInName: string;
    public compCoverage: CoverageOption;
    public collisionCoverage: CoverageOption;
    public ersCoverage: CoverageOption;
    public rentalCoverage: CoverageOption;
    public lienOrLease: string; // no, lease, lien
    public lienholder: LienholderForm;

    constructor(init?: Partial<VehicleDetailForm>) {
        Object.assign(this, init);
    }
}
