import { ContactUsForm } from '../../../models/contact-us/contact-us.model';
import * as actions from '../../actions/contact-us/contact-us.action';

export interface ContactUsState {
    isLoaded: boolean;
    isLoading: boolean;
    custId: string;
    contact: ContactUsForm;
    success: boolean;
    error: string;
}

const INITIAL_STATE: ContactUsState = {
    isLoaded: false,
    isLoading: false,
    custId: null,
    contact: null,
    success: false,
    error: null
};

export function contactUsReducer(state = INITIAL_STATE, action: actions.Actions): ContactUsState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case actions.ActionTypes.INIT: {
            return Object.assign({}, INITIAL_STATE);
        }

        case actions.ActionTypes.SUBMIT: {
            return Object.assign({}, state, {
                cont: action.payload
            });
        }

        case actions.ActionTypes.SUBMIT_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                success: true
            });
        }

        case actions.ActionTypes.SUBMIT_FAIL: {
            return Object.assign({}, state, {
                isLoading: false,
                error: action.payload.error
            });
        }

        default: {
            return state;
        }
    }
}

export const MESSAGE_TYPES = [
    {
        name: 'General',
        code: 'GENERAL'
    },
    {
        name: 'Auto Insurance',
        code: 'AUTO_INSURANCE'
    },
    {
        name: 'Billing',
        code: 'BILLING'
    },
    {
        name: 'Claims',
        code: 'CLAIMS'
    },
    {
        name: 'Health Insurance',
        code: 'HEALTH_INSURANCE'
    },
    {
        name: 'Life Insurance',
        code: 'LIFE_INSURANCE'
    },
    {
        name: 'Home Insurance',
        code: 'HOME_INSURANCE'
    },
    {
        name: 'Request A Quote',
        code: 'REQUEST_A_QUOTE'
    }
];
