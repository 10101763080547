import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { State } from '../..';
import { AutoApiClient } from '../../../asyncServices/api-clients/auto-apiclient.service';
import { CoverageIncreaseDed, CoverageOption, VehicleIncreaseDed } from '../../../models';
import { AutoApiRequestFactory } from '../../../models/auto/autoApi-request.factory';
import { ErrorMsgService } from '../../../utility/error-msg.service';
import {
    ActionTypes,
    ConfirmAction,
    ConfirmFailAction,
    ConfirmSuccessAction,
    SelectVehicleForIncreaseDedAction,
    SetCoverageAction,
    SubmitAddDriverAction,
    SubmitAddDriverFailAction,
    SubmitAddDriverSuccessAction,
    SubmitAddDriverVehicleAction,
    SubmitAddDriverVehicleFailAction,
    SubmitAddDriverVehicleSuccessAction,
    SubmitAddLienholderAction,
    SubmitAddLienholderFailAction,
    SubmitAddLienholderSuccessAction,
    SubmitAddVehicleAction,
    SubmitAddVehicleFailAction,
    SubmitAddVehicleSuccessAction,
    SubmitCancelAction,
    SubmitCancelFailAction,
    SubmitCancelSuccessAction,
    SubmitDeleteVehicleAction,
    SubmitDeleteVehicleFailAction,
    SubmitDeleteVehicleSuccessAction,
    SubmitEditLienholderAction,
    SubmitEditLienholderFailAction,
    SubmitEditLienholderSuccessAction,
    SubmitIncDedAction,
    SubmitIncDedFailAction,
    SubmitIncDedSuccessAction,
    SubmitRemoveAction,
    SubmitRemoveFailAction,
    SubmitRemoveLienholderAction,
    SubmitRemoveLienholderFailAction,
    SubmitRemoveLienholderSuccessAction,
    SubmitRemoveSuccessAction,
    SubmitReplaceAction,
    SubmitReplaceFailAction,
    SubmitReplaceSuccessAction
} from '../../actions/auto/auto-change.action';
import { getUser } from '../../selectors';

@Injectable()
export class AutoChangeEffects {
    constructor(
        private actions$: Actions,
        private autoApiClient: AutoApiClient,
        private appState$: Store<State>,
        private errorMsgService: ErrorMsgService
    ) {}

    @Effect()
    public submitCancelPolicy$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_CANCEL).pipe(
        map((action: SubmitCancelAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser), this.appState$.select(state => state.autoChange.policy)),
        switchMap(([payload, user, policy]) => {
            return this.autoApiClient
                .submitCancelPolicy(AutoApiRequestFactory.createCancelPolicyRequest(payload, user), policy.policyNbr)
                .pipe(
                    map(apiResponse => new SubmitCancelSuccessAction(apiResponse)),
                    catchError(error =>
                        of(
                            new SubmitCancelFailAction({
                                error: this.errorMsgService.getMsg(error)
                            })
                        )
                    )
                );
        })
    );

    @Effect()
    public confirm$: Observable<Action> = this.actions$.ofType(ActionTypes.CONFIRM).pipe(
        map((action: ConfirmAction) => action.payload),
        withLatestFrom(this.appState$.select(state => state.autoChange)),
        switchMap(([payload, state]) => {
            return this.autoApiClient.confirmUpdate(state.changeComplete.apiResponse, state.policy.policyNbr).pipe(
                map(apiResponse => new ConfirmSuccessAction(apiResponse)),
                catchError(error => of(new ConfirmFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public submitEditLienholder$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_EDIT_LIENHOLDER).pipe(
        map((action: SubmitEditLienholderAction) => action.payload),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, state, user]) => {
            const x = this.autoApiClient
                .submitEditLienholder(
                    AutoApiRequestFactory.createLienholderUpdateRequest(state, user),
                    state.policy.policyNbr,
                    state.vehicle.vinSerialNbr
                )
                .pipe(
                    map(apiResponse => new SubmitEditLienholderSuccessAction(apiResponse)),
                    catchError(error => of(new SubmitEditLienholderFailAction({ error: this.errorMsgService.getMsg(error) })))
                );
            return x;
        })
    );

    @Effect()
    public submitAddLienholder$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_ADD_LIENHOLDER).pipe(
        map((action: SubmitAddLienholderAction) => action.payload),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, state, user]) => {
            const x = this.autoApiClient
                .submitAddLienholder(
                    AutoApiRequestFactory.createLienholderUpdateRequest(state, user),
                    state.policy.policyNbr,
                    state.vehicle.vinSerialNbr
                )
                .pipe(
                    map(apiResponse => new SubmitAddLienholderSuccessAction(apiResponse)),
                    catchError(error => of(new SubmitAddLienholderFailAction({ error: this.errorMsgService.getMsg(error) })))
                );
            return x;
        })
    );

    @Effect()
    public submitRemoveLienholder$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_REMOVE_LIENHOLDER).pipe(
        map((action: SubmitRemoveLienholderAction) => action.payload),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, state, user]) => {
            const x = this.autoApiClient
                .submitRemoveLienholder(
                    AutoApiRequestFactory.createLienholderUpdateRequest(state, user),
                    state.policy.policyNbr,
                    state.vehicle.vinSerialNbr
                )
                .pipe(
                    map(apiResponse => new SubmitRemoveLienholderSuccessAction(apiResponse)),
                    catchError(error => of(new SubmitRemoveLienholderFailAction({ error: this.errorMsgService.getMsg(error) })))
                );
            return x;
        })
    );

    @Effect()
    public submitDriver$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_ADD_DRIVER).pipe(
        map((action: SubmitAddDriverAction) => action.payload),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, state, user]) => {
            return this.autoApiClient.submitDriver(AutoApiRequestFactory.createAddDriverRequest(state, user), state.policy.policyNbr).pipe(
                map(apiResponse => new SubmitAddDriverSuccessAction(apiResponse)),
                catchError(error => of(new SubmitAddDriverFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect()
    public submitAddVehicle$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_ADD_VEHICLE).pipe(
        map((action: SubmitAddVehicleAction) => action.payload),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, autoAddVehicle, user]) => {
            return this.autoApiClient
                .submitAddVehicle(AutoApiRequestFactory.createAddVehicleRequest(autoAddVehicle, user), autoAddVehicle.policy.policyNbr)
                .pipe(
                    map(apiResponse => new SubmitAddVehicleSuccessAction(apiResponse)),
                    catchError(error => of(new SubmitAddVehicleFailAction({ error: this.errorMsgService.getMsg(error) })))
                );
        })
    );

    @Effect()
    public submitDriverVehicle$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_ADD_DRIVER_VEHICLE).pipe(
        map((action: SubmitAddDriverVehicleAction) => action.payload),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, state, user]) => {
            const x = this.autoApiClient
                .submitDriverAndVehicle(AutoApiRequestFactory.createAddDriverAndVehicleRequest(state, user), state.policy.policyNbr)
                .pipe(
                    map(apiResponse => {
                        return new SubmitAddDriverVehicleSuccessAction(apiResponse);
                    }),
                    catchError(error => of(new SubmitAddDriverVehicleFailAction({ error: this.errorMsgService.getMsg(error) })))
                );
            return x;
        })
    );

    @Effect()
    public selectVehicleForIncreaseDed$: Observable<Action> = this.actions$.ofType(ActionTypes.SELECT_VEHICLE_FOR_INCREASE_DED).pipe(
        map((action: SelectVehicleForIncreaseDedAction) => action.payload),
        withLatestFrom(
            this.appState$.select(state => state.autoChange),
            this.appState$.select(state => state.autoSupport),
            this.appState$.select(getUser)
        ),
        switchMap(([payload, state, support, user]) => {
            let allReachedMax = true;
            let deductiblesAreAvailable = false;

            // Create new objects for the Vehicle and coverages to store the changes selected
            const vehicleCoverage = new VehicleIncreaseDed();
            Object.assign(vehicleCoverage, payload);
            vehicleCoverage.coverages = [];
            payload.coverages.forEach(coverage => {
                const coverageIncreaseDed = new CoverageIncreaseDed();
                vehicleCoverage.coverages.push(Object.assign(coverageIncreaseDed, coverage));
            });

            for (const coverage of vehicleCoverage.coverages) {
                let deductibleAmt = 0;
                if (coverage.category === 'C') {
                    deductiblesAreAvailable = true;
                    /* get the current deductible as per typeCovCd*/
                    if (coverage.typeCovCd === 'D') {
                        deductibleAmt = coverage.deductibleAmt;
                    } else if (coverage.typeCovCd === 'L') {
                        deductibleAmt = coverage.limit1Amt;
                    }
                }
                coverage.coverageOptions = [];
                if (deductibleAmt < support.coverageListMax[coverage.code]) {
                    allReachedMax = false;
                    const selectcontain: CoverageOption[] = support.coverageList[coverage.code];

                    for (const myamount of selectcontain) {
                        if (myamount.deductibleAmt > deductibleAmt) {
                            coverage.coverageOptions.push(myamount);
                        }
                    }
                }
            }

            vehicleCoverage.allReachedMax = allReachedMax;
            vehicleCoverage.deductiblesAreAvailable = deductiblesAreAvailable;

            return of(new SetCoverageAction(vehicleCoverage));
        })
    );

    @Effect()
    public submitIncreaseDed$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_INCREASE_DED).pipe(
        map((action: SubmitIncDedAction) => action.payload),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, state, user]) => {
            return this.autoApiClient
                .submitIncreaseDed(
                    AutoApiRequestFactory.createIncreaseDedRequest(state.coverages, user),
                    state.policy.policyNbr,
                    state.vehicleIncreaseDed.vinSerialNbr
                )
                .pipe(
                    map(apiResponse => new SubmitIncDedSuccessAction(apiResponse)),
                    catchError(error => of(new SubmitIncDedFailAction({ error: this.errorMsgService.getMsg(error) })))
                );
        })
    );

    @Effect()
    public submitRemoveConverage$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_REMOVE_COVERAGE).pipe(
        map((action: SubmitRemoveAction) => action.payload),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, state, user]) => {
            return this.autoApiClient
                .submitRemoveCoverage(
                    AutoApiRequestFactory.createIncreaseDedRequest(state.coverages, user),
                    state.policy.policyNbr,
                    state.vehicleIncreaseDed.vinSerialNbr
                )
                .pipe(
                    map(apiResponse => new SubmitRemoveSuccessAction(apiResponse)),
                    catchError(error => of(new SubmitRemoveFailAction({ error: this.errorMsgService.getMsg(error) })))
                );
        })
    );

    @Effect()
    public submitReplaceVehicle$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_REPLACE_VEHICLE).pipe(
        map((action: SubmitReplaceAction) => {
            return action.payload;
        }),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, state, user]) => {
            const requestData = AutoApiRequestFactory.createReplaceVehicleRequest(state, user);

            return this.autoApiClient.submitReplaceVehicle(requestData, state.policy.policyNbr, state.vehicle.vinSerialNbr).pipe(
                map(apiResponse => new SubmitReplaceSuccessAction(apiResponse)),
                catchError(error =>
                    of(
                        new SubmitReplaceFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    @Effect()
    public submitDeleteVehicle$: Observable<Action> = this.actions$.ofType(ActionTypes.SUBMIT_DELETE_VEHICLE).pipe(
        map((action: SubmitDeleteVehicleAction) => {
            return action.payload;
        }),
        withLatestFrom(this.appState$.select(state => state.autoChange), this.appState$.select(getUser)),
        switchMap(([payload, state, user]) => {
            const requestData = AutoApiRequestFactory.createDeleteVehicleRequest(state, user);

            return this.autoApiClient.submitDeleteVehicle(requestData, state.policy.policyNbr, state.vehicle.vinSerialNbr).pipe(
                map(apiResponse => new SubmitDeleteVehicleSuccessAction(apiResponse)),
                catchError(error => of(new SubmitDeleteVehicleFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );
}
