import { TabLayout } from '../../../models';
import { ActionTypes, LayoutActions } from '../../actions/layout/layout.action';

export interface LayoutState {
    tabs: {
        homeTab: boolean;
        billingTab: boolean;
        claimsTab: boolean;
        documentsTab: boolean;
        membershipTab: boolean;
        contactTab: boolean;
    };

}

const INITIAL_STATE: LayoutState = {
    tabs: {
        homeTab: true,
        billingTab: true,
        claimsTab: true,
        documentsTab: true,
        membershipTab: true,
        contactTab: true
    }
};

//  Reducers specify how the application’s state changes in response to actions.
export function layoutReducer(state = INITIAL_STATE, action: LayoutActions): LayoutState {

    if (!action) {
        return state;
    }

    switch (action.type) {
        case ActionTypes.UPDATE_NAV_TABS: {
            const payload = action.payload;
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    ...payload
                }
            };
        }

        default: {
            return state;
        }
    }
}

export const getLayoutTabs = (state: LayoutState) => state.tabs;

