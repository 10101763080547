import { Action } from '@ngrx/store';
import { ClaimsContactForm } from '../../../models';
import { type } from '../../../utility';

export const ActionTypes = {

    LOAD_CLIENT_CONTACTS:                  type('[Client Contacts] Load'),
    LOAD_CLIENT_CONTACTS_SUCCESS:          type('[Client Contacts] Load Success'),
    LOAD_CLIENT_CONTACTS_FAIL:             type('[Client Contacts] Load Fail')
};

/**
 * Client Contact Actions
 */

export class LoadClientContactsAction implements Action {
    public type = ActionTypes.LOAD_CLIENT_CONTACTS;
    constructor(public payload: string[] = null) { }
}

export class LoadClientContactsSuccessAction implements Action {
    public type = ActionTypes.LOAD_CLIENT_CONTACTS_SUCCESS;
    constructor(public payload: ClaimsContactForm = null) { }
}

export class LoadClientContactsFailAction implements Action {
    public type = ActionTypes.LOAD_CLIENT_CONTACTS_FAIL;
    constructor(public payload: any = null) { }
}

export type Actions
  = LoadClientContactsAction
  | LoadClientContactsSuccessAction
  | LoadClientContactsFailAction;
