import { AutoClaim, Claim, ErsClaim, GlassClaim, PropertyClaim, WaterClaim } from '../../../../shared/models';
import { PolicyBase } from '../../../models/policy/base/policy-base.model';
import * as claimsFileActions from '../../actions/claims/claims-file.action';

export interface ClaimsFileState {
    isAutoBasicInfoLoaded: boolean;
    isAutoBasicInfoLoading: boolean;
    isAutoBasicInfoError: string;
    isErsBasicInfoLoaded: boolean;
    isErsBasicInfoLoading: boolean;
    isErsBasicInfoFailed: boolean;
    isPropertyBasicInfoLoaded: boolean;
    isPropertyBasicInfoLoading: boolean;
    isPropertyBasicInfoFailed: boolean;
    isGlassBasicInfoLoaded: boolean;
    isGlassBasicInfoLoading: boolean;
    isGlassBasicInfoFailed: boolean;
    isWatercraftBasicInfoLoaded: boolean;
    isWatercraftBasicInfoLoading: boolean;
    isWatercraftBasicInfoFailed: boolean;

    isErsDetailsLoaded: boolean;
    isErsDetailsLoading: boolean;
    isErsDetailsFailed: boolean;
    isAutoDetailsLoaded: boolean;
    isAutoDetailsLoading: boolean;
    isAutoDetailsError: string;
    isGlassDetailsLoaded: boolean;
    isGlassDetailsLoading: boolean;
    isGlassDetailsFailed: boolean;
    isPropertyDetailsLoaded: boolean;
    isPropertyDetailsLoading: boolean;
    isPropertyDetailsFailed: boolean;
    isWatercraftDetailsLoaded: boolean;
    isWatercraftDetailsLoading: boolean;
    isWatercraftDetailsFailed: boolean;

    isWatercraftClientLoaded: boolean;
    isWatercraftClientLoading: boolean;
    isWatercraftClientFailed: boolean;

    isErsDocumentsLoaded: boolean;
    isErsDocumentsLoading: boolean;
    isErsDocumentsFailed: boolean;
    isAutoDocumentsLoaded: boolean;
    isAutoDocumentsLoading: boolean;
    isAutoDocumentsFailed: boolean;
    isPropertyDocumentsLoaded: boolean;
    isPropertyDocumentsLoading: boolean;
    isPropertyDocumentsFailed: boolean;
    isWatercraftDocumentsLoaded: boolean;
    isWatercraftDocumentsLoading: boolean;
    isWatercraftDocumentsFailed: boolean;

    isGlassContactInfoLoaded: boolean;
    isGlassContactInfoLoading: boolean;
    isGlassContactInfoFailed: boolean;
    isErsContactInfoLoaded: boolean;
    isErsContactInfoLoading: boolean;
    isErsContactInfoFailed: boolean;
    isAutoContactInfoLoaded: boolean;
    isAutoContactInfoLoading: boolean;
    isAutoContactInfoError: string;
    isPropertyContactInfoLoaded: boolean;
    isPropertyContactInfoLoading: boolean;
    isPropertyContactInfoFailed: boolean;
    isWatercraftContactInfoLoaded: boolean;
    isWatercraftContactInfoLoading: boolean;
    isWatercraftContactInfoFailed: boolean;

    isAutoVehicleInfoLoading: boolean;
    isAutoVehicleInfoLoaded: boolean;
    isAutoVehicleInfoError: string;
    isWatercraftVehicleInfoLoading: boolean;
    isWatercraftVehicleInfoLoaded: boolean;
    isWatercraftVehicleInfoFailed: boolean;

    isAutoClientInfoLoaded: boolean;
    isAutoClientInfoLoading: boolean;
    isAutoClientInfoError: string;

    isClaimCompleteLoaded: boolean;
    isClaimCompleteLoading: boolean;
    isClaimCompleteError: string;

    isPoliciesLoading: boolean;
    isPoliciesLoaded: boolean;
    isPoliciesError: string;
    policies: PolicyBase[];

    claim: Claim;
}

const INITIAL_STATE: ClaimsFileState = {
    isAutoBasicInfoLoaded: false,
    isAutoBasicInfoLoading: false,
    isAutoBasicInfoError: null,
    isErsBasicInfoLoaded: false,
    isErsBasicInfoLoading: false,
    isErsBasicInfoFailed: false,
    isPropertyBasicInfoLoaded: false,
    isPropertyBasicInfoLoading: false,
    isPropertyBasicInfoFailed: false,
    isGlassBasicInfoLoaded: false,
    isGlassBasicInfoLoading: false,
    isGlassBasicInfoFailed: false,
    isWatercraftBasicInfoLoaded: false,
    isWatercraftBasicInfoLoading: false,
    isWatercraftBasicInfoFailed: false,

    isErsDetailsLoaded: false,
    isErsDetailsLoading: false,
    isErsDetailsFailed: false,
    isAutoDetailsLoaded: false,
    isAutoDetailsLoading: false,
    isAutoDetailsError: null,
    isGlassDetailsLoaded: false,
    isGlassDetailsLoading: false,
    isGlassDetailsFailed: false,
    isPropertyDetailsLoaded: false,
    isPropertyDetailsLoading: false,
    isPropertyDetailsFailed: false,
    isWatercraftDetailsLoaded: false,
    isWatercraftDetailsLoading: false,
    isWatercraftDetailsFailed: false,

    isErsDocumentsLoaded: false,
    isErsDocumentsLoading: false,
    isErsDocumentsFailed: false,
    isAutoDocumentsLoaded: false,
    isAutoDocumentsLoading: false,
    isAutoDocumentsFailed: false,
    isPropertyDocumentsLoaded: false,
    isPropertyDocumentsLoading: false,
    isPropertyDocumentsFailed: false,
    isWatercraftDocumentsLoaded: false,
    isWatercraftDocumentsLoading: false,
    isWatercraftDocumentsFailed: false,

    isAutoVehicleInfoLoading: false,
    isAutoVehicleInfoLoaded: false,
    isAutoVehicleInfoError: null,
    isWatercraftVehicleInfoLoading: false,
    isWatercraftVehicleInfoLoaded: false,
    isWatercraftVehicleInfoFailed: false,

    isAutoClientInfoLoaded: false,
    isAutoClientInfoLoading: false,
    isAutoClientInfoError: null,
    isWatercraftClientLoaded: false,
    isWatercraftClientLoading: false,
    isWatercraftClientFailed: false,

    isGlassContactInfoLoaded: false,
    isGlassContactInfoLoading: false,
    isGlassContactInfoFailed: false,
    isErsContactInfoLoaded: false,
    isErsContactInfoLoading: false,
    isErsContactInfoFailed: false,
    isAutoContactInfoLoaded: false,
    isAutoContactInfoLoading: false,
    isAutoContactInfoError: null,
    isPropertyContactInfoLoaded: false,
    isPropertyContactInfoLoading: false,
    isPropertyContactInfoFailed: false,
    isWatercraftContactInfoLoaded: false,
    isWatercraftContactInfoLoading: false,
    isWatercraftContactInfoFailed: false,
    isClaimCompleteLoaded: false,
    isClaimCompleteLoading: false,
    isClaimCompleteError: null,

    isPoliciesLoading: false,
    isPoliciesLoaded: false,
    isPoliciesError: null,
    policies: new Array<PolicyBase>(),

    claim: new Claim()
};

//  Reducers specify how the application’s state changes in response to actions.
export function claimsFileReducer(state = INITIAL_STATE, action: claimsFileActions.Actions): ClaimsFileState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        // Auto
        case claimsFileActions.ActionTypes.SUBMIT_AUTO_BASIC_INFO: {
            return {
                ...state,
                isAutoBasicInfoLoading: true,
                isAutoBasicInfoError: null,
                claim: {
                    ...state.claim,
                    basicInfo: action.payload.basicInfo
                }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_BASIC_INFO_SUCCESS: {
            return {
                ...state,
                isAutoBasicInfoLoading: false,
                isAutoBasicInfoLoaded: true,
                claim: {
                    ...state.claim,
                    id: action.payload.id
                }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_BASIC_INFO_FAIL: {
            return {
                ...state,
                isAutoBasicInfoLoading: false,
                isAutoBasicInfoLoaded: false,
                isAutoBasicInfoError: action.payload.error
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_DETAIL: {
            return {
                ...state,
                claim: <AutoClaim>{
                    ...state.claim,
                    autoDetail: action.payload
                },
                isAutoDetailsLoaded: false,
                isAutoDetailsLoading: true,
                isAutoDetailsError: null
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_DETAIL_SUCCESS: {
            return {
                ...state,
                isAutoDetailsLoaded: true,
                isAutoDetailsLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_DETAIL_FAIL: {
            return {
                ...state,
                isAutoDetailsLoaded: false,
                isAutoDetailsLoading: false,
                isAutoDetailsError: action.payload.error
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_CLIENT_INFO: {
            return {
                ...state,
                claim: <AutoClaim>{
                    ...state.claim,
                    peopleInvolved: action.payload
                },
                isAutoClientInfoLoading: true,
                isAutoClientInfoError: null
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_CLIENT_INFO_SUCCESS: {
            return {
                ...state,
                isAutoClientInfoLoaded: true,
                isAutoClientInfoLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_CLIENT_INFO_FAIL: {
            return {
                ...state,
                isAutoClientInfoLoaded: false,
                isAutoClientInfoLoading: false,
                isAutoClientInfoError: action.payload.error
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_CONTACT_INFO: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    contacts: action.payload
                },
                isAutoContactInfoLoaded: false,
                isAutoContactInfoLoading: true,
                isAutoContactInfoError: null
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_CONTACT_INFO_SUCCESS: {
            return {
                ...state,
                isAutoContactInfoLoaded: true,
                isAutoContactInfoLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_CONTACT_INFO_FAIL: {
            return {
                ...state,
                isAutoContactInfoLoaded: false,
                isAutoContactInfoLoading: false,
                isAutoContactInfoError: action.payload.error
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_DOCUMENTS: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    photoUploads: action.payload
                },
                isAutoDocumentsLoaded: false,
                isAutoDocumentsLoading: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                isAutoDocumentsLoaded: true,
                isAutoDocumentsLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_DOCUMENTS_FAIL: {
            return {
                ...state,
                isAutoDocumentsLoaded: false,
                isAutoDocumentsLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_VEHICLE_INFO: {
            return {
                ...state,
                // TODO Is this needed
                // claim: {
                //     ...state.claim,
                //     vehicleDetail: new VehicleDetailsForm(action.payload)
                // },
                isAutoVehicleInfoLoading: true,
                isAutoVehicleInfoError: null
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_VEHICLE_INFO_SUCCESS: {
            return {
                ...state,
                isAutoVehicleInfoLoaded: true,
                isAutoVehicleInfoLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_AUTO_VEHICLE_INFO_FAIL: {
            return {
                ...state,
                isAutoVehicleInfoLoaded: false,
                isAutoVehicleInfoLoading: false,
                isAutoVehicleInfoError: action.payload.error
            };
        }

        case claimsFileActions.ActionTypes.SAVE_AUTO_VEHICLE_INFO: {
            return {
                ...state,
                claim: <AutoClaim>{
                    ...state.claim,
                    vehicleDetail: action.payload
                }
            };
        }

        case claimsFileActions.ActionTypes.SAVE_WATERCRAFT_VEHICLE_INFO: {
            return {
                ...state,
                claim: <AutoClaim>{
                    ...state.claim,
                    vehicleDetail: action.payload
                }
            };
        }

        case claimsFileActions.ActionTypes.SAVE_AUTO_VEHICLE_INFO_SUCCESS: {
            return {
                ...state
            };
        }

        case claimsFileActions.ActionTypes.SAVE_AUTO_VEHICLE_INFO_FAIL: {
            return {
                ...state
            };
        }

        case claimsFileActions.ActionTypes.FIND_POLICY: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    basicInfo: action.payload
                },
                isPoliciesLoaded: false,
                isPoliciesLoading: true,
                isPoliciesError: null
            };
        }

        case claimsFileActions.ActionTypes.FIND_POLICY_SUCCESS: {
            return {
                ...state,
                policies: action.payload,
                isPoliciesLoaded: true,
                isPoliciesLoading: false
            };
        }

        case claimsFileActions.ActionTypes.FIND_POLICY_FAIL: {
            return {
                ...state,
                isPoliciesLoaded: false,
                isPoliciesLoading: false,
                isPoliciesError: action.payload.error
            };
        }

        case claimsFileActions.ActionTypes.SELECT_POLICY: {
            return {
                ...state,
                claim: new Claim({
                    basicInfo: state.claim.basicInfo
                })
            };
        }

        case claimsFileActions.ActionTypes.SELECT_POLICY_SUCCESS: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    selectPolicy: action.payload
                }
            };
        }

        case claimsFileActions.ActionTypes.SELECT_POLICY_FAIL: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    selectPolicy: action.payload
                }
            };
        }

        // ERS
        case claimsFileActions.ActionTypes.SUBMIT_ERS_DETAIL: {
            return {
                ...state,
                isErsDetailsLoaded: true,
                isErsDetailsLoading: true,
                claim: <ErsClaim>{
                    ...state.claim,
                    ersDetail: action.payload
                }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_DETAIL_SUCCESS: {
            return {
                ...state,
                isErsDetailsLoading: false,
                isErsDetailsLoaded: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_DETAIL_FAIL: {
            return { ...state, isErsDetailsLoaded: false, isErsDetailsLoading: false, isErsDetailsFailed: true };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_DOCUMENTS: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    photoUploads: action.payload
                },
                isErsDocumentsLoading: true,
                isErsDocumentsLoaded: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                isErsDocumentsLoaded: true,
                isErsDocumentsLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_DOCUMENTS_FAIL: {
            return {
                ...state,
                isErsDocumentsLoading: false,
                isErsDocumentsFailed: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_CONTACT_INFO: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    contacts: action.payload
                },
                isErsContactInfoLoading: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_CONTACT_INFO_SUCCESS: {
            return {
                ...state,
                isErsContactInfoLoading: false,
                isErsContactInfoLoaded: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_CONTACT_INFO_FAIL: {
            return {
                ...state,
                isErsContactInfoLoading: false,
                isErsContactInfoFailed: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_BASIC_INFO: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    basicInfo: action.payload.basicInfo
                },
                isErsBasicInfoLoading: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_BASIC_INFO_SUCCESS: {
            return {
                ...state,
                isErsBasicInfoLoading: false,
                isErsBasicInfoLoaded: true,
                claim: {
                    ...state.claim,
                    id: action.payload.id
                }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_ERS_BASIC_INFO_FAIL: {
            return {
                ...state,
                isErsBasicInfoLoading: false,
                isErsBasicInfoFailed: true
            };
        }

        // Watercraft
        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_DETAIL: {
            return {
                ...state,
                ...state,
                claim: <WaterClaim>{
                    ...state.claim,
                    waterDetail: action.payload
                },
                isWatercraftDetailsLoading: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_DETAIL_SUCCESS: {
            return {
                ...state,
                isWatercraftDetailsLoading: false,
                isWatercraftDetailsLoaded: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_DETAIL_FAIL: {
            return {
                ...state,
                isWatercraftDetailsLoading: false,
                isWatercraftDetailsFailed: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_BASIC_INFO: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    basicInfo: action.payload.basicInfo
                },
                isWatercraftBasicInfoLoading: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_BASIC_INFO_SUCCESS: {
            return {
                ...state,
                isWatercraftBasicInfoLoading: false,
                isWatercraftBasicInfoLoaded: true,
                claim: {
                    ...state.claim,
                    id: action.payload.id
                }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_BASIC_INFO_FAIL: {
            return {
                ...state,
                isWatercraftBasicInfoLoading: false,
                isWatercraftBasicInfoFailed: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_CLIENT_INFO: {
            return {
                ...state,
                claim: <WaterClaim>{
                    ...state.claim,
                    peopleInvolved: action.payload
                },
                isWatercraftClientLoading: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_CLIENT_INFO_SUCCESS: {
            return {
                ...state,
                isWatercraftClientLoading: false,
                isWatercraftClientLoaded: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_CLIENT_INFO_FAIL: {
            return {
                ...state,
                isWatercraftClientLoading: false,
                isWatercraftClientFailed: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_CONTACT_INFO: {
            return {
                ...state,
                claim: { ...state.claim, contacts: action.payload },

                isWatercraftContactInfoLoading: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_CONTACT_INFO_SUCCESS: {
            return { ...state, isWatercraftContactInfoLoaded: true, isWatercraftContactInfoLoading: false };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_CONTACT_INFO_FAIL: {
            return {
                ...state
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_VEHICLE_INFO: {
            return {
                ...state,
                claim: <WaterClaim>{
                    ...state.claim,
                    waterDetail: action.payload
                }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_VEHICLE_INFO_SUCCESS: {
            return {
                ...state
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_VEHICLE_INFO_FAIL: {
            return {
                ...state
            };
        }

        // Glass
        case claimsFileActions.ActionTypes.SUBMIT_GLASS_DETAIL: {
            return {
                ...state,
                claim: <GlassClaim>{
                    ...state.claim,
                    glassDetail: action.payload
                },
                isGlassDetailsLoading: true,
                isGlassDetailsLoaded: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_GLASS_DETAIL_SUCCESS: {
            return {
                ...state,
                isGlassDetailsLoaded: true,
                isGlassDetailsLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_GLASS_DETAIL_FAIL: {
            return {
                ...state,
                isGlassDetailsLoaded: false,
                isGlassDetailsLoading: false,
                isGlassDetailsFailed: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_GLASS_BASIC_INFO: {
            return {
                ...state,
                isGlassBasicInfoLoading: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_GLASS_BASIC_INFO_SUCCESS: {
            return {
                ...state,
                claim: { ...state.claim, id: action.payload.id },
                isGlassBasicInfoLoaded: true,
                isGlassBasicInfoLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_GLASS_BASIC_INFO_FAIL: {
            return {
                ...state,
                isGlassBasicInfoLoaded: false,
                isGlassBasicInfoLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_GLASS_CONTACT_INFO: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    contacts: action.payload
                },
                isGlassContactInfoLoading: true,
                isGlassContactInfoFailed: false,
                isGlassContactInfoLoaded: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_GLASS_CONTACT_INFO_SUCCESS: {
            return {
                ...state,
                isGlassContactInfoLoading: false,
                isGlassContactInfoFailed: false,
                isGlassContactInfoLoaded: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_GLASS_CONTACT_INFO_FAIL: {
            return {
                ...state,
                isGlassContactInfoLoading: false,
                isGlassContactInfoFailed: true,
                isGlassContactInfoLoaded: false
            };
        }

        // Property
        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_DETAIL: {
            return {
                ...state,
                isPropertyDetailsLoaded: false,
                isPropertyDetailsLoading: true,

                claim: <PropertyClaim>{
                    ...state.claim,
                    propertyDetail: action.payload
                }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_DETAIL_SUCCESS: {
            return {
                ...state,
                isPropertyDetailsFailed: false,
                isPropertyDetailsLoading: false,
                isPropertyDetailsLoaded: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_DETAIL_FAIL: {
            return {
                ...state,
                isPropertyDetailsFailed: true,
                isPropertyDetailsLoading: false,
                isPropertyDetailsLoaded: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_BASIC_INFO: {
            return {
                ...state,
                isPropertyBasicInfoLoading: true,
                claim: {
                    ...state.claim,
                    basicInfo: {
                        ...action.payload.basicInfo
                    }
                }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_BASIC_INFO_SUCCESS: {
            return {
                ...state,
                isPropertyBasicInfoLoading: false,
                isPropertyBasicInfoLoaded: true,
                claim: { ...state.claim, id: action.payload.id }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_BASIC_INFO_FAIL: {
            return {
                ...state,
                isPropertyBasicInfoLoading: false,
                isPropertyBasicInfoLoaded: false,
                isPropertyBasicInfoFailed: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_CONTACT_INFO: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    contacts: action.payload
                },
                isPropertyContactInfoFailed: false,
                isPropertyContactInfoLoaded: false,
                isPropertyContactInfoLoading: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_CONTACT_INFO_SUCCESS: {
            return {
                ...state,
                isPropertyContactInfoFailed: false,
                isPropertyContactInfoLoaded: true,
                isPropertyContactInfoLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_CONTACT_INFO_FAIL: {
            return {
                ...state,
                isPropertyContactInfoFailed: true,
                isPropertyContactInfoLoaded: false,
                isPropertyContactInfoLoading: false
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_DOCUMENTS: {
            return {
                ...state,
                isPropertyDocumentsLoading: true,
                claim: {
                    ...state.claim,
                    photoUploads: action.payload
                }
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                isPropertyDocumentsLoading: false,
                isPropertyDocumentsLoaded: true
            };
        }

        case claimsFileActions.ActionTypes.SUBMIT_PROPERTY_DOCUMENTS_FAIL: {
            return {
                ...state,
                isPropertyDocumentsLoading: false,
                isPropertyDocumentsLoaded: false,
                isPropertyBasicInfoFailed: true
            };
        }

        case claimsFileActions.ActionTypes.COMPLETE_CLAIM: {
            return {
                ...state,
                isClaimCompleteLoading: true,
                isClaimCompleteError: null,
                claim: {
                    ...state.claim,
                    agreementConfirmation: true
                }
            };
        }

        case claimsFileActions.ActionTypes.COMPLETE_CLAIM_SUCCESS: {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    claimNbr: action.payload.claimNbr
                },
                isClaimCompleteLoading: false,
                isClaimCompleteLoaded: true
            };
        }

        case claimsFileActions.ActionTypes.COMPLETE_CLAIM_FAIL: {
            return {
                ...state,
                isClaimCompleteLoading: false,
                isClaimCompleteLoaded: false,
                isClaimCompleteError: action.payload.error
            };
        }

        case claimsFileActions.ActionTypes.CLEAR_STORE: {
            return INITIAL_STATE;
        }

        default: {
            return state;
        }
    }
}

export const isAutoBasicInfoLoading = (state: ClaimsFileState) => state.isAutoBasicInfoLoading;
export const isAutoBasicInfoLoaded = (state: ClaimsFileState) => state.isAutoBasicInfoLoaded;

export const getClaim = (state: ClaimsFileState) => state.claim;
export const getSelectedPolicy = (state: ClaimsFileState) => state.claim.selectPolicy;
export const getSelectedPolicyDetails = (state: ClaimsFileState) => state.claim.selectPolicy.policy;
export const getVehicleDetails = (state: ClaimsFileState) => (<AutoClaim>state.claim).vehicleDetail;
export const getPolicies = (state: ClaimsFileState) => state.policies;
export const getClaimsFileError = (state: ClaimsFileState) =>
    state.isAutoBasicInfoError ||
    state.isAutoDetailsError ||
    state.isAutoContactInfoError ||
    state.isAutoVehicleInfoError ||
    state.isPoliciesError ||
    state.isAutoClientInfoError ||
    state.isClaimCompleteError;

export const claimsFileDataLoading = (state: ClaimsFileState) =>
    state.isAutoBasicInfoLoading ||
    state.isErsBasicInfoLoading ||
    state.isPropertyBasicInfoLoading ||
    state.isGlassBasicInfoLoading ||
    state.isWatercraftBasicInfoLoading ||
    state.isErsDetailsLoading ||
    state.isAutoDetailsLoading ||
    state.isGlassDetailsLoading ||
    state.isPropertyDetailsLoading ||
    state.isWatercraftDetailsLoading ||
    state.isWatercraftClientLoading ||
    state.isErsDocumentsLoading ||
    state.isAutoDocumentsLoading ||
    state.isPropertyDocumentsLoading ||
    state.isWatercraftDocumentsLoading ||
    state.isErsContactInfoLoading ||
    state.isAutoContactInfoLoading ||
    state.isPropertyContactInfoLoading ||
    state.isWatercraftContactInfoLoading ||
    state.isAutoClientInfoLoading ||
    state.isClaimCompleteLoading ||
    state.isPoliciesLoading;
