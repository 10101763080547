import { ProcessType } from '../../../enums/lienholderProcessType.enum';
import {
    CancelPolicyForm,
    ChangeComplete,
    CoverageIncreaseDed,
    DeleteVehicleForm,
    Driver,
    DriverHistoryForm,
    DriverInfoForm,
    LienholderForm,
    PolicyBase,
    Vehicle,
    VehicleDetailForm,
    VehicleIncreaseDed,
    VehicleUseForm
} from '../../../models';
import { Actions, ActionTypes } from '../../actions/auto/auto-change.action';

export interface AutoChangeState {
    isLoading: boolean;
    policy: PolicyBase;
    vehicle: Vehicle;
    driver: Driver;

    cancelPolicy: CancelPolicyForm;

    processType: ProcessType;
    lienholder: LienholderForm;

    driverInfo: DriverInfoForm;
    driverHistory: DriverHistoryForm;

    vehicleDetail: VehicleDetailForm;
    vehicleUse: VehicleUseForm;

    vehicleIncreaseDed: VehicleIncreaseDed;
    coverages: CoverageIncreaseDed[];

    deleteVehicle: DeleteVehicleForm;

    showDeclined: boolean;
    changeComplete: ChangeComplete;
}

const INITIAL_STATE: AutoChangeState = {
    isLoading: false,
    policy: null,
    vehicle: null,
    driver: null,

    cancelPolicy: null,

    processType: ProcessType.EDIT_LIENHOLDER,
    lienholder: null,

    driverHistory: null,
    driverInfo: null,

    vehicleDetail: null,
    vehicleUse: null,

    vehicleIncreaseDed: null,
    coverages: null,

    deleteVehicle: null,

    showDeclined: false,
    changeComplete: null
};

export function autoChangeReducer(state = INITIAL_STATE, action: Actions): AutoChangeState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case ActionTypes.INIT: {
            return { ...INITIAL_STATE };
        }

        case ActionTypes.SELECT_POLICY: {
            return { ...state, policy: action.payload };
        }

        case ActionTypes.DECLINE: {
            return { ...state, isLoading: false, showDeclined: true };
        }

        case ActionTypes.SUBMIT_CANCEL: {
            return { ...state, isLoading: true, cancelPolicy: action.payload };
        }

        case ActionTypes.SELECT_VEHICLE: {
            return { ...state, vehicle: action.payload };
        }

        case ActionTypes.SAVE_LIENHOLDER: {
            return { ...state, lienholder: action.payload };
        }

        case ActionTypes.START_ADD_LIENHOLDER: {
            return { ...state, processType: ProcessType.ADD_LIENHOLDER };
        }

        case ActionTypes.CANCEL_ADD_LIENHOLDER: {
            return { ...state, processType: ProcessType.EDIT_LIENHOLDER };
        }

        case ActionTypes.SUBMIT_REMOVE_LIENHOLDER: {
            return { ...state, isLoading: true, processType: ProcessType.REMOVE_LIENHOLDER, vehicle: action.payload };
        }

        case ActionTypes.DECLINE: {
            return { ...state, isLoading: false, showDeclined: true };
        }

        case ActionTypes.CONFIRM:
        case ActionTypes.SUBMIT_ADD_VEHICLE:
        case ActionTypes.SUBMIT_ADD_DRIVER_VEHICLE:
        case ActionTypes.SUBMIT_INCREASE_DED:
        case ActionTypes.SUBMIT_DELETE_VEHICLE:
        case ActionTypes.SUBMIT_REPLACE_VEHICLE:
        case ActionTypes.SUBMIT_ADD_DRIVER:
        case ActionTypes.SUBMIT_EDIT_LIENHOLDER:
        case ActionTypes.SUBMIT_ADD_LIENHOLDER:
        case ActionTypes.SUBMIT_REMOVE_COVERAGE: {
            return { ...state, isLoading: true };
        }

        case ActionTypes.SUBMIT_ADD_VEHICLE_SUCCESS:
        case ActionTypes.SUBMIT_ADD_DRIVER_VEHICLE_SUCCESS:
        case ActionTypes.SUBMIT_INCREASE_DED_SUCCESS:
        case ActionTypes.SUBMIT_DELETE_VEHICLE_SUCCESS:
        case ActionTypes.SUBMIT_REPLACE_VEHICLE_SUCCESS:
        case ActionTypes.SUBMIT_ADD_DRIVER_SUCCESS:
        case ActionTypes.SUBMIT_EDIT_LIENHOLDER_SUCCESS:
        case ActionTypes.SUBMIT_ADD_LIENHOLDER_SUCCESS:
        case ActionTypes.SUBMIT_CANCEL_SUCCESS:
        case ActionTypes.SUBMIT_REMOVE_LIENHOLDER_SUCCESS:

        case ActionTypes.SUBMIT_REMOVE_COVERAGE_SUCCESS: {
            return { ...state, isLoading: false, changeComplete: new ChangeComplete({ apiResponse: action.payload }) };
        }

        case ActionTypes.CONFIRM_FAIL:
        case ActionTypes.SUBMIT_ADD_VEHICLE_FAIL:
        case ActionTypes.SUBMIT_ADD_DRIVER_VEHICLE_FAIL:
        case ActionTypes.SUBMIT_INCREASE_DED_FAIL:
        case ActionTypes.SUBMIT_DELETE_VEHICLE_FAIL:
        case ActionTypes.SUBMIT_REPLACE_VEHICLE_FAIL:
        case ActionTypes.SUBMIT_ADD_DRIVER_FAIL:
        case ActionTypes.SUBMIT_EDIT_LIENHOLDER_FAIL:
        case ActionTypes.SUBMIT_ADD_LIENHOLDER_FAIL:
        case ActionTypes.SUBMIT_CANCEL_FAIL:
        case ActionTypes.SUBMIT_REMOVE_LIENHOLDER_FAIL:

        case ActionTypes.SUBMIT_REMOVE_COVERAGE_FAIL: {
            return { ...state, isLoading: false, changeComplete: new ChangeComplete({ error: action.payload.error }) };
        }

        case ActionTypes.SAVE_ADD_DRIVER_STEP2: {
            return { ...state, driverInfo: action.payload };
        }

        case ActionTypes.SAVE_ADD_DRIVER_STEP3: {
            return { ...state, driverHistory: action.payload };
        }

        case ActionTypes.CONFIRM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                changeComplete: {
                    ...state.changeComplete,
                    confirmationNumber: action.payload.confirmation
                }
            };
        }

        case ActionTypes.SELECT_DRIVER: {
            return { ...state, driver: action.payload };
        }

        case ActionTypes.SAVE_ADD_VEHICLE_STEP2: {
            return { ...state, vehicleDetail: action.payload };
        }

        case ActionTypes.SAVE_ADD_VEHICLE_STEP3: {
            return { ...state, vehicleUse: action.payload };
        }

        case ActionTypes.INCREASE_DED_SET_COVERAGE: {
            return { ...state, vehicleIncreaseDed: action.payload };
        }

        case ActionTypes.SAVE_INCREASE_DED_STEP2: {
            return { ...state, coverages: action.payload };
        }

        case ActionTypes.SAVE_REMOVE_COVERAGE: {
            return { ...state, coverages: action.payload };
        }

        case ActionTypes.SAVE_DELETE_VEHICLE: {
            return {
                ...state,
                deleteVehicle: action.payload
            };
        }

        default: {
            return state;
        }
    }
}

export const getAutoChangeLoading = (state: AutoChangeState) => state.isLoading;
