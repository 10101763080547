import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ClaimsSupportApiClient } from '../../../asyncServices/api-clients/claims-support-apiclient.service';
import { ErrorMsgService } from '../../../utility/error-msg.service';
import * as claimsSupportActions from '../../actions/claims/claims-support.action';
import { State } from '../../index';
import { getPoliciesData } from '../../selectors/';

@Injectable()
export class ClaimsSupportEffects {
    constructor(
        private actions$: Actions,
        private claimsSupportApiClient: ClaimsSupportApiClient,
        private appState$: Store<State>,
        private errorMsgService: ErrorMsgService
    ) {}

    /**
     * All Damage Locations
     */
    @Effect()
    public getAllDamageLocations$: Observable<Action> = this.actions$
        .ofType(claimsSupportActions.ActionTypes.LOAD_ALL_DAMAGE_LOCATIONS)
        .pipe(
            map((action: claimsSupportActions.LoadAllDamageLocationsAction) => action.payload),
            switchMap(state => {
                return this.claimsSupportApiClient.getAllDamageLocations().pipe(
                    map(products => new claimsSupportActions.LoadAllDamageLocationsSuccessAction(products)),
                    catchError(error => of(new claimsSupportActions.LoadAllDamageLocationsFailAction(error)))
                );
            })
        );

    /**
     * Auto Damage Locations
     */
    @Effect()
    public getAutoDamageLocations$: Observable<Action> = this.actions$
        .ofType(claimsSupportActions.ActionTypes.LOAD_AUTO_DAMAGE_LOCATIONS)
        .pipe(
            map((action: claimsSupportActions.LoadAutoDamageLocationsAction) => action.payload),
            switchMap(state => {
                return this.claimsSupportApiClient.getAutoDamageLocations().pipe(
                    map(damageLocations => new claimsSupportActions.LoadAutoDamageLocationsSuccessAction(damageLocations)),
                    catchError(error =>
                        of(new claimsSupportActions.LoadAutoDamageLocationsFailAction({ error: this.errorMsgService.getMsg(error) }))
                    )
                );
            })
        );

    /**
     * Glass Damage Locations
     */
    @Effect()
    public getGlassDamageLocations$: Observable<Action> = this.actions$
        .ofType(claimsSupportActions.ActionTypes.LOAD_GLASS_DAMAGE_LOCATIONS)
        .pipe(
            map((action: claimsSupportActions.LoadGlassDamageLocationsAction) => action.payload),
            switchMap(state => {
                return this.claimsSupportApiClient.getGlassDamageLocations().pipe(
                    map(damageLocations => new claimsSupportActions.LoadGlassDamageLocationsSuccessAction(damageLocations)),
                    catchError(error => of(new claimsSupportActions.LoadGlassDamageLocationsFailAction(error)))
                );
            })
        );

    /**
     * Auto Vehicle Colors
     */
    @Effect()
    public getAutoVehicleColors$: Observable<Action> = this.actions$.ofType(claimsSupportActions.ActionTypes.LOAD_VEHICLE_COLORS).pipe(
        map((action: claimsSupportActions.LoadVehicleColorsAction) => action.payload),
        switchMap(state => {
            return this.claimsSupportApiClient.getAutoVehicleColors().pipe(
                map(colors => new claimsSupportActions.LoadVehicleColorsSuccessAction(colors)),
                catchError(error => of(new claimsSupportActions.LoadVehicleColorsFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    /**
     * Counties for Glass Vendors
     */
    @Effect()
    public getCountiesForGlassVendors$: Observable<Action> = this.actions$.ofType(claimsSupportActions.ActionTypes.LOAD_COUNTIES).pipe(
        map((action: claimsSupportActions.LoadCountiesAction) => action.payload),
        switchMap(state => {
            return this.claimsSupportApiClient.getCountiesForGlassVendors().pipe(
                map(counties => new claimsSupportActions.LoadCountiesSuccessAction(counties)),
                catchError(error => of(new claimsSupportActions.LoadCountiesFailAction(error)))
            );
        })
    );


    /**
     * Claims History
     */
    @Effect()
    public getClaimsHistory$: Observable<Action> = this.actions$.ofType(claimsSupportActions.ActionTypes.LOAD_CLAIMS_HISTORY).pipe(
        map((action: claimsSupportActions.LoadClaimsHistoryAction) => action.payload),
        mergeMap(memberNumber => {
                return this.claimsSupportApiClient.getClaimsHistory(memberNumber).pipe(
                map(history => new claimsSupportActions.LoadClaimsHistorySuccessAction(history)),
                catchError(error => of(new claimsSupportActions.LoadClaimsHistoryFailAction({ error: this.errorMsgService.getMsg(error) })))
            );
        })
    );

    @Effect({dispatch: false})
    public reloadClaimsHistory$: Observable<Action> = this.actions$.ofType(claimsSupportActions.ActionTypes.RELOAD_CLAIMS_HISTORY).pipe(
        map((action: claimsSupportActions.ReloadClaimsHistoryAction) => action.payload),
        withLatestFrom(this.appState$.select(getPoliciesData)),
        mergeMap(([, policies]) => {
            policies.filter(pol => pol.translatedPolicyType === 'MEMB').forEach(mem => this.appState$.dispatch(new claimsSupportActions.LoadClaimsHistoryAction(mem.policyNbr)))
            return EMPTY
        })
    );

    /**
     * Claims reporting agency types
     */
    @Effect()
    public getReportingAgencyTypes$: Observable<Action> = this.actions$.ofType(claimsSupportActions.ActionTypes.LOAD_REPORTING_AGENCY_TYPES).pipe(
            map((action: claimsSupportActions.LoadReportingAgencyTypesAction) => action.payload),
            switchMap(state => {
                return this.claimsSupportApiClient.getReportAgencyTypes().pipe(
                    map(type => new claimsSupportActions.LoadReportingAgencyTypesSuccessAction(type)),
                    catchError(error => of(new claimsSupportActions.LoadReportingAgencyTypesFailAction(error)))
                );
            })
        );

    /**
     * Glass Vendors By County
     */
    @Effect()
    public getGlassVendorsByCounty$: Observable<Action> = this.actions$
        .ofType(claimsSupportActions.ActionTypes.LOAD_GLASS_VENDORS_BY_COUNTY)
        .pipe(
            map((action: claimsSupportActions.LoadGlassVendorsByCountyAction) => action.payload),
            switchMap(county => {
                return this.claimsSupportApiClient.getGlassVendorsByCounty(county).pipe(
                    map(vendors => new claimsSupportActions.LoadGlassVendorsByCountySuccessAction(vendors)),
                    catchError(error => of(new claimsSupportActions.LoadGlassVendorsByCountyFailAction(error)))
                );
            })
        );

    /**
     * Claim Details by Claim Number
     */
    @Effect()
    public getClaimDetailsByClaimNumber$: Observable<Action> = this.actions$
        .ofType(claimsSupportActions.ActionTypes.LOAD_CLAIM_DETAILS)
        .pipe(
            map((action: claimsSupportActions.LoadClaimDetailsAction) => action.payload),
            switchMap(claimNumber => {
                return this.claimsSupportApiClient.getClaimDetails(claimNumber).pipe(
                    map(claim => new claimsSupportActions.LoadClaimDetailsSuccessAction(claim)),
                    catchError(error => of(new claimsSupportActions.LoadClaimDetailsFailAction(error)))
                );
            })
        );
}
