import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AutoService, ClaimsService, ClientService, MyProfileService, PolicyService } from './adapters';
import {
    AutoApiClient,
    BillingApiClient,
    ClaimsFileApiClient,
    ClaimsSupportApiClient,
    ClientApiClient,
    DocumentApiClient,
    MyProfileApiClient,
    OfficeApiClient,
    PaperlessApiClient,
    PayMyBillApiClient,
    PolicyApiClient,
    RegisterApiClient,
    UserApiClient
} from './api-clients';
import { HttpResponseHandler, HttpService } from './http';

@NgModule({
    imports: [CommonModule]
})
export class AsyncServicesModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: AsyncServicesModule,

            providers: [
                HttpService,
                HttpResponseHandler,
                AutoService,
                ClaimsService,
                ClientService,
                PolicyService,
                MyProfileService,

                AutoApiClient,
                ClaimsFileApiClient,
                ClaimsSupportApiClient,
                ClientApiClient,
                UserApiClient,
                PolicyApiClient,
                BillingApiClient,
                OfficeApiClient,
                DocumentApiClient,
                MyProfileApiClient,
                RegisterApiClient,
                PaperlessApiClient,
                PayMyBillApiClient
            ]
        };
    }
}
