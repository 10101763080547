export class CoverageOption {
    public code: string;
    public deductibleAmt: number;
    public deductibleDesc: string;
    public isDefault: boolean;

    constructor(init?: Partial<CoverageOption>) {
        Object.assign(this, init);
    }
}
