
export class GenericClaim {
    public claimNbr: string;
    public techClaimNbr: string;
    public lossDt: string;
    public policyHolder: string;
    public claimStatus: string;
    public claimTypeCd: string;
    public claimCauseLossCd: string;
    public policyNbr: string;
    public policyLob: string;

    constructor(init?: Partial<GenericClaim>) {
        if (!init) {init = {}; }
        this.claimNbr = init.claimNbr || null;
        this.techClaimNbr = init.techClaimNbr || null;
        this.lossDt = init.lossDt || null;
        this.policyHolder = init.policyHolder || null;
        this.claimStatus = init.claimStatus || null;
        this.claimTypeCd = init.claimTypeCd || null;
        this.claimCauseLossCd = init.claimCauseLossCd || null;
        this.policyNbr = init.policyNbr || null;
        this.policyLob = init.policyLob || null;
    }
  }
