import { ProfileResponse } from '../../../models/register/profile-response.model';
import { RegisterAccountForm } from '../../../models/register/registerAccount.model';
import { RegisterPolicyForm } from '../../../models/register/registerPolicy.model';
import { ValidationResponse } from '../../../models/register/validation-response.model';
import * as actions from '../../actions/register/register.action';

export interface RegisterState {
    isLoaded: boolean;
    isLoading: boolean;
    custId: string;
    policy: RegisterPolicyForm;
    policyResponse: ValidationResponse;
    account: RegisterAccountForm;
    accountResponse: ProfileResponse;
    error: string;

    isVerificationLoaded: boolean;
    isVerificationLoading: boolean;
    verificationError: string;
}

const INITIAL_STATE: RegisterState = {
    isLoaded: false,
    isLoading: false,
    custId: null,
    policy: null,
    policyResponse: null,
    account: null,
    accountResponse: null,
    error: null,

    isVerificationLoaded: false,
    isVerificationLoading: false,
    verificationError: null
};

export function registerReducer(state = INITIAL_STATE, action: actions.Actions): RegisterState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case actions.ActionTypes.SUBMIT_POLICY: {
            return Object.assign({}, state, {
                isLoading: true,
                policy: action.payload,
                error: null
            });
        }

        case actions.ActionTypes.SUBMIT_POLICY_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                policyResponse: action.payload
            });
        }

        case actions.ActionTypes.SUBMIT_POLICY_FAIL: {
            return Object.assign({}, state, {
                isLoading: false,
                error: action.payload.error
            });
        }

        case actions.ActionTypes.SUBMIT_ACCOUNT: {
            return Object.assign({}, state, {
                isLoading: true,
                account: action.payload,
                error: null
            });
        }

        case actions.ActionTypes.SUBMIT_ACCOUNT_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                accountResponse: action.payload
            });
        }

        case actions.ActionTypes.SUBMIT_ACCOUNT_FAIL: {
            return Object.assign({}, state, {
                isLoading: false,
                error: action.payload.error
            });
        }

        case actions.ActionTypes.SUBMIT_SECURITY: {
            return Object.assign({}, state, {
                isLoading: true,
                error: null
            });
        }

        case actions.ActionTypes.SUBMIT_SECURITY_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false
            });
        }

        case actions.ActionTypes.SUBMIT_SECURITY_FAIL: {
            return Object.assign({}, state, {
                isLoading: false,
                error: action.payload.error
            });
        }

        case actions.ActionTypes.SUBMIT_VERIFICATION: {
            return Object.assign({}, state, {
                isVerificationLoaded: false,
                isVerificationLoading: true,
                verificationError: null
            });
        }

        case actions.ActionTypes.SUBMIT_VERIFICATION_SUCCESS: {
            return Object.assign({}, state, {
                isVerificationLoaded: true,
                isVerificationLoading: false,
                verificationError: null
            });
        }

        case actions.ActionTypes.SUBMIT_VERIFICATION_FAIL: {
            return Object.assign({}, state, {
                isVerificationLoaded: true,
                isVerificationLoading: false,
                verificationError: action.payload.error
            });
        }

        default: {
            return state;
        }
    }
}

export const getPolicyForm = (state: RegisterState) => state.policy;
export const getPolicyResponse = (state: RegisterState) => state.policyResponse;
export const getAccountForm = (state: RegisterState) => state.account;
export const getAccountResponse = (state: RegisterState) => state.accountResponse;
export const getRegisterError = (state: RegisterState) => state.error;

export const getIsVerificationLoaded = (state: RegisterState) => state.isVerificationLoaded;
export const getIsVerificationLoading = (state: RegisterState) => state.isVerificationLoading;
export const getVerificationError = (state: RegisterState) => state.verificationError;
