import { Component, Input, OnInit } from '@angular/core';
import { PolicyBase } from '../../models';

@Component({
  selector: 'app-policy-heading',
  templateUrl: './policy-heading.component.html'
})
export class PolicyHeadingComponent implements OnInit {

  @Input() public policy: PolicyBase;
  @Input() public heading: string;
  @Input() public image: string;

  constructor() {

  }

  public ngOnInit() {
    if (!this.heading) {
      this.heading = 'View Policy';
    }
    if("CAUTO" === this.policy.policyTypeCd){
      this.image = "commercialauto";
    }else{
      this.image = this.policy.translatedPolicyType;
    }
    
  }
}
