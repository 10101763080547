import { Component, EventEmitter, Input, Output } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    @Input() public loggedIn = false;
    public marketingSite = environment.marketingSite;
}
