import { Agency, Alert, PolicyBase } from '../../../models';
import * as policyActions from '../../actions/policy/policy.action';

export interface PolicyState {
    arePoliciesLoaded: boolean;
    arePoliciesLoading: boolean;
    policiesLoadingError: boolean;
    isPolicyLoaded: boolean;
    isPolicyLoading: boolean;
    policyLoadingError: boolean;
    policies: PolicyBase[];
    alerts: Alert[];

    countOfAgenciesLoading: number;
    agencies: Agency[];
}

const INITIAL_STATE: PolicyState = {
    arePoliciesLoaded: false,
    arePoliciesLoading: false,
    policiesLoadingError: false, // We need policyLoading error, because we cannot infer it from arePoliciesLoaded and arePoliciesLoading
    isPolicyLoaded: false,
    isPolicyLoading: false,
    policyLoadingError: false,
    policies: [],
    alerts: [],
    countOfAgenciesLoading: 0,
    agencies: []
};

//  Reducers specify how the application’s state changes in response to actions.
export function policyReducer(state = INITIAL_STATE, action: policyActions.Actions): PolicyState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case policyActions.ActionTypes.LOAD_POLICIES: {
            return {
                ...state,
                arePoliciesLoading: true,
                policiesLoadingError: false
            };
        }

        case policyActions.ActionTypes.LOAD_POLICIES_SUCCESS: {
            return {
                ...state,
                arePoliciesLoading: false,
                arePoliciesLoaded: true,
                policiesLoadingError: false,
                policies: action.payload.policies,
                alerts: action.payload.alerts
            };
        }

        case policyActions.ActionTypes.LOAD_POLICIES_FAIL: {
            return {
                ...state,
                arePoliciesLoading: false,
                arePoliciesLoaded: false,
                policiesLoadingError: true
            };
        }

        case policyActions.ActionTypes.LOAD_POLICY: {
            return {
                ...state,
                isPolicyLoading: true,
                policyLoadingError: false
            };
        }

        case policyActions.ActionTypes.LOAD_POLICY_SUCCESS_ANNUITY:
        case policyActions.ActionTypes.LOAD_POLICY_SUCCESS_LIFE:
        case policyActions.ActionTypes.LOAD_POLICY_SUCCESS: {
            action.payload.detailsLoaded = true;
            const policies = state.policies.filter(
                pol => pol.policyNbr !== action.payload.policyNbr || pol.translatedPolicyType !== action.payload.translatedPolicyType
            );
            policies.push(action.payload);

            return {
                ...state,
                isPolicyLoading: false,
                isPolicyLoaded: true,
                policyLoadingError: false,
                policies: policies
            };
        }

        case policyActions.ActionTypes.LOAD_POLICY_FAIL: {
            const policies = state.policies;
            const policy = FindPolicy(action.payload.policyNbr, policies, true);
            policy.error = action.payload.error;
            if (!policy.translatedPolicyType) {
                policy.translatedPolicyType = action.payload.translatedPolicyType;
            }

            return {
                ...state,
                isPolicyLoading: false,
                isPolicyLoaded: false,
                policyLoadingError: true,
                policies: policies
            };
        }

        case policyActions.ActionTypes.LOAD_LIFE_SUCCESS: {
            const policies = state.policies;
            const policy = FindPolicy(action.payload.policyNbr, policies, true);
            policy.surrender = action.payload.surrender;
            if (!policy.translatedPolicyType) {
                policy.translatedPolicyType = action.payload.translatedPolicyType;
            }

            return {
                ...state,
                isPolicyLoading: false,
                isPolicyLoaded: true,
                policyLoadingError: false,
                policies: policies
            };
        }

        case policyActions.ActionTypes.LOAD_LIFE_FAIL: {
            let found = false;
            const policies = state.policies;
            for (let i = 0; i < policies.length; i++) {
                if (policies[i].policyNbr === action.payload.policyNbr) {
                    policies[i].surrender = { error: action.payload.error };
                    found = true;
                }
            }
            if (!found) {
                throw new Error(`Policy not found: ${action.payload.policyNbr}`);
            }

            return {
                ...state,
                isPolicyLoading: false,
                isPolicyLoaded: false,
                policyLoadingError: true,
                policies: policies
            };
        }

        case policyActions.ActionTypes.LOAD_ANNUITY_SUCCESS: {
            let found = false;
            const policies = state.policies;
            for (let i = 0; i < policies.length; i++) {
                if (policies[i].policyNbr === action.payload.policyNbr) {
                    policies[i].contributionHistory = action.payload.contributionHistory;
                    found = true;
                }
            }
            if (!found) {
                throw new Error(`Policy not found: ${action.payload.policyNbr}`);
            }

            return {
                ...state,
                isPolicyLoading: false,
                isPolicyLoaded: true,
                policyLoadingError: false,
                policies: policies
            };
        }

        case policyActions.ActionTypes.LOAD_ANNUITY_FAIL: {
            return {
                ...state,
                isPolicyLoading: false,
                isPolicyLoaded: false,
                policyLoadingError: true
            };
        }

        case policyActions.ActionTypes.GET_AGENCY: {
            return {
                ...state,
                countOfAgenciesLoading: state.countOfAgenciesLoading + 1
            };
        }

        case policyActions.ActionTypes.GET_AGENCY_SUCCESS: {
            let newAgencies: Agency[];
            if (action.payload) {
                newAgencies = state.agencies.filter(a => a.officeNbr !== action.payload.officeNbr);
                newAgencies.push(action.payload);
            } else {
                newAgencies = [...state.agencies];
            }
            return {
                ...state,
                countOfAgenciesLoading: state.countOfAgenciesLoading - 1,
                agencies: newAgencies
            };
        }

        case policyActions.ActionTypes.GET_AGENCY_FAIL: {
            return {
                ...state,
                countOfAgenciesLoading: state.countOfAgenciesLoading - 1
            };
        }

        default: {
            return state;
        }
    }
}

function FindPolicy(policyNbr: string, policies: PolicyBase[], createIfNotFound?: boolean): PolicyBase {
    for (let i = 0; i < policies.length; i++) {
        if (policies[i].policyNbr === policyNbr) {
            return policies[i];
        }
    }

    if (createIfNotFound) {
        const policy = new PolicyBase({ policyNbr: policyNbr });
        policies.push(policy);
        return policy;
    } else {
        return null;
    }
}

export const policyDataLoading = (state: PolicyState) =>
    state.arePoliciesLoading || state.isPolicyLoading || state.countOfAgenciesLoading !== 0;

export const getPoliciesData = (state: PolicyState) => state.policies;
export const getAlerts = (state: PolicyState) => state.alerts;
export const getPoliciesLoading = (state: PolicyState) => state.arePoliciesLoading;
export const getPoliciesLoaded = (state: PolicyState) => state.arePoliciesLoaded;

export const getCountOfAgenciesLoading = (state: PolicyState) => state.countOfAgenciesLoading;
export const getAgencies = (state: PolicyState) => state.agencies;
export const getPoliciesLoadingHasError = (state: PolicyState) => state.policiesLoadingError;
export const getPolicyLoadingHasError = (state: PolicyState) => state.policyLoadingError;
