import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from '../../../../../environments/environment';
import { BillPayItem, PolicyBase } from '../../../models';
import { PolicyState } from '../../reducers';

const getPolicies = createFeatureSelector<PolicyState>('policy');

const getItems = (policyState: PolicyState): BillPayItem[] => {

    let model: BillPayItem;
    const models: BillPayItem[] = [];
    const policies = policyState.policies;

    if (!policies) { return []; }

    for (const policy of policies) {
        model = new BillPayItem();
        model.policy = policy;
        model.billToMortgage = policy.billing.displayDue.billToMortgage;
        model.isEFT = policy.billing.displayDue.isEFT;

        model.paymentDisabled = isDisabled(policy);
        model.paymentUrlParams = getPaymentUrlParams(policy);
        if (!model.paymentUrlParams) {
            model.paymentDisabled = true;
        }
        model.paymentUrlHaserror = !model.paymentUrlParams;

        models.push(model);
    }
    return models;
};

const isDisabled = (policy: PolicyBase) => {

    const billing = policy.billing;
    if (!billing) { return true; }

    // always disable Next EFT install per bug2682
    if (billing.displayDue.isEFTNextInstallment) { return true; }

    // If the policy status code is not 01 for P&C policies then disable the Pay Bill button on the billing page.
    if ((policy.translatedPolicyType !== 'life') && (policy.statusCd !== '01')) { return true; }
    if ((billing.billingAccess)
        && (billing.billingAccess.ONE_TIME_PAYMENTS)
        && !billing.billingAccess.ONE_TIME_PAYMENTS.allowed) { return true; }

    // Enabled always for EFT
    if (billing.displayDue.isEFT) { return false; }

    return !(billing.currentDue && billing.displayDue.amount > 0);
};

const getPaymentUrlParams = (policyItem: PolicyBase) => {

    let urlParams;
    try {
        const policyNumber = policyItem.policyNbr;
        const policyType = policyItem.systemId;
        let zip;
        if (policyItem.translatedPolicyType === 'life') {
            // Life insurance
            const clients = policyItem.clients;

            for (const client of clients) {
                if (client.roles && client.roles.PO && client.roles.PO.toLowerCase() === 'payor') {
                    zip = client.mailingAddress.zip.substr(0, 5);
                    break;
                }
            }
        } else if (policyItem.systemId === 'MB') {
            zip = policyItem.mailingAddr.zip.substr(0, 5);
        } else {
            zip = policyItem.namedInsured.mailingAddress.zip.substr(0, 5);
        }
        if (environment.useOldPayUrl === true) {
            urlParams = environment.oldPaymentUrl;
            urlParams = urlParams
                    .replace('##policyNumber##', policyNumber)
                    .replace('##policyType##', policyItem.systemId.substr(0, 1))
                    .replace('##zipCode##', zip);

        } else {
            urlParams = {policyNumber: policyNumber, policyType: policyType, zipcode: zip};
        }
    } catch (err) {
        urlParams = null;
    }

    return urlParams;
};

export const getBillPayItems = createSelector(
    getPolicies,
    getItems
);






