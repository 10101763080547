import { DueAmountsApiResponse } from '../../../../shared/models';
import { Actions, ActionTypes } from '../../actions/pay-my-bill/pay-my-bill.action';

export interface PayMyBillState {
    policySearchLoading: boolean;
    policySearchResponse: DueAmountsApiResponse;
    policySearchFailed: boolean;
    policySearchError: string;
    receiptTriggerData: Location;
}

const INITIAL_STATE: PayMyBillState = {
    policySearchLoading: false,
    policySearchResponse: null,
    policySearchFailed: false,
    policySearchError: null,
    receiptTriggerData: null
};

export function payMyBillReducer(state = INITIAL_STATE, action: Actions): PayMyBillState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case ActionTypes.POLICY_SEARCH: {
            return {
                ...state,
                policySearchLoading: true,
                policySearchResponse: null,
                policySearchFailed: false,
                policySearchError: null
            };
        }
        case ActionTypes.POLICY_SEARCH_SUCCESS: {
            return {
                ...state,
                policySearchLoading: false,
                policySearchResponse: action.payload,
                policySearchFailed: false,
                policySearchError: null
            };
        }
        case ActionTypes.POLICY_SEARCH_FAIL: {
            return {
                ...state,
                policySearchLoading: false,
                policySearchResponse: null,
                policySearchFailed: true,
                policySearchError: action.payload
            };
        }
        case ActionTypes.RECEIPT_TRIGGERED: {
            return {
                ...state,
                receiptTriggerData: action.payload
            };
        }

        default: {
            return state;
        }
    }
}

export const getPolicySearchLoading = (state: PayMyBillState) => state.policySearchLoading;
export const getPolicySearchResponse = (state: PayMyBillState) => state.policySearchResponse;
export const getPolicySearchFailed = (state: PayMyBillState) => state.policySearchFailed;
export const getPolicySearchError = (state: PayMyBillState) => state.policySearchError;

export const getReceiptTriggerData = (state: PayMyBillState) => state.receiptTriggerData;

export const getPayMyBillLoading = (state: PayMyBillState) => state.policySearchLoading;
