export class DeviceInfo {
  public devicePrint?: string;
  public deviceTokenCookie?: string;
  public deviceTokenFSO?: string;

  public httpAccept?: string;
  public httpAcceptEncoding?: string;
  public httpAcceptLanguage?: string;
  public httpReferrer?: string;
  public ipAddress?: string;
  public verify?: string;

  constructor(init?: Partial<DeviceInfo>) {
    if (!init) {
      init = {};
    }
    this.devicePrint = init.devicePrint || null;
    this.deviceTokenCookie = init.deviceTokenCookie || null;
    this.deviceTokenFSO = init.deviceTokenFSO || null;
    this.httpAccept = init.httpAccept || null;
    this.httpAcceptEncoding = init.httpAcceptEncoding || null;
    this.httpAcceptLanguage = init.httpAcceptLanguage || null;
    this.httpReferrer = init.httpReferrer || null;
    this.ipAddress = init.ipAddress || null;
    this.verify = init.verify || null;
  }
}
