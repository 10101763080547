import { DatePipe } from '@angular/common';
import * as abc from '../../../../assets/rsa';
import { UserState } from '../../store/reducers';
import { formatPhoneForApi } from '../../utility';
import { ChallengeApiRequest, DeviceInfo, MyAccount, User, UserApiResponse } from '../user';
import { ForgotPasswordForm } from '../user/forgot-password.model';
import { ForgotUsernameForm } from '../user/forgot-username.model';
import { ForgotPasswordApiRequest } from '../user/forgotPasswordApiRequest';
import { ForgotUsernameApiRequest } from '../user/forgotUsernameApiRequest';
import { ResendVerificationEmailApiRequest } from '../user/resendVerificationEmailApiRequest';
import { ResetPasswordApiRequest } from '../user/resetPasswordApiRequest';
import { ProfileRequest } from './profile-request.model';
import { QuestionRequest } from './question-request.model';
import { RegisterAccountForm } from './registerAccount.model';
import { RegisterPolicyForm } from './registerPolicy.model';
import { RegisterSecurityForm } from './registerSecurity.model';
import { ValidationRequest } from './validation-request.model';
import { ValidationResponse } from './validation-response.model';
declare var encode_deviceprint: any;
declare var BrowserDetect: any;
declare var IE_FingerPrint: any;
declare var Mozilla_FingerPrint: any;
declare var Opera_FingerPrint: any;
declare var FingerPrint: any;

export class RegisterApiRequestFactory {
    public static createRegisterPolicyRq(form: RegisterPolicyForm): ValidationRequest {
        return new ValidationRequest({
            policyNbr: form.policyNum,
            zipCode: form.zipcode,
            ssn: form.ssn,
            dob: new DatePipe('en-US').transform(form.dob, 'yyyy-MM-dd')
        });
    }

    private static getDeviceInfo(): DeviceInfo {
        BrowserDetect.init();

        let d;
        switch (BrowserDetect.browser) {
            case 'Explorer':
                d = new IE_FingerPrint();
                break;
            case 'Firefox':
                d = new Mozilla_FingerPrint();
                break;
            case 'Opera':
                d = new Opera_FingerPrint();
                break;
            default:
                d = new FingerPrint();
        }

        return new DeviceInfo({
            devicePrint: encode_deviceprint(),
            httpAccept: '*/*',
            httpAcceptEncoding: 'gzip, deflate',
            httpAcceptLanguage: d.deviceprint_language(),
            httpReferrer: '/',
            ipAddress: '127.0.0.1'
        });
    }

    private static getRouteUrl(currentRoute: string, targetRoute: string): string {
        let url = window.location.href;

        if (currentRoute === '/login/verify') {
            url = window.location.href + '/verify';
        }

        url = window.location.href.substring(0, url.length - currentRoute.length + 1);
        if (!url.endsWith('/')) {
            url += '/';
        }
        if (targetRoute.startsWith('/')) {
            targetRoute = targetRoute.substring(1);
        }

        return url + targetRoute;
    }

    public static createRegisterAccountRq(form: RegisterAccountForm, policyResponse: ValidationResponse): ProfileRequest {
        let phoneNumber: string;
        try {
            phoneNumber = formatPhoneForApi(form.phone, 'nnn-nnn-nnnn');
        } catch (err) {
            phoneNumber = null;
            console.error(err);
        }

        return new ProfileRequest({
            deviceInfo: RegisterApiRequestFactory.getDeviceInfo(),
            user: {
                firstName: form.firstName,
                lastName: form.lastName,
                userId: form.username,
                password: form.password,
                phoneNumber: phoneNumber,
                emailAddress: form.email
            },
            id: policyResponse.id
        });
    }

    public static createRegisterSecurityRq(form: RegisterSecurityForm, userId: string, currentRoute: string): QuestionRequest {
        return new QuestionRequest({
            bind: form.registerThiscomputer,
            id: userId,
            url: RegisterApiRequestFactory.getRouteUrl(currentRoute, 'register/verify'),
            questions: [
                {
                    questionId: form.question1,
                    questionText: null,
                    userAnswer: form.answer1
                },
                {
                    questionId: form.question2,
                    questionText: null,
                    userAnswer: form.answer2
                },
                {
                    questionId: form.question3,
                    questionText: null,
                    userAnswer: form.answer3
                }
            ],
            deviceInfo: RegisterApiRequestFactory.getDeviceInfo()
        });
    }

    public static createForgotPasswordRequest(form: ForgotPasswordForm, currentRoute: string): ForgotPasswordApiRequest {
        return new ForgotPasswordApiRequest({
            deviceInfo: RegisterApiRequestFactory.getDeviceInfo(),
            taxId: form.ssn,
            dob: new DatePipe('en-US').transform(form.dob, 'yyyy-MM-dd'),
            url: RegisterApiRequestFactory.getRouteUrl(currentRoute, 'login/recoverpassword'),
            user: {
                emailAddress: form.email,
                userId: form.username
            }
        });
    }

    public static createForgotUsernameRequest(form: ForgotUsernameForm): ForgotUsernameApiRequest {
        return new ForgotUsernameApiRequest({
            user: {
                emailAddress: form.email
            },
            taxId: form.ssn,
            dob: new DatePipe('en-US').transform(form.dob, 'yyyy-MM-dd')
        });
    }

    public static createResetPasswordRequest(state: string, user: User): ResetPasswordApiRequest {
        return new ResetPasswordApiRequest({
            id: user.id,
            user: {
                password: state
            }
        });
    }

    public static createResendVerficationEmailRequest(currentRoute: string, user: User): ResendVerificationEmailApiRequest {
        return new ResendVerificationEmailApiRequest({
            id: user.id,
            url: RegisterApiRequestFactory.getRouteUrl(currentRoute, 'register/verify')
        });
    }

    public static createRecoverPasswordAuthRequest(answer: string, userState: UserState): QuestionRequest {
        const request = new QuestionRequest({
            bind: true,
            deviceInfo: this.getDeviceInfo(),
            id: userState.recoverPasswordId,
            questions: userState.recoverPasswordInitResponse.questions
        });

        request.questions[0].userAnswer = answer;

        return request;
    }

    public static createRecoverPasswordCompleteRequest(password: string, userState: UserState): ResetPasswordApiRequest {
        return new ResetPasswordApiRequest({
            deviceInfo: this.getDeviceInfo(),
            id: userState.recoverPasswordId,
            user: {
                password: password
            }
        });
    }

    public static createLoginRequest(user: User): MyAccount {
        return new MyAccount({
            deviceInfo: this.getDeviceInfo(),
            user: new User({
                userId: user.userId,
                password: user.password
            })
        });
    }

    public static createAuthenticateRequest(request: ChallengeApiRequest): ChallengeApiRequest {
        request.deviceInfo = this.getDeviceInfo();
        return request;
    }
}
