export class ClaimsBasicInfoForm {
    public custId:    string;
    public lossDate:  string;
    public lossTime:  string;

    constructor(init?: Partial<ClaimsBasicInfoForm>) {
      if (!init) { init = {}; }
      this.lossDate = init.lossDate || null;
      this.custId   = init.custId || null;
      this.lossTime = init.lossTime || null;
    }
  }
