import { LifeCoverageLayer } from './life-coverage-layer.model';

export class LifeCoverage {

    public type: string;
    public code: string;
    public shortName: string;
    public name: string;
    public issueAge: number;
    public payupDate: string;
    public expireDate: string;
    public volume: number;
    public premium: number;
    public annualizedPremium: number;
    public parentType: string;
    public benefits: LifeCoverage[];
    public layers: LifeCoverageLayer[];

    constructor(init?: Partial<LifeCoverage>) {
        if (!init) { init = {}; }
        this.type = init.type || null;
        this.code = init.code || null;
        this.shortName = init.shortName || null;
        this.name = init.name || null;
        this.issueAge = init.issueAge || null;
        this.payupDate = init.payupDate || null;
        this.expireDate = init.expireDate || null;
        this.volume = init.volume || null;
        this.premium = init.premium || null;
        this.annualizedPremium = init.annualizedPremium || null;
        this.parentType = init.parentType || null;
        this.benefits = init.benefits || new Array<LifeCoverage>();
        this.layers = init.layers || new Array<LifeCoverageLayer>();

    }
}
