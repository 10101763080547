import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatToolbarModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes';
import { AddressComponent } from './address/address.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PolicyButtonsComponent } from './policy-buttons/policy-buttons.component';
import { PolicyCoverageComponent } from './policy-coverage/policy-coverage.component';
import { PolicyDisplayItemComponent } from './policy-display-item';
import { PolicyHeadingComponent } from './policy-heading/policy-heading.component';
import { PolicySummaryComponent } from './policy-summary/policy-summary.component';
import { PrivacyPolicyContentComponent } from './privacy-policy/privacy-policy-content.component';
import { TermsAndConditionsContentComponent } from './terms-and-conditions/terms-and-conditions-content.component';
import { ValidationWarningComponent } from './validation-warning/validation-warning.component';
import { PolicyLifeHeadingComponent } from './policy-life-heading/policy-life-heading.component';
export const COMPONENTS = [
    NavigationComponent,
    HeaderComponent,
    FooterComponent,
    ValidationWarningComponent,
    LoadingSpinnerComponent,
    PolicySummaryComponent,
    PolicyCoverageComponent,
    PolicyDisplayItemComponent,
	PolicyHeadingComponent,
	PolicyLifeHeadingComponent,
    PolicyButtonsComponent,
    AddressComponent,
    PrivacyPolicyContentComponent,
    TermsAndConditionsContentComponent
];

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        PipesModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatIconModule,
        MatExpansionModule,
        MatCardModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatButtonModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})
export class ComponentsModule {}
