import { Pipe, PipeTransform } from '@angular/core';
import { Driver } from '../models/policy/base/policy-base.model';

@Pipe({
    name: 'excludedDrivers'
})
export class ExcludedDriversPipe implements PipeTransform {
    public transform(drivers: Driver[]): any {
        if (!drivers) {
            return drivers;
        }
        return drivers.filter(driver => driver.excluded);
    }
}
