export class ForgotUsernameApiRequest {
    public user: {
        emailAddress: string
    };
    public taxId: string;
    public dob: string;

    constructor(init?: Partial<ForgotUsernameApiRequest>) {
        Object.assign(this, init);
    }
}
