export class AutoAddVehicleApiRequest {
    public customerId: string;
    public addVehicle: {
        year: number;
        makeCd: string;
        makeDesc: string;
        modelCd: string;
        modelDesc: string;
        vinSerialNbr: string;
        typeCd: string;
    };
    public vehLienInd: string;
    public vehLeasedInd: string;
    public datePurchased: string;
    public vehPurchasedFrom: string;
    public vehRegisteredState: string;
    public vehTitledName: string;
    public coverageList: AutoCoverageApiRequest[];
    public vehAccSincePurchase: string;
    public vehAccSincePurchaseExp: boolean;
    public vehDrivenWorkSchool: string;
    public milesDrivenOneWay: string;
    public vehUsedInBusiness: string;
    public vehUsedCarryProperty: string;
    public vehUsedCarryPersons: string;
    public transNetworkCompany: string;
    public foodNetworkCo: string;
    public vehGaragedResidence: string;
    public vehPrimaryDriver: string;
    public vehPrimaryDrv: string;
    public vehLienLeasedAddress: {
        name: string;
        addr1: string;
        addr2: string;
        city: string;
        stateCd: string;
        zip: string;
    };

    constructor(init?: Partial<AutoAddVehicleApiRequest>) {
        Object.assign(this, init);
    }
}

export class AutoCoverageApiRequest {
    public description: string;
    public currentAmount: number;
    public newAmount: number;

    constructor(init?: Partial<AutoCoverageApiRequest>) {
        Object.assign(this, init);
    }
}
