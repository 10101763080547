import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { State } from '../..';
import { ClaimsFileApiClient, PolicyApiClient } from '../../../asyncServices';
import { AutoClaim, ClaimsApiRequest, ClaimsApiRequestFactory } from '../../../models';
import { ErrorMsgService } from '../../../utility/error-msg.service';
import * as claimsFileActions from '../../actions/claims/claims-file.action';
import { ReloadClaimsHistoryAction } from '../../actions/claims/claims-support.action';
import { getClaim, getUser } from '../../selectors';
@Injectable()
export class ClaimsFileEffects {
    constructor(
        private actions$: Actions,
        private claimsFileApiClient: ClaimsFileApiClient,
        private policyApiClient: PolicyApiClient,
        private appState$: Store<State>,
        private errorMsgService: ErrorMsgService
    ) {}

    /**
     * Auto Claim Effects
     */
    @Effect()
    public submitAutoBasicInfo$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_AUTO_BASIC_INFO).pipe(
        map((action: claimsFileActions.SubmitAutoBasicInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.claimsFileApiClient.setAutoClaimBasicInfo(ClaimsApiRequestFactory.createAutoBasicInfoRq(payload, user)).pipe(
                map(claimsApiResponse => new claimsFileActions.SubmitAutoBasicInfoSuccessAction(claimsApiResponse)),
                catchError(error =>
                    of(
                        new claimsFileActions.SubmitAutoBasicInfoFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    @Effect()
    public updateAutoBasicInfo$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.UPDATE_AUTO_BASIC_INFO).pipe(
        map((action: claimsFileActions.UpdateAutoBasicInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim), this.appState$.select(getUser)),
        switchMap(([payload, claim, user]) => {
            return this.claimsFileApiClient
                .updateAutoClaimBasicInfo(ClaimsApiRequestFactory.createAutoBasicInfoRq(payload, user), claim.id)
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.UpdateAutoBasicInfoSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.UpdateAutoBasicInfoFailAction(error)))
                );
        })
    );

    @Effect()
    public submitAutoVehicleInfo$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_AUTO_VEHICLE_INFO).pipe(
        map((action: claimsFileActions.SubmitAutoVehicleInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient.setAutoClaimVehicleInfo(payload, claim.id).pipe(
                map(claimsApiResponse => new claimsFileActions.SubmitAutoVehicleInfoSuccessAction(claimsApiResponse)),
                catchError(error =>
                    of(
                        new claimsFileActions.SubmitAutoVehicleInfoFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    // @Effect()
    // public saveAutoVehicleInfo$: Observable<Action> = this.actions$
    //     .ofType(claimsFileActions.ActionTypes.SAVE_AUTO_VEHICLE_INFO)
    //     .pipe(map((action: claimsFileActions.SubmitAutoVehicleInfoAction) => action.payload)
    //     .pipe(map(claimsApiResponse => new claimsFileActions.SaveAutoVehicleInfoAction(claimsApiResponse))
    //     catchError(error => of(new claimsFileActions.SaveAutoVehicleInfoFailAction(error)));
    //     }

    @Effect()
    public submitAutoClientInfo$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_AUTO_CLIENT_INFO).pipe(
        map((action: claimsFileActions.SubmitAutoClientInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient
                .setAutoClaimClientInfo(ClaimsApiRequestFactory.createClientRq(payload, (<AutoClaim>claim).autoDetail), claim.id)
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitAutoClientInfoSuccessAction(claimsApiResponse)),
                    catchError(error =>
                        of(
                            new claimsFileActions.SubmitAutoClientInfoFailAction({
                                error: this.errorMsgService.getMsg(error)
                            })
                        )
                    )
                );
        })
    );

    @Effect()
    public submitAutoDetail$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_AUTO_DETAIL).pipe(
        map((action: claimsFileActions.SubmitAutoDetailAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient.setAutoClaimDetailInfo(ClaimsApiRequestFactory.createAutoDetailsRq(payload, claim), claim.id).pipe(
                map(claimsApiResponse => new claimsFileActions.SubmitAutoDetailSuccessAction(claimsApiResponse)),
                catchError(error =>
                    of(
                        new claimsFileActions.SubmitAutoDetailFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    @Effect()
    public submitAutoDocuments$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_AUTO_DOCUMENTS).pipe(
        map((action: claimsFileActions.SubmitAutoDocumentsAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient
                .setAutoClaimAttachments(ClaimsApiRequestFactory.createAutoAttachmentsRq(payload), claim.id)
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitAutoDocumentsSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.SubmitAutoDocumentsFailAction(error)))
                );
        })
    );

    @Effect()
    public submitAutoContacts$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_AUTO_CONTACT_INFO).pipe(
        map((action: claimsFileActions.SubmitAutoContactInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient.setAutoClaimContactInfo(payload, claim.id).pipe(
                map(claimsApiResponse => new claimsFileActions.SubmitAutoContactInfoSuccessAction(claimsApiResponse)),
                catchError(error =>
                    of(
                        new claimsFileActions.SubmitAutoContactInfoFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    /**
     * Auto ERS Claim Effects
     */
    @Effect()
    public submitErsBasicInfo$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_ERS_BASIC_INFO).pipe(
        map((action: claimsFileActions.SubmitErsBasicInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.claimsFileApiClient.setAutoErsClaimBasicInfo(ClaimsApiRequestFactory.createAutoErsBasicInfoRq(payload, user)).pipe(
                map(claimsApiResponse => new claimsFileActions.SubmitErsBasicInfoSuccessAction(claimsApiResponse)),
                catchError(error => of(new claimsFileActions.SubmitErsBasicInfoFailAction(error)))
            );
        })
    );

    @Effect()
    public updateErsBasicInfo$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.UPDATE_ERS_BASIC_INFO).pipe(
        map((action: claimsFileActions.UpdateErsBasicInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim), this.appState$.select(getUser)),
        switchMap(([payload, claim, user]) => {
            return this.claimsFileApiClient
                .updateAutoErsClaimBasicInfo(ClaimsApiRequestFactory.createAutoErsBasicInfoRq(payload, user), claim.id)
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.UpdateErsBasicInfoSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.UpdateErsBasicInfoFailAction(error)))
                );
        })
    );

    @Effect()
    public submitErsDetail$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_ERS_DETAIL).pipe(
        map((action: claimsFileActions.SubmitErsDetailAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient
                .setAutoErsClaimDetailInfo(ClaimsApiRequestFactory.createAutoErsDetailRq(payload, claim), claim.id)
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitErsDetailSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.SubmitErsDetailFailAction(error)))
                );
        })
    );

    @Effect()
    public submitErsDocuments$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_ERS_DOCUMENTS).pipe(
        map((action: claimsFileActions.SubmitErsDocumentsAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient
                .setAutoErsClaimAttachments(ClaimsApiRequestFactory.createErsAttachmentsRq(payload), claim.id)
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitErsDocumentsSuccessAction(claimsApiResponse)),
                    catchError(error => of(
                        new claimsFileActions.SubmitErsDocumentsFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
                );
        })
    );

    @Effect()
    public submitErsContacts$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_ERS_CONTACT_INFO).pipe(
        map((action: claimsFileActions.SubmitErsContactInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient.setAutoErsClaimContactInfo(payload, claim.id).pipe(
                map(claimsApiResponse => new claimsFileActions.SubmitErsContactInfoSuccessAction(claimsApiResponse)),
                catchError(error => of(new claimsFileActions.SubmitErsContactInfoFailAction(error)))
            );
        })
    );

    /**
     * Auto Glass Claim Effects
     */
    @Effect()
    public submitGlassBasicInfo$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_GLASS_BASIC_INFO).pipe(
        map((action: claimsFileActions.SubmitGlassBasicInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.claimsFileApiClient
                .setAutoGlassClaimBasicInfo(ClaimsApiRequestFactory.createAutoGlassBasicInfoRq(payload, user))
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitGlassBasicInfoSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.SubmitGlassBasicInfoFailAction(error)))
                );
        })
    );

    @Effect()
    public updateGlassBasicInfo$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.UPDATE_GLASS_BASIC_INFO).pipe(
        map((action: claimsFileActions.UpdateGlassBasicInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim), this.appState$.select(getUser)),
        switchMap(([payload, claim, user]) => {
            return this.claimsFileApiClient
                .updateAutoGlassClaimBasicInfo(ClaimsApiRequestFactory.createAutoGlassBasicInfoRq(payload, user), claim.id)
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.UpdateGlassBasicInfoSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.UpdateGlassBasicInfoFailAction(error)))
                );
        })
    );

    @Effect()
    public submitGlassDetail$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_GLASS_DETAIL).pipe(
        map((action: claimsFileActions.SubmitGlassDetailAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient
                .setAutoGlassClaimDetailInfo(ClaimsApiRequestFactory.createAutoGlassDetailRq(payload, claim), claim.id)
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitGlassDetailSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.SubmitGlassDetailFailAction(error)))
                );
        })
    );

    @Effect()
    public submitGlassContacts$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_GLASS_CONTACT_INFO).pipe(
        map((action: claimsFileActions.SubmitGlassContactInfoAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient.setAutoGlassClaimContactInfo(payload, claim.id).pipe(
                map(claimsApiResponse => new claimsFileActions.SubmitGlassContactInfoSuccessAction(claimsApiResponse)),
                catchError(error => of(new claimsFileActions.SubmitGlassContactInfoFailAction(error)))
            );
        })
    );

    /**
     * Property Claim Effects
     */
    @Effect()
    public submitPropertyBasicInfo$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.SUBMIT_PROPERTY_BASIC_INFO)
        .pipe(
            map((action: claimsFileActions.SubmitPropertyBasicInfoAction) => action.payload),
            withLatestFrom(this.appState$.select(getUser)),
            switchMap(([payload, user]) => {
                return this.claimsFileApiClient
                    .setPropertyClaimBasicInfo(ClaimsApiRequestFactory.createPropertyBasicInfoRq(payload, user))
                    .pipe(
                        map(claimsApiResponse => new claimsFileActions.SubmitPropertyBasicInfoSuccessAction(claimsApiResponse)),
                        catchError(error => of(new claimsFileActions.SubmitPropertyBasicInfoFailAction(error)))
                    );
            })
        );

    @Effect()
    public updatePropertyBasicInfo$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.UPDATE_PROPERTY_BASIC_INFO)
        .pipe(
            map((action: claimsFileActions.SubmitPropertyBasicInfoAction) => action.payload),
            withLatestFrom(this.appState$.select(getClaim), this.appState$.select(getUser)),
            switchMap(([payload, claim, user]) => {
                return this.claimsFileApiClient
                    .updatePropertyClaimBasicInfo(ClaimsApiRequestFactory.createPropertyBasicInfoRq(payload, user), claim.id)
                    .pipe(
                        map(claimsApiResponse => new claimsFileActions.UpdatePropertyBasicInfoSuccessAction(claimsApiResponse)),
                        catchError(error => of(new claimsFileActions.UpdatePropertyBasicInfoFailAction(error)))
                    );
            })
        );

    @Effect()
    public submitPropertyDetail$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_PROPERTY_DETAIL).pipe(
        map((action: claimsFileActions.SubmitPropertyDetailAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient
                .setPropertyClaimDetailInfo(ClaimsApiRequestFactory.createPropertyDetailInfoRq(payload, claim), claim.id)
                .pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitPropertyDetailSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.SubmitPropertyDetailFailAction(error)))
                );
        })
    );

    @Effect()
    public submitPropertyClientInfo$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.SUBMIT_PROPERTY_CLIENT_INFO)
        .pipe(
            map((action: claimsFileActions.SubmitPropertyClientInfoAction) => action.payload),
            withLatestFrom(this.appState$.select(getClaim)),
            switchMap(([payload, claim]) => {
                return this.claimsFileApiClient
                    .setPropertyClaimClientInfo(ClaimsApiRequestFactory.createPropertyClientInfoRq(payload), claim.id)
                    .pipe(
                        map(claimsApiResponse => new claimsFileActions.SubmitPropertyClientInfoSuccessAction(claimsApiResponse)),
                        catchError(error => of(new claimsFileActions.SubmitPropertyClientInfoFailAction(error)))
                    );
            })
        );

    @Effect()
    public submitPropertyDocuments$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.SUBMIT_PROPERTY_DOCUMENTS)
        .pipe(
            map((action: claimsFileActions.SubmitAutoDocumentsAction) => action.payload),
            withLatestFrom(this.appState$.select(getClaim)),
            switchMap(([payload, claim]) => {
                return this.claimsFileApiClient
                    .setPropertyClaimAttachments(ClaimsApiRequestFactory.createPropertyAttachmentsRq(payload), claim.id)
                    .pipe(
                        map(claimsApiResponse => new claimsFileActions.SubmitPropertyDocumentsSuccessAction(claimsApiResponse)),
                        catchError(error => of(
                            new claimsFileActions.SubmitPropertyDocumentsFailAction({
                                error: this.errorMsgService.getMsg(error)
                            })
                        )
                    )
                    );
            })
        );

    @Effect()
    public submitPropertyContacts$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.SUBMIT_PROPERTY_CONTACT_INFO)
        .pipe(
            map((action: claimsFileActions.SubmitPropertyContactInfoAction) => action.payload),
            withLatestFrom(this.appState$.select(getClaim)),
            switchMap(([payload, claim]) => {
                return this.claimsFileApiClient.setPropertyClaimContactInfo(payload, claim.id).pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitPropertyContactInfoSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.SubmitPropertyContactInfoFailAction(error)))
                );
            })
        );

    /**
     * Watercraft Claim Effects
     */
    @Effect()
    public submitWatercraftBasicInfo$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_BASIC_INFO)
        .pipe(
            map((action: claimsFileActions.SubmitWatercraftBasicInfoAction) => action.payload),
            withLatestFrom(this.appState$.select(getUser)),
            switchMap(([payload, user]) => {
                return this.claimsFileApiClient
                    .setWatercraftClaimBasicInfo(ClaimsApiRequestFactory.createWatercraftBasicInfoRq(payload, user))
                    .pipe(
                        map(claimsApiResponse => new claimsFileActions.SubmitWatercraftBasicInfoSuccessAction(claimsApiResponse)),
                        catchError(error => of(new claimsFileActions.SubmitWatercraftBasicInfoFailAction(error)))
                    );
            })
        );

    @Effect()
    public updateWatercraftBasicInfo$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.UPDATE_WATERCRAFT_BASIC_INFO)
        .pipe(
            map((action: claimsFileActions.UpdateWatercraftBasicInfoAction) => action.payload),
            withLatestFrom(this.appState$.select(getClaim), this.appState$.select(getUser)),
            switchMap(([payload, claim, user]) => {
                return this.claimsFileApiClient
                    .updateWatercraftClaimBasicInfo(ClaimsApiRequestFactory.createWatercraftBasicInfoRq(payload, user), claim.id)
                    .pipe(
                        map(claimsApiResponse => new claimsFileActions.UpdateWatercraftBasicInfoSuccessAction(claimsApiResponse)),
                        catchError(error => of(new claimsFileActions.UpdateWatercraftBasicInfoFailAction(error)))
                    );
            })
        );

    @Effect()
    public submitWatercraftVehicleInfo$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_VEHICLE_INFO)
        .pipe(
            map((action: claimsFileActions.SubmitWatercraftVehicleInfoAction) => action.payload),
            withLatestFrom(this.appState$.select(getClaim)),
            switchMap(([payload, claim]) => {
                return this.claimsFileApiClient.setWatercraftClaimVehicleInfo(payload, claim.id).pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitWatercraftVehicleInfoSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.SubmitWatercraftVehicleInfoFailAction(error)))
                );
            })
        );

    @Effect()
    public submitWatercraftClientInfo$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_CLIENT_INFO)
        .pipe(
            map((action: claimsFileActions.SubmitWatercraftClientInfoAction) => action.payload),
            withLatestFrom(this.appState$.select(getClaim)),
            switchMap(([payload, claim]) => {
                return this.claimsFileApiClient
                    .setWatercraftClaimClientInfo(ClaimsApiRequestFactory.createClientRq(payload, (<AutoClaim>claim).autoDetail), claim.id)
                    .pipe(
                        map(claimsApiResponse => new claimsFileActions.SubmitWatercraftClientInfoSuccessAction(claimsApiResponse)),
                        catchError(error => of(new claimsFileActions.SubmitWatercraftClientInfoFailAction(error)))
                    );
            })
        );

    @Effect()
    public submitWatercraftDetail$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_DETAIL).pipe(
        map((action: claimsFileActions.SubmitWatercraftDetailAction) => action.payload),
        withLatestFrom(this.appState$.select(getClaim)),
        switchMap(([payload, claim]) => {
            return this.claimsFileApiClient.setWatercraftClaimVehicleInfo(payload, claim.id).pipe(
                map(claimsApiResponse => new claimsFileActions.SubmitWatercraftDetailSuccessAction(claimsApiResponse)),
                catchError(error => of(new claimsFileActions.SubmitWatercraftDetailFailAction(error)))
            );
        })
    );

    @Effect()
    public submitWatercraftContacts$: Observable<Action> = this.actions$
        .ofType(claimsFileActions.ActionTypes.SUBMIT_WATERCRAFT_CONTACT_INFO)
        .pipe(
            map((action: claimsFileActions.SubmitWatercraftContactInfoAction) => action.payload),
            withLatestFrom(this.appState$.select(getClaim)),
            switchMap(([payload, claim]) => {
                return this.claimsFileApiClient.setWatercraftClaimContactInfo(payload, claim.id).pipe(
                    map(claimsApiResponse => new claimsFileActions.SubmitWatercraftContactInfoSuccessAction(claimsApiResponse)),
                    catchError(error => of(new claimsFileActions.SubmitWatercraftContactInfoFailAction(error)))
                );
            })
        );

    /**
     * General Claims Effects
     */

    @Effect()
    public getPoliciesByCustIdAndDate$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.FIND_POLICY).pipe(
        map((action: claimsFileActions.FindPolicyAction) => action.payload),
        withLatestFrom(this.appState$.select(getUser)),
        switchMap(([payload, user]) => {
            return this.policyApiClient.getPoliciesByCustIdByDate(user.custId.toString(), payload.lossDate).pipe(
                map(policies => {
                    return new claimsFileActions.FindPolicySuccessAction(policies);
                }),
                catchError(error =>
                    of(
                        new claimsFileActions.FindPolicyFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );

    @Effect()
    public setSelectedPolicy$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.SELECT_POLICY).pipe(
        map((action: claimsFileActions.SelectPolicyAction) => action.payload),
        map(policies => new claimsFileActions.SelectPolicySuccessAction(policies))
    );

    @Effect()
    public completeClaim$: Observable<Action> = this.actions$.ofType(claimsFileActions.ActionTypes.COMPLETE_CLAIM).pipe(
        map((action: claimsFileActions.CompleteClaimAction) => action.payload),
        switchMap(id => {
            return this.claimsFileApiClient.completeClaim(id).pipe(
                mergeMap(claimsApiResponse => [
                    new claimsFileActions.CompleteClaimSuccessAction(claimsApiResponse),
                    new ReloadClaimsHistoryAction()
                ]),
                catchError(error =>
                    of(
                        new claimsFileActions.CompleteClaimFailAction({
                            error: this.errorMsgService.getMsg(error)
                        })
                    )
                )
            );
        })
    );
}
