export class AutoAddDriverApiRequest {
    public customerId: string;
    public driver: {
        firstName: string,
        lastName: string,
        dateOfBirth: string,
        gender: string,
        maritalStatusCd: string,
        maritalStatusDesc: string,
        licenseStateCd: string,
        licenseStateDesc: string,
        licenseNbr: string
    };
    public relationToInsured: string;
    public primaryVehicle: {
        year: string,
        makeCd: string,
        makeDesc: string,
        modelCd: string,
        modelDesc: string
    };
    public inInsuredHousehold: string;
    public continuousCoverage: string;
    public licSuspRevoked: string;
    public licSuspRevokedWhen: number;
    public licSuspRevokedWhy: string;
    public drvForfeitConvicted: string;
    public vehStolenBurned: string;
    public accLastThreeYears: string;
    public insCancelRejected: string;

    constructor(init?: Partial<AutoAddDriverApiRequest>) {
        Object.assign(this, init);
    }
}
