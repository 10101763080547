import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Document } from '../../models';
import { DocumentList } from '../../models/document/document-list.model';
import { Adapter, Body, GET, HttpService, Path, POST, PUT } from '../http';

@Injectable()
export class DocumentApiClient extends HttpService {
  /**
   *    API Finds DocumentList and Document Contents
   */

  @GET('document/search/policy/{policyNbr}',false)
  //   @Adapter(ClientService.clientContactAdapter)
  public getPCPolicyDocuments(
    @Path('policyNbr') policyNbr: string
  ): Observable<DocumentList> {
    return null;
  }

  @GET('document/search/membership/{memberNbr}',false)
  //   @Adapter(ClientService.clientContactAdapter)
  public getMembershipDocuments(
    @Path('memberNbr') memberNbr: string
  ): Observable<DocumentList> {
    return null;
  }

  @GET('document/search/lifepolicy/{policyNbr}',false)
  //   @Adapter(ClientService.clientContactAdapter)
  public getLFPolicyDocuments(
    @Path('policyNbr') policyNbr: string
  ): Observable<DocumentList> {
    return null;
  }

  //    @Get('document/search/lifepolicy/{policyNbr}')
  // //   @Adapter(ClientService.clientContactAdapter)
  //   public getLFPolicyDocumentd(@Path('policyNbr') policyNbr: string): Observable<Document []> { return null; }
  //    @Get('document/search/lifepolicy/{policyNbr}')
  // //   @Adapter(ClientService.clientContactAdapter)
  //   public getLFPolicyDocumentd(@Path('policyNbr') policyNbr: string): Observable<Document []> { return null; }
}
