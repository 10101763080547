import {formatDate} from '@angular/common';
import { Injectable } from '@angular/core';
import { PolicyConstants } from '../../constants';
import { Access, AutoPolicy, Coverage, Driver, PolicyAccess, PolicyBase, PolicyResponse, PropertyPolicy } from '../../models';

@Injectable()
export class PolicyService {
    public static getPolicyBannerBackground(policy) {
        const background = PolicyConstants.policyCd[policy.policyTypeCd];

        let backgroundPath = 'assets/img/view-policy/banner/generic.jpg';
        if (background) {
            backgroundPath = 'assets/img/view-policy/banner/' + background + '.jpg';
        }
        return backgroundPath;
    }

    public static policiesAdapter(response: any): PolicyResponse {
        try {
            const result = new PolicyResponse();
            if (!response.policies) {
                return result;
            }
            result.policies = response.policies.map(policy => {
                return PolicyService.policyAdapter(policy);
            });
            if (response.alerts) {
                result.alerts = response.alerts.map(alert => alert);
            } else {
                result.alerts = [];
            }
            return result;
        } catch (e) {
            console.error('Exception caught');
            console.error(e);
            return null;
        }
    }

    public static policyAdapter(response: any): PolicyBase {
        try {
            const x: PolicyBase = response;

            if(x.policyAccess === undefined){
                x.policyAccess = new PolicyAccess({
                    EDIT_PAPERLESS: {
                        allowed: false,
                        reason: 'Missing Access'
                    },
                    ENROLL_PAPERLESS: {
                        allowed: false,
                        reason: 'Missing Access'
                    },
                    FILE_CLAIM: {
                        allowed: false,
                        reason: 'Missing Access'
                    },
                    POLICY_CHANGES: {
                        allowed: false,
                        reason: 'Missing Access'
                    },
                    VIEW_DOCUMENTS: {
                        allowed: false,
                        reason: 'Missing Access'
                    },
                });
            }
            x.policyAccess.VIEW_DOCUMENTS = new Access({ allowed: true });
            x.translatedPolicyType = PolicyConstants.translatePolicyType(x.policyTypeCd);
            x.sortCatagory = PolicyConstants.translatePolicyCategory(x.policyTypeCd);
            if (
                (x.translatedPolicyType === "COMM" ||x.translatedPolicyType ==='commercial') && x.sourceSystem==='GUIDEWIRE' &&
                (x.policyTypeDesc === "Commercial Package" ||
                    x.policyTypeDesc === "Commercial Property") &&
                x.hasOwnProperty("generalLiabilityPart")
            ) {
                x["generalLiabilityPart"].sublines.forEach(subline=>{
                    if(subline.coverages)
                    subline.coverages = PolicyService.setGeneralLiabilityCoverageOrder(subline.coverages
                    );
                    if(subline.locations && subline.locations.length>0){
                        subline.locations.forEach((location)=>{
                    if(location.coverages && location.coverages.length>0){
                    location.coverages = PolicyService.setGeneralLiabilityCoverageOrder(location.coverages)
                }
                })
            }
                })
                if(x['generalLiabilityPart'].lineCoverages && x['generalLiabilityPart'].lineCoverages.length>0){
                    x['generalLiabilityPart'].lineCoverages = PolicyService.setGeneralLiabilityCoverageOrder(x['generalLiabilityPart'].lineCoverages)
                }
                if(x['generalLiabilityPart'].exposures && x['generalLiabilityPart'].exposures.length>0 ) {
                    x['generalLiabilityPart'].exposures.forEach((exposure)=>{
                        if(exposure.coverages && exposure.coverages.length>0)
                        exposure.coverages = PolicyService.setGeneralLiabilityCoverageOrder(exposure.coverages)
                    })       
                }           
            }
            PolicyService.setDisplayDue(x);
            if (x.clients) {
                x.primaryInsured = x.clients.filter(e => (e.hasOwnProperty('roles') ? e.roles.PI === 'Primary Insured' : ''))[0];
                x.owner = x.clients.filter(e => (e.hasOwnProperty('roles') ? e.roles.OWN === 'Owner' : ''))[0];
                x.payor = x.clients.filter(e => (e.hasOwnProperty('roles') ? e.roles.PO === 'Payor' : ''))[0];
            }

            if (x.translatedPolicyType === 'property') {
                if ((<PropertyPolicy>x).dwellings) {
                    (<PropertyPolicy>x).dwellings.forEach(d => {
                        d.barns = [];
                        d.farmEquipment = [];
                        d.misc = [];
                        /* get extended coverage and farmEquipment from extendedCovarage object */
                        if (d.extendedCoverages) {
                            d.extendedCoverages.forEach(ec => {
                                const typ = ec.type;
                                if (typ === 'FARM_AND_PERSONAL_PROPERTY') {
                                    d.farmEquipment.push(ec);
                                } else if (typ === 'BARNS_AND_OTHER_STRUCTURES') {
                                    d.barns.push(ec);
                                } else if (typ === 'MISCELLANEOUS') {
                                    d.misc.push(ec);
                                }
                            });
                        }
                        d.ghiForms = [];
                        if (d.forms) {
                            for (let j = 0; j < d.forms.length; j++)  {
                                let form = d.forms[j];
                                if ('/^(TF26|TF27|TF28)$/'.match(form.id) && d.ghiForms.indexOf(form.id) === -1) {
                                    d.ghiForms.push(form.id);
                                }
                            }
                        }
                    });
                }
            }

            if (x.translatedPolicyType === 'auto' || x.translatedPolicyType === 'commercialauto' || x.translatedPolicyType === 'watercraft') {
                PolicyService.ProcessDrivers(<AutoPolicy>x);
            }

            if (x.translatedPolicyType === 'life') {
                x.policyAccess.VIEW_DOCUMENTS.allowed = false;
            }

            if (x.effectiveDt && x.expirationYear) {
                const monthDay = x.effectiveDt.slice(-5); // MM-DD
                const expirationDate = `${x.expirationYear}-${monthDay}`;
                x.displayExpiration = expirationDate;
            }

            return x;
        } catch (e) {
            console.error('Exception caught');
            console.error(e);
            return null;
        }
    }

    public static ProcessDrivers(policy: AutoPolicy) {
        for (const veh in policy.vehicles) {
            // Driver number of 0 means trailer, or not assigned
            if (policy.drivers) {
                if (policy.vehicles[veh].driverNbr === '0') {
                    const driver = new Driver({
                        number: '0',
                        /* firstName: 'Not Applicable',*/
                        /*If no driver is returned then we need to show blank instaed of Not Applicable as discuseed in 8/10/2016 call*/
                        firstName: '',
                        lastName: ''
                    });
                    policy.vehicles[veh].driver = driver;
                } else {
                    for (const vehDriver in policy.drivers) {
                        if (policy.drivers[vehDriver].number === policy.vehicles[veh].driverNbr) {
                            /*Match vehicle's driverNbr with number in driver's array*/
                            policy.vehicles[veh].driver = policy.drivers[vehDriver];
                        }
                    }
                }
            }
        }
    }

    public static setDisplayDue(item: any) {
        // adding a totalDue key that adds the current & past due amount
        item.billing = item.billing || {};
        item.billing.displayDue = {};
        if (item.billing.billedToCd && item.billing.billedToCd === 'M') {
            item.billing.displayDue.billToMortgage = true;
        }

        item.billing.displayDue.label = 'Amount Due';
        item.billing.displayDue.amount = null;
        item.billing.displayDue.whenText = null;
        item.billing.displayDue.planDescText = item.billing.billPlanDesc ? item.billing.billPlanDesc + ' Payment of ' : '';

        // billingSortOrder is used for the billing landing page.
        // status color is set via sass in pages/policy-item.scss

        let currentDue = null;

        if (item.billing.currentDue && item.billing.currentDue.length > 0) {
            currentDue = PolicyService.FindOldestAmountDue(item.billing.currentDue);
        }

        if (item.systemId === 'PC' && currentDue && currentDue.dueAmt > 0) {
            PolicyService.combineDueAmounts(currentDue, item.billing);
        }

        if (
            (item.sourceSystem  === 'SERIES3' || item.sourceSystem  === undefined || item.sourceSystem  === '') &&
            ((item.systemId === 'PC' && !item.billing.policyStatusCd) ||
            (item.billing.policyStatusCd && !item.billing.policyStatusCd.match(/^(X|O|P)$/)))
        ) {
            item.billing.displayDue.billingSortOrder = 2;
            item.billing.displayDue.planDescText = 'Please contact our Customer Service Center at 1-877-876-2222.';
            item.billing.displayDue.statusColor = 'billing-past';
            item.billing.displayDue.whenDate = undefined;
        } else if (currentDue && currentDue.dueAmt > 0 && item.billing.billPlanDesc !== 'EFT') {
            // Current Due - Red
            // Display: Direct Bill Payment of $$$$ due on January 24, 2018
            item.billing.displayDue.billingSortOrder = 1;
            item.billing.displayDue.amount = currentDue.dueAmt;
            item.billing.displayDue.whenText = 'due on ';
            item.billing.displayDue.whenDate = currentDue.dueDate;
            item.billing.displayDue.statusColor = 'billing-current';
        } else if (currentDue && currentDue.dueAmt > 0 && item.billing.billPlanDesc === 'EFT') {
            // EFT Payment currently due - Red
            // Display: EFT Payment of $$$ scheduled on January 24, 2018
            item.billing.displayDue.billingSortOrder = 1;
            item.billing.displayDue.planDescText = 'EFT payment of ';
            item.billing.displayDue.amount = currentDue.dueAmt;
            item.billing.displayDue.whenText = ' scheduled on ';
            item.billing.displayDue.whenDate = currentDue.dueDate;
            item.billing.displayDue.statusColor = 'billing-current';
            item.billing.displayDue.isEFT = true;
        } else if (item.billing.pastDueAmt) {
            // Past Due - Red
            // Display: Payment of $$$ past due.
            item.billing.displayDue.amount = item.billing.pastDueAmt;
            item.billing.displayDue.whenText = 'past due';
            item.billing.displayDue.statusColor = 'billing-past';
            item.billing.displayDue.billingSortOrder = 0;
            item.billing.displayDue.whenDate = undefined;
            item.billing.displayDue.planDescText = undefined;
            if (item.billing.billPlanDesc === 'EFT') {
                item.billing.displayDue.isEFT = true;
            }
        } else if (item.billing.nextInvoiceAmt && item.billing.nextInvoiceAmt > 0 && item.billing.billPlanDesc === 'EFT') {
            // EFT Payment next invoice - Scheduled
            // Display: Next EFT installment is scheduled January 24, 2018
            item.billing.displayDue.billingSortOrder = 2;
            item.billing.displayDue.planDescText = 'Next EFT installment is scheduled ';
            item.billing.displayDue.amount = undefined;
            // This is a flag sepcific for EFT, so that we can turn on the Pay Bill button, otherwise, with
            // displayDue.amount = undefined, the button will be disabled.
            item.billing.displayDue.isEFT = true;
            item.billing.displayDue.isEFTNextInstallment = true;
            item.billing.displayDue.whenDate = item.billing.nextInvoiceDueDate;
            item.billing.displayDue.statusColor = 'billing-scheduled';
        } else if (item.billing.nextInvoiceAmt && item.billing.nextInvoiceAmt > 0) {
            // Next Invoice Date
            // Display: Direct Bill Payment of $$$$ due on January 24, 2018
            item.billing.displayDue.billingSortOrder = 1;
            item.billing.displayDue.amount = item.billing.nextInvoiceAmt;
            item.billing.displayDue.whenText = 'due on ';
            item.billing.displayDue.whenDate = item.billing.nextInvoiceDueDate;
            item.billing.displayDue.statusColor = 'billing-default';
        } else if (item.billing.policyBalance) {
            // Policy Balance - Red
            // Display: $$$ (Display only amount)
            item.billing.displayDue.billingSortOrder = 2;
            item.billing.displayDue.label = 'Balance';
            item.billing.displayDue.amount = item.billing.policyBalance;
            item.billing.displayDue.statusColor = 'billing-current';
            item.billing.displayDue.whenDate = undefined;
            item.billing.displayDue.planDescText = undefined;
        } else {
            // Display: $0.00
            item.billing.displayDue.billingSortOrder = 3;
            item.billing.displayDue.amount = 0; // formatCurrency will format to $0.00
            item.billing.displayDue.statusColor = 'billing-default';
            item.billing.displayDue.whenDate = undefined;
            item.billing.displayDue.planDescText = undefined;
        }

        // Push membership to the last.
        if (item.policyTypeCd === 'MEMB') {
            item.billing.displayDue.billingSortOrder = 4;
        }
    }

    public static FindOldestAmountDue(dueArray) {
        let ret = 0;

        for (let i = 0; i < dueArray.length; i++) {
            if (dueArray[i].dueDate < dueArray[ret].dueDate) {
                ret = i;
            }
        }

        return dueArray[ret];
    }

    public static combineDueAmounts(currentDue, billing) {
        const today = formatDate(new Date(), 'yyyy-MM-dd', 'en-US', 'America/Chicago');

        if (currentDue.dueDate < today) {
            billing.pastDueAmt = (billing.pastDueAmt ? currentDue.dueAmt + billing.pastDueAmt : currentDue.dueAmt);
            billing.pastDueDate = currentDue.dueDate;
            currentDue.dueAmt = 0;
            currentDue.dueDate = '';
        } else {
            currentDue.dueAmt = (billing.pastDueAmt ? currentDue.dueAmt + billing.pastDueAmt : currentDue.dueAmt);
            billing.pastDueAmt = 0;
            billing.pastDueDate = '';
        }
    }

    public static getPolicyDetailsByDate_Adapter(data): PolicyBase {
        const date = new Date();
        data.mDetail = true;
        data.mDate = date ? date : PolicyConstants.NO_DATE;
        data.canFileClaim = data.policyAccess.FILE_CLAIM.allowed;

        if (data.expDate) {
            data.policyExpirationDateObject = new Date(data.expDate);
        }

        if (data.policyTypeCd) {
            data.type = PolicyConstants.policyCd[data.policyTypeCd];
            data.translatedPolicyType = PolicyConstants.translatePolicyType(data.policyTypeCd);
        } else {
            //  console.info('Service policy: getDetails() : policyTypeCd not found, using default policy type');
        }

        // Per Communications, Annuity Life should display as 'Annuity'
        if (data.policyTypeCd === 'ANNY') {
            data.policyTypeDesc = 'Annuity';
        }
        return data;
    }

    public static getPoliciesByCustIdByDate_Adapter(data): PolicyBase[] {
        const returnData: PolicyBase[] = [];
        data.policies.forEach(pol => {
            const temp = PolicyService.getPolicyDetailsByDate_Adapter(pol);
            returnData.push(temp);
        });
        return returnData;
    }
    
    public static setGeneralLiabilityCoverageOrder(
        coverages: any
    ): any {
            const sortOrder = (code) => {
                switch (code) {
                                case "GL7Lmts":
                                   return 1;
                                case "GL7Deds":
                                    return 2;
                                case "GL7MedPay":
                                    return 3;
                                case "GL7DamageToPremisesRentedToYou":
                                    return 4;
                                default:
                                    return 5;
                            }
            }
            coverages.sort((b, a) => {
                const aCode = sortOrder(a.code)
                const bCode = sortOrder(b.code)
                if (aCode === bCode) {
                    return 0;
                } else if (aCode < bCode) {
                    return 1;
                } else {
                    return -1;
                }
            });
        return coverages;
    }
}

