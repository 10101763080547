import { Injectable } from '@angular/core';
import { SecurityQuestionsGroup } from '../../models/register/security-questions-group.model';
import { User } from '../../models';

@Injectable()
export class MyProfileService {
    public static questionGroupAdapter(questions: SecurityQuestionsGroup[]): SecurityQuestionsGroup[] {
        return questions;
    }
    public static userAdapter(user: User): User {
        return user;
    }
}
