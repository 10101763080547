import { Pipe, PipeTransform } from '@angular/core';
import { PolicyBase } from '../../shared/models';

@Pipe({
    name: 'excludeMembership'
})
export class ExcludeMembershipPipe implements PipeTransform {
    public transform(policies: PolicyBase[]): any {
        if (!policies) { return policies; }
        return policies.filter(policy => policy.policyTypeCd !== 'MEMB');
    }
}
