import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorMsgService {

    public messages = [
        { api: 'auto', errorCode: 400100, message: 'step cannot come before' },
        { api: 'auto', errorCode: 400400, message: 'step cannot come after' },
        { api: 'auto', errorCode: 400101, message: 'You have already completed this step. Please continue.' },
        { api: 'auto', errorCode: 400102, message: 'Sorry, we cannot complete your request at this time. Please contact a Farm Bureau agent to continue.' },
        { api: 'auto', errorCode: 400103, message: 'Sorry, we cannot complete your request at this time. Please contact a Farm Bureau agent to continue.' },
        { api: 'autochange', errorCode: 400201, message: 'Sorry, we cannot complete your request at this time. Please contact a Farm Bureau agent to continue.' },
        { api: 'myaccount', errorCode: 400001, message: 'Your username or password is incorrect.' },
        { api: 'myaccount', errorCode: 400003, message: 'Your account has been locked.  Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'myaccount', errorCode: 400004, message: 'You have been denied access.  Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'myaccount', errorCode: 400005, message: 'Your session has timed out. Please try again later.' },
        { api: 'myaccount', errorCode: 400006, message: 'Sorry, we cannot complete your request at this time. Please try again later.' },
        { api: 'myaccount', errorCode: 400007, message: 'Policy number not found.' },
        { api: 'myaccount', errorCode: 400008, message: 'Policy number not active.' },
        { api: 'myaccount', errorCode: 400009, message: 'The zip code you entered does not match what we have on file.' },
        { api: 'myaccount', errorCode: 400010, message: 'Your social security number or date of birth is already registered on another account. Please use the forgot username link to find the account.' },
        { api: 'myaccount', errorCode: 400011, message: 'The social security number or date of birth you entered does not match what we have on file.' },
        { api: 'myaccount', errorCode: 400012, message: 'We found two customers matching your social security number or date of birth. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'myaccount', errorCode: 400013, message: 'Sorry, we cannot complete your request at this time. Please try again later.' },
        { api: 'myaccount', errorCode: 400015, message: 'This username is already taken. Please try again.' },
        { api: 'myaccount', errorCode: 400016, message: 'The forgot password link has expired.  Please click forgot password again to request a new link.' },
        { api: 'myaccount', errorCode: 400017, message: 'We found multiple customers with the same social security number or date of birth but with different data. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'myaccount', errorCode: 400018, message: 'Sorry, we cannot complete your request at this time. Please try again later.' },
        { api: 'myaccount', errorCode: 400019, message: 'Sorry, we cannot complete your request at this time. Please try again later.' },
        { api: 'myaccount', errorCode: 400020, message: 'Your paperless change has already been verified.' },
        { api: 'myaccount', errorCode: 400021, message: 'Authentication has already been completed at login.' },
        { api: 'myaccount', errorCode: 400022, message: 'This reset security request has timed out.  Please contact a Customer Service Representative at 877-876-2222 for help.'},
        { api: 'myaccount', errorCode: 400023, message: 'The reset security link has expired. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'myaccount', errorCode: 400025, message: 'Your temporary passord has expired.  Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'myaccount', errorCode: 400026, message: 'This forgot password request has timed out.  Please click forgot password again to start over.'},
        { api: 'myaccount', errorCode: 400028, message: 'This forgot password request has already been completed.  Please click forgot password again to start over.'},
        { api: 'myaccount', errorCode: 400029, message: 'This reset security password request has already been completed.  Please contact a Customer Service Representative at 877-876-2222 for help.'},
        { api: 'myaccount', errorCode: 400031, message: 'Please use a homeowner, auto, or life policy to register.'},
        { api: 'myaccount', errorCode: 400033, message: 'This policy is already set up for paperless.'},
        { api: 'myaccount', errorCode: 400035, message: 'This link is no longer valid. To finish setting up paperless for this policy, please contact your agent.'},
        { api: 'billing', errorCode: 400001, message: 'Payments are not allowed on this policy at this time.  Please contact your local office or a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'billing', errorCode: 400002, message: 'The ZIP code you entered doesn\'t match what we have on file. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'billing', errorCode: 400006, message: 'Before enrolling in auto pay, the minimum payment on your policy must be paid.' },
        { api: 'billing', errorCode: 400007, message: 'This payment method cannot be deleted because it is still being used for auto pay. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'claims', errorCode: 404100, message: 'The claim number you entered cannot be found.' },
        { api: 'claims', errorCode: 400100, message: 'Sorry, we cannot complete your request at this time. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'life', errorCode: 400100, message: 'step cannot come before' },
        { api: 'life', errorCode: 400400, message: 'step cannot come after' },
        { api: 'life', errorCode: 400101, message: 'You have already completed this step. Please continue.' },
        { api: 'life', errorCode: 400102, message: 'Sorry, we cannot complete your request at this time. Please contact a Farm Bureau agent to continue.' },
        { api: 'life', errorCode: 400103, message: 'Sorry, we cannot complete your request at this time. Please contact a Farm Bureau agent to continue.' },
        { api: 'myaccount', errorCode: 404001, message: 'Sorry, this customer information cannot be found. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'myaccount', errorCode: 404004, message: 'Sorry, we cannot complete your request at this time. Please try again later.' },
        { api: 'autochange', errorCode: 404201, message: 'Sorry, we cannot complete your request at this time. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'autochange', errorCode: 409201, message: 'Sorry, we cannot complete your request at this time. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'billing', errorCode: 409201, message: 'Sorry, we cannot complete your request at this time. Please contact a Customer Service Representative at 877-876-2222 for help.' },
        { api: 'claims', errorCode: 422999, message: 'Sorry, the file type you are trying to upload is not supported.' }
    ];

    public static defaultError = 'Oops! It looks like we are having some technical issues. If you need assistance, please contact our Customer Service Center at 877-876-2222.';



    public static defaultValidationErrMsg = 'Some of the information you entered is invalid. Please try again.';

    // public getMsg = function (response, url) {

    //     if (response.statusCode < 500 &&
    //         response.apiErrorList &&
    //         response.apiErrorList.length > 0) {
    //         let apiError = response.apiErrorList[0];

    //         if (apiError.errorCode) {
    //             let msg = this.lookupMsg(apiError.errorCode, url);
    //             if (msg) { return msg; }
    //         }
    //         if (response.apiErrorList[0].message) {
    //             if (!response.apiErrorList[0].message.includes('No message available')) {
    //                 return response.apiErrorList[0].message;
    //             }
    //         }
    //     }
    // };

    public getMsg(response) {
        if(response.status === 401 || response.status === 403){
            console.log("Session error:", response);
            setTimeout(() => {
                sessionStorage.clear();
                location.reload();
            }, 500);
            return "Session Error. Reloading..."
        }

        const contentType = response.headers.get('Content-type');

        if (response._body && contentType && contentType.startsWith('application/json')) {
            const data = response.json();

            if (data.statusCode < 500 &&
                data.apiErrorList &&
                data.apiErrorList.length > 0) {
                const apiError = data.apiErrorList[0];

                if (apiError.errorCode === 422000) {
                    this.lookupFieldErrorMsg(apiError);
                }

                if (apiError.errorCode) {
                    const msg = this.lookupMsg(apiError.errorCode, response.url);
                    if (msg) { return msg; }
                }
                if (data.apiErrorList[0].message) {
                    if (!data.apiErrorList[0].message.includes('No message available')) {
                        return data.apiErrorList[0].message;
                    }
                }
            }
        }
        return ErrorMsgService.defaultError;
    };


    private lookupFieldErrorMsg (apiError) {
        if (apiError.fieldErrorList && apiError.fieldErrorList.length > 0) {
            for (const i of apiError.fieldErrorList) {
                switch (i.error) {
                    case 'is not a valid mime type': {
                        apiError.errorCode = 422999;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }
    }

    public lookupMsg(errorCode, url) {
        const api = url.split(environment.api.baseUrl)[1].split('/')[0];

        const err = this.messages.find(function (msg) { return msg.api === api && msg.errorCode === errorCode; });
        if (err) { return err.message; }
        return null;
    };

    public getDefaultMsg() {
        return ErrorMsgService.defaultError;
    }
}
