import { createSelector } from '@ngrx/store';
import { State } from '../..';
import * as fromAutoSupport from '../../reducers/auto/auto-support.reducer';

export const getAutoSupport = (state: State) => state.autoSupport;

export const getAutoCancelReasons = createSelector(
    getAutoSupport,
    fromAutoSupport.getAutoCancelReasons
);

export const getAutoCancelReasonsLoading = createSelector(
    getAutoSupport,
    fromAutoSupport.getAutoCancelReasonsLoading
);
export const getAutoCancelReasonsLoaded = createSelector(
    getAutoSupport,
    fromAutoSupport.getAutoCancelReasonsLoaded
);

export const getAutoSupportLoading = createSelector(
    getAutoSupport,
    fromAutoSupport.getAutoSupportLoading
);
