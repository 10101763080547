import { HammerGestureConfig } from '@angular/platform-browser';

declare var Hammer: any;

export class MyHammerConfig extends HammerGestureConfig {
    public buildHammer(element: HTMLElement) {
        const mc = new Hammer(element, {
            touchAction: 'pan-y'
        });
        return mc;
    }
}
