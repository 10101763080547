import { Component, OnDestroy, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from './analytics/goolge/google-analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public title = 'Farm Bureau Insurance';

    public portalMaintenance = false;

    private maintCheckTimeout = 1000 * 60 * 5;
    private iplTimes: IplTimes[] = [
    {
        startDowntime: new Date('Jan 13, 2024 17:00:00 CST'),
        stopDowntime: new Date('Jan 13, 2024 18:00:00 CST')
    },
    {
        startDowntime: new Date('Feb 10, 2024 17:00:00 CST'),
        stopDowntime: new Date('Feb 10, 2024 18:00:00 CST')
    },
    {
        startDowntime: new Date('Mar 9, 2024 17:00:00 CST'),
        stopDowntime: new Date('Mar 9, 2024 18:00:00 CST')
    },
    {
        startDowntime: new Date('Apr 13, 2024 17:00:00 CDT'),
        stopDowntime: new Date('Apr 13, 2024 18:00:00 CDT')
    },
    {
        startDowntime: new Date('May 11, 2024 17:00:00 CDT'),
        stopDowntime: new Date('May 11, 2024 18:00:00 CDT')
    },
    {
        startDowntime: new Date('June 8, 2024 17:00:00 CDT'),
        stopDowntime: new Date('June 8, 2024 18:00:00 CDT')
    },
    {
        startDowntime: new Date('July 13, 2024 17:00:00 CDT'),
        stopDowntime: new Date('July 13, 2024 18:00:00 CDT')
    },
    {
        startDowntime: new Date('Aug 10, 2024 17:00:00 CDT'),
        stopDowntime: new Date('Aug 10, 2024 18:00:00 CDT')
    },
    {
        startDowntime: new Date('Sep 14, 2024 17:00:00 CDT'),
        stopDowntime: new Date('Sep 14, 2024 18:00:00 CDT')
    },
    {
        startDowntime: new Date('Oct 12, 2024 17:00:00 CDT'),
        stopDowntime: new Date('Oct 12, 2024 18:00:00 CDT')
    },
    {
        startDowntime: new Date('Nov 2, 2024 17:00:00 CDT'),
        stopDowntime: new Date('Nov 2, 2024 19:00:00 CDT')
    },
    {
        startDowntime: new Date('Dec 14, 2024 17:00:00 CST'),
        stopDowntime: new Date('Dec 14, 2024 18:00:00 CST')
    }
];

    constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

    public ngOnInit() {
        // subscribe to the ga posts
        this.googleAnalyticsService.subscribe();
        this.checkForPortalMaintenance();
    }
    public ngOnDestroy() {
        // unsubscribe to the post
        this.googleAnalyticsService.unsubscribe();
    }

    private checkForPortalMaintenance() {
        const currDate = new Date();
        const iplTime = this.getIplTimesForMonth(currDate);
        if (iplTime === undefined) {
            return;
        } else {
            if (currDate > iplTime.startDowntime && currDate < iplTime.stopDowntime) {
                this.portalMaintenance = true;
            } else {
                this.portalMaintenance = false;
            }
            setTimeout(() => this.checkForPortalMaintenance(), this.maintCheckTimeout);
        }
    }

    private getIplTimesForMonth(currDate: Date): IplTimes {
        if (currDate.getFullYear() > 2024) {
            return undefined;
        } else {
            return this.iplTimes[currDate.getMonth()];
        }
    }
}

interface IplTimes {
    startDowntime: Date;
    stopDowntime: Date;
}
