import { DeviceInfo } from './deviceInfo.model';
import { SecurityQuestions } from './securityQuestions.model';

export class ChallengeApiRequest {
  public questions?: SecurityQuestions[];
  public bind?: boolean;
  public id?: string;
  public deviceInfo?: DeviceInfo;

  constructor(init?: Partial<ChallengeApiRequest>) {
    if (!init) {
      init = {};
    }

    this.questions = init.questions || null;
    this.bind = init.bind || false;
    this.id = init.id || null;
    this.deviceInfo = init.deviceInfo || null;
  }
}
