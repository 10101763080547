import { createSelector } from '@ngrx/store';
import { State } from '../..';

import * as fromClient from '../../reducers/client';

export const getClientState = (state: State) => state.client;
export const getClientContacts = createSelector(getClientState, fromClient.getClientContactsData);

export const getClientError = createSelector(getClientState, fromClient.getClientContactsError);

export const getClientDataLoading = createSelector(getClientState, fromClient.getClientDataLoading);
