import { Dwelling } from '../policy/property/dwelling.model';
import { ClaimClient } from './claimClient.model';

export class ClaimProperty extends Dwelling {
    public claimClients: ClaimClient[];
    public propertyKey: string;
    public buildingNbr: string;
    public unitTypeCd: string;
    public unitDesc: string;
    public lossInfoId: string;
    public damageDesc: string;
    public unitComment: string;
    public unitTypeInd: string;
    public unitObjectTypeCd: string;
    public unitObjectSeqNbr: string;
    public whereCanBeViewed: string;
    public whenCanBeViewed: string;
    public alcoholInd: boolean;
    public totalLossInd: boolean;
    public nonLivable: boolean;
    public dwellUseCd: string;
    public floorArea: string;
    public nbrOfFloors: string;
    public bldQualityCd: string;
    public onPremisesInd: boolean;
    public basementInd: boolean;
    public atticInd: boolean;
    public mhTiedownInd: string;
    public mhParkedInd: string;
    public entrySource: string;
    public insuredIsOwnerInd: string;

    constructor(init: Partial<ClaimProperty>) {
        if (!init) {init = {}; }
        super(init);
        this.claimClients = init.claimClients || null;
        this.propertyKey = init.propertyKey || null;
        this.buildingNbr = init.buildingNbr || null;
        this.unitTypeCd = init.unitTypeCd || null;
        this.unitDesc = init.unitDesc || null;
        this.lossInfoId = init.lossInfoId || null;
        this.damageDesc = init.damageDesc || null;
        this.unitComment = init.unitComment || null;
        this.unitTypeInd = init.unitTypeInd || null;
        this.unitObjectTypeCd = init.unitObjectTypeCd || null;
        this.unitObjectSeqNbr = init.unitObjectSeqNbr || null;
        this.whereCanBeViewed = init.whereCanBeViewed || null;
        this.whenCanBeViewed = init.whenCanBeViewed || null;
        this.alcoholInd = init.alcoholInd === undefined ? null : init.alcoholInd;
        this.totalLossInd = init.totalLossInd === undefined ? null : init.totalLossInd;
        this.nonLivable = init.nonLivable === undefined ? null : init.nonLivable;
        this.dwellUseCd = init.dwellUseCd || null;
        this.floorArea = init.floorArea || null;
        this.nbrOfFloors = init.nbrOfFloors || null;
        this.bldQualityCd = init.bldQualityCd || null;
        this.onPremisesInd = init.onPremisesInd === undefined ? null : init.onPremisesInd;
        this.basementInd = init.basementInd === undefined ? null : init.basementInd;
        this.atticInd = init.atticInd === undefined ? null : init.atticInd;
        this.mhTiedownInd = init.mhTiedownInd || null;
        this.mhParkedInd = init.mhParkedInd || null;
        this.entrySource = init.entrySource || null;
        this.insuredIsOwnerInd = init.insuredIsOwnerInd || null;
    }
}
