import { PolicyBase } from '../policy';

export class BillPayItem {
    public policy: PolicyBase;
    public paymentUrlParams: Object;
    public paymentDisabled: boolean;
    public errorMessage: string;
    public isEFT: boolean;
    public billToMortgage: boolean;
    public paymentUrlHaserror: boolean;

    constructor(init?: Partial<BillPayItem>) {
        if (!init) { init = {}; }
        ({
            policy: this.policy,
            paymentUrlParams: this.paymentUrlParams,
            paymentDisabled: this.paymentDisabled,
            errorMessage: this.errorMessage,
            isEFT: this.isEFT,
            billToMortgage: this.billToMortgage,
            paymentUrlHaserror: this.paymentUrlHaserror
        } = init);
    }
}
