import { Action } from '@ngrx/store';
import { CancelReason } from '../../../models';
import { type } from '../../../utility';

export const ActionTypes = {
    GET_CANCEL_REASONS: type('[Auto Change Support] Get Cancel Reasons'),
    GET_CANCEL_REASONS_SUCCESS: type('[Auto Change Support] Get Cancel Reasons Success'),
    GET_CANCEL_REASONS_FAIL: type('[Auto Change Support] Get Cancel Reasons Fail'),

    GET_DELETE_VEHICLE_REASONS: type('[Auto Change Support] Get Delete Vehicle Reasons'),
    GET_DELETE_VEHICLE_REASONS_SUCCESS: type('[Auto Change Support] Get Delete Vehicle Reasons Success'),
    GET_DELETE_VEHICLE_REASONS_FAIL: type('[Auto Change Support] Get Delete Vehicle Reasons Fail'),

    GET_VEHICLE_TYPES: type('[Auto Change Support] Get Vehicle Types'),
    GET_VEHICLE_TYPES_SUCCESS: type('[Auto Change Support] Get Vehicle Types Success'),
    GET_VEHICLE_TYPES_FAIL: type('[Auto Change Support] Get Vehicle Types Fail'),

    GET_VEHICLE_YEARS: type('[Auto Change Support] Get Vehicle Years'),
    GET_VEHICLE_YEARS_SUCCESS: type('[Auto Change Support] Get Vehicle Years Success'),
    GET_VEHICLE_YEARS_FAIL: type('[Auto Change Support] Get Vehicle Years Fail'),

    GET_VEHICLE_MAKES: type('[Auto Change Support] Get Vehicle Makes'),
    GET_VEHICLE_MAKES_SUCCESS: type('[Auto Change Support] Get Vehicle Makes Success'),
    GET_VEHICLE_MAKES_FAIL: type('[Auto Change Support] Get Vehicle Makes Fail'),

    GET_VEHICLE_MODELS: type('[Auto Change Support] Get Vehicle Models'),
    GET_VEHICLE_MODELS_SUCCESS: type('[Auto Change Support] Get Vehicle Models Success'),
    GET_VEHICLE_MODELS_FAIL: type('[Auto Change Support] Get Vehicle Models Fail'),

    GET_COVERAGES: type('[Auto Change Support] Get Coverages'),
    GET_COVERAGES_SUCCESS: type('[Auto Change Support] Get Coverages Success'),
    GET_COVERAGES_FAIL: type('[Auto Change Support] Get Coverages Fail'),

    LOOKUP_VIN: type('[Auto Change Support] Lookup Vin'),
    LOOKUP_VIN_SUCCESS: type('[Auto Change Support] Lookup Vin Success'),
    LOOKUP_VIN_FAIL: type('[Auto Change Support] Lookup Vin Fail'),

    GET_MILES_DRIVEN: type('[Auto Change Support] Get Miles Driven'),
    GET_MILES_DRIVEN_SUCCESS: type('[Auto Change Support] Get Miles Driven Success'),
    GET_MILES_DRIVEN_FAIL: type('[Auto Change Support] Get Miles Driven Fail')
};

export class GetCancelReasonsAction implements Action {
    public type = ActionTypes.GET_CANCEL_REASONS;
    constructor(public payload: any = null) {}
}

export class GetCancelReasonsSuccessAction implements Action {
    public type = ActionTypes.GET_CANCEL_REASONS_SUCCESS;
    constructor(public payload: CancelReason[] = null) {}
}

export class GetCancelReasonsFailAction implements Action {
    public type = ActionTypes.GET_CANCEL_REASONS_FAIL;
    constructor(public payload: any = null) {}
}

export class GetDeleteVehicleReasonsAction implements Action {
    public type = ActionTypes.GET_DELETE_VEHICLE_REASONS;
    constructor(public payload: any = null) {}
}

export class GetDeleteVehicleReasonsSuccessAction implements Action {
    public type = ActionTypes.GET_DELETE_VEHICLE_REASONS_SUCCESS;
    constructor(public payload: any = null) {}
}

export class GetDeleteVehicleReasonsFailAction implements Action {
    public type = ActionTypes.GET_DELETE_VEHICLE_REASONS_FAIL;
    constructor(public payload: any = null) {}
}

export class GetVehicleTypesAction implements Action {
    public type = ActionTypes.GET_VEHICLE_TYPES;
    constructor(public payload: string = null) {}
}

export class GetVehicleTypesSuccessAction implements Action {
    public type = ActionTypes.GET_VEHICLE_TYPES_SUCCESS;
    constructor(public payload: any = null) {}
}

export class GetVehicleTypesFailAction implements Action {
    public type = ActionTypes.GET_VEHICLE_TYPES_FAIL;
    constructor(public payload: any = null) {}
}

export class GetVehicleYearsAction implements Action {
    public type = ActionTypes.GET_VEHICLE_YEARS;
    constructor(public payload: string = null) {}
}

export class GetVehicleYearsSuccessAction implements Action {
    public type = ActionTypes.GET_VEHICLE_YEARS_SUCCESS;
    constructor(public payload: any = null) {}
}

export class GetVehicleYearsFailAction implements Action {
    public type = ActionTypes.GET_VEHICLE_YEARS_FAIL;
    constructor(public payload: any = null) {}
}

export class GetVehicleMakesAction implements Action {
    public type = ActionTypes.GET_VEHICLE_MAKES;
    constructor(public payload: any = null) {}
}

export class GetVehicleMakesSuccessAction implements Action {
    public type = ActionTypes.GET_VEHICLE_MAKES_SUCCESS;
    constructor(public payload: any = null) {}
}

export class GetVehicleMakesFailAction implements Action {
    public type = ActionTypes.GET_VEHICLE_MAKES_FAIL;
    constructor(public payload: any = null) {}
}

export class GetVehicleModelsAction implements Action {
    public type = ActionTypes.GET_VEHICLE_MODELS;
    constructor(public payload: any = null) {}
}

export class GetVehicleModelsSuccessAction implements Action {
    public type = ActionTypes.GET_VEHICLE_MODELS_SUCCESS;
    constructor(public payload: any = null) {}
}

export class GetVehicleModelsFailAction implements Action {
    public type = ActionTypes.GET_VEHICLE_MODELS_FAIL;
    constructor(public payload: any = null) {}
}

export class GetCoveragesAction implements Action {
    public type = ActionTypes.GET_COVERAGES;
    constructor(public payload: string = null) {}
}

export class GetCoveragesSuccessAction implements Action {
    public type = ActionTypes.GET_COVERAGES_SUCCESS;
    constructor(public payload: any = null) {}
}

export class GetCoveragesFailAction implements Action {
    public type = ActionTypes.GET_COVERAGES_FAIL;
    constructor(public payload: any = null) {}
}

export class LookupVinAction implements Action {
    public type = ActionTypes.LOOKUP_VIN;
    constructor(public payload: string = null) {}
}

export class LookupVinSuccessAction implements Action {
    public type = ActionTypes.LOOKUP_VIN_SUCCESS;
    constructor(public payload: any = null) {}
}

export class LookupVinFailAction implements Action {
    public type = ActionTypes.LOOKUP_VIN_FAIL;
    constructor(public payload: any = null) {}
}

export class GetMilesDrivenAction implements Action {
    public type = ActionTypes.GET_MILES_DRIVEN;
    constructor(public payload: string = null) {}
}

export class GetMilesDrivenSuccessAction implements Action {
    public type = ActionTypes.GET_MILES_DRIVEN_SUCCESS;
    constructor(public payload: any = null) {}
}

export class GetMilesDrivenFailAction implements Action {
    public type = ActionTypes.GET_MILES_DRIVEN_FAIL;
    constructor(public payload: any = null) {}
}

export type Actions =
    | GetCancelReasonsAction
    | GetCancelReasonsSuccessAction
    | GetCancelReasonsFailAction
    | GetVehicleTypesAction
    | GetVehicleTypesSuccessAction
    | GetVehicleTypesFailAction
    | GetVehicleYearsAction
    | GetVehicleYearsSuccessAction
    | GetVehicleYearsFailAction
    | GetVehicleMakesAction
    | GetVehicleMakesSuccessAction
    | GetVehicleMakesFailAction
    | GetVehicleModelsAction
    | GetVehicleModelsSuccessAction
    | GetVehicleModelsFailAction
    | GetCoveragesAction
    | GetCoveragesSuccessAction
    | GetCoveragesFailAction
    | LookupVinAction
    | LookupVinSuccessAction
    | LookupVinFailAction
    | GetMilesDrivenAction
    | GetMilesDrivenSuccessAction
    | GetMilesDrivenFailAction;
