import { formatPhoneForApi, ObjectCleaner } from '../../utility';
import { SecurityQuestions, User } from '../user';
import { ChangeSecurityQuestionsForm } from './changeSecurityQuestionsForm.model';

export class MyProfileApiRequestFactory {
    public static createChangePasswordRq(existingPass: string, newPass: string): { existingPassword: string; user: User } {
        const req = {
            existingPassword: existingPass,
            user: new User({ password: newPass })
        };
        return ObjectCleaner.removeUnneededKeys(req);
    }

    public static createChangeSecurityQuestionsRq(form: ChangeSecurityQuestionsForm): { questions: SecurityQuestions[] } {
        const req = {
            questions: form.questionGroups.map(
                group => new SecurityQuestions({ questionId: group.selectedQuestion, userAnswer: group.answer })
            )
        };
        return ObjectCleaner.removeUnneededKeys(req);
    }

    public static createSaveProfileChangesRq(user: User, userId: string): { user: User } {
        let phoneNumber: string;
        try {
            phoneNumber = formatPhoneForApi(user.phoneNumber, 'nnn-nnn-nnnn');
        } catch (err) {
            phoneNumber = null;
            console.error(err);
        }

        const req = {
            user: <User>{
                ...user,
                userId: userId,
                phoneNumber: phoneNumber
            }
        };
        return ObjectCleaner.removeUnneededKeys(req);
    }
}
