import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ClaimsApiResponse } from '../../models';
import { ClaimsService } from '../adapters';
import { Adapter, Body, HttpService, Path, POST, PUT } from '../http';

@Injectable()
export class ClaimsFileApiClient extends HttpService {
    /**
     * General API Calls for All Claims
     */

    @PUT('claims/complete/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public completeClaim(@Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/save/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public saveClaim(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    /**
     * Auto Claim API Calls
     */
    @POST('claims/file/auto/basicinfo',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoClaimBasicInfo(@Body form: any): Observable<ClaimsApiResponse> {
        return null;
    }

    @PUT('claims/file/auto/basicinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public updateAutoClaimBasicInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/auto/vehicleinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoClaimVehicleInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/auto/clientinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoClaimClientInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/auto/detailinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoClaimDetailInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/auto/attachments/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoClaimAttachments(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/auto/contactinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoClaimContactInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    /**
     * Auto ERS Claim API Calls
     */

    @POST('claims/file/ers/basicinfo',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoErsClaimBasicInfo(@Body form: any): Observable<ClaimsApiResponse> {
        return null;
    }

    @PUT('claims/file/ers/basicinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public updateAutoErsClaimBasicInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/ers/detailinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoErsClaimDetailInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/ers/attachments/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoErsClaimAttachments(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/ers/contactinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoErsClaimContactInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    /**
     * Auto Glass Claim API Calls
     */

    @POST('claims/file/glass/basicinfo',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoGlassClaimBasicInfo(@Body form: any): Observable<ClaimsApiResponse> {
        return null;
    }

    @PUT('claims/file/glass/basicinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public updateAutoGlassClaimBasicInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/glass/detailinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoGlassClaimDetailInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/glass/contactinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setAutoGlassClaimContactInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    /**
     * Property Claim API Calls
     */

    @POST('claims/file/property/basicinfo',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setPropertyClaimBasicInfo(@Body form: any): Observable<ClaimsApiResponse> {
        return null;
    }

    @PUT('claims/file/property/basicinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public updatePropertyClaimBasicInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/property/propertyinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setPropertyClaimDetailInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/property/clientinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setPropertyClaimClientInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/property/attachments/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setPropertyClaimAttachments(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/property/contactinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setPropertyClaimContactInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    /**
     * Watercraft Claim API Calls
     */
    @POST('claims/file/watercraft/basicinfo',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setWatercraftClaimBasicInfo(@Body form: any): Observable<ClaimsApiResponse> {
        return null;
    }

    @PUT('claims/file/watercraft/basicinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public updateWatercraftClaimBasicInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/watercraft/vehicleinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setWatercraftClaimVehicleInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/watercraft/clientinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setWatercraftClaimClientInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/watercraft/detailinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setWatercraftClaimDetailInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }

    @POST('claims/file/watercraft/contactinfo/{id}',false)
    @Adapter(ClaimsService.apiResponseAdapter)
    public setWatercraftClaimContactInfo(@Body form: any, @Path('id') id: string): Observable<ClaimsApiResponse> {
        return null;
    }
}
