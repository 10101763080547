import { Component, EventEmitter, Input, Output } from '@angular/core';
import {environment} from '../../../../environments/environment';
import { TabLayout } from '../../models';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
    @Input() public loggedIn = false;
    @Input() public tabLayout = new TabLayout();

    public marketingSite = environment.marketingSite;
}
