import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
    private defaultMsg = 'You have unsaved changes! If you leave, your changes will be lost.';

    public canDeactivate(component: ComponentCanDeactivate): boolean {
        if (!component.canDeactivate()) {
            if (confirm(component.getDeactivateMsg() !== null ? component.getDeactivateMsg() : this.defaultMsg)) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}
