import { Action } from '@ngrx/store';
import { type } from '../../utility';

// tslint:disable-next-line:variable-name
export const ActionTypes = {
    LOGOUT: type('[App] Logout')
};

/**
 * Claims Support Actions
 */

export class LogoutAction implements Action {
    public type = ActionTypes.LOGOUT;
}

export type Actions = LogoutAction;
