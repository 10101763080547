import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';


@Injectable()
export class StorageService implements OnDestroy {
    private onSubject = new Subject<{ key: string, newValue: string, oldValue: string }>();
    public changes = this.onSubject.asObservable().pipe(
        share()
    );

    constructor() {
        this.start();
    }

    public ngOnDestroy() {
        this.stop();
    }

    public getStorage() {
        const s = [];
        for (let i = 0; i < localStorage.length; i++) {
            s.push({
                key: localStorage.key(i),
                value: localStorage.getItem(localStorage.key(i))
            });
        }
        return s;
    }

    public store(key: string, data: string): void {
        localStorage.setItem(key, data);
        // the local application doesn't seem to catch changes to localStorage...
        // const oldValue = localStorage.getItem(key);
        // this.onSubject.next({ key: key, newValue: data, oldValue });
    }

    public clear(key) {
        localStorage.removeItem(key);
        // the local application doesn't seem to catch changes to localStorage...
        // const oldValue = localStorage.getItem(key);
        // this.onSubject.next({ key: key, newValue: null, oldValue });
    }


    private start(): void {
        window.addEventListener('storage', this.storageEventListener.bind(this));
    }

    private storageEventListener(event: StorageEvent) {
        if (event.storageArea === localStorage) {

            this.onSubject.next({ key: event.key, newValue: event.newValue, oldValue: event.oldValue });
        }
    }

    private stop(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
        this.onSubject.complete();
    }
}
