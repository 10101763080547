import { Vehicle } from '../../../policy/auto/vehicle.model';
import { Claim } from '../../claim.model';
import { AutoDetailsForm } from './autoDetails.model';
import { PeopleInvolvedForm } from './peopleInvolved.model';
import { VehicleDetailsForm } from './vehicleDetails.model';

export class AutoClaim extends Claim {
    public vehicle: Vehicle;
    public autoDetail: AutoDetailsForm;
    public vehicleDetail: VehicleDetailsForm;
    public peopleInvolved: PeopleInvolvedForm;

    constructor(init?: Partial<AutoClaim>) {
        if (!init) {
            init = {};
        }
        super(init);
        this.vehicle = init.vehicle || new Vehicle();
        this.autoDetail = init.autoDetail || new AutoDetailsForm();
        this.vehicleDetail = init.vehicleDetail || new VehicleDetailsForm();
        this.peopleInvolved = init.peopleInvolved || new PeopleInvolvedForm();
    }
}
