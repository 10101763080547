

export class LifeCoverageLayer {

    public layerNbr: number;
    public riskClass: string;
    public applicationDate: string;
    public issueDate: string;
    public expireDate: string;
    public volume: number;
    public premium: number;
    public annualizedPremium: number;

    constructor(init?: Partial<LifeCoverageLayer>) {
        if (!init) { init = {}; }
        this.layerNbr = init.layerNbr || null;
        this.riskClass = init.riskClass || null;
        this.applicationDate = init.applicationDate || null;
        this.issueDate = init.issueDate || null;
        this.expireDate = init.expireDate || null;
        this.volume = init.volume || null;
        this.premium = init.premium || null;
        this.annualizedPremium = init.annualizedPremium || null;
       
    }
}
