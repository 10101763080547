import { createSelector } from '@ngrx/store';
import { State } from '../..';

import * as fromRegister from '../../reducers/register/register.reducer';

export const getRegister = (state: State) => state.register;

export const getPolicyForm = createSelector(
    getRegister,
    fromRegister.getPolicyForm
);

export const getPolicyResponse = createSelector(
    getRegister,
    fromRegister.getPolicyResponse
);

export const getAccountForm = createSelector(
    getRegister,
    fromRegister.getAccountForm
);

export const getAccountResponse = createSelector(
    getRegister,
    fromRegister.getAccountResponse
);

export const getRegisterError = createSelector(
    getRegister,
    fromRegister.getRegisterError
);

export const getIsVerificationLoaded = createSelector(
    getRegister,
    fromRegister.getIsVerificationLoaded
);

export const getIsVerificationLoading = createSelector(
    getRegister,
    fromRegister.getIsVerificationLoading
);

export const getVerificationError = createSelector(
    getRegister,
    fromRegister.getVerificationError
);
