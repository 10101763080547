export class ClaimConstants{

    public static translatePolicyCategory(policyType) {
        let typ = '';
        switch (policyType) {
            case 'BusinessAuto':
            case 'PersonalAuto':
                typ = 'AUT';
                break;
            case 'Boat_FB':
                typ = 'BOT';
                break;
            case 'farmowners':
            case 'PersonalUmbrella':
            case 'PersonalLiability_FB':
            case 'PersonalHomeowners_FB':
            case 'HOPHomeowners':
            case 'DwellingFire_FB':
            case 'DwellingFireFarm_FB':
            case 'CommercialPackage':
                typ = 'PRO';
                break;
            default:
                typ = '';
        }
        return typ;
    }




}