export class LienholderForm {
    public name: string;
    public address: string;
    public address2: string;
    public state: string;
    public city: string;
    public zip: string;

    constructor(init?: Partial<LienholderForm>) {
        Object.assign(this, init);
    }
}
