import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TabLayout } from '../../models';
import { Sandbox } from '../../sandbox/base.sandbox';
import * as store from '../../store';
import { UpdateTabLayoutAction } from '../../store/actions/layout/layout.action';

@Injectable()
export class LayoutSandbox extends Sandbox {
    constructor(protected appState$: Store<store.State>, private router: Router) {
        super(appState$);
    }
    public $dataLoading = this.appState$.select(store.dataLoading);
    public $tabLayout = this.appState$.select(store.getTabLayout);

    public setTabLayout(tabLayout: TabLayout) {
        this.appState$.dispatch(new UpdateTabLayoutAction(tabLayout));
    }
}
