import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { State } from '../..';
import { PaperlessApiClient } from '../../../asyncServices/api-clients/paperless-apiclient.service';
import { PaperlessUpdateInfo, User } from '../../../models';
import { ErrorMsgService } from '../../../utility/error-msg.service';
import {
    ActionTypes,
    SendPaperlessVerificationsAction,
    SendPaperlessVerificationsFailAction,
    SendPaperlessVerificationsSuccessAction,
    UpdatePaperlessAction,
    UpdatePaperlessFailAction,
    UpdatePaperlessSuccessAction,
    VerifyPaperlessAction,
    VerifyPaperlessFailAction,
    VerifyPaperlessSuccessAction
} from '../../actions/myprofile/paperless.action';
import { LoadPoliciesAction } from '../../actions/policy/policy.action';
import { getUser } from '../../selectors';

@Injectable()
export class PaperlessEffects {
    constructor(private actions$: Actions, public paperlessClient: PaperlessApiClient,
        private errorMsgService: ErrorMsgService,
        private appState$: Store<State>) { }

    private createCallArray(payload: PaperlessUpdateInfo, user: User) {
        const calls: Observable<any>[] = [];
        if (payload.addPayload.paperlessInfo.length > 0) {
            calls.push(this.paperlessClient.AddPaperless(payload.addPayload, user.custId.toString()));
        }

        if (payload.updatePayload.paperlessInfo.length > 0) {
            calls.push(this.paperlessClient.UpdatePaperless(payload.updatePayload, user.custId.toString()));
        }

        payload.cancelPayload.forEach(
            cancelPayload => {
                calls.push(this.paperlessClient
                    .CancelPaperless(user.custId.toString(), cancelPayload.policyNbr, cancelPayload.systemId, cancelPayload.sourceSystem));
            }
        );
        return calls;
    }


    @Effect()
    public updatePaperless$ = this.actions$
        .ofType(ActionTypes.UPDATE_PAPERLESS).pipe(
            map((action: UpdatePaperlessAction) => action.payload),
            withLatestFrom(this.appState$.select(getUser)),

            switchMap(([payload, user]) =>
                forkJoin(this.createCallArray(payload, user)).pipe(
                    map(response => {
                        return new UpdatePaperlessSuccessAction(response);
                    }),
                    catchError(
                        error => of(new UpdatePaperlessFailAction(
                            { error: this.errorMsgService.getMsg(error) }
                        ))
                    )
                )
            )
        );

    // Reload policies
    @Effect()
    public reloadAfterUpdate$ = this.actions$
        .ofType(ActionTypes.UPDATE_PAPERLESS_SUCCESS).pipe(
            mapTo(new LoadPoliciesAction(true))
        );

    // Reload policies
    @Effect()
    public reloadAfterFail$ = this.actions$
        .ofType(ActionTypes.UPDATE_PAPERLESS_FAIL).pipe(
            mapTo(new LoadPoliciesAction(true))
        );

    // Resend paperless verification
    @Effect()
    public sendPaperlessVerification$ = this.actions$
        .ofType(ActionTypes.SEND_PAPERLESS_VERIFICATIONS).pipe(
            map((action: SendPaperlessVerificationsAction) => action.payload),
            withLatestFrom(this.appState$.select(getUser)),
            switchMap(([verifications, user]) =>
                forkJoin(
                    verifications.map(
                        verification => this.paperlessClient.SendVerification(verification, user.custId.toString())
                    )
                ).pipe(
                    map(response => new SendPaperlessVerificationsSuccessAction(verifications)),
                    catchError(error => of(new SendPaperlessVerificationsFailAction({ error: this.errorMsgService.getMsg(error) })))
                )
            )

        );


    @Effect()
    public verifyPaperless$: Observable<Action> = this.actions$.ofType(ActionTypes.VERIFY_PAPERLESS).pipe(
        map((action: VerifyPaperlessAction) => action.payload),
        switchMap((payload) => {
            return this.paperlessClient.VerifyPaperless(payload.id, payload.source, payload.policy, payload.date).pipe(
                map(apiResponse => new VerifyPaperlessSuccessAction(apiResponse)),
                catchError(error =>
                    of(
                        new VerifyPaperlessFailAction({ error: this.errorMsgService.getMsg(error) })
                    )
                )
            );
        })
    );

}
