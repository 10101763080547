export class ValidationMatch {
    public key: number;
    public firstName: string;
    public lastName: string;
    public emailAddress: string;
    public phoneNbr: string;
    public onOtherAccount: boolean;

    constructor(init?: Partial<ValidationMatch>) {
        if (!init) {
            init = {};
        }
        this.key = init.key || null;
        this.firstName = init.firstName || null;
        this.lastName = init.lastName || null;
        this.emailAddress = init.emailAddress || null;
        this.phoneNbr = init.phoneNbr || null;
        this.onOtherAccount = init.onOtherAccount || null;
    }
}
