export class causeOfLossConstants {

    public static readonly propCols = [
        {
            'type': 'Weather',
            'cd': 'AL'
        },
        {
            'type': 'Theft',
            'cd': 'theftentire'
        },
        {
            'type': 'Other',
            'cd': 'AllOther_FB'
        }
    ]

    public static readonly propWeatherCols = [
        {
            'type': 'Wind',
            'cd': 'wind'
        },
        {
            'type': 'Hail',
            'cd': 'hail'
        },
        {
            'type': 'Wind & Hail',
            'cd': 'hail'
        },
        {
            'type': 'Lightning Only',
            'cd': 'lightning_FB'
        },
        {
            'type': 'Food Spoilage Only',
            'cd': 'storm_food_spoil_FB'
        },
        {
            'type':'Wind/Tree Damage Only',
            'cd':'wind_tree_damage_only_FB'
        }
    ]

}