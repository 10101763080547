import { SecurityQuestions } from '../user';

export class SecurityQuestionsGroup {
    public groupName: string;
    public questions: SecurityQuestions[];
    public retired: boolean;

    constructor(init?: Partial<SecurityQuestionsGroup>) {
        if (!init) {
            init = {};
        }
        this.groupName = init.groupName || null;
        this.questions = init.questions || null;
        this.retired = init.retired || null;
    }
}
