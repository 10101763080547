import { ClaimClient } from '../../claimClient.model';
import { PersonalInfo } from '../../personalInfo.model';


export class PeopleInvolvedForm {
    public driver:        ClaimClient;
    public otherDriver:   PersonalInfo;
    public addInjured:    boolean;
    public injured:       PersonalInfo[];
    public addWitnesses:  boolean;
    public witnesses:     PersonalInfo[];

    constructor(init?: Partial<PeopleInvolvedForm>) {
        if (!init) { init = {}; }
        this.driver = init.driver || null;
        this.otherDriver = init.otherDriver || new PersonalInfo();
        this.addInjured = init.addInjured || null;
        this.injured = init.injured || new Array<PersonalInfo>();
        this.addWitnesses = init.addWitnesses || null;
        this.witnesses = init.witnesses || new Array<PersonalInfo>();
    }
}
