export class WaterDetailsForm {
    public location: string;
    public damageAreas: string[];

    constructor(init?: Partial<WaterDetailsForm>) {
        if (!init) {
            init = {};
        }
        this.location = init.location || null;
        this.damageAreas = init.damageAreas || new Array<string>();
    }
}
