import { ContactUsForm } from '../contact-us/contact-us.model';
import { ContactApiRequest } from './contactApiRequest';

export class UserApiRequestFactory {
    public static createContactRequest(state: ContactUsForm): ContactApiRequest {
        const request = new ContactApiRequest({
            category: state.messageType,
            policy: {
                policyNbr: state.policyNumber
            },
            comments: state.message
        });

        request.requestor = {
            firstName: state.firstName,
            lastName: state.lastName,
            mailingAddress: {
                addr1: state.address,
                city: state.city,
                stateCd: state.state,
                zip: state.zipCode
            },
            contactList: [
                {
                    type: 'HOME_EMAIL',
                    value: state.email
                },
                {
                    type: 'HOME_PHONE',
                    value: state.phone
                }
            ]
        };

        return request;
    }
}
