export class ContactApiRequest {
    public category: string;
    public requestor: {
        firstName: string;
        lastName: string;
        mailingAddress: {
            addr1: string,
            city: string,
            stateCd: string,
            zip: string
        },
        contactList: [
            {
                type: 'HOME_EMAIL',
                value: string
            },
            {
                type: 'HOME_PHONE',
                value: string
            }
        ]
    };
    public policy: {
        policyNbr: string;
    };
    public comments: string;

    constructor(init?: Partial<ContactApiRequest>) {
        Object.assign(this, init);
    }
}
