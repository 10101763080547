import { Injectable } from '@angular/core';
import { ClaimsContactForm, ContactMechanism } from '../../models';

@Injectable()
export class ClientService {
    /**
     * Transforms data recieved from the API into array of 'County' instances
     *
     * @param location
     */
    public static clientContactAdapter(contacts: ContactMechanism[]): ClaimsContactForm {
        return new ClaimsContactForm({ contactList: contacts });
    }
}
