import { PolicyBase } from '../base/policy-base.model';
import { LifeCoverage } from './life-coverage.model';

export class LifePolicy extends PolicyBase {

    // if ($scope.policy[0].moratorium) {
    //     $scope.policy[0].premium = 0;
    //     $scope.policy[0].annualizedPremium = 0;
    //     $scope.moratoriumBilling = "No Active Billing";
    //     $scope.annuityMoratorium = true;
    // }
    public moratorium: boolean;

    public coverages: LifeCoverage[];

    // tslint:disable-next-line:variable-name
    private _annualizedPremium: number;
    public get annualizedPremium() {
        return this.moratorium ? 0 : this._annualizedPremium;

    }
    public set annualizedPremium(value: number) {
        this._annualizedPremium = value;
    }

    // tslint:disable-next-line:variable-name
    private _premium: number;
    public get premium() {
        return this.moratorium ? 0 : this._premium;
    }
    public set premium(value: number) {
        this._premium = value;
    }

    constructor(init?: Partial<LifePolicy>) {
        if (!init) { init = {}; }
        super(init);

    }
}
