export class PolicyConstants {
    public static NO_DATE = '0';

    public static readonly policyCd = {
        'PAUTO':    'auto',
        'AUTO':     'auto',
        'CAUTO':    'commercialauto',
        'WTC':      'watercraft',
        'COMM':     'commercial',
        'PFIRE':    'fire',
        'FFIRE':    'fire',
        'HOME':     'home',
        'PHOME':    'home',
        'FARM':     'farm',
        'UMBL':     'umbrella',
        'PLIAB':    'liability',
        'TERM':     'life',
        'WHOLE':    'life',
        'UNIV':     'life',
        'BOP':      'businessowner',
        'AL':       'lifeannuity',
        'FPANY':    'lifeannuity',
        'SPANY':    'lifeannuity',
        'ANNY':     'lifeannuity',
        'MEMB':     'membership'
    };

    public static readonly vehicleType = [
        {value: 'PP', text: 'Car'},
        {value: 'PU', text: 'Van'},
        {value: 'VN', text: 'Truck'}
    ];

    public static translatePolicyType(policyType) {
        let typ = '';
        switch (policyType) {
            case 'auto':
            case 'CAUTO':
            case 'PAUTO':
            case 'commercial-auto':
            case 'commercialauto':
            case 'autoNonTN': // to cover non Tennessee state
                typ = 'auto';
                break;
            case 'COMM':
            case 'commercial-property':
                typ = 'commercial';
                break;
            case 'BOP':
                typ = 'businessowner';
                break;
            case 'HOME':
            case 'home':
            case 'homeowner':
            case 'PHOME':
            case 'FARM':
            case 'personal-homeowner':
            case 'farm':
            case 'farmowner':
            case 'fire':
            case 'PFIRE':
            case 'FFIRE':
            case 'personal-fire':
            case 'liability':
            case 'PLIAB':
            case 'personal-liability':
            case 'PROP':
                typ = 'property';
                break;
            case 'TERM':
            case 'ANNY':
            case 'term-life':
            case 'annuity-life':
            case 'annuity':
            case 'universal-life':
            case 'UNIV':
            case 'whole-life':
            case 'WHOLE':
            case 'lifeannuity':
                typ = 'life';
                break;
            case 'UMBL':
                typ = 'umbrella';
                break;
            case 'WTC':
            case 'watercraft':
                typ = 'watercraft';
                break;
            default:
                typ = policyType;
        }
        return typ;
    }

    public static translatePolicyCategory(policyType) {
        let typ = '';
        switch (policyType) {
            case 'auto':
            case 'CAUTO':
            case 'PAUTO':
            case 'commercial-auto':
            case 'commercialauto':
            case 'autoNonTN': // to cover non Tennessee state
            case 'WTC':
            case 'watercraft':
                typ = '1auto';
                break;
            case 'COMM':
            case 'commercial-property':
            case 'HOME':
            case 'home':
            case 'homeowner':
            case 'PHOME':
            case 'FARM':
            case 'personal-homeowner':
            case 'farm':
            case 'farmowner':
            case 'fire':
            case 'PFIRE':
            case 'FFIRE':
            case 'personal-fire':
            case 'liability':
            case 'PLIAB':
            case 'personal-liability':
            case 'UMBL':
            case 'PROP':
                typ = '2property';
                break;
            case 'TERM':
            case 'ANNY':
            case 'term-life':
            case 'annuity-life':
            case 'annuity':
            case 'universal-life':
            case 'UNIV':
            case 'whole-life':
            case 'WHOLE':
            case 'lifeannuity':
                typ = '3life';
                break;
            default:
                typ = '4';
        }
        return typ;
    }
}
