export class ClaimsSupport {
    public tableId:             string;
    public tableSeqNbr:         number;
    public code:                string;
    public code2:               string;
    public code3:               string;
    public codeDes:             string;


    constructor(init?: Partial<ClaimsSupport>) {
      if (!init) {init = {}; }
      this.tableId            = init.tableId || null;
      this.tableSeqNbr        = init.tableSeqNbr || null;
      this.code               = init.code || null;
      this.code2              = init.code2 || null;
      this.code3              = init.code3 || null;
      this.codeDes            = init.codeDes || null;
    }
  }
