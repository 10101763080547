import { createSelector } from '@ngrx/store';
import { State } from '../..';
import * as fromUser from '../../reducers/user/user.reducer';

export const getUserState = (state: State) => state.user;
export const securityQuestions = createSelector(
    getUserState,
    fromUser.userQuestions
);
export const getLoginChallengeError = createSelector(
    getUserState,
    fromUser.getLoginChallengeError
);

export const getLoginChallengeSubmit = createSelector(
    getUserState,
    fromUser.getIsUserLoginChallengeSubmit
);

export const getIsUserLoggingIn = createSelector(
    getUserState,
    fromUser.getIsUserLoggingIn
);
export const getIsUserLoggedIn = createSelector(
    getUserState,
    fromUser.getIsUserLoggedIn
);
export const getIsUserLoginFail = createSelector(
    getUserState,
    fromUser.getIsUserLoginFail
);
export const getUserLoginError = createSelector(
    getUserState,
    fromUser.getUserLoginError
);
export const getIsUserLoginChallenged = createSelector(
    getUserState,
    fromUser.getIsUserLoginChallenged
);
export const getUser = createSelector(
    getUserState,
    fromUser.getUser
);

export const getIsResetPasswordLoaded = createSelector(
    getUserState,
    fromUser.getIsResetPasswordLoaded
);
export const getIsResetPasswordLoading = createSelector(
    getUserState,
    fromUser.getIsResetPasswordLoading
);
export const getResetPasswordError = createSelector(
    getUserState,
    fromUser.getResetPasswordError
);

export const getIsRegVerifyLoaded = createSelector(
    getUserState,
    fromUser.getIsRegVerifyLoaded
);
export const getIsRegVerifyLoading = createSelector(
    getUserState,
    fromUser.getIsRegVerifyLoading
);
export const getRegVerifyError = createSelector(
    getUserState,
    fromUser.getRegVerifyError
);

export const getResetQuestions = createSelector(
    getUserState,
    fromUser.getResetQuestions
);
export const getQuestionUpdateLoaded = createSelector(
    getUserState,
    fromUser.getQuestionUpdateLoaded
);
export const getQuestionUpdateLoading = createSelector(
    getUserState,
    fromUser.getQuestionUpdateLoading
);
export const getQuestionUpdateError = createSelector(
    getUserState,
    fromUser.getQuestionUpdateError
);

export const getIsRecoverPasswordInitLoaded = createSelector(
    getUserState,
    fromUser.getIsRecoverPasswordInitLoaded
);
export const getIsRecoverPasswordInitLoading = createSelector(
    getUserState,
    fromUser.getIsRecoverPasswordInitLoading
);
export const getRecoverPasswordInitResponse = createSelector(
    getUserState,
    fromUser.getRecoverPasswordInitResponse
);

export const getIsRecoverPasswordAuthLoaded = createSelector(
    getUserState,
    fromUser.getIsRecoverPasswordAuthLoaded
);
export const getIsRecoverPasswordAuthLoading = createSelector(
    getUserState,
    fromUser.getIsRecoverPasswordAuthLoading
);
export const getRecoverPasswordAuthResponse = createSelector(
    getUserState,
    fromUser.getRecoverPasswordAuthResponse
);

export const getIsRecoverPasswordCompleteLoaded = createSelector(
    getUserState,
    fromUser.getIsRecoverPasswordCompleteLoaded
);
export const getIsRecoverPasswordCompleteLoading = createSelector(
    getUserState,
    fromUser.getIsRecoverPasswordCompleteLoading
);
export const getRecoverPasswordCompleteResponse = createSelector(
    getUserState,
    fromUser.getRecoverPasswordCompleteResponse
);

export const getRecoverPasswordError = createSelector(
    getUserState,
    fromUser.getRecoverPasswordError
);

export const getResetSecurityInitError = createSelector(
    getUserState,
    fromUser.getResetSecurityInitError
);

export const getIsResetSecurityInitFailed = createSelector(
    getUserState,
    fromUser.getIsResetSecurityInitFailed
);

export const getIsResetSecurityInitLoaded = createSelector(
    getUserState,
    fromUser.getIsResetSecurityInitLoaded
);
