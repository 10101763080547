import { createSelector } from '@ngrx/store';
import { State } from '../..';

import * as fromPolicy from '../../reducers/policy/policy.reducer';

export const getPolicyState = (state: State) => state.policy;

export const getPoliciesData = createSelector(getPolicyState, fromPolicy.getPoliciesData);
export const getAlerts = createSelector(getPolicyState, fromPolicy.getAlerts);
export const getPoliciesLoading = createSelector(getPolicyState, fromPolicy.getPoliciesLoading);
export const getPoliciesLoaded = createSelector(getPolicyState, fromPolicy.getPoliciesLoaded);

export const policyDataLoading = createSelector(getPolicyState, fromPolicy.policyDataLoading);

export const getCountOfAgenciesLoading = createSelector(getPolicyState, fromPolicy.getCountOfAgenciesLoading);
export const getAgencies = createSelector(getPolicyState, fromPolicy.getAgencies);
export const getPoliciesLoadingHasError = createSelector(getPolicyState, fromPolicy.getPoliciesLoadingHasError);
export const getPolicyLoadingHasError = createSelector(getPolicyState, fromPolicy.getPolicyLoadingHasError);
