import { DeviceInfo } from './deviceInfo.model';

export class ForgotPasswordApiRequest {

    public deviceInfo: DeviceInfo;
    public taxId: string;
    public url: string;
    public user: {
        emailAddress: string,
        userId: string
    };
    public dob: string;

    constructor(init?: Partial<ForgotPasswordApiRequest>) {
        Object.assign(this, init);
    }
}
