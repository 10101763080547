import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
    // public transform(val, args) {
    //     val = val.charAt(0) !== 0 ? '0' + val : '' + val;
    //     let newStr = '';
    //     let i = 0;
    //     for (i = 0; i < Math.floor(val.length / 2) - 1; i++) {
    //         newStr = newStr + val.substr(i * 2, 2) + '-';
    //     }
    //     return newStr + val.substr(i * 2);
    // }
    public transform(val, args) {
        if (val === null) {
            return val;
        }
        let value: string = val.replace(/[^0-9]+/g, '');
        if (value.length === 10) {
            value =
                '(' +
                value.slice(0, 3) +
                ')' +
                value.slice(3, 6) +
                '-' +
                value.slice(6, 10);
        }
        return value;
    }
}
