import { AutoCoverageApiRequest } from './autoAddVehicleApi-request';

export class AutoIncreaseDedApiRequest {
    public customerId: string;
    public coverageList: AutoCoverageApiRequest[];

    constructor(init?: Partial<AutoIncreaseDedApiRequest>) {
        Object.assign(this, init);
    }
}
