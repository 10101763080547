import { Coverage } from '../../policy/auto/coverage.model';
import { CoverageOption } from '../add-vehicle/coverage-option.model';

export class CoverageIncreaseDed extends Coverage {
    public coverageOptions: CoverageOption[];
    public checked: boolean;
    public selectedDed: number;

    constructor(init?: Partial<CoverageIncreaseDed>) {
        super(init);
        Object.assign(this, init);
    }
}
