import { Vehicle } from '../policy/auto/vehicle.model';
import { PolicyBase } from '../policy/base/policy-base.model';
import { Dwelling } from '../policy/property/dwelling.model';

export class ClaimsSelectPolicy {
    public policyNbr:   string;
    public unitNbr:     string;
    public claimType:   string;
    public policy: PolicyBase;
    public vehicle: Vehicle;
    public dwelling: Dwelling;

    constructor(init?: Partial<ClaimsSelectPolicy>) {
        if (!init) { init = {}; }
        this.policyNbr = init.policyNbr || null;
        this.unitNbr = init.unitNbr || null;
        this.claimType = init.claimType || null;
        this.policy = init.policy || new PolicyBase();
        this.vehicle = init.vehicle || new Vehicle();
        this.dwelling = init.dwelling || new Dwelling();
    }
  }
