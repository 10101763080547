import { MailingAddress } from '../policy/base/mailingAddress.model';
import { ContactMechanism } from './contact-mechanism.model';

export class ClaimClient {
    public roleCd: string;
    public firstName: string;
    public lastName: string;
    public roleDesc: string;
    public adrSeqNbr: string;
    public cciCheckSum: string;
    public cctCheckSum: string;
    public cctTableKey: string;
    public drvLicenseNbr: string;
    public roleRelCd: string;
    public bodilyInjuryDesc: string;
    public entrySource: string;
    public extClientId: string;
    public processNotes: string[];
    public clientUpdated: boolean;
    public excluded: boolean;
    public clientId: string;
    public physicalAddress: MailingAddress;
    public contactList: ContactMechanism[];

    constructor(init?: Partial<ClaimClient>) {
        if (!init) { init = {}; }
        this.roleCd = init.roleCd || null;
        this.firstName = init.firstName || null;
        this.lastName = init.lastName || null;
        this.roleDesc = init.roleDesc || null;
        this.adrSeqNbr = init.adrSeqNbr || null;
        this.cciCheckSum = init.cciCheckSum || null;
        this.cctCheckSum = init.cctCheckSum || null;
        this.cctTableKey = init.cctTableKey || null;
        this.drvLicenseNbr = init.drvLicenseNbr || null;
        this.roleRelCd = init.roleRelCd || null;
        this.bodilyInjuryDesc = init.bodilyInjuryDesc || null;
        this.entrySource = init.entrySource || null;
        this.extClientId = init.extClientId || null;
        this.processNotes = init.processNotes || new Array<string>();
        this.clientUpdated = init.clientUpdated === undefined ? null : init.clientUpdated;
        this.excluded = init.excluded === undefined ? null : init.excluded;
        this.clientId = init.clientId || null;
        this.physicalAddress = init.physicalAddress || new MailingAddress;
        this.contactList = init.contactList || new Array<ContactMechanism>();
    }
}
