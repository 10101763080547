import { NavigationExtras } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, share, startWith, tap } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { User } from '../models';
import { getUser, State } from '../store';
import { LoadUserDataAction } from '../store/actions/myprofile/myprofile.action';
import { RouterGo } from '../store/actions/router/router.action';
import { LogoutAction } from '../store/actions/store.actions';

export abstract class Sandbox {
    private readonly userStorage = 'currentUser';
    private readonly storageLocation = sessionStorage; // sessionStorage or localStorage;
    private marketingSite = environment.marketingSite;

    constructor(protected appState$: Store<State>) {}

    public readonly user$ = this.appState$.select(getUser);

    public isUserLoggedIn$ = this.user$.pipe(
        map(user => {
            if (!user || !user.custId) {
                const currentUser: User = JSON.parse(this.storageLocation.getItem(this.userStorage));
                if (!currentUser) {
                    return false;
                } else {
                    if (!currentUser.custId) {
                        return false;
                    } else {
                        this.appState$.dispatch(new LoadUserDataAction(currentUser.custId.toString()));
                        return false;
                    }
                }
            } else {
                this.storageLocation.setItem(this.userStorage, JSON.stringify(user));
                return true;
            }
        }),
        share()
    );

    public userLogOut() {
        this.storageLocation.removeItem(this.userStorage);
        this.storageLocation.clear();
        this.appState$.dispatch(new LogoutAction());
        // this.reroute({ path: ['/login'] });
        window.open(this.marketingSite, '_self');
    }

    public createConditionalObservable<D>(
        conditionObservable: Observable<boolean>,
        dataObservable: Observable<D>,
        action: Action
    ): Observable<D> {
        return combineLatest(
            conditionObservable.pipe(
                filter(loaded => !loaded && this.storageLocation.length > 0),
                tap(() => this.appState$.dispatch(action)),
                share(),
                startWith(null)
            ),
            dataObservable,
            (a, b) => b
        ).pipe(distinctUntilChanged());
    }

    public reroute(payload: { path: any[]; queryParams?: object; extras?: NavigationExtras }) {
        this.appState$.dispatch(new RouterGo(payload));
    }
}
