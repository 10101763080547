import { PolicyBase } from '../../models';

export class PaperlessItem {

    public status: string;
    public statusDesc: string;
    public badgeDesc: string;
    public paperlessRequest: string;
    public emailEditPrompt: string;
    public managePaperlessPrompt: string;
    public pendingEmail: string;
    public editingEmail: string;
    public editingEmailOiginal: string;
    public policy: PolicyBase;


    constructor(init: Partial<PaperlessItem> = {}) {

        ({
            status: this.status,
            statusDesc: this.statusDesc,
            badgeDesc: this.badgeDesc,
            paperlessRequest: this.paperlessRequest,
            emailEditPrompt: this.emailEditPrompt,
            managePaperlessPrompt: this.managePaperlessPrompt,
            pendingEmail: this.pendingEmail,
            editingEmail: this.editingEmail,
            editingEmailOiginal: this.editingEmailOiginal,
            policy: this.policy
        } = init);
    }
}
