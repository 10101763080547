import { Coverage } from '../auto/coverage.model';

export class Dwelling {

    public addr1: string;
    public city: string;
    public countyCd: string;
    public stateCd: string;
    public zip: string;
    public unitNbr: string;
    public mobileHomeLength: number;
    public mobileHomeWidth: number;
    public ownerOccupied: boolean;
    public coverages: Coverage[];

    public extendedCoverages: any[];
    public farmEquipment: any[];
    public barns: any[];
    public misc: any[];
    public mortgagees: any[];
    public deductibleAmt: number;
    public totalPremium: number;
	public forms: any[];
	public ghiForms: string[];

    constructor(init?: Partial<Dwelling>) {
        if (!init) { init = {}; }
        this.addr1 = init.addr1 || null;
        this.city = init.city || null;
        this.countyCd = init.countyCd || null;
        this.stateCd = init.stateCd || null;
        this.zip = init.zip || null;
        this.unitNbr = init.unitNbr || null;
        this.mobileHomeLength = init.mobileHomeLength || null;
        this.mobileHomeWidth = init.mobileHomeWidth || null;
        this.ownerOccupied = init.ownerOccupied || null;
        this.coverages = init.coverages || new Array<Coverage>();
        this.extendedCoverages = init.extendedCoverages || null;
        this.farmEquipment = init.farmEquipment || null;
        this.barns = init.barns || null;
        this.misc = init.misc || null;
        this.mortgagees = init.mortgagees || null;
        this.deductibleAmt = init.deductibleAmt || null;
        this.totalPremium = init.totalPremium || null;
        this.forms = init.forms || null;
        this.ghiForms = init.ghiForms || null;
    }
}