import { DeviceInfo } from './deviceInfo.model';
import { SecurityQuestions } from './securityQuestions.model';

export class User {
    public firstName?: string;
    public middleName?: string;
    public lastName?: string;
    public custId?: number;

    public phoneNumber?: string;
    public emailAddress?: string;
    public userId?: string;
    public password?: string;

    public questions?: SecurityQuestions[];
    public bind?: boolean;
    public id?: string;
    public status?: string;

    constructor(init?: Partial<User>) {
        if (!init) {
            init = {};
        }
        this.firstName = init.firstName || null;
        this.middleName = init.middleName || null;
        this.lastName = init.lastName || null;
        this.phoneNumber = init.phoneNumber || null;
        this.emailAddress = init.emailAddress || null;
        this.userId = init.userId || null;
        this.password = init.password || null;
        this.questions = init.questions || null;
        this.bind = init.bind || false;
        this.id = init.id || null;
        this.custId = init.custId || null;
        this.status = init.status || null;
    }
}
