import { ValidationMatch } from './validation-match.model';

export class ValidationResponse {
    public id: string;
    public matches: ValidationMatch[];

    constructor(init?: Partial<ValidationResponse>) {
        if (!init) {
            init = {};
        }
        this.id = init.id || null;
        this.matches = init.matches || null;
    }
}
