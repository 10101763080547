import { Component, Input, OnInit } from '@angular/core';
import { MailingAddress } from '../../models';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html'
})
export class AddressComponent implements OnInit {

    @Input() public address: MailingAddress;

    constructor() { }

    public ngOnInit() {
    }

}
