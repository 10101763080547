import { Injectable } from '@angular/core';
import { ClaimConstants } from '../../constants/claim.constants';
import {
    AutoClaim,
    AutoPolicy,
    ClaimsApiRequest,
    ClaimsApiRequestFactory,
    ClaimsApiResponse,
    ClaimsSupport,
    County,
    GenericClaim,
    PeopleInvolvedForm,
    Vendor,
    WaterClaim,
    WatercraftPolicy
} from '../../models';
import { CcDetail } from '../../models/claims/genericGWCC-claim.model';

@Injectable()
export class ClaimsService {
    private productsSubscription;

    /**
     * Transforms grid data products recieved from the API into array of 'Product' instances
     *
     * @param products
     */
    public static gridAdapter(products: any): ClaimsSupport[] {
        return products.map(product => new ClaimsSupport(product));
    }

    /**
     * Transforms product details recieved from the API into instance of 'Product'
     *
     * @param product
     */
    public static productDetailsAdapter(product: any): ClaimsSupport {
        return new ClaimsSupport(product);
    }

    /**
     * Transforms data recieved from the API into array of 'County' instances
     *
     * @param county
     */
    public static countyAdapter(counties: any): County[] {
        const result = counties.map(county => new County(county));
        result.sort(function compare(a, b) {
            if (a.countyDesc < b.countyDesc) {
                return -1;
            }
            if (a.countyDesc > b.countyDesc) {
                return 1;
            }
            return 0;
        });
        return result;
    }
    /**
     * Transforms data recieved from the API into array of 'County' instances
     *
     * @param vendor
     */
    public static vendorAdapter(vendors: any): Vendor[] {
        return vendors.map(vendor => new Vendor(vendor));
    }
    /**
     * Transforms data recieved from the API into array of 'County' instances
     *
     * @param location
     */
    public static glassDamageAdapter(locations: any): Vendor[] {

        let glassDamages = locations.filter(function (location){
            return location.code2 === "AUT";
          }
         )

        return locations;
    }

    /**
     * Transforms data recieved from the API into array of 'County' instances
     *
     * @param location
     */
    public static autoDamageAdapter(damagelocations: ClaimsSupport[]): ClaimsSupport[] {

        let autoDamages = damagelocations.filter(function (location){
           return location.code2 === "AUT";
         }
        )

        return autoDamages;

        
    }

     /**
     * Transforms data recieved from the API into array of 'County' instances
     *
     * @param location
     */
    public static boatDamageAdapter(damagelocations: ClaimsSupport[]): ClaimsSupport[] {

        let boatDamages = damagelocations.filter(function (location){
           return location.code2 === "WTR";
         }
        )

        return boatDamages;

        
    }
    /**
     * Transforms product details recieved from the API into instance of 'Product'
     *
     * @param product
     */
    public static apiResponseAdapter(resp: any): ClaimsApiResponse {
        return new ClaimsApiResponse(resp);
    }
    /**
     * Transforms data recieved from the API into array of 'GenericClaim' instances
     *
     * @param claim
     */
    public static claimHistoryAdapter(response: any): CcDetail[] {

       const x: CcDetail[]= response;

       x.forEach(y  => {
           y.policyTypeCode = ClaimConstants.translatePolicyCategory(y.policyTypeCode)           
       });
        
        return x;
    }

    /**
     * Transforms data recieved from the UI Form for vehicle and peopleInvolved
     *
     * @param claim
     */
    public getOperator(peopleInvolved: PeopleInvolvedForm, claimModel: AutoClaim): any {
        const vehicleSelected = (<WatercraftPolicy>claimModel.selectPolicy.policy).watercrafts.find(
            vehicle => vehicle.unitNbr === claimModel.selectPolicy.unitNbr
        );

        const waterDetail = claimModel.vehicleDetail;

        const vehicleInfo = ClaimsApiRequestFactory.createWatercraftDetailsRq(
            waterDetail,
            peopleInvolved,
            vehicleSelected.vinSerialNbr,
            claimModel.selectPolicy.unitNbr
        );

        return vehicleInfo;
    }

    /**
     * Transforms data recieved from the UI Form for vehicle and peopleInvolved
     *
     * @param claim
     */
    public getDriver(peopleInvolved: PeopleInvolvedForm, claimModel: AutoClaim): any {
        if (claimModel.selectPolicy.claimType === 'watercraft') {
            return this.getOperator(peopleInvolved, claimModel);
        }
        const vehicleSelected = (<AutoPolicy>claimModel.selectPolicy.policy).vehicles.find(
            vehicle => vehicle.unitNbr === claimModel.selectPolicy.unitNbr
        );

        const vehicleDetail = claimModel.vehicleDetail;

        const vehicleInfo = ClaimsApiRequestFactory.createAutoVehicleDetailsRq(
            vehicleDetail,
            peopleInvolved,
            vehicleSelected.vinSerialNbr,
            claimModel.selectPolicy.unitNbr
        );

        return vehicleInfo;
    }
    public static claimDetailAdapter(claim: ClaimsApiRequest): ClaimsApiRequest {
        const claimsDetails = new ClaimsApiRequest(claim);
        return claimsDetails;
    }
}
