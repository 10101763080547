export class AutoLienholderApiRequest {

	public name: string;
	public addr1: string;
	public addr2: string;
	public city: string;
	public stateCd: string;
	public zip: string;

    constructor(init?: Partial<AutoLienholderApiRequest>) {
        Object.assign(this, init);
    }
}
