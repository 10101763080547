export class DocumentProperties {
    public caption: string;
    public userSelDocType: string;

    constructor(init?: Partial<DocumentProperties>) {
        if (!init) {init = {}; }
        this.caption = init.caption || null;
        this.userSelDocType = init.userSelDocType || null;
    }
}
