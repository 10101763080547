import { createSelector } from '@ngrx/store';
import { State } from '../..';

import * as fromRouter from '../../reducers/router/router.reducer';

export const getRouterState = (state: State) => state.router;

export const routerDataLoading = createSelector(
  getRouterState,
  fromRouter.routerDataLoading
);
