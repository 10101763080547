import { DeviceInfo, User } from '../user';

export class ProfileRequest {
    public id: string;
    public user: User;
    public deviceInfo: DeviceInfo;

    constructor(init?: Partial<ProfileRequest>) {
        if (!init) {
            init = {};
        }
        this.id = init.id || null;
        this.user = init.user || null;
        this.deviceInfo = init.deviceInfo || null;
    }
}
