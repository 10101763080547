import { PolicyBase } from '../../models';

export class PaperlessUpdateInfo {

    public addPayload: {
        paperlessInfo: any[];
        url: string;
    };
    public updatePayload: {
        paperlessInfo: any[];
        url: string;
    };

    public cancelPayload: {
        policyNbr: string;
        systemId: string;
        sourceSystem: string;
    }[];

    constructor(init: Partial<PaperlessUpdateInfo> = {}) {
        ({
            addPayload: this.addPayload,
            updatePayload: this.updatePayload,
            cancelPayload: this.cancelPayload
        } = init);
    }
}
