import { Component, Input, OnInit } from '@angular/core';
import { PolicyBase } from '../../../shared/models';

@Component({
  selector: 'app-policy-summary',
  templateUrl: './policy-summary.component.html'
})
export class PolicySummaryComponent implements OnInit {

    @Input() public policy: PolicyBase;

    constructor() { }

    public ngOnInit() {
    }

}
