import { DocumentLocation } from '../../enums/document-location.enum';
import { DocumentAttachment } from './document-attachment.model';

export class Document {
    public category: String;
    public name: String;
    public location: DocumentLocation;
    public url: String;
    public firstName: String;
    public lastName: String;
    public policyNumber: String;
    public controlNumber: String;
    public typeCd: String;
    public typeDesc: String;
    public dateCreated: number;
    public dateModified: number;
    public properties: Map<String, Object>;
    public contentlist: DocumentAttachment[];

    constructor(init?: Partial<Document>) {
        if (!init) { init = {}; }
        this.category = init.category || null;
        this.name = init.name || null;
        this.location = init.location || null;
        this.url = init.url || null;
        this.firstName = init.firstName || null;
        this.lastName = init.lastName || null;
        this.policyNumber = init.policyNumber || null;
        this.controlNumber = init.controlNumber || null;
        this.typeCd = init.typeCd || null;
        this.typeDesc = init.typeDesc || null;
        this.dateCreated = init.dateCreated || null;
        this.dateModified = init.dateModified || null;
        this.properties = init.properties || new Map<String, Object>();
        this.contentlist = init.contentlist || new Array<DocumentAttachment>();
    }
}
