import { Vehicle } from '../../policy/auto/vehicle.model';
import { CoverageIncreaseDed } from './coverage-increase-ded';

export class VehicleIncreaseDed extends Vehicle {
    public allReachedMax: boolean;
    public deductiblesAreAvailable: boolean;
    public coverages: CoverageIncreaseDed[];

    constructor(init?: Partial<VehicleIncreaseDed>) {
        super(init);
        Object.assign(this, init);
    }
}
