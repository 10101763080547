export class ValidationRequest {
    public policyNbr: string;
    public zipCode: string;
    public ssn: string;
    public dob: string;

    constructor(init?: Partial<ValidationRequest>) {
        if (!init) {
            init = {};
        }
        this.policyNbr = init.policyNbr || null;
        this.zipCode = init.zipCode || null;
        this.ssn = init.ssn || null;
        this.dob = init.dob || null;
    }
}
