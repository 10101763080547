import { CancelReason, CoverageMaximums, CoverageOption, CoverageTypes, VehicleDeleteReason, VinLookupResults } from '../../../models';
import { Actions, ActionTypes } from '../../actions/auto/auto-support.action';

export interface AutoSupportState {
    isCancelReasonsLoaded: boolean;
    isCancelReasonsLoading: boolean;
    cancelReasons: CancelReason[];

    isVehicleDeleteReasonsLoaded: boolean;
    isVehicleDeleteReasonsLoading: boolean;
    vehicleDeleteReasons: VehicleDeleteReason[];

    vinLookupResults: VinLookupResults;
    vinLookupFailed: boolean;
    isVinLookupLoading: boolean;
    vehicleTypes: any[];
    isVehicleTypesLoading: boolean;
    vehicleYears: any[];
    isVehicleYearsLoading: boolean;
    vehicleMakes: any[];
    isVehicleMakesLoading: boolean;
    vehicleModels: any[];
    isVehicleModelsLoading: boolean;
    coverages: CoverageOption[];
    isCoveragesLoading: boolean;
    coverageList: CoverageTypes;
    coverageListMax: CoverageMaximums;
    milesDrivenOptions: any[];
    isMilesDrivenLoading: boolean;
    error: any;
}

const INITIAL_STATE: AutoSupportState = {
    isCancelReasonsLoaded: false,
    isCancelReasonsLoading: false,
    cancelReasons: null,

    isVehicleDeleteReasonsLoaded: false,
    isVehicleDeleteReasonsLoading: false,
    vehicleDeleteReasons: null,

    vinLookupResults: null,
    vinLookupFailed: null,
    isVinLookupLoading: false,
    vehicleTypes: null,
    isVehicleTypesLoading: false,
    vehicleYears: null,
    isVehicleYearsLoading: false,
    vehicleMakes: null,
    isVehicleMakesLoading: false,
    vehicleModels: null,
    isVehicleModelsLoading: false,
    coverages: null,
    isCoveragesLoading: false,
    coverageList: null,
    coverageListMax: null,
    milesDrivenOptions: null,
    isMilesDrivenLoading: null,
    error: null
};

export function autoSupportReducer(state = INITIAL_STATE, action: Actions): AutoSupportState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case ActionTypes.GET_CANCEL_REASONS: {
            return { ...state, isCancelReasonsLoading: true };
        }

        case ActionTypes.GET_CANCEL_REASONS_SUCCESS: {
            return { ...state, isCancelReasonsLoaded: true, isCancelReasonsLoading: false, cancelReasons: action.payload };
        }

        case ActionTypes.GET_CANCEL_REASONS_FAIL: {
            return { ...state, isCancelReasonsLoaded: false, isCancelReasonsLoading: false, error: action.payload.error };
        }

        case ActionTypes.GET_DELETE_VEHICLE_REASONS: {
            return { ...state, isVehicleDeleteReasonsLoading: true };
        }

        case ActionTypes.GET_DELETE_VEHICLE_REASONS_SUCCESS: {
            return {
                ...state,
                isVehicleDeleteReasonsLoaded: true,
                isVehicleDeleteReasonsLoading: false,
                vehicleDeleteReasons: action.payload
            };
        }

        case ActionTypes.GET_DELETE_VEHICLE_REASONS_FAIL: {
            return { ...state, isVehicleDeleteReasonsLoaded: false, isVehicleDeleteReasonsLoading: false, error: action.payload.error };
        }

        case ActionTypes.GET_VEHICLE_TYPES: {
            return { ...state, isVehicleTypesLoading: true };
        }

        case ActionTypes.GET_VEHICLE_TYPES_SUCCESS: {
            return { ...state, isVehicleTypesLoading: false, vehicleTypes: action.payload };
        }

        case ActionTypes.GET_VEHICLE_TYPES_FAIL: {
            return { ...state, isVehicleTypesLoading: false, error: action.payload.error };
        }

        case ActionTypes.GET_VEHICLE_YEARS: {
            return { ...state, isVehicleYearsLoading: true };
        }

        case ActionTypes.GET_VEHICLE_YEARS_SUCCESS: {
            return { ...state, isVehicleYearsLoading: false, vehicleYears: action.payload };
        }

        case ActionTypes.GET_VEHICLE_YEARS_FAIL: {
            return { ...state, isVehicleYearsLoading: false, error: action.payload.error };
        }

        case ActionTypes.GET_VEHICLE_MAKES: {
            return { ...state, isVehicleMakesLoading: true };
        }

        case ActionTypes.GET_VEHICLE_MAKES_SUCCESS: {
            return { ...state, isVehicleMakesLoading: false, vehicleMakes: action.payload };
        }

        case ActionTypes.GET_VEHICLE_MAKES_FAIL: {
            return { ...state, isVehicleMakesLoading: false };
        }

        case ActionTypes.GET_VEHICLE_MODELS: {
            return { ...state, isVehicleModelsLoading: true };
        }

        case ActionTypes.GET_VEHICLE_MODELS_SUCCESS: {
            return { ...state, isVehicleModelsLoading: false, vehicleModels: action.payload };
        }

        case ActionTypes.GET_VEHICLE_MODELS_FAIL: {
            return { ...state, isVehicleModelsLoading: false };
        }

        case ActionTypes.GET_COVERAGES: {
            return { ...state, isCoveragesLoading: true };
        }

        case ActionTypes.GET_COVERAGES_SUCCESS: {
            return {
                ...state,
                isCoveragesLoading: false,
                coverages: action.payload.coverages,
                coverageList: action.payload.coverageList,
                coverageListMax: action.payload.coverageListMax
            };
        }

        case ActionTypes.GET_COVERAGES_FAIL: {
            return { ...state, isCoveragesLoading: false, error: action.payload };
        }
        case ActionTypes.LOOKUP_VIN: {
            return { ...state, isVinLookupLoading: true };
        }

        case ActionTypes.LOOKUP_VIN_SUCCESS: {
            return {
                ...state,
                isVinLookupLoading: false,
                vinLookupFailed: false,
                vinLookupResults: new VinLookupResults({
                    vehicleType: action.payload.typeCd,
                    vehicleTypeDesc: state.vehicleTypes.find(t => t.vehTypeCd === action.payload.typeCd).vehTypeDesc,
                    vehicleYear: action.payload.year,
                    vehicleMake: action.payload.makeCd,
                    vehicleModel: action.payload.modelCd,
                    vehicleMakeDesc: action.payload.makeDesc,
                    vehicleModelDesc: action.payload.modelDesc
                })
            };
        }

        case ActionTypes.LOOKUP_VIN_FAIL: {
            return { ...state, isVinLookupLoading: false, error: action.payload.error, vinLookupFailed: true };
        }

        case ActionTypes.GET_MILES_DRIVEN: {
            return { ...state, isMilesDrivenLoading: true };
        }

        case ActionTypes.GET_MILES_DRIVEN_SUCCESS: {
            return { ...state, isMilesDrivenLoading: false, milesDrivenOptions: action.payload };
        }

        case ActionTypes.GET_MILES_DRIVEN_FAIL: {
            return { ...state, isMilesDrivenLoading: false, error: action.payload.error };
        }

        default: {
            return state;
        }
    }
}
export const getAutoCancelReasons = (state: AutoSupportState) => state.cancelReasons;
export const getAutoCancelReasonsLoading = (state: AutoSupportState) => state.isCancelReasonsLoading;
export const getAutoCancelReasonsLoaded = (state: AutoSupportState) => state.isCancelReasonsLoaded;

export const getAutoSupportLoading = (state: AutoSupportState) =>
    state.isCancelReasonsLoading ||
    state.isVinLookupLoading ||
    state.isVehicleTypesLoading ||
    state.isVehicleYearsLoading ||
    state.isVehicleMakesLoading ||
    state.isVehicleModelsLoading ||
    state.isCoveragesLoading ||
    state.isMilesDrivenLoading;
