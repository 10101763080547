export class TcParameters {
    // TSLint rule disbaled because of required variable names.
    // tslint:disable:variable-name
    public trxcustid: string;
    public bcc: string;
    public NACHA_terms: string;
    public checking_ach: string;
    public pre_auth: string;
    public storeonly: string;
    public bcustid: string;
    public show_email: string;
    public 'customfield[4]': string;
    public 'customfield[2]': string;
    public 'customfield[1]': string;
    public CreditCard_saveLater: string;
    public ticket_no: string;
    public CC_terms: string;
    public baddress: string;
    public postal: string;
    public is_redirect: string;
    public amountequalorless: string;
    public avs: string;
    public response_url: string;
    // tslint:disable:variable-name
}
