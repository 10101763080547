
export class BillingActivity {
    public activityDate: string;
    public activityCd: string;
    public reasonDesc: string;
    public reasonType: string;
    public isLife: boolean;
    public date: string;
    public amount: number;

    constructor(init?: Partial<BillingActivity>) {
        if (!init) { init = {}; }
        ({
            activityDate: this.activityDate,
            activityCd: this.activityCd,
            reasonDesc: this.reasonDesc,
            reasonType: this.reasonType,
            isLife: this.isLife,
            date: this.date,
            amount: this.amount
        } = init);
    }
}
