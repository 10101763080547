
export class ErsDetailsForm {
    public incidentDetails:    string;
    public serviceBillAmount:  string;
    public serviceCompany:     string;

    constructor(init?: Partial<ErsDetailsForm>) {
      if (!init) {init = {}; }
      this.incidentDetails = init.incidentDetails || null;
      this.serviceBillAmount = init.serviceBillAmount || null;
      this.serviceCompany = init.serviceCompany || null;

    }
  }
