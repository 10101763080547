export class UnitDamage {
    public dmgLocationCd: string;
    public dmgLocationDesc: string;

    constructor(init?: Partial<UnitDamage>) {
        if (!init) { init = {}; }
        this.dmgLocationCd = init.dmgLocationCd || null;
        this.dmgLocationDesc = init.dmgLocationDesc || null;
    }
}
