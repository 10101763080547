export class ObjectCleaner {
    public static removeUnneededKeys<T>(obj: T): T {
        if (obj instanceof Array) {
            return <any>this.arrayLoop(obj);
        } else if (obj instanceof Map) {
            return <any>this.mapLoop(obj);
        } else if (typeof obj === 'object') {
            return this.objectLoop(obj);
        } else {
            return obj;
        }
    }

    private static arrayLoop<T> (arr: T[]): T[] {
        return arr
                .map(item => this.removeUnneededKeys(item))
                .filter(item => this.isNeeded(item));
    }

    private static objectLoop<U>(obj: U): U {
        return Object.keys(obj)
        .filter(key => this.isNeeded(obj[key]))
          .reduce((newObj, key) => {
            const tempObj = this.removeUnneededKeys(obj[key]);
            if (this.isNeeded(tempObj)) {
                return Object.assign(newObj, {[key]: tempObj});  // Recurse.
            } else {
                return newObj;
            }
          },
            <U>{}
          );
    }

    private static mapLoop<V, W>(mapObj: Map<V, W>) {
        const newMap = new Map<V, W>();
        mapObj.forEach((value, key) => {
            const tempObj = this.removeUnneededKeys(value);
            if (this.isNeeded(tempObj)) {
                newMap.set(key, value);
            }
        });
        return newMap;
    }

    private static isNeeded(obj: any): boolean {
        if (
            obj === undefined ||
            obj === null ||
            obj === {} ||
            obj === [] ||
            ( obj instanceof Array && obj.length === 0) ||
            ( obj instanceof Map && obj.size) === 0 ||
            ( typeof obj === 'object' && Object.keys(obj).length === 0)
            ) {
                return false;
            } else {
                return true;
            }
    }
}
