import { ActionReducerMap } from '@ngrx/store';

import * as fromEffects from './effects';
import * as fromMetaReducers from './metareducers';
import * as fromReducers from './reducers';

export interface State {
    claimsFile: fromReducers.ClaimsFileState;
    claimsSupport: fromReducers.ClaimsSupportState;
    policy: fromReducers.PolicyState;
    autoChange: fromReducers.AutoChangeState;
    autoSupport: fromReducers.AutoSupportState;
    client: fromReducers.ClientState;
    user: fromReducers.UserState;
    forgot: fromReducers.ForgotState;
    contactUs: fromReducers.ContactUsState;
    router: fromReducers.RouterState;
    myprofile: fromReducers.MyProfileState;
    register: fromReducers.RegisterState;
    paperless: fromReducers.PaperlessState;
    layout: fromReducers.LayoutState;
    payMyBill: fromReducers.PayMyBillState;
}

export const reducers: ActionReducerMap<State> = {
    claimsFile: fromReducers.claimsFileReducer,
    claimsSupport: fromReducers.claimsSupportReducer,
    policy: fromReducers.policyReducer,
    autoChange: fromReducers.autoChangeReducer,
    autoSupport: fromReducers.autoSupportReducer,
    client: fromReducers.clientReducer,
    user: fromReducers.userReducer,
    forgot: fromReducers.forgotReducer,
    contactUs: fromReducers.contactUsReducer,
    router: fromReducers.routerReducer,
    myprofile: fromReducers.myProfileReducer,
    register: fromReducers.registerReducer,
    paperless: fromReducers.paperlessReducer,
    layout: fromReducers.layoutReducer,
    payMyBill: fromReducers.payMyBillReducer
};

export const metareducers = [fromMetaReducers.clearState];

export const effects = [
    fromEffects.ClaimsFileEffects,
    fromEffects.ClaimsSupportEffects,
    fromEffects.PolicyEffects,
    fromEffects.AutoChangeEffects,
    fromEffects.AutoSupportEffects,
    fromEffects.ClientEffects,
    fromEffects.UserEffects,
    fromEffects.RouterEffects,
    fromEffects.MyProfileEffects,
    fromEffects.RegisterEffects,
    fromEffects.PaperlessEffects,
    fromEffects.PayMyBillEffects
];

export * from './selectors';
