export class VehicleDetailsForm {
    public vehicleColor:    string;
    public driveableInd:    boolean;
    public airBagsDeploy:   boolean;
    public rentalNeeded:    boolean;
    public towingLocation:  string;
    public towingCompany:   string;
    public damageAreas:     string[];

    constructor(init?: Partial<VehicleDetailsForm>) {
        if (!init) { init = {}; }
        this.vehicleColor = init.vehicleColor   || null;
        this.driveableInd = init.driveableInd   || false;
        this.airBagsDeploy = init.airBagsDeploy || false;
        this.rentalNeeded = init.rentalNeeded   || false;
        this.towingLocation = init.towingLocation || null;
        this.towingCompany = init.towingCompany  || null;
        this.damageAreas = init.damageAreas || new Array<string>();
    }
  }
