import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorMsgService } from '../../../../shared/utility/error-msg.service';
import { PayMyBillApiClient } from '../../../asyncServices/api-clients/pay-my-bill-apiclient.service';
import {
    ActionTypes,
    PolicySearchAction,
    PolicySearchFailAction,
    PolicySearchSuccessAction,
    TcpPostPaymentAction
} from '../../actions/pay-my-bill/pay-my-bill.action';

@Injectable()
export class PayMyBillEffects {
    constructor(
        private actions$: Actions,
        private payMyBillApiClient: PayMyBillApiClient,
        private errorMsgService: ErrorMsgService
    ) {}

    @Effect()
    public policySearch$: Observable<Action> = this.actions$.ofType(ActionTypes.POLICY_SEARCH).pipe(
        map((action: PolicySearchAction) => action.payload),
        switchMap(payload => {
                payload.policyNumber = (payload.policyNumber === null || payload.policyNumber === undefined) ? null : payload.policyNumber.trim().toUpperCase();
            return this.payMyBillApiClient.getDueAmounts(payload.policyNumber, payload.systemId, payload.zipcode).pipe(
                map(apiResponse => {
                    return new PolicySearchSuccessAction(apiResponse);
                }),
                catchError(error => of(new PolicySearchFailAction(this.errorMsgService.getMsg(error))))
            );
        })
    );

    @Effect({dispatch: false})
    public tcpPayment$: Observable<void> = this.actions$.ofType(ActionTypes.TCP_POST_PAYMENT).pipe(
        map((action: TcpPostPaymentAction) => action.payload),
        switchMap(payload => {
            return this.payMyBillApiClient.postTcpPayment(payload).pipe(
                map(() => {
                    console.log('Payment Post Success');
                }),
                catchError(error => of(console.error('Payment Post Failed')))
            );
        })
    );
}
