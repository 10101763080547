import { DeviceInfo, SecurityQuestions, User } from '../user';
import { SecurityQuestionsGroup } from './security-questions-group.model';

export class ProfileResponse {
    public deviceInfo: DeviceInfo;
    public questionsGroups?: SecurityQuestionsGroup[];
    public status: string;
    public id: string;

    constructor(init?: Partial<ProfileResponse>) {
        if (!init) {
            init = {};
        }
        this.deviceInfo = init.deviceInfo || null;
        this.questionsGroups = init.questionsGroups || null;
        this.status = init.status || null;
        this.id = init.id || null;
    }
}
