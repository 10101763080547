import { SecurityQuestions } from '../user';

export class ChangeSecurityQuestionsForm {
    public questionGroups: QuestionsGroup[];
}

class QuestionsGroup {
    public answer: string;
    public selectedQuestion: string;
    public questions: SecurityQuestions[];
}
