export class MailingAddress {
    public addr1: string;
    public addr2: string;
    public adrSeqNbr: string;
    public city: string;
    public countyCd: string;
    public clientId: string;
    public stateCd: string;
    public zip: string;

    constructor(init?: Partial<MailingAddress>) {
        if (!init) { init = {}; }
        this.addr1 = init.addr1 || null;
        this.adrSeqNbr = init.adrSeqNbr || null;
        this.city = init.city || null;
        this.countyCd = init.countyCd || null;
        this.clientId = init.clientId || null;
        this.stateCd = init.stateCd || null;
        this.zip = init.zip || null;
    }
}
