export class PersonalInfo {
    public firstName:   string;
    public lastName:    string;
    public email:       string;
    public phone:       string;
    public address:     string;
    public zipcode:     string;
    public city:        string;
    public state:       string;
    public licenseNbr:  string;

    constructor(init?: Partial<PersonalInfo>) {
        if (!init) { init = {}; }
        this.firstName = init.firstName || null;
        this.lastName = init.lastName || null;
        this.email = init.email || null;
        this.phone = init.phone || null;
        this.address = init.address || null;
        this.zipcode = init.zipcode || null;
        this.city = init.city || null;
        this.state = init.state || null;
        this.licenseNbr = init.licenseNbr || null;
    }
  }
