import { DeviceInfo } from './deviceInfo.model';
import { User } from './user.model';

export class MyAccount {
  public user?: User;
  public deviceInfo: DeviceInfo;

  constructor(init?: Partial<MyAccount>) {
    if (!init) {
      init = {};
    }
    this.user = init.user || null;
    this.deviceInfo = init.deviceInfo || null;
  }
}
