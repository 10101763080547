export class PolicySearchForm {
    public systemId: string;
    public policyNumber: string;
    public zipcode: string;

    constructor(init?: Partial<PolicySearchForm>) {
        this.policyNumber = init.policyNumber || null;
        this.systemId = init.systemId || null;
        this.zipcode = init.zipcode || null;
    }
}
