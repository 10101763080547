import { Coverage } from '../auto/coverage.model';
import { Driver, LossPayee } from '../base';

export class Watercraft {
    public makeCd: string;
    public makeDesc: string;
    public modelCd: string;
    public modelDesc: string;
    public totalPremium: number;
    public typeCd: string;
    public typeDesc: string;
    public unitNbr: string;
    public useCd: string;
    public useDesc: string;
    public vinSerialNbr: string;
    public year: string;
    public driverNbr: string;
    public coverages: Coverage[];
    public driver: Driver;
    public losspayee: LossPayee;
    public lessor: any;

    constructor(init?: Partial<Watercraft>) {
        if (!init) {
            init = {};
        }
        this.makeCd = init.makeCd || null;
        this.makeDesc = init.makeDesc || null;
        this.modelCd = init.modelCd || null;
        this.modelDesc = init.modelDesc || null;
        this.totalPremium = init.totalPremium || null;
        this.typeCd = init.typeCd || null;
        this.typeDesc = init.typeDesc || null;
        this.unitNbr = init.unitNbr || null;
        this.useCd = init.useCd || null;
        this.useDesc = init.useDesc || null;
        this.vinSerialNbr = init.vinSerialNbr || null;
        this.year = init.year || null;
        this.driverNbr = init.driverNbr || null;
        this.coverages = init.coverages || new Array<Coverage>();
        this.lessor = init.lessor || null;
    }
}
