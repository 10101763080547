import { ContactMechanism } from '../../claims/contact-mechanism.model';
import { MailingAddress } from './mailingAddress.model';

export class Insured {
    public clientId: string;
    public contactList: ContactMechanism[];
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public suffixDesc: string;
    public mailingAddress: MailingAddress;

    constructor(init?: Partial<Insured>) {
        if (!init) { init = {}; }
        this.clientId = init.clientId || null;
        this.contactList = init.contactList || new Array<ContactMechanism>();
        this.firstName = init.firstName || null;
        this.middleName = init.middleName || null;
        this.lastName = init.lastName || null;
        this.mailingAddress = init.mailingAddress || null;
    }
}
