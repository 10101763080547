import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivationEnd,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs/operators';
import { State } from '../..';
import {
  ActionTypes,
  RouteChange,
  RouteLazyLoadEnd,
  RouteLazyLoadStart,
  RouterGo
} from '../../actions/router/router.action';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private store: Store<State>
  ) {
    this.listenToRouter();
  }

  @Effect({ dispatch: false })
  public navigate$ = this.actions$.pipe(
    ofType(ActionTypes.GO),
    map((action: RouterGo) => action.payload),
    tap(({ path, queryParams, extras }) =>
      this.router.navigate(path, { queryParams, ...extras })
    )
  );

  @Effect({ dispatch: false })
  public navigateBack$ = this.actions$.pipe(
    ofType(ActionTypes.BACK),
    tap(() => this.location.back())
  );

  @Effect({ dispatch: false })
  public navigateForward$ = this.actions$.pipe(
    ofType(ActionTypes.FORWARD),
    tap(() => this.location.forward())
  );

  private listenToRouter() {
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof ActivationEnd ||
            event instanceof RouteConfigLoadStart ||
            event instanceof RouteConfigLoadEnd
        )
      )
      .subscribe(event => {
        switch (event.constructor) {
          case ActivationEnd:
            this.store.dispatch(
              new RouteChange({
                params: { ...(<ActivationEnd>event).snapshot.params },
                path: (<ActivationEnd>event).snapshot.routeConfig.path
              })
            );
            break;
          case RouteConfigLoadStart:
            this.store.dispatch(new RouteLazyLoadStart());
            break;
          case RouteConfigLoadEnd:
            this.store.dispatch(new RouteLazyLoadEnd());
            break;

          default:
            break;
        }
      });
  }
}
