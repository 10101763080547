import { Action } from '@ngrx/store';
import { PaperlessVerification } from '../../../../shared/models';
import { PaperlessUpdateInfo } from '../../../../shared/models/myprofile/paperless-update-info.model';
import { type } from '../../../utility';

// tslint:disable-next-line:variable-name
export const ActionTypes = {
    UPDATE_PAPERLESS: type('[Paperless] Update paperless requests'),
    UPDATE_PAPERLESS_SUCCESS: type('[Paperless] Updated paperless requests sucessfully'),
    UPDATE_PAPERLESS_FAIL: type('[Paperless] Failed to update paperless requests'),
    SEND_PAPERLESS_VERIFICATIONS: type('[Paperless] Send paperless verifications'),
    SEND_PAPERLESS_VERIFICATIONS_SUCESS: type('[Paperless] Sent paperless verifications sucessfully'),
    SEND_PAPERLESS_VERIFICATIONS_FAIL: type('[Paperless] Failed to send paperless verifications'),
    SEND_PAPERLESS_VERIFICATIONS_CLEAR_ACK: type('[Paperless] Clear the paperlessVerificationAckNeeded bit'),
    VERIFY_PAPERLESS: type('[Paperless] Verify Paperless Request'),
    VERIFY_PAPERLESS_SUCCESS: type('[Paperless] Verify Paperless Request Success'),
    VERIFY_PAPERLESS_FAIL: type('[Paperless] Verify Paperless Request Fail')
};

export class UpdatePaperlessAction implements Action {
    public type = ActionTypes.UPDATE_PAPERLESS;
    constructor(public payload: PaperlessUpdateInfo
        = null) { }
}

export class UpdatePaperlessSuccessAction implements Action {
    public type = ActionTypes.UPDATE_PAPERLESS_SUCCESS;
    constructor(public payload: any
        = null) { }
}

export class UpdatePaperlessFailAction implements Action {
    public type = ActionTypes.UPDATE_PAPERLESS_FAIL;
    constructor(public payload: any
        = null) { }
}

export class SendPaperlessVerificationsAction implements Action {
    public type = ActionTypes.SEND_PAPERLESS_VERIFICATIONS;
    constructor(public payload: PaperlessVerification[]
        = null) { }
}

export class SendPaperlessVerificationsSuccessAction implements Action {
    public type = ActionTypes.SEND_PAPERLESS_VERIFICATIONS_SUCESS;
    constructor(public payload: PaperlessVerification[]
        = null) { }
}

export class SendPaperlessVerificationsFailAction implements Action {
    public type = ActionTypes.SEND_PAPERLESS_VERIFICATIONS_FAIL;
    constructor(public payload: any
        = null) { }
}

export class SendPaperlessVerificationsClearAck implements Action {
    public type = ActionTypes.SEND_PAPERLESS_VERIFICATIONS_CLEAR_ACK;
    constructor(public payload: any
        = null) { }
}


export class VerifyPaperlessAction implements Action {
    public type = ActionTypes.VERIFY_PAPERLESS;
    constructor(public payload: {id: string, source: string, policy: string, date: string}) { }
}

export class VerifyPaperlessSuccessAction implements Action {
    public type = ActionTypes.VERIFY_PAPERLESS_SUCCESS;
    constructor(public payload: any = null) { }
}

export class VerifyPaperlessFailAction implements Action {
    public type = ActionTypes.VERIFY_PAPERLESS_FAIL;
    constructor(public payload: any = null) { }
}

export type PaperlessActions
    = UpdatePaperlessAction
    | UpdatePaperlessSuccessAction
    | UpdatePaperlessFailAction
    | SendPaperlessVerificationsAction
    | SendPaperlessVerificationsSuccessAction
    | SendPaperlessVerificationsFailAction
    | VerifyPaperlessAction
    | VerifyPaperlessSuccessAction
    | VerifyPaperlessFailAction
    | SendPaperlessVerificationsClearAck
    ;
