// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // api: {
    //     baseUrl: 'https://dev.fbitn.com/api-non-secure/',
    //     // baseUrl: 'https://modelproxy.fbitn.com/api-non-secure/',
    //     withCredentials: false
    // },
    api: {
        baseUrl: 'https://dev.fbitn.com/dev1/api/',
        withCredentials: false
    },
    claimCC:{
        baseUrl: 'https://intdv.tfbf.com/',
        withCredentials: true,
        supportUrl:'rest/claims/support/'
    },
    paymentUrl: 'https://stagepremier.trustcommerce.com/trustcommerce_payment/index.php',
    useOldPayUrl: false,
    oldPaymentUrl:
        'https://paymentstfic.billmatrix.com/welcome.aspx?' +
        'Policy_Type=##policyType##&Policy_Number=##policyNumber##&Zip_Code=##zipCode##',
    azureAD: false,
    marketingSite: 'https://is.tfic.com/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
