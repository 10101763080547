export class ResendEmail {
    public id: string;
    public url: string;

    constructor(init?: Partial<ResendEmail>) {
        if (!init) {
            init = {};
        }
        this.id = init.id || null;
        this.url = init.url || null;
    }
}
